import { convertCompilerOptionsFromJson } from "typescript";
import { Menu_Tree } from "../generated/graphql";


export interface UiTreeItem {
    key: string;
    value: string;
    title: string;
    children: UiTreeItem[];
}

export function convertToTree(list: Pick<Menu_Tree, "id" | "title" | "parent_id">[]) : UiTreeItem[] {
    const map : Map<string, UiTreeItem> = new Map();

    let threeDataLoaded : UiTreeItem[] = [];

    list.forEach(item => {
        let node : UiTreeItem = {
            key: item.id,
            value: item.id,
            title: item.title,
            children: []
        };
        map.set(item.id, node);
    });

    list.forEach(item => {
        const row = map.get(item.id);
        if (!row) {
            return;
        }
        if (item.parent_id) {
            let parent = map.get(item.parent_id);
            if (parent) {
                parent.children.push(row);
            }
        } else {
            threeDataLoaded.push(row);
        }
    });

    return threeDataLoaded;
}

