import { PlusCircleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd'
import React from 'react'
import { Redirect, useParams } from 'react-router-dom';
import { OrderOverview } from './overview';
import { SessionPaymentsTsx } from './payments';
const { TabPane } = Tabs;

interface UrlParams {
    sessionId: string;
}

export function OrderSession() {
    const {sessionId} = useParams<UrlParams>();

    return (
        <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            <TabPane tab="Overview" key="1">
                <OrderOverview />
            </TabPane>
            <TabPane tab="Payments" key="2">
                <SessionPaymentsTsx />
            </TabPane>
            <TabPane tab={(
                <div>New Items <PlusCircleOutlined /></div>
            )} key="3">
                <Redirect to={`/orders/session/${sessionId}/new`} />
            </TabPane>
        </Tabs>
    );
}