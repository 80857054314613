import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Select, Radio } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { GetMenuProducersQuery } from '../../../generated/graphql';

type MenuProducer = GetMenuProducersQuery["menu_producer"][number];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: MenuProducer;
  index: number;
  children: React.ReactNode;
}


interface EditableTableProps {
    loading: boolean;
    data?: MenuProducer[];
    onRowUpdated: (id: string, user: Partial<MenuProducer>) => Promise<void>;
    deleteRow: (id: string) => Promise<void>;
}

export const EditableTable = (props: EditableTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: MenuProducer) => record.id === editingKey;

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  
    if (dataIndex === 'colors') {

      const colors = [
        {color: "#34495e"},
        {color: "#e74c3c"},
        {color: "#16a085"},
      ]
      inputNode = (
        <Radio.Group>
          {colors.map(color => (
            <Radio.Button
                key={color.color}
              style={{backgroundColor: color.color}} 
              value={color.color}></Radio.Button>
          ))}
        </Radio.Group>
      )
    }
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  

  const edit = (record: Partial<MenuProducer>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as MenuProducer;
       await props.onRowUpdated(key, row);
       setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: true,
    },
    {
      title: 'colors',
      dataIndex: 'colors',
      key: 'colors',
      editable: true,
      render: (color: string) => (
        <Radio.Button
          style={{backgroundColor: color}} 
          ></Radio.Button>
      )
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        render: (_: any, record: MenuProducer) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
              <>
                  <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                      Edit
                  </Typography.Link>

  
                   <div></div>
  
                   <Popconfirm title="Sure to delete?" onConfirm={() => props.deleteRow(record.id)}>
                      <a>Delete</a>
                   </Popconfirm>
              </>
  
          );
        },
      }

  ];


  const mergedColumns = columns.map(col => {
    if (!col.editable) {
        return col;
    }
    return {
      ...col,
      onCell: (record: MenuProducer) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowKey={"id"}
        loading={props.loading}
        bordered
        dataSource={props.data}
        columns={mergedColumns}
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};