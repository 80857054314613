import { SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, DatePicker, Empty, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CurrencyPrice } from '../../../components/currency/price';
import { GetSessionHistoryQuery, useGetSessionHistoryQuery, Timestamptz_Comparison_Exp} from '../../../generated/graphql';
import { pennyToPrice } from '../../../utils/price';

const { RangePicker } = DatePicker;

type RowType = GetSessionHistoryQuery["sessions"][number];

export function OrderHistory() {

    const [variables, setVariables] = useState<{
        offset: number, 
        createdAtFilter: Timestamptz_Comparison_Exp,
    }>({
        offset: 0,
        createdAtFilter: {},
    });
    const { data , loading } = useGetSessionHistoryQuery({
        fetchPolicy: 'network-only',
        variables,
    });

    const history = useHistory();

    const columns : ColumnsType<RowType> = [
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        // },
        {
            title: 'Type',
            render: (_: void, row: RowType) => <Tag>table: {row.table.name}</Tag>,
        },
        {
            title: 'Paid',
            render: (_: void, row: RowType) => {

                const diff = row.total - row.total_paid;

                if (diff <= 0) {
                    return (<Tag color="green"><CurrencyPrice price={row.total} /></Tag>)
                }

                return (<Tag color="orange"><CurrencyPrice price={row.total_paid} hideCurrency />/<CurrencyPrice price={row.total} /></Tag>)
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (d: string) => <span>{(new Date(d)).toLocaleString()}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                 <RangePicker 
                    // style={{ marginBottom: 8, display: 'block' }}
                    onChange={(values) => {
                        console.log(values);
                        if (values) {
                            setSelectedKeys(values as any);
                        }
                    }}
                    showTime={true} />
                  {/* <Input
                    placeholder={`Search Menu name`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm({closeDropdown: true})}
                    style={{ marginBottom: 8, display: 'block' }}
                  /> */}
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => confirm({closeDropdown: true})}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Search
                    </Button>
                    <Button onClick={() => {
                      setSelectedKeys([]);
                      confirm({closeDropdown: true})
                    }} size="small" style={{ width: 90 }}>
                      Reset
                    </Button>
                  </Space>
                </div>
              ),
        },
    ];
    
    return (
        <>
    <ConfigProvider
      renderEmpty={() => <Empty description="No orders closed yet"/>}
      >
             <Table
                    loading={loading}
                    columns={columns}
                    rowKey="id"
                    dataSource={data?.sessions || []}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            history.push(`/orders/session/${record.id}`)
                          },
                        };
                    }}
                    onChange={(pagination, filter) => {
                        console.log({
                            pagination,
                            filter,

                        })

                        let createdAtFilter : Timestamptz_Comparison_Exp = {};

                        if (filter["created_at"]) {
                            const [from, to] = filter["created_at"] as unknown as [moment.Moment, moment.Moment];
                            console.log({
                                from,
                                to
                            })

                            createdAtFilter._gte = from;
                            createdAtFilter._lte = to;
                        }

                        // if (filter[])

                        setVariables({
                            offset: ((pagination.current || 1) - 1) * 10,
                            createdAtFilter,
                        })
                    }}
                    pagination={{
                        total: data?.sessions_aggregate.aggregate?.count // total count returned from backend
                    }}

              />
         </ConfigProvider>
        </>
    );
}

