import { Button, Checkbox, Form, Input, InputNumber, message, Select, TreeSelect } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { CurrencySymbol } from '../../../../components/currency/price';
import { ImageFormInput } from '../../../../components/forms/imageFormInput';
import { LoadMenuDetailQuery, LoadMenuItemDetailQuery, Menu_Item_Insert_Input, useUpsertMenuItemMutation, useUpsertMenuItemWithoutPriceMutation } from '../../../../generated/graphql';
import { pennyToPrice } from '../../../../utils/price';
import { convertToTree } from '../../../../utils/tree';

const {Option, OptGroup} = Select;

type MenuItem = LoadMenuItemDetailQuery["item"];
type FoodLabels = LoadMenuItemDetailQuery["labels"]
type MenuTrees = LoadMenuItemDetailQuery["menuTrees"]


interface MenuItemFormComponentProps {
    item: MenuItem;
    labels: FoodLabels;
    menuTrees: MenuTrees;
    itemId: string;
}

export function MenuItemFormComponent({item, labels, menuTrees, itemId}: MenuItemFormComponentProps) {
    const [addItem] = useUpsertMenuItemMutation();
    const [updateItemSamePrice] = useUpsertMenuItemWithoutPriceMutation();
    const { t } = useTranslation('menu');
    const tree = convertToTree(menuTrees || []);

    function getLabelSelect(labels: FoodLabels = []) {
        const reduced = labels.reduce((acc : {[type: string] : FoodLabels}, value) => {
            acc[value.type] = acc[value.type] || [];
            acc[value.type].push(value);
            return acc;
        }, {});
    
        return (
            <Select
                mode="multiple"
                placeholder="Please select"
                style={{ width: '100%' }}
            >
                {Object.keys(reduced).map(type => (
                    <OptGroup key={type} label={type}>
                        {reduced[type].map(label => (
                            <Option key={label.key}>{t(`labels.${label.key}`)}</Option>
                        ))}
                      </OptGroup>
                ))}
    
            </Select>
        )
    }

    
    async function onFormFinish(values : any) {
        const {price} = values;
        delete values.price;
    
        const menuItem: Menu_Item_Insert_Input = values;
    
        if (values.id && price == item?.active_price_item?.price) {
            console.log("price the same, updating", price, item?.active_price_item?.price);
    
            await updateItemSamePrice({
                variables: {
                    id: values.id,
                    menuItem: menuItem
                },
            })
        } else {
    
            menuItem.active_price_item = {
                data: {
                    price,
                    menu_item_id: values.id,
                }
            }
    
            await addItem({
                variables: {
                    menuItem: menuItem
                },
            })
        }

        message.success("product updated");
    
      }
      
    return (
        <>
                <Form
                    name="basic"
                    initialValues={{
                        live: true,
                        ...item,
                        id: itemId,
                        price: item?.active_price_item?.price || 0,
                    }}
                    onFinish={onFormFinish}

                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="name" label="Product name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Product description">
                        <Input />
                    </Form.Item>
                    <Form.Item name="price" label="Product price">
                         <InputNumber
                            addonAfter={<CurrencySymbol />}
                            formatter={(value) => {
                                console.log(value, typeof value);
                                if (!value) {
                                    return '0';
                                } else {
                                    return `${pennyToPrice(parseInt(value.toString(),10))}`
                                }
                            }}
                            parser={value => {
                                const cleaned = value!.replace(/\/s+/g, '')
                                console.log("cleaned", cleaned)

                                if (cleaned.indexOf('.') >= 0) {
                                    const [unit, cents] = cleaned.replace(/,/g, '').split('.');
                                    console.log({cents,unit})
                                    const final = (parseInt(unit.trim() || '0', 10) * 100) + (parseInt(cents.trim().padEnd(2, '0') || '0', 10) % 100) 
                                    console.log({final})
                                    return final;
                                } else if (cleaned.indexOf(',') >= 0) {
                                    const [unit, cents] = cleaned.replace(/\./g, '').split(',');
                                    const final = (parseInt(unit.trim() || '0', 10) * 100) + (parseInt(cents.trim().padEnd(2, '0') || '0', 10) % 100) 
                                    return final;
                                }
                                const final = (parseInt(cleaned, 10) * 100);
                                return final;
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="menu_parent" label="Menu Category" rules={[{ required: true }]}>

                    <TreeSelect
                        // showSearch
                        style={{ width: '300px' }}
                        multiple={false}
                        // value={value}
                        treeData={tree}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        treeDefaultExpandAll
                        // showCheckedStrategy={"SHOW_CHILD"}

                      >
                        {/* {tree.map(displayTreeNodes)} */}
                      </TreeSelect>

                    </Form.Item>
                    <Form.Item  name="imageUrl" label='Item image'>
                        <ImageFormInput />
                    </Form.Item>
                    <Form.Item name="labels" label="Product labels">
                        {getLabelSelect(labels)}
                    </Form.Item>

                    <Form.Item name="live" label="Product live to customer" valuePropName="checked" >
                        <Checkbox />
                    </Form.Item>                  
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
        </>
    );
}