import { CameraOutlined } from '@ant-design/icons';
import { Modal , notification, Select, Spin } from 'antd';
import { table } from 'console';
import QrScanner from 'qr-scanner';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useGetQrCodeIdFromLinkLazyQuery, useInsertQrMutation, useUpdateTableQrCodeMutation } from '../../../generated/graphql';


interface QrScanIconProps {
    tables: Array<{id: string, name:string}>;
}

export function QrScanIcon(prosp: QrScanIconProps) {
    
    const [open, setOpen] = useState(false);
    const [qrScanner, setScanner] = useState<QrScanner>();
    const videoRef = useRef<HTMLVideoElement>(null);


    const [attachQr] = useUpdateTableQrCodeMutation()
    const [getQrCodeIdFromLink, codeData] = useGetQrCodeIdFromLinkLazyQuery({
        fetchPolicy: 'network-only',
        // onCompleted: useCallback(() => {
        //     console.log("getQrCodeIdFromLink done!")
        // }, [])
    })

    const {loading: l1, data: qrData} = codeData;


    
    function handleCancel() {
        setOpen(false);
        setScanner(undefined);

        // setQrData(undefined);
        if (qrScanner) {
            qrScanner.stop();
        }
    }


    function openModal() {
        setOpen(true);

        console.log(videoRef.current)

        if (videoRef.current) {
            const scanner = new QrScanner(videoRef.current, async (link: string) => {
                console.log('decoded qr code:', link);
                scanner.stop();
                setScanner(undefined);

                try {
                    console.log("ok");
                    getQrCodeIdFromLink({
                        variables: {
                            link
                        }
                    });
                    // console.log("passed with ", linkData)
    
                    // const codeId = linkData?.getQrLinkRealId?.qrId;
                    // if (!codeId) {
                    //     notification.error({
                    //         message: "qrcode invalid"
                    //     })
                    //     return;
                    // }
    
                    // const {data : qrData} = await getQrData({
                    //     variables: {
                    //         codeId,
                    //     }
                    // })

                    // console.log("ok", qrData);
                    // setQrData(qrData?.qr);
                } catch (err) {
                    // console.log("eeeee");
                    // console.error(err);
                    // notification.error({
                    //     message: "unable to find qr data"
                    // })
                }
            });

            scanner.start();
            console.log("SETTING");
            setScanner(scanner)
        }
    }

    function ModalContent() {
        if (l1) {
            return <Spin />
        }



        if (!qrScanner && qrData?.getQrLinkRealId) {
            const qr = qrData?.getQrLinkRealId;

            return (
                <>
                    <p>Code valid ✅</p>

                    <div>

                        <span>Change Attached table:</span>

                        <Select
                        placeholder="Not attached to any tables"
                        allowClear
                        onChange={async (tableId: string) => {
                            try {
                                await attachQr({
                                    variables: {
                                        qrId: qr.qrId,
                                        tableId,
                                    }
                                })
                                notification.success({
                                    message: "qrcode successfully configured"
                                })
                            } catch (err) {
                                console.log(err);
                                notification.error({
                                    message: "Unable to configure QR code"
                                })
                            }


                        }}
                        defaultValue={qr.tableId}
                    >
                        {prosp.tables.map(table => (
                            <Select.Option
                                key={table.id}
                                value={table.id}
                                >
                                    {table.name}
                            </Select.Option>
                        ))}
                        </Select>
                    </div>
                    {(qr.tableId && qr.tableName) ? (
                        <Link to={`/orders/table/${qr.tableId}`}>Got to table {qr.tableName}</Link>
                    ): null}


                
                </>
            )
        }

        return (
            <span>Loading..</span>
        )

    }


    return (
        <>
            <Modal 
                forceRender
                 title="Qr scanner" visible={open} onCancel={handleCancel} footer={null}>

                    <video 
                        height={qrScanner ? 400 : 0}
                        width={qrScanner ? 400: 0}
                        ref={videoRef}
                    ></video>
                    {ModalContent()}

            </Modal>
            <span className='text-2xl cursor-pointer' onClick={openModal}>
                <CameraOutlined />
            </span>   
        </>

    );
}