import React, { useContext } from "react";
import {
  BrowserRouter as Router, Route, Switch,
} from "react-router-dom";
import { SideMenu } from "./components/navigations/SideMenu";
import Pages from "./components/navigations/Pages";
import { Row, Col, Spin } from 'antd';
import { AuthContext, SignedInStatus } from "./context/Auth";
import Login from "./components/login/login";
import { MobileFooterMenu } from "./components/navigations/MobileMenu";
import { MagicLogin } from "./pages/magic/magic";
import { isDeviceDesktop } from "./utils/isDesktop";

export default function App() {

  if (isDeviceDesktop()) {
    return (
      <Router>
        <div className="flex h-screen" id="pagerContainer">
          <div id="leftMenu">
            <SideMenu />
          </div>
          <div className="grow h-screen overflow-y-scroll" id="rightMenu">
            <Pages />
          </div>
        </div>
      </Router>
    );
  } else {
    return (
      <div 
        style={{
          height: "var(--app-height)"
        }}
        className="w-full flex flex-col" >

          <div className="grow shrink overflow-scroll " >
           <Pages />
          </div>
          <MobileFooterMenu />

      </div>
    )
  }


}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()