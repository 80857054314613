import { Menu, Tag } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { AuthContext, SignedInStatus } from '../../context/Auth';
import { User_Roles_Enum_Enum, useTutorialCheckSubscription } from '../../generated/graphql';

const { SubMenu } = Menu;
export  function SideMenu() {
  
  const {data, loading} = useTutorialCheckSubscription();
  const {user} = useContext(AuthContext);
  let location = useLocation();
  const paths = location.pathname.split('/');
  let selectedKeys = [];
  let acc = [];
  for (let path of paths) {
    if (!path) {
      continue;
    }
    acc.push(path);
    selectedKeys.push('/' + acc.join('/'));
  }

  if (user.status !== SignedInStatus.SIGNED_IN) {
    return null;
  }

  return (
    <Menu
      theme="dark"
      selectedKeys={selectedKeys}
      defaultOpenKeys={[
        "/orders",
      ]}
      mode="inline"
      className='h-full  overflow-y-scroll min-w-[200px]'
    >
      {!loading && (
        <>
            <Menu.Item key="title" className='h-21'>
                <h2 className='text-white text-lg'>{data?.restaurants[0]?.name}</h2>
            </Menu.Item>

          {data?.restaurants[0]?.restaurant_stripe?.namespace === 'demo' && (
            <Menu.Item key="payment" className='h-21'>
              <Link to="/sandbox">
                <Tag color="volcano">Payment: sandbox</Tag>
              </Link>
            </Menu.Item>
          )}


          {data?.restaurants[0]?.tutorial === true && (
            <Menu.Item key="/tutorial" icon={<span>🏁</span>}>
                <Link to="/tutorial">Tutorial</Link>
            </Menu.Item>
          )}
        </>
      )}


        <SubMenu key="/orders" icon={<span>✉️</span>} title="Orders">
           <Menu.Item key="/orders/overview">
              <Link to="/orders/overview">Overview</Link>
            </Menu.Item>
            <Menu.Item key="/orders/table">
              <Link to="/orders/table">Tables</Link>
            </Menu.Item>
            <Menu.Item key="/orders/progress">
              <Link to="/orders/progress">In progress</Link>
            </Menu.Item>
            <Menu.Item key="/orders/history">
              <Link to="/orders/history">Past</Link>
            </Menu.Item>
        </SubMenu>


        {(user.userRole !== User_Roles_Enum_Enum.Staff) && (
          <>
            <Menu.Item key="/restaurant" icon={<span>🏘️</span>}>
              <Link to="/restaurant">Restaurant</Link>
            </Menu.Item>

            <Menu.Item key="/staff" icon={<span>🧑‍🍳</span>}>
              <Link to="/staff">Staff</Link>
            </Menu.Item>

            <SubMenu key="/menu" icon={<span>🍴</span>} title="Menu">
                  <Menu.Item key="/menu/producers">
                    <Link to="/menu/producers">Menu Producers</Link>
                  </Menu.Item>
                  <Menu.Item key="/menu/tree">
                    <Link to="/menu/tree">Menu Tree</Link>
                  </Menu.Item>
                  <Menu.Item key="/menu/items">
                    <Link to="/menu/items">Menu Items</Link>
                  </Menu.Item>
              </SubMenu>

            <SubMenu key="/tables" icon={<span>🪑</span>} title="Tables">
                <Menu.Item key="/tables/rooms">
                  <Link to="/tables/rooms">Rooms</Link>
                </Menu.Item>
                <Menu.Item key="/tables/list">
                    <Link to="/tables/list">Tables</Link>
                  </Menu.Item>
            </SubMenu>


          
            <Menu.Item key="/qr" icon={<span>📸</span>}>
              <Link to="/qr">Qr codes</Link>
            </Menu.Item>

            <Menu.Item key="/printers" icon={<span>🖨️</span>}>
              <Link to="/printers">Printers</Link>
            </Menu.Item>
          </>

        )}






      <Menu.Item key="/profile" icon={<span>⚙️</span>}>
        <Link to="/profile">My profile</Link>
      </Menu.Item>


    </Menu>
  );
}



