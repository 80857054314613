import { Spin } from 'antd';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/Auth';

interface CurrencyPriceProps {
    price: number;
    hideCurrency?: boolean;
}


export function CurrencyPrice(props: CurrencyPriceProps) {
    const {currencyDetails} = useContext(AuthContext);
    if (!currencyDetails) {
        return (<Spin />);
    }

    return (<span>{(props.price / (Math.pow(10, currencyDetails.precision)))}{!props.hideCurrency && (currencyDetails.currency)}</span>);
}

export function CurrencySymbol() {
    const {currencyDetails} = useContext(AuthContext);
    if (!currencyDetails) {
        return (<Spin />);
    }

    return (<span>{currencyDetails.currency}</span>);
}