import { Tabs } from 'antd';
import React from 'react'
import { useParams } from 'react-router-dom';
import { LoaderPage } from '../../../../components/utils/loader';
import { useLoadMenuItemDetailQuery } from '../../../../generated/graphql';
import { MenuItemFormComponent } from './details';
import { ProductExtra } from './extra';

const {TabPane} = Tabs;

export function MenuItemComponent() {

    const {itemId} = useParams<{itemId: string}>();

    const isNew = itemId === 'new';

    const { data, loading} = useLoadMenuItemDetailQuery({
        fetchPolicy: "network-only",
        variables: {
            itemId: !isNew ? itemId : 'cec33f9c-42df-4aeb-aaeb-3c532f5e0c49'
        }
    });

    if (loading || !data) {
        return <LoaderPage />
    }
    
    return (
        <>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Product details" key="1">
                    <MenuItemFormComponent
                        itemId={itemId}
                        item={data.item}
                        labels={data.labels}
                        menuTrees={data.menuTrees} />
                </TabPane>
                <TabPane tab="Product extra" key="2">
                    <ProductExtra />
                </TabPane>
            </Tabs>

        </>
    );
}
