import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Select } from 'antd';
import { RoomType } from './type';
import { Link } from 'react-router-dom';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: RoomType;
  index: number;
  children: React.ReactNode;
}


interface EditableRoomProps {
    loading: boolean;
    data?: RoomType[];
    onRowUpdated: (id: string, roomName: Partial<RoomType>) => Promise<void>;
    deleteRow: (id: string) => Promise<void>;
}

export const EditableTable = (props: EditableRoomProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: RoomType) => record.id === editingKey;

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  

  const edit = (record: Partial<RoomType>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as RoomType;
       await props.onRowUpdated(key, row);
       setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: true,
    },
    // {
    //     title: 'Role',
    //     dataIndex: 'role',
    //     key: 'role',
    //     editable: true,
    // },
    {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (d: string) => <span>{(new Date(d)).toLocaleDateString()}</span>,
    },
    {
      title: 'Tables',
      dataIndex: 'Tables',
      key: 'Tables',
      render: (_: void, record: RoomType) => <span>{record.tables_aggregate.aggregate?.count}</span>,
    },
    {
      title: 'floorplan',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <Link to={`/tables/rooms/${id}`}>floorplan</Link>,
    },
    {
        title: 'operation',
        dataIndex: 'operation',
        render: (_: any, record: RoomType) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
              <>
                  <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                      Edit
                  </Typography.Link>
                    

  
                   <div></div>
  
                   <Popconfirm title="Sure to delete?" onConfirm={() => props.deleteRow(record.id)}>
                      <a>Delete</a>
                   </Popconfirm>
              </>
  
          );
        },
      }

  ];


  const mergedColumns = columns.map(col => {
    if (!col.editable) {
        return col;
    }
    return {
      ...col,
      onCell: (record: RoomType) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowKey={"id"}
        loading={props.loading}
        bordered
        dataSource={props.data}
        columns={mergedColumns}
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};