import { Input } from 'antd';
import React, { useState } from 'react'

interface KeyPadProps {
    onTableSelected: (tableNumber: string) => void;
}

export function KeyPad(props: KeyPadProps) {

    const [table, setTable] = useState("");

    function addDigit(digit: number) {
        return (() => {
            setTable(`${table}${digit}`);
        });
    }
    const digit = [
        {onClick: addDigit(1), d : '1'},
        {onClick: addDigit(2), d : '2'},
        {onClick: addDigit(3), d : '3'},
        {onClick: addDigit(4), d : '4'},
        {onClick: addDigit(5), d : '5'},
        {onClick: addDigit(6), d : '6'},
        {onClick: addDigit(7), d : '7'},
        {onClick: addDigit(8), d : '8'},
        {onClick: addDigit(9), d : '9'},
        {onClick: () => { setTable(table.slice(0, -1)) }, d : '⬅️'},
        {onClick:  addDigit(0), d : '0'},
        {onClick: () => { props.onTableSelected(table); setTable(""); }, d : 'enter'},
    ]

    return (
        <div className='w-80'>
            <Input size='large' className='text-center' value={table} disabled />

             <div className='grid grid-cols-3'>
                {digit.map((item, i) => (
                    <div
                        onClick={item.onClick}
                        key={i}
                        className="transition-colors border-2 rounded-full border-blue-800 	text-blue-800 cursor-pointer text-2xl  m-2 text-center h-16 w-16 flex justify-center items-center hover:bg-blue-800 hover:text-white">
                        {item.d}
                    </div>
                ))}

            </div>
        </div>
    );
}