import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Modal, message, Alert, notification } from "antd";
import React, { useState } from "react";
import { useGetStripeAccountLinkMutation } from "../../../generated/graphql";


export default function RestaurantStripe() {

    const [query] = useGetStripeAccountLinkMutation();


    async function addStripeKey(values: {key: string}) {
    }



    async function stripeConnect() {
        try {
            const stripe = await query();

            const link = stripe.data?.accountLink?.link;
            if (link) {
                window.open(link, '_blank')//.focus();
            } else {
                notification.error({
                    message: "unable to get your stripe link"
                })
            }
        } catch (e) {
            notification.error({
                message: "unable to get your stripe link"
            })
        }




    }


    return (<div className='max-w-xl mx-auto'>
        <Alert 
            message="Stripe" 
            type="info" 
            showIcon
            description={<>
                <p>
                    To receive payments at your table you need to create a stripe account. <br/>
                    <br/>
                    ✅ Qrave does not receive the money, it comes directly into your secured Stripe account for you to withdraw.<br/>
                    <br/>
                    Stripe fee:<br/>
                    ➡️ European Economic Area cards: 1,4 % + 0,25€<br/>
                    ➡️ International cards: 2.9% + 0,25€<br/>
                    ➡️ Ideal: 0,29€<br/><br/>

                    for more information about fee and accounts: <a target="_blank" href="https://stripe.com/">go to stripe's website</a>
                </p>
            </>}
            />

    
        <div className="mt-2"> </div>

        <Button onClick={stripeConnect}>Connect to stripe</Button>
    </div>);
}

