import { Button, Input, Form, InputNumber, notification, Table, Checkbox, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LoaderPage } from '../../components/utils/loader';
import { useGetRoomsQuery, useGetTableByIdQuery, useInsertTableMutation, useUpdateTableMutation } from '../../generated/graphql';
const {Option} = Select;

interface FormValues {
    id?: string;
    name: string;
    room_id: string;
    seats?: number;
}

export function EditTables() {
    let { id: tableId } = useParams<{id: string}>();
    const isCreateTable: boolean = tableId === 'new';
    const {data : rooms} = useGetRoomsQuery();
    console.log(rooms);
    const [createTable] = useInsertTableMutation();
    const [updateTable] = useUpdateTableMutation();
    const {loading, data, error} = useGetTableByIdQuery({
        fetchPolicy: "network-only",
        variables: {
            tableId,
        },
        skip: isCreateTable,
    });


    const history = useHistory();

    async function onFormFinish(values: FormValues) {
        console.log(values);

        if (isCreateTable) {

            try {
                const result = await createTable({
                    variables: {
                        tableData : values
                    }
                });
        
                if (result.data && !result.errors) {
                    // const newTableId = result.data.insert_seating_table_one?.id
                    history.push(`/tables/list/`);
                    notification.success({
                        message: "table created",
                        placement: "top",
                        duration: 0.9,
                        
                    })
                } else if (result.errors) {
                    notification.error({
                        message: "table !",
                        description: JSON.stringify(result.errors)
                    })
                }
            } catch (err) {
                notification.error({
                    message: "unable to create table",
                }) 
            }


        } else {
            await updateTable({
                variables: {
                    tableId,
                    tableData: values,
                }
            })

            notification.success({
                message: "table updated!"
            })
        }


    }

    if (loading) {
        return (<LoaderPage />);
    }

    let initialValues: FormValues | {} = {};
    if (data?.table) {
        initialValues = data.table;
    }


  return (
    <>

                <div className='flex justify-between m-4'>
                    <h1 className='text-2xl'>Tables Details:</h1>

                </div>

                <Form
                    name="basic"
                    initialValues={initialValues}
                    onFinish={onFormFinish}

                >
                    <Form.Item 
                        rules={[
                            {
                                pattern: new RegExp(/^[0-9]*$/),
                                message: "only digits (0-9) allowed"
                            }
                            ]}
                        required 
                        name="name"
                        label="Table name">
                        <Input />
                    </Form.Item>
                    <Form.Item  required name="seats" label="Available seats">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item required name="room_id" label="Room">
                        <Select
                            placeholder="Select table room"
                            allowClear
                        >
                            {rooms?.seating_table_room.map(room => (
                                <Option
                                    key={room.id}
                                    value={room.id}
                                    >
                                        {room.name}
                                </Option>

                            ))}

                        </Select>
                    </Form.Item>
                    {/* <Form.Item valuePropName="checked"  name="open" label="table openned">
                        <Checkbox />
                    </Form.Item>                 */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {isCreateTable ? `Create table` : `Update table`}
                        </Button>
                    </Form.Item>

                </Form>
                
        
    </>
  );
}

