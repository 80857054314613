import React from 'react'
import { Button, Steps } from 'antd';
import { useCompleteTutorialMutation, useGetTutorialStepsQuery } from '../../generated/graphql';
import { LoaderPage } from '../../components/utils/loader';
import { Link, useHistory } from 'react-router-dom';
import { AlignerComponent } from '../../components/layout/Aligner';
const { Step } = Steps;

export function TutorialPage() {

    const {data, error, loading} = useGetTutorialStepsQuery({
        fetchPolicy: 'network-only'
    });
    const [complete] = useCompleteTutorialMutation();
    const history = useHistory();

    console.log({
        data,
        error
    })

    if (loading) {
        return (<LoaderPage></LoaderPage>)
    }

    async function completeTutorial() {
        await complete();
        history.push("/");
    }

    return (
    <AlignerComponent>
        <Steps direction="vertical">
            <Step 
                status={data?.restaurants[0]?.logoUrl ? "finish" : 'wait'}
                title={
                    <Link to="/restaurant">
                            Add your Restaurant Image
                    </Link>
                }
                description="When customer order your logo will be on the top" />
            <Step 
                status={(data?.item.aggregate?.count || 0) > 0 ? "finish" : 'wait'}
                title={
                    <Link to="/menu/producers">
                        Create your first menu item
                    </Link>
                }
                description="You need at least one item for your customer to order" />
            <Step 
                status={(data?.table.aggregate?.count || 0) > 0 ? "finish" : 'wait'}
                title={
                    <Link to="/tables/rooms">
                        Create your first table
                    </Link>
                }
                description="order are attached to a table so you know where to deliver the order " />
            <Step
                status={(data?.qr.aggregate?.count || 0) > 0 ? "finish" : 'wait'}
                title={
                    <Link to="/qr">
                        Create your first QR code
                    </Link>
                }
                description="With QR code customer can order and pay at their table " />
        </Steps>

        <div className='text-center mt-10'>
            <Button size="large" type="primary" onClick={completeTutorial}>Complete tutorial</Button>
        </div>
    </AlignerComponent>

    );
}