import React, { useEffect, useState } from 'react';
import SortableTree, { TreeItem } from 'react-sortable-tree';
import { Alert, Button, Form, Input, Modal, notification, Select } from 'antd';
import { Menu_Tree_Insert_Input, useGetMenuProducersQuery, useGetMenuTreeLazyQuery, useUpsertMenuTreeMutation } from '../../../generated/graphql';
import { v4 as uuidv4 } from 'uuid';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { LoaderPage } from '../../../components/utils/loader';
import { AlignerComponent } from '../../../components/layout/Aligner';

interface UiTreeItem {
    id?: string;
    title: string;
    producer_id: string;
    foodItemCount: number;
    expanded: boolean;
    children: UiTreeItem[];
}

function FoodMenu() {


  const [saveTree] = useUpsertMenuTreeMutation();
  const history = useHistory();


  const [treeData, setTreeData] = useState<UiTreeItem[] | null>(null);
  const {data: producers, loading: producerLoading} = useGetMenuProducersQuery();
  const [editItem, setEditItem] = useState<(TreeItem & {id : string}) | null>(null);
  const [unsavedChange, setUnsaveChange] = useState<boolean>(false);
  const [form] = Form.useForm();

   const [query] = useGetMenuTreeLazyQuery({
       fetchPolicy: 'no-cache',
   });

  useEffect(() => {
    query()
        .then((res) => {
            const list = res.data?.menu;

            if (!list) {
                return;
            }

            console.log("listlist", list)

            const map : Map<string, UiTreeItem> = new Map();
            let threeDataLoaded : UiTreeItem[] = [];

            list.forEach(item => {

                let node : UiTreeItem = {
                    id: item.id,
                    title: item.title,
                    producer_id: item.producer_id,
                    expanded: true,
                    foodItemCount: item.menu_items_aggregate?.aggregate?.count || 0,
                    children: []
                };
                map.set(item.id, node);
            });

            list.forEach(item => {
                const row = map.get(item.id);
                if (!row) {
                    return;
                }
                
                if (item.parentId) {
                    let parent = map.get(item.parentId);
                    if (parent) {
                        parent.children.push(row);
                    }
                } else {
                    threeDataLoaded.push(row);
                }
            });

            setTreeData(threeDataLoaded);


        })
  }, []);


  async function saveMenu() {

    if (!treeData) {
        return;
    }

    const toSave : Menu_Tree_Insert_Input[] = [];

    function flatten(arr: UiTreeItem[], parentId: string | null) {
        arr.forEach(item => {
            item.id = item.id || uuidv4()
            toSave.push({
                id: item.id,
                title: item.title,
                producer_id: item.producer_id,
                parent_id: parentId,
            });

            if (item.children) {
                flatten(item.children, item.id);
            }
        });
    }

    flatten(treeData, null);

    console.log(toSave);


    try {
        const result = await saveTree({
            variables: {
                menu: toSave,
                allTreeIds: toSave.map(s => s.id)
            }
        });
    
        console.log(result);
    
        notification.success({
            message: 'Menu saved',
        })
        setUnsaveChange(false);
    } catch(err) {
        notification.error({
            message: 'cannot save menu',
        })
    }

  }


  console.log(treeData);


  const producerColorMap = producers?.menu_producer.reduce((acc : Map<string, string>, curr) => {
    acc.set(curr.id, curr.colors);
    return acc;
  }, new Map())


  if (!treeData || producerLoading) {
    return (<LoaderPage />);
  } else if (producers!.menu_producer.length === 0){
    return (
        <AlignerComponent>
          <Alert 
            type="warning"
            showIcon
            description={<>
                <p>You should create a producer before creating your menu</p>
                <Link to="/menu/producers"><Button>Create a producer</Button></Link>

            </>}
          />
        </AlignerComponent>
      )
  }


  return (
    <div className=' h-full'>
        <Prompt
          when={unsavedChange}
          message="your edited your menu but didn't save, are you sure you want to discard?"
        />
        <div className='flex flex-col h-full'>

            <Form 
                layout='inline'
                form={form}
                initialValues={{
                    producer_id: producers!.menu_producer[0].id
                }}
                onFinish={(values) => {
                    setTreeData(treeData.concat({
                        children: [],
                        title: values.title,
                        producer_id: values.producer_id,
                        expanded: false,
                        foodItemCount: 0,
                    }));
                    setUnsaveChange(true);
                    notification.success({
                        message: `${values.title} added`,
                    })
                    form.resetFields();
                }}
                >
                <Form.Item 
                    rules={[{ required: true, message: 'Please add menu name' }]}
                    label="title"
                    name="title">
                    <Input  />
                </Form.Item>

              <Form.Item
                rules={[{ required: true, message: 'Select your producer' }]}
                label="Producer"
                name="producer_id">
                    <Select
                            placeholder="Select table room"
                            allowClear
                        >
                        {producers?.menu_producer.map(producer => (
                            <Select.Option
                                key={producer.id}
                                value={producer.id}
                                >
                                    {producer.name}
                            </Select.Option>
                
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add menu category
                    </Button>
                </Form.Item>
            </Form>

            <Modal title="Edit menu" visible={!!editItem} onCancel={() => setEditItem(null)} footer={null}>
                    <Form
                        name="basic"
                        key={editItem?.id}
                        initialValues={editItem!}
                        onFinish={(values: {id: string, title: string, producer_id: string}) => {
                            console.log(values);

                            function recursive(d: UiTreeItem) {
                                if (d.id === values.id) {
                                    d.title = values.title;
                                    d.producer_id = values.producer_id;
                                }
                                if (d.children) {
                                    d.children = d.children.map(recursive);
                                }
                                return d;
                            }

                            const updatedData = treeData.map(recursive);
                            console.log({updatedData})
                            setTreeData(updatedData);
                            setEditItem(null);
                            setUnsaveChange(true);
                        }}

                    >
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="title" label="Product title">
                            <Input />
                        </Form.Item>   
                        <Form.Item label="Producer" name="producer_id">
                            <Select
                                    placeholder="Select table room"
                                    allowClear
                                >
                                {producers?.menu_producer.map(producer => (
                                    <Select.Option
                                        key={producer.id}
                                        value={producer.id}
                                        >
                                            {producer.name}
                                    </Select.Option>
                        
                                ))}
                            </Select>
                        </Form.Item>                       
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update Details
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button
                        onClick={() => {
                            const dId : string = editItem!.id;

                            function recursive(acc: UiTreeItem[], d: UiTreeItem) {
                                if (d.id !== dId) {
                                    acc.push(d);
                                }
                                if (d.children) {
                                    d.children = d.children.reduce(recursive, []);
                                }
                                return acc;
                            }

                            const updatedData = treeData.reduce(recursive, []);
                            setTreeData(updatedData);
                            setEditItem(null);
                            setUnsaveChange(true);
                        }}
                        danger type="primary" >
                            Delete
                    </Button>
            </Modal>


            {treeData.length > 0 ? (
                <>
                            <SortableTree
                                treeData={treeData}
                                onMoveNode={() => {
                                    setUnsaveChange(true)
                                }}
                                generateNodeProps={({ node, path }) => {
                                    let color = producerColorMap?.get(node.producer_id) || "black";
                                    return ({
                                        style: { color },
                                        buttons: [
                                            <Button onClick={() => setEditItem(node as (TreeItem & {id : string}))}>
                                            Modify
                                            </Button>,
                                            <Button
                                                onClick={() => {
                                                    history.push(`/menu/items/?menu_parent=${node.id}`);
                                                }}
                                            >
                                                items {node.foodItemCount}
                                            </Button>,
                                        ],
                                        })}
                            }


                                onChange={(treeData: UiTreeItem[]) => setTreeData(treeData)}
                            />

                            <Button 
                                type="primary"
                                disabled={!unsavedChange}
                                size='large'
                                onClick={saveMenu}>
                                    Save Menu
                            </Button>
                </>
            ) : (
                <div className='mt-6'>

                    <Alert 
                        message="How is my menu organised?" 
                        type="info"
                        
                        showIcon
                        description={<>
                            <p>
                                you can set your menu categories as a tree.<br />
                                A simple category would be burgers all you different burgers would be under. <br />
                                But you can have more nested menu, for Example you may have "Drinks" category which would have "Soft" / "Beer" / "Wine" as childrens. <br />
                                "Wine" could also have "Red" / "White" / "Rose".. you can set up you menu as you prefer. this is great for customer to find what they are looking for quickly.<br /><br />

                                Each category is linked to a menu producer so we know where to send the order.
                            </p>
                            <img className='h-96' src='/assets/menu_tree_example.png' />
                        </>}
                        />

                </div>
            )}



        </div>





    </div>
  );
}

export default FoodMenu;
