import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Select, Button, Modal, notification } from 'antd';
import { UserType } from './type';
import { ColumnsType } from 'antd/lib/table';
import { useGetStaffLoginTokenMutation, User_Roles_Enum_Enum } from '../../generated/graphql';
import QRCode from 'qrcode'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: UserType;
  index: number;
  children: React.ReactNode;
}


interface EditableTableProps {
    loading: boolean;
    data?: UserType[];
    roles: string[];
    onRowUpdated: (id: string, user: Partial<UserType>) => Promise<void>;
    deleteRow: (id: string) => Promise<void>;
    hasEditValue: boolean;
}

const generateQR = async (path: string) : Promise<string> => {
  return await QRCode.toDataURL(window.location.origin + path, {
      errorCorrectionLevel: 'L',
      color: {
          dark: "#000000",
          light: "#ffffff",
      }
  });
}

export const EditableTable = (props: EditableTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [loginQr, setLoginQr] = useState<string>();
  const [getCode] = useGetStaffLoginTokenMutation();

  const isEditing = (record: UserType) => record.id === editingKey;

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  
    if (dataIndex === 'role') {
      inputNode = (
          <Select
          placeholder="Select table room"
          allowClear
      >
          {props.roles.map(role => (
              <Select.Option
                  key={role}
                  value={role}
                  >
                      {role}
              </Select.Option>
  
          ))}
  
      </Select>
      )
    }
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  

  const edit = (record: Partial<UserType>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as UserType;
       await props.onRowUpdated(key, row);
       setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        editable: true,
    },
    // {
    //     title: 'Joined',
    //     dataIndex: 'created_at',
    //     key: 'created_at',
    //     render: (d: string) => <span>{(new Date(d)).toLocaleDateString()}</span>,
    // },
    {
        title: 'operation',
        dataIndex: 'operation',
        render: (_: any, record: UserType) => {
          const editable = isEditing(record);

          async function getInstantLoginCode() {
            if (record.role === User_Roles_Enum_Enum.Staff) {
              const {data} = await getCode({
                variables: {
                  userId: record.id,
                }
              })
              const token = data?.getStaffLoginToken?.token;
  
              if (token) {
                const qrData = await generateQR('/mlogin/' + token);
                setLoginQr(qrData)
              } else {
                notification.error({
                  message: "unable to get token"
                })
              }
            } else {
              const qrData = await generateQR('/login/?email=' + record.email);
              setLoginQr(qrData);
            }



          }

          return editable ? (
            <div>
              <span>
                <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            </div>

          ) : (
            <div className='flex'>
                      <Button 
                        className="mr-2"
                        style={{
                          height: "4em",
                          width: "80px"
                        }}
                        onClick={getInstantLoginCode}>
                          {(record.role === User_Roles_Enum_Enum.Staff) ? (
                            <span>Instant <br /> QrLogin </span>
                          ) : (
                            <span> Login </span>
                          )}
                        </Button>

                    <div>
                        {(props.hasEditValue) && (
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                        )}

      
                      <div></div>
      
                      <Popconfirm title="Sure to delete?" onConfirm={() => props.deleteRow(record.id)}>
                          <a>Delete</a>
                      </Popconfirm>
                  </div>
            </div>
  
          );
        },
      }

  ];


  const mergedColumns = columns.map(col => {
    if (!col.editable) {
        return col;
    }
    return {
      ...col,
      onCell: (record: UserType) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
        <Modal
            
            visible={!!loginQr}
            onCancel={() => setLoginQr(undefined)}
            footer={null}
            title="Scan Instant loging 📱"
        
        >
            <img key={loginQr} src={loginQr} className='h-full mx-auto' />

        </Modal>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey={"id"}
            loading={props.loading}
            bordered
            dataSource={props.data}
            columns={mergedColumns}
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>

    </>

  );
};