import { Booking_Order_Status_Enum } from "../generated/graphql";

export default {
    "translation": {
      "Welcome": "Welcome2 to React and react-i18next",
    },
    "orders": {
      "status": {
        [Booking_Order_Status_Enum.S_010Draft]: "Draft",
        [Booking_Order_Status_Enum.S_020Waitting]: "Waitting",
        [Booking_Order_Status_Enum.S_030Preparing]: "Preparing",
        [Booking_Order_Status_Enum.S_040Completed]: "Completed",
        [Booking_Order_Status_Enum.S_050Delivered]: "Delivered",
      }
    },
    "menu": {
      "labels": {
        "ALLERGEN_CELERY": "contains: celery",
        "ALLERGEN_CEREALS": "contains: gluten",
        "ALLERGEN_CRUSTACEANS": "contains: crustaceans",
        "ALLERGEN_EGGS": "contains: eggs",
        "ALLERGEN_FISH": "contains: fish",
        "ALLERGEN_LUPIN": "contains: lupin",
        "ALLERGEN_MILK": "contains: milk",
        "ALLERGEN_MOLLUSCS": "contains: molluscs",
        "ALLERGEN_MUSTARD": "contains: mustard",
        "ALLERGEN_PEANUTS": "contains: peanuts",
        "ALLERGEN_SESAME": "contains: sesame",
        "ALLERGEN_SOYBEANS": "contains: soybeans",
        "ALLERGEN_SULPHUR_SULPHITES": "contains: sulphites",
        "ALLERGEN_TREE_NUTS": "contains: tree nuts",

        "PREFERENCE_VEGAN": "vegan",
        "PREFERENCE_VEGETARIAN": "vegetarian",
        "PREFERENCE_HALAL": "halal",
        "PREFERENCE_KOSHER": "kosher",
      }
    }
}