import { PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { QRAVE_DOMAIN } from '../../config/environnement';
import { AuthContext, AuthContextHub, SignedInStatus } from '../../context/Auth';

export function MagicLogin() {

    const {token} = useParams<{token: string}>();
    const [errorState, setErrorState] = useState(false);
    const [success, setSucces] = useState(false);
    const {user} = useContext(AuthContext);


    const history = useHistory();

    useEffect(() => {
        if (success) {
            console.log("already success, skipping");
            return;
        }
        if (user.status === SignedInStatus.SIGNED_IN) {
            console.log("already signed-In, but- logging-in");
        }
        console.log("useEffect, token", token);
        setErrorState(false);
        fetch(`https://auth.${QRAVE_DOMAIN}/login/${token}`)
            .then(async (res) => {
                if (res.ok) {
                    const jwt = await res.text();
                    setSucces(true);
                    AuthContextHub.signIn(jwt);
                    console.log("going to root url");
                    // history.replace("/");
                } else {
                    console.log("already used");
                    setErrorState(true);
                    // notification.error({
                    //     message: "code error2"
                    // });
                }

            })
            .catch((err) => {
                console.log("err", err);
                setErrorState(true);
                // notification.error({
                //     message: "code error"
                // });
            })
    }, [token])

    if (user.status === SignedInStatus.SIGNED_IN) {
        return (<Redirect to="/" /> );
    }

    if (errorState) {
        return (
            <div  className='flex items-center justify-center h-screen'>
                <div className='w-6/12 flex items-center justify-center'>
                    <Alert message="Error" type="error" showIcon description={(
                        <>
                            <p>This link is either invalid or has already been used</p>
                            <Link to="/login">
                                <Button
                                type='primary'
                                >
                                    Login
                                </Button>
                            </Link>
                        </>
                    )} />  
                </div>
            </div>

        )
    }

    return (
        <>
             <Spin></Spin>
        </>
    );
}