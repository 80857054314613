import { Button, Card, message, Tag } from 'antd';
import React from 'react'
import { useParams } from 'react-router-dom';
import { GetSessionPaymentsQuery, useGetSessionPaymentsQuery, useSendReceiptMutation } from '../../../generated/graphql';
import { pennyToPrice } from '../../../utils/price';
import prompt from "antd-prompt";
import { isEmail } from '../../../utils/isEmail';
import { resolveProjectReferencePath } from 'typescript';
import { LoaderPage } from '../../../components/utils/loader';
import { CurrencyPrice } from '../../../components/currency/price';


type Payment = Exclude<GetSessionPaymentsQuery["session"], null | undefined>["payments"][number];

interface UrlParams {
    sessionId: string;
}

interface PaymentReceiptBlockProps {
    payment: Payment;
    sendEmail: (paymentId: string) => void;
}

function PaymentReceiptBlock(props: PaymentReceiptBlockProps) {

    const {payment} = props;

    const refund = payment.refund ? (
        <span>(<CurrencyPrice price={payment.refund} /> refunded)</span>
    ) : null;
    const itemsCount = payment.items_aggregate.aggregate?.count || 0;
    const itemdBlock =  itemsCount ? ( <span>for {itemsCount} items</span>) : null;

    return (                
        <Card>
            <div className='flex justify-between'>
                <div className='flex'>
                {(payment.stripe_payment_id) && (
                    <span>
                        <Tag
                            color="blue"
                            className='cursor-pointer'
                            onClick={() => {
                                if (window) {
                                    window.open(`https://dashboard.stripe.com/payments/${payment.stripe_payment_id}`, '_blank');
                                }
                            }}
                        >Stripe</Tag>
                    </span>
                )}
                <p className='font-bold'>Paid <CurrencyPrice price={payment.total} /> {refund} {itemdBlock} on {(new Date(payment.created_at).toLocaleString())}</p>
                </div>
                {(itemsCount) ? (
                    <Button onClick={() => props.sendEmail(payment.id)}>Send receipt email</Button>
                ) : null}
                
            </div>


            <ul className="list-disc">
                {payment.receipts.map(r => (
                    <li key={r.id}>
                        sent to {r.email} on {(new Date(r.created_at)).toLocaleString()}
                    </li>
                ))}
            </ul>
        
        </Card>
   )
}

export function SessionPaymentsTsx() {

    const {sessionId} = useParams<UrlParams>();

    const {data,refetch, loading, error} = useGetSessionPaymentsQuery({
        fetchPolicy: "network-only",
        variables: {
            sessionId,
        }
    });

    const [sendreceipt] =  useSendReceiptMutation();

    async function sendEmail(paymentId?: string) {
        try {
            const email = await prompt({
              title: "Please enter your email",
              placeholder: "Email",
              rules: [
                {
                  required: true,
                  message: "You must enter email"
                }
              ]
            });

            if (email && isEmail(email)) {
                await sendreceipt({
                    variables: {
                        email,
                        sessionId,
                        paymentId,
                    }
                })
                message.success("email sent");
                refetch();
            } else {
                message.error("invalid email");
            }

          } catch (e) {
            // message.error("Please enter email");
          }
    }

    if (loading) {
        return <LoaderPage />
    }

    return (
        <>

            <Button type='primary' onClick={() => sendEmail()}>Send Email Reicept of <CurrencyPrice price={data?.session?.total} /></Button>

            <ul className="list-disc">
                {data?.session?.session_receipts.map(r => (
                    <li key={r.id}>
                        sent to {r.email} on {(new Date(r.created_at)).toLocaleString()}
                    </li>
                ))}
            </ul>


             <h2 className="text-center text-xl">Customer Payments:</h2>

             {data?.session?.payments.map((payment => (
                 <PaymentReceiptBlock key={payment.id} payment={payment} sendEmail={sendEmail} />
             )))}
        </>
    );
}