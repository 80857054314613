import { Alert, Button, Checkbox, notification } from 'antd';
import React, { useState } from 'react'
import { GetAvailableTablesQuery, useGenerateTableQrCodesMutation, useGetAvailableTablesQuery, useUpdateTableQrCodeMutation } from '../../../generated/graphql';
import Immutable from 'immutable';
import QRCode from 'qrcode'

interface TableSelectProps {
    onSelect: (data: any) => void;
}
type RoomStore = GetAvailableTablesQuery["rooms"][number];;

const generateQR = async (text: string) : Promise<string> => {

    /*
        https://qra.li/o-il6drnoc
        https://qra.li/99_nqlOYuz
        https://qra.li/I2FKlV5H1z
        https://qra.li/YZBqPUoVgV
        https://qra.li/teu-AATEUl
    */

    return await QRCode.toDataURL(text, {
        // errorCorrectionLevel: 'H',
        // errorCorrectionLevel: 'medium',
        color: {
            // dark: colors[(Math.floor(Math.random()*100))%colors.length],
            dark: "#000000",
            light: "#ffffff",
        }
    });
  }
  
export function TableSelect(props: TableSelectProps) {
    const [rooms, setRooms] = useState<Map<string, RoomStore>>(new Map());
    const [updateTableQrCode] = useUpdateTableQrCodeMutation();
    const [selectTable, setSelectedTable] = useState<Immutable.Map<string, {id: string, name: string}>>(Immutable.Map());
    const [generateCode] = useGenerateTableQrCodesMutation();
    const {loading} = useGetAvailableTablesQuery({
        onCompleted: (data) => {
            const rooms = data.rooms || [];
            const roomsMap = new Map<string, RoomStore>();
    
            for (let room of rooms) {
                roomsMap.set(room.id, room);
                // const tableMap = new Map();
                // for (let table of room.tables) {
    
                // }
            }
            setRooms(roomsMap);
    
        }
    });

    async function generatsCodes(size: number) : Promise<{qrData: string, qrId: string}[] | null> {

        const data = await generateCode({
            variables: {
                size,
            }
        });
        const promises = data.data?.codes.map((code) => {
            return generateQR(`https://qra.li/${code.id}`).then((data) => {
                console.log("data", data);
    
                return {
                    qrData: data,
                    qrId: code.qrId,
                };
            })
        })

        if (promises) {
            //Promise.all(promises).then(urls => setURls(urls));
            return await Promise.all(promises);
        }

        return null;
      };


    async function generateCodeFromTables() {
        // for (let [key, value] of selectTable.entrySeq() ) {
    
        // }
    
        const qrs = await generatsCodes(selectTable.size);
        if (!qrs) {
            notification.error({
                message: 'unable to create your qr codes'
            })
            return;
        }
    
        console.log(qrs);
    
        const tables = selectTable.toArray();


        const elements : any[] = [];
    
        for (let i = 0; i < tables.length; i++) {
            const [key, table] = tables[i];
            const qr = qrs[i];
    
            await updateTableQrCode({
                variables: {
                    tableId: table.id,
                    qrId: qr.qrId,
                }
            })

            elements.push({
                table: table.name,
                qr: qr.qrData,
            })
    

    
        }
    
        props.onSelect({elements});
      }

      
    return (
        <>
        <div className='mt-6'>

        </div>

        {!loading && rooms.size === 0 && (
            <p>You don't have any rooms yet!</p>
        )}

        <span>
            {Array.from(rooms.values()).map(room => (
                <div key={room.id}>

                    <div className='flex'>
                        <h3 className='text-md'>{room.name}</h3>
                        <Button className='ml-4' size='small' onClick={() => {
                                setSelectedTable((set) => {
                                    room.tables.forEach(table => {
                                        set = set.set(table.id, table)
                                    })

                                    return set;
                                })
                           
                        }}>Select all</Button>
                    </div>


                    {room.tables.map(table => (
                        <Checkbox
                         checked={selectTable.has(table.id)}
                         onChange={(e) => {
                            console.log(e.target.checked);
                            if (e.target.checked) {
                                setSelectedTable((set) => set.set(table.id, table))
                            } else {
                                setSelectedTable((set) => set.remove(table.id))
                            }
                        }} key={table.id}>{table.name}</Checkbox>
                    ))}
                </div>
            ))}
        </span>
        <Button
            onClick={generateCodeFromTables}
            disabled={selectTable.size===0}
            >Create {selectTable.size} qr codes</Button>
        </>
    );
}