import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import en from './languages/en'

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isDeviceDesktop } from './utils/isDesktop';
import path from 'path';
import { AuthContextHub } from './context/Auth';
import { QRAVE_DOMAIN } from './config/environnement';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });



/*
Sentry.init({
  dsn: "https://1fd84faa588e40a79ed258748f6fab23@o1222357.ingest.sentry.io/6366183",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/


console.log(window.location);

const { pathname } = window.location;
if (pathname.startsWith("/mlogin/")) {
    const token = pathname.replace('/mlogin/', '');
    const urlParams = new URLSearchParams(window.location.search);

    fetch(`https://auth.${QRAVE_DOMAIN}/login/${token}`)
    .then(async (res) => {
        if (res.ok) {
            const jwt = await res.text();
            AuthContextHub.signIn(jwt);
          }
    
        window.location.href = urlParams.get('redirect') || '/';
    })
    .catch((err) => {
        console.log("err", err);
        window.location.href = '/';
    })
} else {

  if (isDeviceDesktop()) {
    (function(d) {
      const t = "script";
      var BASE_URL="https://app.chatwoot.com";
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      if (s.parentNode) {
        s.parentNode.insertBefore(g,s);
      }
      g.onload=function(){
        (window as any).chatwootSDK.run({
          websiteToken: 'y1EpsGHt2cwTLtuLu91UiYxV',
          baseUrl: BASE_URL
        })
      }
    })(document);
  }

  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
