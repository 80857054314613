import { Button, Form, Input, Menu, notification, Spin } from 'antd';
import React, { Component, useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid'
import { userInfo } from 'os';
import { useLocation } from 'react-router-dom';
import { QRAVE_DOMAIN } from '../../config/environnement';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Login() {


    const [user, setUser] = useState<{email: string}>();
    const [isWaitting, setIsWaitting] = useState(false);
    let query = useQuery();
    console.log({query});

    async function login({email}: {email: string}) {

        setIsWaitting(true);
        try {
          await fetch(`https://auth.${QRAVE_DOMAIN}/login/email`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email})
          })
          setUser({email});
        } catch (err) {
          notification.error({
            message: "login error",
            duration: 1,
          })
        } finally {
          setIsWaitting(false);
        }

    }


    async function verify(values : {code: string}) {
        setIsWaitting(true);

        try {
            // This will throw an error if the user is not yet authenticated:
            // // const abc = await Auth.sendCustomChallengeAnswer(user, values.code);
            // console.log(abc)
            // const session = await Auth.currentSession();

            // console.log("session", session);

        } catch {
            console.log('Apparently the user did not enter the right code');
            notification.error({
              message: "something went wrong",
              duration: 1,
            })
        } finally {
          setIsWaitting(false);
        }
    }

    if (!user) {
        return (
            <>
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-md w-full space-y-8">
                <div>
                  <img  className="mx-auto h-24 w-auto text-6xl text-center" src="logo_t.png" alt="" />
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
    
                </div>
                <Form className="mt-8 space-y-6"  onFinish={login} initialValues={{
                  email: query.get("email") || ''
                }}>
    
                <Form.Item name="email" className="rounded-md shadow-sm -space-y-px">
                    <Input
                        placeholder='Email'
                         className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        />
                </Form.Item>
    
      
      
                  <div>
                    <button
                      type="submit"
                      disabled={isWaitting}
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-200"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        {isWaitting ? (
                          <Spin />
                        ) : (
                          <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                        )}
                      </span>
                        Login
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )
    }

    return (
      <>
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img  className="mx-auto h-24 w-auto text-6xl text-center" src="logo_t.png" alt="" />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Email sent to: {user.email}
              </p>
              <p className="mt-2 text-center text-sm text-gray-600">
                <a href="#" onClick={() => setUser(undefined)} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Code not received? Try again
                </a>
              </p>
            </div>

          </div>
        </div>
      </>
    )
  }