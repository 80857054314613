import { Button, Form, Input, InputNumber, notification, Select, Space, Tabs, Tree, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UiMenuItemFilter } from '../../../../components/orders/menuFilter';
import { UiMenuItem } from '../../../../components/orders/menuItem';
import { useGetCompleteMenuQuery } from '../../../../generated/graphql';
import { pennyToPrice } from '../../../../utils/price';
import { NewOrderForm } from './form';
import {v4 as uuidv4} from 'uuid';
import { MenuItem, OrderItemsForm, MenuItems, MenuTree, ItemsByProductIdsMap } from './type';
import Immutable from 'immutable';
import { flatten } from 'lodash';
import { CurrencyPrice } from '../../../../components/currency/price';

const {TabPane} = Tabs;

 function convertToTree(list: MenuTree) : UiTreeItem[] {
    const map : Map<string, UiTreeItem> = new Map();
  
    const threeDataLoaded : UiTreeItem[] = [];
  
    list.forEach(item => {
        const node : UiTreeItem = {
            key: item.id,
            value: item.id,
            title: item.title,
            children: [],
            menuItems: item.items,
        };
        map.set(item.id, node);
    });
  
    list.forEach(item => {
        const row = map.get(item.id);
        if (!row) {
            return;
        }
        if (item.parent_id) {
            const parent = map.get(item.parent_id);
            if (parent) {
                parent.children.push(row);
            }
        } else {
            threeDataLoaded.push(row);
        }
    });
  
    return threeDataLoaded;
  }


interface UiTreeItem {
    key: string;
    value: string;
    title: string;
    children: UiTreeItem[];
    menuItems: MenuItems;
  }



export function MakeOrderV2() {


    const [idToQuantity, setidToQuantity] = useState<Map<string, number>>(new Map())
    const [selectedMenu, setSelectedMenu] = useState<string[]>([]);

    const [total, setOrderTotal] = useState(0);
    const [itemsByProductIds, setItemsByProductIds] = useState<ItemsByProductIdsMap>(Immutable.Map({}));
    
    const {data, loading} = useGetCompleteMenuQuery();

    const tree = convertToTree(data?.menu || [])


    function renderMenuFilter() {
        const menuItemRow : Array<{items: UiTreeItem[]}> = [];
        menuItemRow.push({
          items: tree,
        });
        let node = tree;
    
        for (const selected of selectedMenu) {
          const nextNode = node.find(n => n.key === selected)?.children;
          if (!nextNode) {
            break;
          }
          node = nextNode;
          menuItemRow.push({
            items: node,
          });
        }
    
        return (
          <>
            {menuItemRow.map((props, idx) => (
              <UiMenuItemFilter 
                selectedKey={selectedMenu[idx]}
                onItemSelect={(nodeId) => {
                  const nextSelected = selectedMenu.splice(0, idx);
                  if (selectedMenu[selectedMenu.length -1] !== nodeId) {
                    nextSelected.push(nodeId);
                  }
                  setSelectedMenu(nextSelected);
                }}
                key={idx}
                {...props}/> 
              ))}
          </>
        )
    
      }



      let rootItems: Pick<UiTreeItem, "children" | "menuItems"> = {children: tree, menuItems: []};
      for (const selected of selectedMenu) {
        const nextNode = rootItems.children.find(n => n.key === selected);
        if (!nextNode) {
          return (<>
            Selection errors
          </>)
        }
        
        rootItems = nextNode;
      }
    
    
      function addToMenuItems(node : UiTreeItem) {
        menuItems = menuItems.concat(node.menuItems);
        node.children.forEach((node) => addToMenuItems(node));
      }
      let menuItems: MenuItems = rootItems.menuItems;
      rootItems.children.forEach((node) => addToMenuItems(node));


      function plusOneToItem(itemForm: OrderItemsForm) {
        let previousItems : OrderItemsForm | undefined = itemsByProductIds.get(itemForm.productId);

        if (!previousItems) {
          previousItems = itemForm;
        } else {
          previousItems.items = [
            ...previousItems.items,
            ...itemForm.items,
          ]
        }

        const next = itemsByProductIds.set(itemForm.productId, previousItems);
        setItemsByProductIds(next);
        calculate(flatten(Array.from(next.values())))
    }

    function calculate(products: OrderItemsForm[]) {
        let tt = 0;

        const ids =  new Map<string, number>();
        let total = 0;
        for (let product of products) {
          ids.set(product.productId, product.items.length);
          total += product.items.reduce((acc, current) => acc + current.price, 0);
        }

        console.log({total});


        setOrderTotal(total);
        setidToQuantity(ids);
    }



    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="Add new item" key="1">
        <div>
              <div>
                  {renderMenuFilter()}
              </div>
              
              <div id='menuItems' className='mt-2 grow overflow-auto'>
                  <div className='mx-4'>
                      {menuItems.map(i => (
                          <UiMenuItem 
                              item={i}
                              quantity={idToQuantity.get(i.id)}
                              onAddItem={(item)=> {
                                plusOneToItem(item);
                              }}
                              onRemoveOneItem={()=> {
                              }}
                              key={i.id}
                          />
                      ))}
                  </div>

              </div>
            </div>
        </TabPane>
      <TabPane disabled={total==0} tab={total? (
        <> Order (<CurrencyPrice price={total} />) </>
      ) : 'Order'} key="2">
            <NewOrderForm 
              update={(next) => {
                setItemsByProductIds(next);
                calculate(flatten(Array.from(next.values())))
              }}
              itemsByProductIds={itemsByProductIds}
              orderTotal={total || 0} />
      </TabPane>
    </Tabs>

 



        
    )
}

