import { Button, ConfigProvider, Empty, message, notification, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import relativeTime  from 'dayjs/plugin/relativeTime';

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GetOrdersSubscription, Order_By, Booking_Order_Order_By, Booking_Order_Status_Enum, useGetOrderFiltersQuery, useGetOrdersSubscription, useMarkOrderAsCompleteMutation, useMarkOrderAsDeliveredMutation, useStartPreparingOrderMutation } from '../../../generated/graphql';
import ColorHash from 'color-hash';
import { SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { MoveOrderStatus } from '../../../components/orders/moveOrderStatus';

import './progress.css';

type OrderType = GetOrdersSubscription["orders"][number];

dayjs.extend(relativeTime)

var colorHash = new ColorHash();

export function OrdersPage() {

    const { t } = useTranslation('orders');
    const [_updatedAt, setUpdatedAt] = useState(new Date());
    const [subVariables, setSubscriptionsVars] = useState<{
      orderBy: Booking_Order_Order_By[]
    }>({
      orderBy: [{
        status: Order_By.Desc,
      },{
        created_at: Order_By.Desc,
      }],
    })
    const {data: orderFilters, loading: filterLoader} = useGetOrderFiltersQuery();
    const {data, loading} = useGetOrdersSubscription({
      variables: subVariables
    });
    // const abc = useGetOrdersSubscription();
    // abc.variables
    const history = useHistory();
    const isDesktop = window.innerWidth > 800;


    useEffect(() => {
      let interval = setInterval(function() {
        setUpdatedAt(new Date());
      }, 8000);

      return (() => {
        console.log("cancel");
        clearInterval(interval);
      })
    }, [])

    // if (filterLoader) {
    //     return <p>Loading</p>;
    // }
    
    const columns = [
        {
          title: 'status',
          dataIndex: 'status',
          key: 'status',
          // filters: orderFilters?.statuses.map((status) => ({text: status.values, value: status.values})),
          render: (status : string) => (
            <span>
              <Tag color={colorHash.hex(status)}>
                {t(`status.${status}`)}
              </Tag>
            </span>
          ),
      },
      {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        hide: !isDesktop,
        render: (type : string, order: OrderType) => (<span
          className="cusor-pointer"
          onClick={() => {
          history.push(`/orders/session/${order.table_session.id}`)
          console.log("ok");
        }}>
          <Tag>
            {type}: {order.table_session.table.name}
          </Tag>
          
        </span>),
      },
      {
        hide: !isDesktop,
        title: 'producer',
        key: 'producer',
        render: (_: void,  order: OrderType) => (<span>{order.menu_producer.name}</span>),
      },
      {
        title: 'ordered',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        hide: !isDesktop,
        render: (d: string) => <span>{dayjs(d).fromNow()}</span>,
    },
    {
      title: 'Details',
      hide: isDesktop,
      render: (_: void, order: OrderType) => (
        <div className='flex flex-col justify-center items-center'>
          <Tag onClick={() => {
            history.push(`/orders/session/${order.table_session.id}`)
            console.log("ok");
          }}>
            {order.type}: {order.table_session.table.name}
          </Tag>
          <b>{order.menu_producer.name}</b>
          <div className='text-center'>{dayjs(order.created_at).fromNow()}</div>
        </div>
      ),
    },
        // {
        //     title: 'price',

        //     render: (_ : void, order: OrderType) => {

        //       if (!order.total) {
        //         return null;
        //       }

        //       const price = order.total / 100;

        //       return (
        //         <span>{price}{order.currency}</span>
        //       )
        //     },
        // },

        {
          title: 'Actions',
          render: (_: void, order: OrderType) => <span>

            <MoveOrderStatus 
                order={order}
            />

          </span>,
        }
    ].filter(col => !col.hide);

    console.log("orders, data", data)

    console.log("render");
    

  return (
    <>

    
    <ConfigProvider
      renderEmpty={() => <Empty description="No orders waiting"/>}
      >
        <Table
              rowKey="id" 
              loading={loading}
              expandable={true ? ({
                expandedRowRender: record => (
                  <>
                    {record.order_items.map(orderItem => (
                      <div key={orderItem.id} className="mb-2">
                        <div className="flex">
                          <span>1 x {orderItem.menu_item_price.active_menu_item?.name} </span>
                          <div>
                            {orderItem.extras.map((extra, i) => (
                                <Tag key={i}>{extra.item_extra.name}</Tag>
                            ))}
                          </div>
                        </div>

                        {orderItem.note && (
                          <div className="flex">
                            <b>Note:</b><span>{orderItem.note}</span>
                          </div>
                        )}

                      </div>
                    ))}
                  </>
                ),
                rowExpandable: record => true,
              }) : undefined}
              // onRow={(record: any) => {
              //     return {
              //       onClick: () => {
              //         console.log(record);
              //         history.push(`/orders/${record.id}`)
              //         // props.onItemSelect(record);
              //       },
              //     };
              //   }}
              dataSource={data?.orders}
              onChange={(pagination, filter, sorter: SorterResult<any> | SorterResult<any>[]) => {
                // let tablefilter : Table_Bool_Exp = {};
                // if (filter.room) {
                //   tablefilter.room_id = {
                //     _in: filter.room
                //   }
                // }

                let tableOrderBy : Booking_Order_Order_By = {};
                if (!Array.isArray(sorter) && sorter.column) {
                  const field = sorter.field as ('paid_at');
                  if (sorter.order === 'ascend') {
                    tableOrderBy[field] = Order_By.Asc;
                  } else if (sorter.order == 'descend') {
                    tableOrderBy[field] = Order_By.Desc;
                  }
                }

                // setSubscriptionsVars({
                //   orderBy: tableOrderBy,
                // })
              }}
              columns={columns as any} />;

    </ConfigProvider>

        
    </>
  );
}

