import { Button, Form, Input, InputNumber, Table } from 'antd';
import React from 'react'
import { useParams } from 'react-router-dom';
import { CurrencyPrice } from '../../../../components/currency/price';
import { Menu_Item_Extra_Insert_Input, useGetItemExtraQuery, useInsertItemExtraMutation } from '../../../../generated/graphql';
import { pennyToPrice } from '../../../../utils/price';

export function ProductExtra() {
    const {itemId} = useParams<{itemId: string}>();

    const [insert] = useInsertItemExtraMutation();
    const {data, loading, refetch} = useGetItemExtraQuery({
        variables: {
            itemId,
        }
    });

    async function onFormFinish(value: Menu_Item_Extra_Insert_Input) {
        console.log(value);

        await insert({
            variables: {
                input: value,
            }
        })

        console.log("ok");
        refetch();
    }

    const columns = [
        {
          title: 'name',
          dataIndex: 'name',
          key: 'name'
        },
        {
            title: 'price',
            dataIndex: 'price',
            key: 'price',
            render(price: number) {
                if (price === 0) {
                    return (<span>no price change</span>)
                }
                return (<span><CurrencyPrice price={price} /></span>)
            }
        },
        {
            title: 'created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (d: any) => <span>{(new Date(d)).toLocaleString()}</span>,
        },
    ];


    return (
        <>
             <Form 
                initialValues={{
                    item_id: itemId,
                    price: 0
                }}
                className="p-4"
                onFinish={onFormFinish}>

                <Form.Item name="item_id" hidden>
                    <Input />
                </Form.Item>
                 
                 <Form.Item name="name" label="extra name">
                    <Input />
                </Form.Item>

                <Form.Item name="price" label="optional extra price (in pence)">
                    <InputNumber />
                </Form.Item>


                

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Create Product Extra
                    </Button>
                </Form.Item>

             </Form>



             <Table
                rowKey="id" 
                loading={loading}
                dataSource={data?.menu_item_extra}
                columns={columns} />;
        </>
    );
}
