import { Alert, Button, Form, Input, notification, Table } from 'antd';
import { omit, pick } from 'lodash';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LoaderPage } from '../../../components/utils/loader';
import { GetMenuProducersQuery, useGetMenuProducersQuery, useInsertMenuProducerMutation, useUpdateMenuProducerMutation } from '../../../generated/graphql';
import { EditableTable } from './editableTable';

type MenuProducer = GetMenuProducersQuery["menu_producer"][number];


export function MenuProducers() {

    const [insertProducer] = useInsertMenuProducerMutation();
    const {data, loading, refetch} = useGetMenuProducersQuery();
    const [updateProducer] = useUpdateMenuProducerMutation();

    async function deleteRow(id: string) {
        notification.error({
            message: "can't delete yet",
        })
    }

    async function onRowUpdated(id: string, producer: Partial<MenuProducer>) {
        console.log("updated", {id, producer});
        try {
            await updateProducer({
                variables: {
                    pk: id,
                    update: omit(producer, 'id'),
                }
            })
            notification.success({
                message: 'Producer modified',
            })
        } catch (e) {
            notification.error({
                message: 'Unable to edit Producer',
            })
        }

        refetch();

    }


    async function addUser({name} : {name: string}) {
        try {
            await insertProducer({
                variables: {
                   input: {
                       name,
                       colors: "#34495e",
                   }
                }
            })
        } catch (err) {
            console.log("err", err);
            let message =  "coudn't add producer";

            // if (err instanceof Error && err.message.includes("Uniqueness violation")) {
            //     message = "User with this email already exist";
            // }

            notification.error({
                message,
            })
            return;
        }

        notification.success({
            duration: 1,
            message: 'Menu Producer added',
        })
        refetch();
    }


    if (loading) {
        return (<LoaderPage />)
    }


  return (
    <>
            <div className='my-6'>
                <Alert 
                    message="Where will your food/drinks will be made?" 
                    type="info"
                    
                    showIcon
                    description={<>
                        <p>
                            Producer are where your items prepared.<br />
                            For example you may have drinks sent to the <b>Bar</b> and food sent to the <b>Kitchen</b> <br/>
                            This is useful to sent items to different place but there is no problem if you just have one producer.
                        </p>
                    </>}
                    />
            </div>

            <Form
                onFinish={addUser}
                layout='inline'
            >
                <Form.Item required name="name" label="Producer name" rules={[{ required: true }]} >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Create a new producer
                    </Button>
               </Form.Item>  
            </Form>

            {(data?.menu_producer.length || 0) > 0 && (
                <EditableTable
                onRowUpdated={onRowUpdated}
                deleteRow={deleteRow}
                loading={false}
                data={data?.menu_producer}
            />
            )}



        
    </>
  );
}

