import { Button, Col, Form, Input, notification, Row, Spin, Upload } from 'antd';
import { useGetTablesQuery, Seating_Table, useGetRestaurantInfoQuery, useUpdateRestaurantMutation, Restaurants, useGetSignedUrlLazyQuery} from '../../../generated/graphql';
import omit from 'lodash/omit';
import { ImageFormInput } from '../../../components/forms/imageFormInput';
import { LoaderPage } from '../../../components/utils/loader';

export function RestaurantInfo() {

    const {data, loading, refetch} = useGetRestaurantInfoQuery();
    const [update] = useUpdateRestaurantMutation()


   async function save(values : Restaurants) {

       console.log(values);

        await update({
            variables: {
                resturantId: values.id,
                set: omit(values, 'id'),
            }
        })

        refetch();
        notification.success({
            message: "restaurant info updated!"
        })
    }

    if (loading) {
        return (<LoaderPage />);
    }

    const restaurant = data!.restaurants[0];



    return (
        <>
        
        <Form
            name="basic"
            onFinish={save}
            initialValues={restaurant}

        >

            <Form.Item name="id" label='Restaurant id' hidden>
                <Input disabled />
            </Form.Item>

            <Form.Item
                rules={[{ required: true  }]}
                name="name" label='Restaurant name'
            >
                <Input />
            </Form.Item>

            <Form.Item 
                name="description"
                label='Restaurant tagline'>
                <Input />
            </Form.Item>

            <Form.Item 
                rules={[{ required: true  }]}
                name="address" 
                label='Restaurant address' 
                tooltip="This is added to your reicept"
            >
                <Input.TextArea rows={4} />
            </Form.Item>


              <Form.Item 
                name="receiptFooter" 
                label='Receipt thank you message' 
                tooltip="This is added to the bottom of your reicept"
            >
                <Input.TextArea rows={4} />
            </Form.Item>


            <Form.Item name="logoUrl" label='Restaurant logo'>
                <ImageFormInput />
            </Form.Item>

            
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    save
                </Button>
            </Form.Item>
        </Form>
        </>


    )
}

