import { Alert, Button, message, Space, Steps } from 'antd';
import React, { useState } from 'react';
import { QRAVE_DOMAIN } from '../../../config/environnement';
import { QrChooseDesign } from './choose_design';
import { TableSelect } from './table_select';
const { Step } = Steps;

enum EStepKey {
    DESIGN,
    TABLES,
    PRINT
}

const steps = [
  {
    step: EStepKey.DESIGN,
    title: 'Select your base design',
    content: 'First-content',
  },
  {
    step: EStepKey.TABLES,
    title: 'Choose your tables',
    content: 'Second-content',
  },
  {
    step: EStepKey.PRINT,
    title: 'Print',
    content: 'Last-content',
  },
];

export const DoItYourselfPage = () => {
  const [current, setCurrent] = useState<EStepKey>(EStepKey.DESIGN);
  const [printData, setPrintData] = useState<any>({});
  console.log({printData});

  function goToPrintApp() {
    const printerApp = window.open(`https://editor.${QRAVE_DOMAIN}/`);
    // const printerApp = window.open("http://localhost:3001/");
    if (!printerApp) {
        return message.error("unable to open the editor app");
    }

    function onMessageReceived(msg: MessageEvent<any>) {
        if (!printerApp || printerApp.closed) {
            return;
        }
        console.log("received message", {msg});

        const {data} = msg;

        if (data.type == 'ready') {
            printerApp.postMessage(printData, '*');
        }

    }

    window.addEventListener("message", onMessageReceived);

    var timer = setInterval(function() { 
        if(printerApp.closed) {
            clearInterval(timer);
            window.removeEventListener("message", onMessageReceived);
        }
    }, 1000);

  }

  const onChange = (value: number) => {
    console.log('onChange:', value);
    if (value < current) {
        setCurrent(value);
    }
  };

  return (
    <div className="max-w-3xl m-4 mx-auto">

        <Alert 
            message="print your own QR code" 
            type="info"
            showIcon
            description={<>
                <div>
                    <ul className='list-decimal'>
                        <li>Select the design {'&'} tables you would like a QR code for</li>
                        <li>Once you click 'Create' you will be redirected to a tool to make you own layout</li>
                        <li>You can print your design on any type of A4 paper: regular or sticky paper</li>
                    </ul>
                </div>
            </>}
        />

       <div className="mt-2"></div>

      <Steps onChange={onChange} current={current}>
        {steps.map((item) => (
          <Step key={item.step} title={item.title} />
        ))}
      </Steps>

      <div>
        {current === EStepKey.DESIGN && (
            <QrChooseDesign onSelect={(data) => {
                setPrintData((previous : any) => ({...previous, ...data}));
                setCurrent(EStepKey.TABLES);
            }} />
        )}
        {current === EStepKey.TABLES && (
            <TableSelect onSelect={(data) => {
                setPrintData((previous : any) => ({...previous, ...data}));
                setCurrent(EStepKey.PRINT);
            }} />
        )}
        {current === EStepKey.PRINT && (
            <div className='flex justify-center items-center m-6'>
                <p></p>
                <Button type='primary' size='large' onClick={goToPrintApp}>Go to print app</Button>
            </div>
        )}
      </div>

    </div>
  );
};

