import { Button, Form, Input, notification, Radio } from 'antd'
import React from 'react'
import { Restaurant_Wifi, Restaurant_Wifi_Insert_Input, useUpdateWifiSettingMutation } from '../../../generated/graphql';

export function RestaurantWifi() {

    const [updateWifi] = useUpdateWifiSettingMutation();

    async function onFinish(values : Restaurant_Wifi_Insert_Input) {

        console.log(values);

        try {

            await updateWifi({
                variables: {
                    wifi: values,
                }
             })
     
             notification.success({
                 message: "your restaurant wifi details were updated"
             })
        } catch (e) {
            notification.error({
                message: "Unable to update your wifi settings"
            })
        }



        
    }
    
    return (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="ssid (wifi name)"
            name="ssid"
            rules={[{ required: true, message: 'Please input your ssid!' }]}
          >
            <Input />
          </Form.Item>
    
          <Form.Item
            label="Wifi Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
    
          <Form.Item
                label="wifi security"
                name="encryption"
                rules={[{ required: true, message: 'select your wifi security' }]}
            >
                <Radio.Group>
                    <Radio.Button value="WPA">WPA</Radio.Button>
                    <Radio.Button value="WEP">WEP</Radio.Button>
                    <Radio.Button value="NONE">None</Radio.Button>
                </Radio.Group>
            </Form.Item>
    
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save Wifi
            </Button>
          </Form.Item>
        </Form>);
}