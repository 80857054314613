import { Alert } from 'antd';
import React from 'react'
import { AlignerComponent } from '../../components/layout/Aligner';

export function SanddBoxPage() {
    return (
        <AlignerComponent>
            <Alert 
                message="Sandbox" 
                type="info" 
                showIcon
                description={<>
                    <p>
                        At the moment payments are not real so you can explore the app.<br />
                        If you are ready to take customer payment contact us via the chat below<br />
                        We will take send a form so you can fill in your details and start receiving real payments.
                    </p>
                </>}
                />
        </AlignerComponent>
    );
}