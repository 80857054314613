import clsx from 'clsx';

interface Props {
    items: Array<{key: string, title: string}>;
    selectedKey?: string;
    onItemSelect: (nodeKey: string) => void;
}

export function UiMenuItemFilter(props : Props) {

	console.log("details", props.selectedKey)
    return (
        <ul className="grid grid-cols-12 items-center pb-0.5 pl-4 "
        style={{borderColor: "rgba(0, 0, 0, 0.05)"}}>

            {
                props.items.map((item) => (
                  <li 
                    onClick={() => props.onItemSelect(item.key)}
                    key={item.key}
                    className="col-span-3">
                    <div className={clsx("rs-nav-tab cursor-pointer px-1 py-1 rounded-3xl text-center", {
                        "bg-yellow": props.selectedKey == item.key,
                        "bg-white": props.selectedKey !== item.key,
                    })}
                        data-id="tab1">
                        <span className="grow shrink text-xs font-normal text-black leading-none">{item.title}</span>
                    </div>
                 </li>
                ))
            }
        

    </ul>
    )
}