import React, { useContext } from "react";

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContext, AuthContextHub } from "./Auth";
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split, HttpLink } from '@apollo/client';
import { QRAVE_DOMAIN } from "../config/environnement";


function Apollo(props: any) {

  function getLink() {
    const httpLink = createHttpLink({
      uri: `https://hasura.${QRAVE_DOMAIN}/v1/graphql`,
    });
  
    const wsLink = new WebSocketLink({
      uri: `wss://hasura.${QRAVE_DOMAIN}/v1/graphql`,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => {
          // const user = await Auth.currentAuthenticatedUser();
          let idJwtToken = localStorage.getItem('jwt');

          return ({
            headers: {
              Authorization: `Bearer ${idJwtToken}`
            }
          })
        }
      },
  
    });
    
    const authLink = setContext(async (_, { headers }) => {
      //  const user = await Auth.currentAuthenticatedUser();
       let idJwtToken = localStorage.getItem('jwt');
  
      let extraHeaders: any = {};
      extraHeaders['Authorization'] = `Bearer ${idJwtToken}`;
  
      return {
        headers: {
          ...headers,
          ...extraHeaders,
        }
      }
    });
  
  
  
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(httpLink),
    );
  }



  const client = new ApolloClient({
    link: getLink(),
    cache: new InMemoryCache()
  });

  client.onResetStore(async () => {
    client.setLink(getLink());
  })


  AuthContextHub.hub.on("auth", () => {
    console.log("apollo new auth");
    client.resetStore();
  })

  /*
  Hub.listen('auth',(data : any) => {
    const { channel, payload } = data;
    if (channel === 'auth') {
      console.log("reconnecting WS for event" + payload.event);
      client.resetStore()
    }
  });
  */
  
    return (
      <ApolloProvider client={client}>
        {props.children}
      </ApolloProvider>
    );
}


export default Apollo;