import { Button, Card, Checkbox, Input, message, Modal, notification, Popconfirm, Radio, Steps, Tag } from 'antd';
import { divide, orderBy } from 'lodash';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LoaderPage } from '../../../components/utils/loader';
import { Booking_Order_Payment_Items_Insert_Input, Booking_Order_Status_Enum, Booking_Table_Sessions_Status_Enum, useInsertOrderPaymentMutation, useUpdateSessionStatusMutation, useOveriteOrderStatusMutation, useGetSessionOverviewSubscription, useGetSessionOverviewStaticQuery, useGetSessionOrdersOverviewSubscription, User_Roles_Enum_Enum } from '../../../generated/graphql';
import { pennyToPrice } from '../../../utils/price';
import ColorHash from 'color-hash';
import dayjs from 'dayjs';
import { MoveOrderStatus } from '../../../components/orders/moveOrderStatus';
import { CodeSandboxOutlined, DollarOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { ItemNote } from '../../../components/orders/itemNote';
import Immutable from 'immutable';
import { OrderItemPriceWithDiscount } from '../../../components/orders/editPriceDiscount';
import { AuthContext } from '../../../context/Auth';
import { CurrencyPrice, CurrencySymbol } from '../../../components/currency/price';

interface UrlParams {
    sessionId: string;
}

var colorHash = new ColorHash();


const { Step } = Steps;

interface TakePaymentState {
    open: boolean;
    manualAmount?: string;
    selected?: Immutable.Set<string>;
    selectedTotal?: number;
}

export function OrderOverview() {
    const { t } = useTranslation('orders');
    const {sessionId} = useParams<UrlParams>();
    const {user} = useContext(AuthContext);
    const {data: sessionStatic} = useGetSessionOverviewStaticQuery();
    const {data, loading} = useGetSessionOverviewSubscription({
        variables: {
            sessionId, 
        }
    })
    const {data: ordersData, loading: orderLoading} = useGetSessionOrdersOverviewSubscription({
        variables: {
            sessionId, 
        }
    })
    const [markItemAsPaid] = useInsertOrderPaymentMutation();
    const [overiteOrderStatus] = useOveriteOrderStatusMutation();
    const [updateStatus] = useUpdateSessionStatusMutation();
    // const [selectedItems, setSelectedItem] = useState<{
    //     total: number,
    //     selected: Immutable.Set<string>
    // }>({
    //     total: 0,
    //     selected: Immutable.Set()
    // });

    const [paymentModal, setPaymentModal] = useState<TakePaymentState>({open: false});

    const session = data?.session;
    let orderStatus = (sessionStatic?.orderStatus || []).map(s => s.values);
    orderStatus = ([...orderStatus]).sort((a, b) => a.localeCompare(b))
    console.log(orderStatus);

    if (loading || orderLoading || !session) {
        return <LoaderPage/>
    }
    const unpaidTotal = Math.max(session.total - session.paid, 0);

    /*
    async function markAsPaid() {
        console.log(selectedItems)

        const items : Booking_Order_Payment_Items_Insert_Input[] = selectedItems.selected.toArray().map(id => ({
            order_item_id: id
        }))



        await markItemAsPaid({
            variables: {
              tableSessionId: sessionId,
               total: 0,
               items: items
            }
        })

        notification.success({
            message: "items marked as paid"
        })

        setSelectedItem({
            total: 0,
            selected: Immutable.Set()
        });
    }
    */


    // function getOrderTag(total: number, paid: number) {

    //     if (paid === 0) {
    //         return (
    //             <Tag color="red">
    //                 unpaid
    //             </Tag>
    //         )
    //     } else if (total - paid === 0) {
    //         return (
    //             <Tag color="green">
    //                 paid
    //             </Tag>
    //         ) 
    //     } else {
    //         return (
    //             <Tag color="orange">
    //                 partly paid
    //             </Tag>
    //         )          
    //     }

    // }

    async function updateSessionStatus(status: Booking_Table_Sessions_Status_Enum) {
        await updateStatus({
            variables: {
                sessionId,
                status
            }
        })
    }


    async function closeSession() {
        await updateSessionStatus(Booking_Table_Sessions_Status_Enum.Closed)
        notification.success({
            message: "session closed!",
        })
    }

    async function reopnedSession() {
        if ((user.userRole == User_Roles_Enum_Enum.Staff)) {
            return notification.error({
                message: "only a manager can re-open a session",
            })
        }
        await  updateSessionStatus(Booking_Table_Sessions_Status_Enum.Active)
            notification.success({
                message: "session re-opned!",
            })
    }

    const hasUndelivered = ordersData?.orders.find(o => o.status != Booking_Order_Status_Enum.S_050Delivered)

    return (
        <>
            <Modal
                visible={paymentModal.open}
                onCancel={() =>  setPaymentModal({open: false})}
                okButtonProps={{
                    disabled: !paymentModal.manualAmount && paymentModal.selected?.size === 0,
                }}
                okText={"Take Payment"}
                onOk={async () => {

                    let amount = 0;
                    let items : Booking_Order_Payment_Items_Insert_Input[] = [];

                    if (paymentModal.selected?.size) {
                        items = paymentModal.selected.toArray().map(id => ({
                            order_item_id: id
                        }))
                        amount = paymentModal.selectedTotal || 0;    
                    } else {
                        amount = Math.floor(parseFloat(paymentModal.manualAmount!) * 100)
                    }

                    console.log({amount, items});
                    await markItemAsPaid({
                        variables: {
                          tableSessionId: sessionId,
                           total: amount,
                           items,
                        }
                    })
                    setPaymentModal({open: false})
                }}
            >
                {/* <div>payment modal</div> */}
                
                <Radio.Group 
                    onChange={(e) => {
                        const r = e.target.value;
                        if (r === "auto") {
                            setPaymentModal({
                                open: false,
                                selectedTotal: 0,
                                selected: Immutable.Set(),
                            })

                        }
                    }}
                    value={paymentModal.selected?.size ? "auto" : "manual"}
                    size="large" 
                    className='justify-around' 
                    style={{display: 'flex'}}>
                    <Radio.Button value="manual">Enter Amount</Radio.Button>
                    <Radio.Button value="auto">Select Items</Radio.Button>
                </Radio.Group>

                {(paymentModal.selected?.size) ? (
                    <div>
                        <a onClick={() => {
                            setPaymentModal({
                                ...paymentModal,
                                open: false,
                            })
                        }}>
                            {paymentModal.selected?.size} items selected for <CurrencyPrice price={paymentModal.selectedTotal!} />
                        </a>
                    </div>
                ) : (
                    <div className='flex align-middle m-5'>
                    <Button 
                        onClick={() => {
                            setPaymentModal({
                                open: true,
                                manualAmount: (unpaidTotal / 100).toFixed(2),
                            })
                        }}
                        size='middle'>Full</Button>
                    <Button
                        onClick={() => {
                            setPaymentModal({
                                open: true,
                                manualAmount: (unpaidTotal / 200).toFixed(2),
                            })
                        }}
                        size='middle'>/2</Button>
                    <Button
                        onClick={() => {
                            setPaymentModal({
                                open: true,
                                manualAmount: (unpaidTotal / 300).toFixed(2),
                            })
                        }}
                        size='middle'>/3</Button>
                    <Button
                        onClick={() => {
                            setPaymentModal({
                                open: true,
                                manualAmount: (unpaidTotal / 400).toFixed(2),
                            })
                        }}
                        size='middle'>/4</Button>
                    <Input
                        value={paymentModal.manualAmount}
                        onChange={(e) => {
                            console.log(e.target.value)

                            setPaymentModal({
                                open: true,
                                manualAmount: e.target.value
                            })

                        }}
                        inputMode='numeric'
                        addonAfter={<CurrencySymbol />} />
                    </div>
                )}


            </Modal>
            <div className='flex justify-between m-2'>
                <div>
                    <p className='m-0'>{session.reference}</p>
                    <p className='m-0'>{(new Date(session.created_at).toLocaleString())}</p>
                    <p className='m-0'>table: {session.table.name}</p>
                    <p className='m-0'>paid: <CurrencyPrice price={session.paid} hideCurrency />/<CurrencyPrice price={session.total} /></p>
                </div>

                {(session.status === Booking_Table_Sessions_Status_Enum.Closed) ? (
                <Popconfirm 
                    onConfirm={reopnedSession}
                    placement="topLeft"
                    title="Are you sure you want to re-open this session?" >
                    <Button 
                        icon={<UnlockOutlined />}
                    >Re-open Session</Button>
                    </Popconfirm>
                ) : (
                    ((unpaidTotal || hasUndelivered) ? (
                        <Popconfirm
                        icon={null}
                        placement="topLeft" title={() => (
                            <>
                                <div>All items paid: {unpaidTotal > 0  ? ('❌') : ('✅')}</div>
                                <div>All items delivered: {hasUndelivered ? ('❌') : ('✅')}</div>
                            </>
                        )}
                        onConfirm={closeSession} okText="close anyway" cancelText="cancel">
                        <Button 
                            size="small"
                            icon={<LockOutlined />}

                            >Close session</Button>
                    </Popconfirm>
                    ): (
                        <Button 
                          onClick={closeSession}
                          type='primary'
                        ><LockOutlined /> Close session</Button>
                    ))


                )}
            </div>


            <div className='m-2 flex justify-around'>
                {paymentModal.selected?.size ? (
                    <Button 
                        onClick={() => {
                            setPaymentModal({
                                ...paymentModal,
                                open: true,
                            })
                        }}>
                            <span>
                                Pay {paymentModal.selected.size} items for <CurrencyPrice price={paymentModal.selectedTotal!} />            
                            </span>
                        </Button>
                ) : (
                    <Button
                        onClick={() => setPaymentModal({open: true})}>
                         Take payment
                     </Button>
                )}
            </div>

             {ordersData?.orders.map((order, id) => (
                 <Card 
                    key={order.id}
                    title={(

                        <>
                            <div className="flex mb-4">
                                <span  className='grow' style={{
                                    color: colorHash.hex(order.id)
                                }} >
                                    Order {id+1}: {dayjs(order.created_at).fromNow()}
                                </span>

                                <div>
                                    <Tag>
                                        <CurrencyPrice price={order.total} />  
                                    </Tag>
                                </div>
                            </div>
                            <Steps
                                onChange={async (idx) => {
                                    console.log(orderStatus[idx]);
                                    try {
                                        await overiteOrderStatus({
                                            variables: {
                                                orderId: order.id,
                                                orderStatus: orderStatus[idx] as Booking_Order_Status_Enum,
                                            }
                                        })
    
                                        message.success("order status changed");
                                    } catch (err) {
                                        message.error("unable to change status");
                                    }
  
                                }}
                                size="small"
                                current={orderStatus.indexOf(order.status)}
                                progressDot >
                                {orderStatus.map(status => (
                                    <Step key={status} title={t(`status.${status}`)} />
                                ))}
                            </Steps>
                        </>
                    )}>
                    
                    {order.order_items.map(item => (
                        <div key={item.id} className="flex">
                            {(paymentModal.selected) ? (
                                <Checkbox 
                                className='grow shrink'
                                checked={paymentModal.selected?.has(item.id)}
                                onChange={(checkbox) => {

                                    setPaymentModal(state => {
                                        // ...state,
                                        // name: target.value,

                                        let {selected, selectedTotal = 0} = state;

                                        if (!selected) {
                                            throw new Error("selected not found");
                                        }

                                        if (checkbox.target.checked) {
                                            selected = selected.add(item.id);
                                            selectedTotal += item.computed_total;
                                        } else if (selected.has(item.id)) {
                                            selected = selected.delete(item.id);
                                            selectedTotal -= item.computed_total;
                                        }
                                        return {
                                            selected,
                                            selectedTotal,
                                            open: false,
                                        };
                                    });

                                }}>
                                <Tag>{item.order_payment_items_aggregate.aggregate?.count || '0'} payment linked</Tag>
                                
                                {item.menu_item_price.menu_item?.name} <ItemNote note={item.note} /> 

                                {item.extras.map(extra => (
                                    <Tag key={extra.item_extra.name}>{extra.item_extra.name}</Tag>
                                ))}
                                </Checkbox>
                            ) : (
                                <span className='grow shrink'>
                                    {item.menu_item_price.menu_item?.name} <ItemNote note={item.note} /> 

                                    {item.extras.map(extra => (
                                        <Tag key={extra.item_extra.name}>{extra.item_extra.name}</Tag>
                                    ))}
                                </span>
                            )}
                            <div>
                               <OrderItemPriceWithDiscount item={item} />                  
                            </div>
      
                        </div>
                    ))}

                 </Card>
             ))}
        </>
    );
}

