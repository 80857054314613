import { Button, Checkbox, Form, Input, InputNumber, Modal } from "antd";
import { useState } from "react";
import { GetCompleteMenuQuery } from "../../generated/graphql";
import { OrderItemsForm } from "../../pages/orders/session/new/type";
import { pennyToPrice } from "../../utils/price";
import {v4 as uuidv4} from 'uuid';
import { ContainerOutlined } from "@ant-design/icons";
import { CurrencyPrice } from "../currency/price";

type MenuTree = GetCompleteMenuQuery["menu"]
type MenuItems = MenuTree[number]["items"];
type MenuItem = MenuItems[number];

interface UiMenuItemProps {
    item: MenuItem,
    quantity?: number;
    onAddItem: (details: OrderItemsForm) => void,
    onRemoveOneItem: () => void,
}

export function UiMenuItem({item, onAddItem, onRemoveOneItem, quantity}: UiMenuItemProps) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()


    function handleCancel(e: React.MouseEvent) {
        setIsModalVisible(false);
        e.preventDefault();
        e.stopPropagation();
    }

    function formFinish(values: any) {
        console.log({values});

        // const extraIds = Object.keys(values);

        const selectedExtra = item.extras.filter(extra => values[extra.id])

        form.resetFields();
        onAddItem({
            rowId: uuidv4(),
            productId: item.id,
            productName: item.name,
            items: [{
                id: uuidv4(),
                itemPriceId: item.active_price_item.id,
                note: values.note ? values.note : undefined,
                price: item.active_price_item.price + selectedExtra.reduce((acc,c) => acc + c.price, 0),
                extra: selectedExtra,
            }]
        });
        setIsModalVisible(false);
    }

    const hasExtra = item.extras.length > 0;

    function itemClick(forceModal = false) {

        if (forceModal || hasExtra) {
            setIsModalVisible(true)

        } else {
            onAddItem({
                rowId: uuidv4(),
                productId: item.id,
                productName: item.name,
                items: [{
                    id: uuidv4(),
                    itemPriceId: item.active_price_item.id,
                    price: item.active_price_item.price,
                    extra: [],
                }]
            });
        }

    }

    function PriceLabel({name, price} : {price: number, name: string}) {

        if (price === 0) {
            return (
                <span>{name}</span>
            )
        } else if (price > 0) {
            return (
                <span>{name} +<CurrencyPrice price={price} /></span>
            )
        }
        return (
            <span>{name} <CurrencyPrice price={price} /></span>
        )                                
    }

    return (

        <div>
            <Modal
            footer={null}
            closable={false}
            visible={isModalVisible}
            onCancel={handleCancel}>


                <Form
                    form={form}
                    onFinish={formFinish}
                    initialValues={{
                    }}
                    >

                    <Form.Item 
                        name="note">
                            <Input placeholder="add a note (optional)"></Input>
                    </Form.Item>           

                    {item.extras.map(extra => (
                        <Form.Item 
                            key={extra.id}
                            name={extra.id}
                            valuePropName="checked" >
                            <Checkbox> <PriceLabel {...extra} /> </Checkbox>
                        </Form.Item>     
                    ))}

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add Item
                        </Button>
                    </Form.Item>

                </Form>
        </Modal>
          <div onClick={() => itemClick()}  className="grid grid-cols-12  bg-white rounded-2xl p-4 mb-4 cursor-pointer">
            <div className="col-span-8 flex items-center ">
                {(item.imageUrl) && (
                   <div className="w-16 shrink-0">
                      <img className="rounded-2xl h-16 w-full" src={item.imageUrl} alt="" />
                   </div>
                )}
                <div className="ml-4">
                    <h4 className="text-black font-medium text-xs font-body leading-tight mb-1">
                        {item.name}
                    </h4>
                    <p className="text-gray500 text-xm font-normal leading-tight mb-1 line-clamp-3">
                        {item.description}
                    </p>
                </div>
            </div>
            <div className="col-span-4 text-right">
                <p className="text-black font-medium leading-tight text-sm px-3 mb-3">
                    {quantity ? <b>{quantity} x</b> : null} <CurrencyPrice price={item.active_price_item.price} />
                </p>

                <div
                className="p-2"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    itemClick(true);

                }}>
                    + <ContainerOutlined />
                </div>

            </div>
        </div>

        </div>
  
    )
}