import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamptz: any;
  uuid: any;
};

export type BookingItemsCreated = {
  __typename?: 'BookingItemsCreated';
  itemId: Scalars['uuid'];
  name: Scalars['String'];
  orderId: Scalars['uuid'];
  price: Scalars['Int'];
  priceId: Scalars['uuid'];
};

export type Boolean_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _cast?: InputMaybe<Boolean_Cast_Exp>;
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type GetQrLinkRealIdOutput = {
  __typename?: 'GetQrLinkRealIdOutput';
  qrId: Scalars['String'];
  tableId?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
};

export type Int_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _cast?: InputMaybe<Int_Cast_Exp>;
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type OrderItemExtra = {
  id: Scalars['uuid'];
};

export type OrderItems = {
  extra?: InputMaybe<Array<OrderItemExtra>>;
  itemPriceId: Scalars['uuid'];
  note?: InputMaybe<Scalars['String']>;
};

export type QrData = {
  __typename?: 'QrData';
  id: Scalars['String'];
  qrId: Scalars['String'];
};

export type SignedUrlOutput = {
  __typename?: 'SignedUrlOutput';
  fields: Scalars['jsonb'];
  url: Scalars['String'];
};

export type StaffLoginToken = {
  __typename?: 'StaffLoginToken';
  token: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  link: Scalars['String'];
};

export type Bigint_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _cast?: InputMaybe<Bigint_Cast_Exp>;
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "booking.order" */
export type Booking_Order = {
  __typename?: 'booking_order';
  created_at: Scalars['timestamptz'];
  currency: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu_producer: Menu_Producer;
  /** An array relationship */
  order_items: Array<Booking_Order_Items>;
  /** An aggregate relationship */
  order_items_aggregate: Booking_Order_Items_Aggregate;
  /** An object relationship */
  order_status: Booking_Order_Status;
  /** An object relationship */
  order_type: Booking_Order_Type;
  paid_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  printer_job_orders: Array<Booking_Printer_Job_Order>;
  /** An aggregate relationship */
  printer_job_orders_aggregate: Booking_Printer_Job_Order_Aggregate;
  producer_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  status: Booking_Order_Status_Enum;
  /** An object relationship */
  table_session: Booking_Table_Sessions;
  table_session_id: Scalars['uuid'];
  /** A computed field, executes function "order_total_price" */
  total?: Maybe<Scalars['bigint']>;
  type: Booking_Order_Type_Enum;
};


/** columns and relationships of "booking.order" */
export type Booking_OrderOrder_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


/** columns and relationships of "booking.order" */
export type Booking_OrderOrder_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


/** columns and relationships of "booking.order" */
export type Booking_OrderPrinter_Job_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


/** columns and relationships of "booking.order" */
export type Booking_OrderPrinter_Job_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};

/** aggregated selection of "booking.order" */
export type Booking_Order_Aggregate = {
  __typename?: 'booking_order_aggregate';
  aggregate?: Maybe<Booking_Order_Aggregate_Fields>;
  nodes: Array<Booking_Order>;
};

/** aggregate fields of "booking.order" */
export type Booking_Order_Aggregate_Fields = {
  __typename?: 'booking_order_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Max_Fields>;
  min?: Maybe<Booking_Order_Min_Fields>;
};


/** aggregate fields of "booking.order" */
export type Booking_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.order" */
export type Booking_Order_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Order_Max_Order_By>;
  min?: InputMaybe<Booking_Order_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.order" */
export type Booking_Order_Arr_Rel_Insert_Input = {
  data: Array<Booking_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.order". All fields are combined with a logical 'AND'. */
export type Booking_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_producer?: InputMaybe<Menu_Producer_Bool_Exp>;
  order_items?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  order_status?: InputMaybe<Booking_Order_Status_Bool_Exp>;
  order_type?: InputMaybe<Booking_Order_Type_Bool_Exp>;
  paid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  printer_job_orders?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
  producer_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Booking_Order_Status_Enum_Comparison_Exp>;
  table_session?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  table_session_id?: InputMaybe<Uuid_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<Booking_Order_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order" */
export enum Booking_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'order_pkey'
}

/** input type for inserting data into table "booking.order" */
export type Booking_Order_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_producer?: InputMaybe<Menu_Producer_Obj_Rel_Insert_Input>;
  order_items?: InputMaybe<Booking_Order_Items_Arr_Rel_Insert_Input>;
  order_status?: InputMaybe<Booking_Order_Status_Obj_Rel_Insert_Input>;
  order_type?: InputMaybe<Booking_Order_Type_Obj_Rel_Insert_Input>;
  paid_at?: InputMaybe<Scalars['timestamptz']>;
  printer_job_orders?: InputMaybe<Booking_Printer_Job_Order_Arr_Rel_Insert_Input>;
  producer_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Booking_Order_Status_Enum>;
  table_session?: InputMaybe<Booking_Table_Sessions_Obj_Rel_Insert_Input>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Booking_Order_Type_Enum>;
};

/** columns and relationships of "booking.order_items" */
export type Booking_Order_Items = {
  __typename?: 'booking_order_items';
  computed_total: Scalars['Int'];
  discount: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu_item_price: Menu_Item_Price;
  menu_price_id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  order: Booking_Order;
  /** An array relationship */
  order_items_extras: Array<Booking_Order_Items_Extra>;
  /** An aggregate relationship */
  order_items_extras_aggregate: Booking_Order_Items_Extra_Aggregate;
  /** An array relationship */
  order_payment_items: Array<Booking_Order_Payment_Items>;
  /** An aggregate relationship */
  order_payment_items_aggregate: Booking_Order_Payment_Items_Aggregate;
  orders_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};


/** columns and relationships of "booking.order_items" */
export type Booking_Order_ItemsOrder_Items_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


/** columns and relationships of "booking.order_items" */
export type Booking_Order_ItemsOrder_Items_Extras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


/** columns and relationships of "booking.order_items" */
export type Booking_Order_ItemsOrder_Payment_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


/** columns and relationships of "booking.order_items" */
export type Booking_Order_ItemsOrder_Payment_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};

/** aggregated selection of "booking.order_items" */
export type Booking_Order_Items_Aggregate = {
  __typename?: 'booking_order_items_aggregate';
  aggregate?: Maybe<Booking_Order_Items_Aggregate_Fields>;
  nodes: Array<Booking_Order_Items>;
};

/** aggregate fields of "booking.order_items" */
export type Booking_Order_Items_Aggregate_Fields = {
  __typename?: 'booking_order_items_aggregate_fields';
  avg?: Maybe<Booking_Order_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Items_Max_Fields>;
  min?: Maybe<Booking_Order_Items_Min_Fields>;
  stddev?: Maybe<Booking_Order_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Booking_Order_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Booking_Order_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Booking_Order_Items_Sum_Fields>;
  var_pop?: Maybe<Booking_Order_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Booking_Order_Items_Var_Samp_Fields>;
  variance?: Maybe<Booking_Order_Items_Variance_Fields>;
};


/** aggregate fields of "booking.order_items" */
export type Booking_Order_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.order_items" */
export type Booking_Order_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Booking_Order_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Order_Items_Max_Order_By>;
  min?: InputMaybe<Booking_Order_Items_Min_Order_By>;
  stddev?: InputMaybe<Booking_Order_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Booking_Order_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Booking_Order_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Booking_Order_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Booking_Order_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Booking_Order_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Booking_Order_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "booking.order_items" */
export type Booking_Order_Items_Arr_Rel_Insert_Input = {
  data: Array<Booking_Order_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Booking_Order_Items_Avg_Fields = {
  __typename?: 'booking_order_items_avg_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "booking.order_items" */
export type Booking_Order_Items_Avg_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "booking.order_items". All fields are combined with a logical 'AND'. */
export type Booking_Order_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Items_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Items_Bool_Exp>>;
  computed_total?: InputMaybe<Int_Comparison_Exp>;
  discount?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_item_price?: InputMaybe<Menu_Item_Price_Bool_Exp>;
  menu_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Booking_Order_Bool_Exp>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
  orders_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_items" */
export enum Booking_Order_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeOrdersItemsPkey = 'stripe_orders_items_pkey'
}

/** columns and relationships of "booking.order_items_extra" */
export type Booking_Order_Items_Extra = {
  __typename?: 'booking_order_items_extra';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  item_extra: Menu_Item_Extra;
  menu_item_extra: Scalars['uuid'];
  /** An object relationship */
  order_item: Booking_Order_Items;
  order_items_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};

/** aggregated selection of "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Aggregate = {
  __typename?: 'booking_order_items_extra_aggregate';
  aggregate?: Maybe<Booking_Order_Items_Extra_Aggregate_Fields>;
  nodes: Array<Booking_Order_Items_Extra>;
};

/** aggregate fields of "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Aggregate_Fields = {
  __typename?: 'booking_order_items_extra_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Items_Extra_Max_Fields>;
  min?: Maybe<Booking_Order_Items_Extra_Min_Fields>;
};


/** aggregate fields of "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Order_Items_Extra_Max_Order_By>;
  min?: InputMaybe<Booking_Order_Items_Extra_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Arr_Rel_Insert_Input = {
  data: Array<Booking_Order_Items_Extra_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Items_Extra_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.order_items_extra". All fields are combined with a logical 'AND'. */
export type Booking_Order_Items_Extra_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Items_Extra_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Items_Extra_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_extra?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
  menu_item_extra?: InputMaybe<Uuid_Comparison_Exp>;
  order_item?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  order_items_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_items_extra" */
export enum Booking_Order_Items_Extra_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderItemsExtraPkey = 'order_items_extra_pkey'
}

/** input type for inserting data into table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_extra?: InputMaybe<Menu_Item_Extra_Obj_Rel_Insert_Input>;
  menu_item_extra?: InputMaybe<Scalars['uuid']>;
  order_item?: InputMaybe<Booking_Order_Items_Obj_Rel_Insert_Input>;
  order_items_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booking_Order_Items_Extra_Max_Fields = {
  __typename?: 'booking_order_items_extra_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  menu_item_extra?: Maybe<Scalars['uuid']>;
  order_items_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_extra?: InputMaybe<Order_By>;
  order_items_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Order_Items_Extra_Min_Fields = {
  __typename?: 'booking_order_items_extra_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  menu_item_extra?: Maybe<Scalars['uuid']>;
  order_items_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_extra?: InputMaybe<Order_By>;
  order_items_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Mutation_Response = {
  __typename?: 'booking_order_items_extra_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Items_Extra>;
};

/** on_conflict condition type for table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_On_Conflict = {
  constraint: Booking_Order_Items_Extra_Constraint;
  update_columns?: Array<Booking_Order_Items_Extra_Update_Column>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_items_extra". */
export type Booking_Order_Items_Extra_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_extra?: InputMaybe<Menu_Item_Extra_Order_By>;
  menu_item_extra?: InputMaybe<Order_By>;
  order_item?: InputMaybe<Booking_Order_Items_Order_By>;
  order_items_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_items_extra */
export type Booking_Order_Items_Extra_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "booking.order_items_extra" */
export enum Booking_Order_Items_Extra_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MenuItemExtra = 'menu_item_extra',
  /** column name */
  OrderItemsId = 'order_items_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "booking.order_items_extra" */
export type Booking_Order_Items_Extra_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_item_extra?: InputMaybe<Scalars['uuid']>;
  order_items_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "booking.order_items_extra" */
export enum Booking_Order_Items_Extra_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MenuItemExtra = 'menu_item_extra',
  /** column name */
  OrderItemsId = 'order_items_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for incrementing numeric columns in table "booking.order_items" */
export type Booking_Order_Items_Inc_Input = {
  computed_total?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "booking.order_items" */
export type Booking_Order_Items_Insert_Input = {
  computed_total?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_item_price?: InputMaybe<Menu_Item_Price_Obj_Rel_Insert_Input>;
  menu_price_id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Booking_Order_Obj_Rel_Insert_Input>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Arr_Rel_Insert_Input>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Arr_Rel_Insert_Input>;
  orders_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booking_Order_Items_Max_Fields = {
  __typename?: 'booking_order_items_max_fields';
  computed_total?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  menu_price_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  orders_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.order_items" */
export type Booking_Order_Items_Max_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_price_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orders_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Order_Items_Min_Fields = {
  __typename?: 'booking_order_items_min_fields';
  computed_total?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  menu_price_id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  orders_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.order_items" */
export type Booking_Order_Items_Min_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_price_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orders_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.order_items" */
export type Booking_Order_Items_Mutation_Response = {
  __typename?: 'booking_order_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Items>;
};

/** input type for inserting object relation for remote table "booking.order_items" */
export type Booking_Order_Items_Obj_Rel_Insert_Input = {
  data: Booking_Order_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Items_On_Conflict>;
};

/** on_conflict condition type for table "booking.order_items" */
export type Booking_Order_Items_On_Conflict = {
  constraint: Booking_Order_Items_Constraint;
  update_columns?: Array<Booking_Order_Items_Update_Column>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_items". */
export type Booking_Order_Items_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_price?: InputMaybe<Menu_Item_Price_Order_By>;
  menu_price_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  order?: InputMaybe<Booking_Order_Order_By>;
  order_items_extras_aggregate?: InputMaybe<Booking_Order_Items_Extra_Aggregate_Order_By>;
  order_payment_items_aggregate?: InputMaybe<Booking_Order_Payment_Items_Aggregate_Order_By>;
  orders_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_items */
export type Booking_Order_Items_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "booking.order_items" */
export enum Booking_Order_Items_Select_Column {
  /** column name */
  ComputedTotal = 'computed_total',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MenuPriceId = 'menu_price_id',
  /** column name */
  Note = 'note',
  /** column name */
  OrdersId = 'orders_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "booking.order_items" */
export type Booking_Order_Items_Set_Input = {
  computed_total?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_price_id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  orders_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Booking_Order_Items_Stddev_Fields = {
  __typename?: 'booking_order_items_stddev_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "booking.order_items" */
export type Booking_Order_Items_Stddev_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Booking_Order_Items_Stddev_Pop_Fields = {
  __typename?: 'booking_order_items_stddev_pop_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "booking.order_items" */
export type Booking_Order_Items_Stddev_Pop_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Booking_Order_Items_Stddev_Samp_Fields = {
  __typename?: 'booking_order_items_stddev_samp_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "booking.order_items" */
export type Booking_Order_Items_Stddev_Samp_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Booking_Order_Items_Sum_Fields = {
  __typename?: 'booking_order_items_sum_fields';
  computed_total?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "booking.order_items" */
export type Booking_Order_Items_Sum_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** update columns of table "booking.order_items" */
export enum Booking_Order_Items_Update_Column {
  /** column name */
  ComputedTotal = 'computed_total',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  MenuPriceId = 'menu_price_id',
  /** column name */
  Note = 'note',
  /** column name */
  OrdersId = 'orders_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** aggregate var_pop on columns */
export type Booking_Order_Items_Var_Pop_Fields = {
  __typename?: 'booking_order_items_var_pop_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "booking.order_items" */
export type Booking_Order_Items_Var_Pop_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Booking_Order_Items_Var_Samp_Fields = {
  __typename?: 'booking_order_items_var_samp_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "booking.order_items" */
export type Booking_Order_Items_Var_Samp_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Booking_Order_Items_Variance_Fields = {
  __typename?: 'booking_order_items_variance_fields';
  computed_total?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "booking.order_items" */
export type Booking_Order_Items_Variance_Order_By = {
  computed_total?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Booking_Order_Max_Fields = {
  __typename?: 'booking_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  producer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_session_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.order" */
export type Booking_Order_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Order_Min_Fields = {
  __typename?: 'booking_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  producer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_session_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.order" */
export type Booking_Order_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.order" */
export type Booking_Order_Mutation_Response = {
  __typename?: 'booking_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order>;
};

/** input type for inserting object relation for remote table "booking.order" */
export type Booking_Order_Obj_Rel_Insert_Input = {
  data: Booking_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_On_Conflict>;
};

/** on_conflict condition type for table "booking.order" */
export type Booking_Order_On_Conflict = {
  constraint: Booking_Order_Constraint;
  update_columns?: Array<Booking_Order_Update_Column>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order". */
export type Booking_Order_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_producer?: InputMaybe<Menu_Producer_Order_By>;
  order_items_aggregate?: InputMaybe<Booking_Order_Items_Aggregate_Order_By>;
  order_status?: InputMaybe<Booking_Order_Status_Order_By>;
  order_type?: InputMaybe<Booking_Order_Type_Order_By>;
  paid_at?: InputMaybe<Order_By>;
  printer_job_orders_aggregate?: InputMaybe<Booking_Printer_Job_Order_Aggregate_Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  table_session?: InputMaybe<Booking_Table_Sessions_Order_By>;
  table_session_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** columns and relationships of "booking.order_payment" */
export type Booking_Order_Payment = {
  __typename?: 'booking_order_payment';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  order_payment_items: Array<Booking_Order_Payment_Items>;
  /** An aggregate relationship */
  order_payment_items_aggregate: Booking_Order_Payment_Items_Aggregate;
  /** An array relationship */
  order_payment_receipts: Array<Booking_Session_Email_Receipt>;
  /** An aggregate relationship */
  order_payment_receipts_aggregate: Booking_Session_Email_Receipt_Aggregate;
  /** An object relationship */
  order_payment_status: Booking_Order_Payment_Status;
  refund?: Maybe<Scalars['Int']>;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  status: Booking_Order_Payment_Status_Enum;
  stripe_payment_data?: Maybe<Scalars['jsonb']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  table_session: Booking_Table_Sessions;
  table_session_id: Scalars['uuid'];
  total: Scalars['Int'];
  /** A computed field, executes function "order_payment_total_price" */
  total_items?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "booking.order_payment" */
export type Booking_Order_PaymentOrder_Payment_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


/** columns and relationships of "booking.order_payment" */
export type Booking_Order_PaymentOrder_Payment_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


/** columns and relationships of "booking.order_payment" */
export type Booking_Order_PaymentOrder_Payment_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


/** columns and relationships of "booking.order_payment" */
export type Booking_Order_PaymentOrder_Payment_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


/** columns and relationships of "booking.order_payment" */
export type Booking_Order_PaymentStripe_Payment_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "booking.order_payment" */
export type Booking_Order_Payment_Aggregate = {
  __typename?: 'booking_order_payment_aggregate';
  aggregate?: Maybe<Booking_Order_Payment_Aggregate_Fields>;
  nodes: Array<Booking_Order_Payment>;
};

/** aggregate fields of "booking.order_payment" */
export type Booking_Order_Payment_Aggregate_Fields = {
  __typename?: 'booking_order_payment_aggregate_fields';
  avg?: Maybe<Booking_Order_Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Payment_Max_Fields>;
  min?: Maybe<Booking_Order_Payment_Min_Fields>;
  stddev?: Maybe<Booking_Order_Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Booking_Order_Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Booking_Order_Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Booking_Order_Payment_Sum_Fields>;
  var_pop?: Maybe<Booking_Order_Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Booking_Order_Payment_Var_Samp_Fields>;
  variance?: Maybe<Booking_Order_Payment_Variance_Fields>;
};


/** aggregate fields of "booking.order_payment" */
export type Booking_Order_Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.order_payment" */
export type Booking_Order_Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Booking_Order_Payment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Order_Payment_Max_Order_By>;
  min?: InputMaybe<Booking_Order_Payment_Min_Order_By>;
  stddev?: InputMaybe<Booking_Order_Payment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Booking_Order_Payment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Booking_Order_Payment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Booking_Order_Payment_Sum_Order_By>;
  var_pop?: InputMaybe<Booking_Order_Payment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Booking_Order_Payment_Var_Samp_Order_By>;
  variance?: InputMaybe<Booking_Order_Payment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Booking_Order_Payment_Append_Input = {
  stripe_payment_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "booking.order_payment" */
export type Booking_Order_Payment_Arr_Rel_Insert_Input = {
  data: Array<Booking_Order_Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Booking_Order_Payment_Avg_Fields = {
  __typename?: 'booking_order_payment_avg_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Avg_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "booking.order_payment". All fields are combined with a logical 'AND'. */
export type Booking_Order_Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Payment_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Payment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
  order_payment_receipts?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
  order_payment_status?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
  refund?: InputMaybe<Int_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Booking_Order_Payment_Status_Enum_Comparison_Exp>;
  stripe_payment_data?: InputMaybe<Jsonb_Comparison_Exp>;
  stripe_payment_id?: InputMaybe<String_Comparison_Exp>;
  table_session?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  table_session_id?: InputMaybe<Uuid_Comparison_Exp>;
  total?: InputMaybe<Int_Comparison_Exp>;
  total_items?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_payment" */
export enum Booking_Order_Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPaymentPkey = 'order_payment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Booking_Order_Payment_Delete_At_Path_Input = {
  stripe_payment_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Booking_Order_Payment_Delete_Elem_Input = {
  stripe_payment_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Booking_Order_Payment_Delete_Key_Input = {
  stripe_payment_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "booking.order_payment" */
export type Booking_Order_Payment_Inc_Input = {
  refund?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "booking.order_payment" */
export type Booking_Order_Payment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Arr_Rel_Insert_Input>;
  order_payment_receipts?: InputMaybe<Booking_Session_Email_Receipt_Arr_Rel_Insert_Input>;
  order_payment_status?: InputMaybe<Booking_Order_Payment_Status_Obj_Rel_Insert_Input>;
  refund?: InputMaybe<Scalars['Int']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Booking_Order_Payment_Status_Enum>;
  stripe_payment_data?: InputMaybe<Scalars['jsonb']>;
  stripe_payment_id?: InputMaybe<Scalars['String']>;
  table_session?: InputMaybe<Booking_Table_Sessions_Obj_Rel_Insert_Input>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
  total?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "booking.order_payment_items" */
export type Booking_Order_Payment_Items = {
  __typename?: 'booking_order_payment_items';
  /** An object relationship */
  order_item: Booking_Order_Items;
  order_item_id: Scalars['uuid'];
  /** An object relationship */
  order_payment: Booking_Order_Payment;
  order_payment_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};

/** aggregated selection of "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Aggregate = {
  __typename?: 'booking_order_payment_items_aggregate';
  aggregate?: Maybe<Booking_Order_Payment_Items_Aggregate_Fields>;
  nodes: Array<Booking_Order_Payment_Items>;
};

/** aggregate fields of "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Aggregate_Fields = {
  __typename?: 'booking_order_payment_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Payment_Items_Max_Fields>;
  min?: Maybe<Booking_Order_Payment_Items_Min_Fields>;
};


/** aggregate fields of "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Order_Payment_Items_Max_Order_By>;
  min?: InputMaybe<Booking_Order_Payment_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Arr_Rel_Insert_Input = {
  data: Array<Booking_Order_Payment_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Payment_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.order_payment_items". All fields are combined with a logical 'AND'. */
export type Booking_Order_Payment_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Payment_Items_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Payment_Items_Bool_Exp>>;
  order_item?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  order_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  order_payment?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  order_payment_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_payment_items" */
export enum Booking_Order_Payment_Items_Constraint {
  /** unique or primary key constraint on columns "order_item_id", "order_payment_id" */
  OrderPaymentItemsPkey = 'order_payment_items_pkey'
}

/** input type for inserting data into table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Insert_Input = {
  order_item?: InputMaybe<Booking_Order_Items_Obj_Rel_Insert_Input>;
  order_item_id?: InputMaybe<Scalars['uuid']>;
  order_payment?: InputMaybe<Booking_Order_Payment_Obj_Rel_Insert_Input>;
  order_payment_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booking_Order_Payment_Items_Max_Fields = {
  __typename?: 'booking_order_payment_items_max_fields';
  order_item_id?: Maybe<Scalars['uuid']>;
  order_payment_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Max_Order_By = {
  order_item_id?: InputMaybe<Order_By>;
  order_payment_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Order_Payment_Items_Min_Fields = {
  __typename?: 'booking_order_payment_items_min_fields';
  order_item_id?: Maybe<Scalars['uuid']>;
  order_payment_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Min_Order_By = {
  order_item_id?: InputMaybe<Order_By>;
  order_payment_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Mutation_Response = {
  __typename?: 'booking_order_payment_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Payment_Items>;
};

/** on_conflict condition type for table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_On_Conflict = {
  constraint: Booking_Order_Payment_Items_Constraint;
  update_columns?: Array<Booking_Order_Payment_Items_Update_Column>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_payment_items". */
export type Booking_Order_Payment_Items_Order_By = {
  order_item?: InputMaybe<Booking_Order_Items_Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  order_payment?: InputMaybe<Booking_Order_Payment_Order_By>;
  order_payment_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_payment_items */
export type Booking_Order_Payment_Items_Pk_Columns_Input = {
  order_item_id: Scalars['uuid'];
  order_payment_id: Scalars['uuid'];
};

/** select columns of table "booking.order_payment_items" */
export enum Booking_Order_Payment_Items_Select_Column {
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  OrderPaymentId = 'order_payment_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "booking.order_payment_items" */
export type Booking_Order_Payment_Items_Set_Input = {
  order_item_id?: InputMaybe<Scalars['uuid']>;
  order_payment_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "booking.order_payment_items" */
export enum Booking_Order_Payment_Items_Update_Column {
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  OrderPaymentId = 'order_payment_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** aggregate max on columns */
export type Booking_Order_Payment_Max_Fields = {
  __typename?: 'booking_order_payment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  refund?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  table_session_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refund?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  stripe_payment_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Order_Payment_Min_Fields = {
  __typename?: 'booking_order_payment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  refund?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  table_session_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refund?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  stripe_payment_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.order_payment" */
export type Booking_Order_Payment_Mutation_Response = {
  __typename?: 'booking_order_payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Payment>;
};

/** input type for inserting object relation for remote table "booking.order_payment" */
export type Booking_Order_Payment_Obj_Rel_Insert_Input = {
  data: Booking_Order_Payment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Payment_On_Conflict>;
};

/** on_conflict condition type for table "booking.order_payment" */
export type Booking_Order_Payment_On_Conflict = {
  constraint: Booking_Order_Payment_Constraint;
  update_columns?: Array<Booking_Order_Payment_Update_Column>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_payment". */
export type Booking_Order_Payment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_payment_items_aggregate?: InputMaybe<Booking_Order_Payment_Items_Aggregate_Order_By>;
  order_payment_receipts_aggregate?: InputMaybe<Booking_Session_Email_Receipt_Aggregate_Order_By>;
  order_payment_status?: InputMaybe<Booking_Order_Payment_Status_Order_By>;
  refund?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_payment_data?: InputMaybe<Order_By>;
  stripe_payment_id?: InputMaybe<Order_By>;
  table_session?: InputMaybe<Booking_Table_Sessions_Order_By>;
  table_session_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  total_items?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_payment */
export type Booking_Order_Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Booking_Order_Payment_Prepend_Input = {
  stripe_payment_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "booking.order_payment" */
export enum Booking_Order_Payment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Refund = 'refund',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentData = 'stripe_payment_data',
  /** column name */
  StripePaymentId = 'stripe_payment_id',
  /** column name */
  TableSessionId = 'table_session_id',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "booking.order_payment" */
export type Booking_Order_Payment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  refund?: InputMaybe<Scalars['Int']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Booking_Order_Payment_Status_Enum>;
  stripe_payment_data?: InputMaybe<Scalars['jsonb']>;
  stripe_payment_id?: InputMaybe<Scalars['String']>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
  total?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "booking.order_payment_status" */
export type Booking_Order_Payment_Status = {
  __typename?: 'booking_order_payment_status';
  /** An array relationship */
  order_payments: Array<Booking_Order_Payment>;
  /** An aggregate relationship */
  order_payments_aggregate: Booking_Order_Payment_Aggregate;
  values: Scalars['String'];
};


/** columns and relationships of "booking.order_payment_status" */
export type Booking_Order_Payment_StatusOrder_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


/** columns and relationships of "booking.order_payment_status" */
export type Booking_Order_Payment_StatusOrder_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};

/** aggregated selection of "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Aggregate = {
  __typename?: 'booking_order_payment_status_aggregate';
  aggregate?: Maybe<Booking_Order_Payment_Status_Aggregate_Fields>;
  nodes: Array<Booking_Order_Payment_Status>;
};

/** aggregate fields of "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Aggregate_Fields = {
  __typename?: 'booking_order_payment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Payment_Status_Max_Fields>;
  min?: Maybe<Booking_Order_Payment_Status_Min_Fields>;
};


/** aggregate fields of "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Payment_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "booking.order_payment_status". All fields are combined with a logical 'AND'. */
export type Booking_Order_Payment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Payment_Status_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Payment_Status_Bool_Exp>>;
  order_payments?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  values?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_payment_status" */
export enum Booking_Order_Payment_Status_Constraint {
  /** unique or primary key constraint on columns "values" */
  OrderPaymentEnumPkey = 'order_payment_enum_pkey'
}

export enum Booking_Order_Payment_Status_Enum {
  Draft = 'draft',
  Paid = 'paid'
}

/** Boolean expression to compare columns of type "booking_order_payment_status_enum". All fields are combined with logical 'AND'. */
export type Booking_Order_Payment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Booking_Order_Payment_Status_Enum>;
  _in?: InputMaybe<Array<Booking_Order_Payment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Booking_Order_Payment_Status_Enum>;
  _nin?: InputMaybe<Array<Booking_Order_Payment_Status_Enum>>;
};

/** input type for inserting data into table "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Insert_Input = {
  order_payments?: InputMaybe<Booking_Order_Payment_Arr_Rel_Insert_Input>;
  values?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Booking_Order_Payment_Status_Max_Fields = {
  __typename?: 'booking_order_payment_status_max_fields';
  values?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Booking_Order_Payment_Status_Min_Fields = {
  __typename?: 'booking_order_payment_status_min_fields';
  values?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Mutation_Response = {
  __typename?: 'booking_order_payment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Payment_Status>;
};

/** input type for inserting object relation for remote table "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Obj_Rel_Insert_Input = {
  data: Booking_Order_Payment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Payment_Status_On_Conflict>;
};

/** on_conflict condition type for table "booking.order_payment_status" */
export type Booking_Order_Payment_Status_On_Conflict = {
  constraint: Booking_Order_Payment_Status_Constraint;
  update_columns?: Array<Booking_Order_Payment_Status_Update_Column>;
  where?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_payment_status". */
export type Booking_Order_Payment_Status_Order_By = {
  order_payments_aggregate?: InputMaybe<Booking_Order_Payment_Aggregate_Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_payment_status */
export type Booking_Order_Payment_Status_Pk_Columns_Input = {
  values: Scalars['String'];
};

/** select columns of table "booking.order_payment_status" */
export enum Booking_Order_Payment_Status_Select_Column {
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "booking.order_payment_status" */
export type Booking_Order_Payment_Status_Set_Input = {
  values?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking.order_payment_status" */
export enum Booking_Order_Payment_Status_Update_Column {
  /** column name */
  Values = 'values'
}

/** aggregate stddev on columns */
export type Booking_Order_Payment_Stddev_Fields = {
  __typename?: 'booking_order_payment_stddev_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Stddev_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Booking_Order_Payment_Stddev_Pop_Fields = {
  __typename?: 'booking_order_payment_stddev_pop_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Stddev_Pop_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Booking_Order_Payment_Stddev_Samp_Fields = {
  __typename?: 'booking_order_payment_stddev_samp_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Stddev_Samp_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Booking_Order_Payment_Sum_Fields = {
  __typename?: 'booking_order_payment_sum_fields';
  refund?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Sum_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** update columns of table "booking.order_payment" */
export enum Booking_Order_Payment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Refund = 'refund',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentData = 'stripe_payment_data',
  /** column name */
  StripePaymentId = 'stripe_payment_id',
  /** column name */
  TableSessionId = 'table_session_id',
  /** column name */
  Total = 'total'
}

/** aggregate var_pop on columns */
export type Booking_Order_Payment_Var_Pop_Fields = {
  __typename?: 'booking_order_payment_var_pop_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Var_Pop_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Booking_Order_Payment_Var_Samp_Fields = {
  __typename?: 'booking_order_payment_var_samp_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Var_Samp_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Booking_Order_Payment_Variance_Fields = {
  __typename?: 'booking_order_payment_variance_fields';
  refund?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "booking.order_payment" */
export type Booking_Order_Payment_Variance_Order_By = {
  refund?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order */
export type Booking_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "booking.order" */
export enum Booking_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableSessionId = 'table_session_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "booking.order" */
export type Booking_Order_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  paid_at?: InputMaybe<Scalars['timestamptz']>;
  producer_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Booking_Order_Status_Enum>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Booking_Order_Type_Enum>;
};

/** columns and relationships of "booking.order_status" */
export type Booking_Order_Status = {
  __typename?: 'booking_order_status';
  /** An array relationship */
  orders: Array<Booking_Order>;
  /** An aggregate relationship */
  orders_aggregate: Booking_Order_Aggregate;
  values: Scalars['String'];
};


/** columns and relationships of "booking.order_status" */
export type Booking_Order_StatusOrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "booking.order_status" */
export type Booking_Order_StatusOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};

/** aggregated selection of "booking.order_status" */
export type Booking_Order_Status_Aggregate = {
  __typename?: 'booking_order_status_aggregate';
  aggregate?: Maybe<Booking_Order_Status_Aggregate_Fields>;
  nodes: Array<Booking_Order_Status>;
};

/** aggregate fields of "booking.order_status" */
export type Booking_Order_Status_Aggregate_Fields = {
  __typename?: 'booking_order_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Status_Max_Fields>;
  min?: Maybe<Booking_Order_Status_Min_Fields>;
};


/** aggregate fields of "booking.order_status" */
export type Booking_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "booking.order_status". All fields are combined with a logical 'AND'. */
export type Booking_Order_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Status_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Status_Bool_Exp>>;
  orders?: InputMaybe<Booking_Order_Bool_Exp>;
  values?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_status" */
export enum Booking_Order_Status_Constraint {
  /** unique or primary key constraint on columns "values" */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Booking_Order_Status_Enum {
  S_010Draft = 'S_010_DRAFT',
  S_020Waitting = 'S_020_WAITTING',
  S_030Preparing = 'S_030_PREPARING',
  S_040Completed = 'S_040_COMPLETED',
  S_050Delivered = 'S_050_DELIVERED'
}

/** Boolean expression to compare columns of type "booking_order_status_enum". All fields are combined with logical 'AND'. */
export type Booking_Order_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Booking_Order_Status_Enum>;
  _in?: InputMaybe<Array<Booking_Order_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Booking_Order_Status_Enum>;
  _nin?: InputMaybe<Array<Booking_Order_Status_Enum>>;
};

/** input type for inserting data into table "booking.order_status" */
export type Booking_Order_Status_Insert_Input = {
  orders?: InputMaybe<Booking_Order_Arr_Rel_Insert_Input>;
  values?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Booking_Order_Status_Max_Fields = {
  __typename?: 'booking_order_status_max_fields';
  values?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Booking_Order_Status_Min_Fields = {
  __typename?: 'booking_order_status_min_fields';
  values?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "booking.order_status" */
export type Booking_Order_Status_Mutation_Response = {
  __typename?: 'booking_order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Status>;
};

/** input type for inserting object relation for remote table "booking.order_status" */
export type Booking_Order_Status_Obj_Rel_Insert_Input = {
  data: Booking_Order_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Status_On_Conflict>;
};

/** on_conflict condition type for table "booking.order_status" */
export type Booking_Order_Status_On_Conflict = {
  constraint: Booking_Order_Status_Constraint;
  update_columns?: Array<Booking_Order_Status_Update_Column>;
  where?: InputMaybe<Booking_Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_status". */
export type Booking_Order_Status_Order_By = {
  orders_aggregate?: InputMaybe<Booking_Order_Aggregate_Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_status */
export type Booking_Order_Status_Pk_Columns_Input = {
  values: Scalars['String'];
};

/** select columns of table "booking.order_status" */
export enum Booking_Order_Status_Select_Column {
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "booking.order_status" */
export type Booking_Order_Status_Set_Input = {
  values?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking.order_status" */
export enum Booking_Order_Status_Update_Column {
  /** column name */
  Values = 'values'
}

/** columns and relationships of "booking.order_type" */
export type Booking_Order_Type = {
  __typename?: 'booking_order_type';
  /** An array relationship */
  orders: Array<Booking_Order>;
  /** An aggregate relationship */
  orders_aggregate: Booking_Order_Aggregate;
  values: Scalars['String'];
};


/** columns and relationships of "booking.order_type" */
export type Booking_Order_TypeOrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "booking.order_type" */
export type Booking_Order_TypeOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};

/** aggregated selection of "booking.order_type" */
export type Booking_Order_Type_Aggregate = {
  __typename?: 'booking_order_type_aggregate';
  aggregate?: Maybe<Booking_Order_Type_Aggregate_Fields>;
  nodes: Array<Booking_Order_Type>;
};

/** aggregate fields of "booking.order_type" */
export type Booking_Order_Type_Aggregate_Fields = {
  __typename?: 'booking_order_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Order_Type_Max_Fields>;
  min?: Maybe<Booking_Order_Type_Min_Fields>;
};


/** aggregate fields of "booking.order_type" */
export type Booking_Order_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Order_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "booking.order_type". All fields are combined with a logical 'AND'. */
export type Booking_Order_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Order_Type_Bool_Exp>>;
  _not?: InputMaybe<Booking_Order_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Order_Type_Bool_Exp>>;
  orders?: InputMaybe<Booking_Order_Bool_Exp>;
  values?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.order_type" */
export enum Booking_Order_Type_Constraint {
  /** unique or primary key constraint on columns "values" */
  OrderTypePkey = 'order_type_pkey'
}

export enum Booking_Order_Type_Enum {
  Delivery = 'delivery',
  Table = 'table',
  Takeway = 'takeway'
}

/** Boolean expression to compare columns of type "booking_order_type_enum". All fields are combined with logical 'AND'. */
export type Booking_Order_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Booking_Order_Type_Enum>;
  _in?: InputMaybe<Array<Booking_Order_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Booking_Order_Type_Enum>;
  _nin?: InputMaybe<Array<Booking_Order_Type_Enum>>;
};

/** input type for inserting data into table "booking.order_type" */
export type Booking_Order_Type_Insert_Input = {
  orders?: InputMaybe<Booking_Order_Arr_Rel_Insert_Input>;
  values?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Booking_Order_Type_Max_Fields = {
  __typename?: 'booking_order_type_max_fields';
  values?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Booking_Order_Type_Min_Fields = {
  __typename?: 'booking_order_type_min_fields';
  values?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "booking.order_type" */
export type Booking_Order_Type_Mutation_Response = {
  __typename?: 'booking_order_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Order_Type>;
};

/** input type for inserting object relation for remote table "booking.order_type" */
export type Booking_Order_Type_Obj_Rel_Insert_Input = {
  data: Booking_Order_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Order_Type_On_Conflict>;
};

/** on_conflict condition type for table "booking.order_type" */
export type Booking_Order_Type_On_Conflict = {
  constraint: Booking_Order_Type_Constraint;
  update_columns?: Array<Booking_Order_Type_Update_Column>;
  where?: InputMaybe<Booking_Order_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.order_type". */
export type Booking_Order_Type_Order_By = {
  orders_aggregate?: InputMaybe<Booking_Order_Aggregate_Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_order_type */
export type Booking_Order_Type_Pk_Columns_Input = {
  values: Scalars['String'];
};

/** select columns of table "booking.order_type" */
export enum Booking_Order_Type_Select_Column {
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "booking.order_type" */
export type Booking_Order_Type_Set_Input = {
  values?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking.order_type" */
export enum Booking_Order_Type_Update_Column {
  /** column name */
  Values = 'values'
}

/** update columns of table "booking.order" */
export enum Booking_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableSessionId = 'table_session_id',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "booking.printer_job_order" */
export type Booking_Printer_Job_Order = {
  __typename?: 'booking_printer_job_order';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  order: Booking_Order;
  order_id: Scalars['uuid'];
  /** An object relationship */
  printer: Printer;
  printer_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};

/** aggregated selection of "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Aggregate = {
  __typename?: 'booking_printer_job_order_aggregate';
  aggregate?: Maybe<Booking_Printer_Job_Order_Aggregate_Fields>;
  nodes: Array<Booking_Printer_Job_Order>;
};

/** aggregate fields of "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Aggregate_Fields = {
  __typename?: 'booking_printer_job_order_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Printer_Job_Order_Max_Fields>;
  min?: Maybe<Booking_Printer_Job_Order_Min_Fields>;
};


/** aggregate fields of "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Printer_Job_Order_Max_Order_By>;
  min?: InputMaybe<Booking_Printer_Job_Order_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Arr_Rel_Insert_Input = {
  data: Array<Booking_Printer_Job_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Printer_Job_Order_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.printer_job_order". All fields are combined with a logical 'AND'. */
export type Booking_Printer_Job_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Printer_Job_Order_Bool_Exp>>;
  _not?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Printer_Job_Order_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  order?: InputMaybe<Booking_Order_Bool_Exp>;
  order_id?: InputMaybe<Uuid_Comparison_Exp>;
  printer?: InputMaybe<Printer_Bool_Exp>;
  printer_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.printer_job_order" */
export enum Booking_Printer_Job_Order_Constraint {
  /** unique or primary key constraint on columns "printer_id" */
  PrinterJobOrderPkey = 'printer_job_order_pkey'
}

/** input type for inserting data into table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  order?: InputMaybe<Booking_Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['uuid']>;
  printer?: InputMaybe<Printer_Obj_Rel_Insert_Input>;
  printer_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booking_Printer_Job_Order_Max_Fields = {
  __typename?: 'booking_printer_job_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['uuid']>;
  printer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Printer_Job_Order_Min_Fields = {
  __typename?: 'booking_printer_job_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  order_id?: Maybe<Scalars['uuid']>;
  printer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Mutation_Response = {
  __typename?: 'booking_printer_job_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Printer_Job_Order>;
};

/** input type for inserting object relation for remote table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Obj_Rel_Insert_Input = {
  data: Booking_Printer_Job_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Printer_Job_Order_On_Conflict>;
};

/** on_conflict condition type for table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_On_Conflict = {
  constraint: Booking_Printer_Job_Order_Constraint;
  update_columns?: Array<Booking_Printer_Job_Order_Update_Column>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.printer_job_order". */
export type Booking_Printer_Job_Order_Order_By = {
  created_at?: InputMaybe<Order_By>;
  order?: InputMaybe<Booking_Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
  printer?: InputMaybe<Printer_Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_printer_job_order */
export type Booking_Printer_Job_Order_Pk_Columns_Input = {
  printer_id: Scalars['uuid'];
};

/** select columns of table "booking.printer_job_order" */
export enum Booking_Printer_Job_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "booking.printer_job_order" */
export type Booking_Printer_Job_Order_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  order_id?: InputMaybe<Scalars['uuid']>;
  printer_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "booking.printer_job_order" */
export enum Booking_Printer_Job_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** columns and relationships of "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt = {
  __typename?: 'booking_session_email_receipt';
  created_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  order_payment?: Maybe<Booking_Order_Payment>;
  payment_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  /** An object relationship */
  table_session: Booking_Table_Sessions;
  table_session_id: Scalars['uuid'];
};

/** aggregated selection of "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Aggregate = {
  __typename?: 'booking_session_email_receipt_aggregate';
  aggregate?: Maybe<Booking_Session_Email_Receipt_Aggregate_Fields>;
  nodes: Array<Booking_Session_Email_Receipt>;
};

/** aggregate fields of "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Aggregate_Fields = {
  __typename?: 'booking_session_email_receipt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Session_Email_Receipt_Max_Fields>;
  min?: Maybe<Booking_Session_Email_Receipt_Min_Fields>;
};


/** aggregate fields of "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Session_Email_Receipt_Max_Order_By>;
  min?: InputMaybe<Booking_Session_Email_Receipt_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Arr_Rel_Insert_Input = {
  data: Array<Booking_Session_Email_Receipt_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Session_Email_Receipt_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.session_email_receipt". All fields are combined with a logical 'AND'. */
export type Booking_Session_Email_Receipt_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Session_Email_Receipt_Bool_Exp>>;
  _not?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Session_Email_Receipt_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_payment?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  payment_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  table_session?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  table_session_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.session_email_receipt" */
export enum Booking_Session_Email_Receipt_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPaymentReceiptPkey = 'order_payment_receipt_pkey'
}

/** input type for inserting data into table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_payment?: InputMaybe<Booking_Order_Payment_Obj_Rel_Insert_Input>;
  payment_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table_session?: InputMaybe<Booking_Table_Sessions_Obj_Rel_Insert_Input>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booking_Session_Email_Receipt_Max_Fields = {
  __typename?: 'booking_session_email_receipt_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_session_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Session_Email_Receipt_Min_Fields = {
  __typename?: 'booking_session_email_receipt_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_session_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_session_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Mutation_Response = {
  __typename?: 'booking_session_email_receipt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Session_Email_Receipt>;
};

/** on_conflict condition type for table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_On_Conflict = {
  constraint: Booking_Session_Email_Receipt_Constraint;
  update_columns?: Array<Booking_Session_Email_Receipt_Update_Column>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.session_email_receipt". */
export type Booking_Session_Email_Receipt_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_payment?: InputMaybe<Booking_Order_Payment_Order_By>;
  payment_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_session?: InputMaybe<Booking_Table_Sessions_Order_By>;
  table_session_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_session_email_receipt */
export type Booking_Session_Email_Receipt_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "booking.session_email_receipt" */
export enum Booking_Session_Email_Receipt_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableSessionId = 'table_session_id'
}

/** input type for updating data in table "booking.session_email_receipt" */
export type Booking_Session_Email_Receipt_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payment_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table_session_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "booking.session_email_receipt" */
export enum Booking_Session_Email_Receipt_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableSessionId = 'table_session_id'
}

/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_Sessions = {
  __typename?: 'booking_table_sessions';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  order_payments: Array<Booking_Order_Payment>;
  /** An aggregate relationship */
  order_payments_aggregate: Booking_Order_Payment_Aggregate;
  /** An array relationship */
  orders: Array<Booking_Order>;
  /** An aggregate relationship */
  orders_aggregate: Booking_Order_Aggregate;
  reference: Scalars['String'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  /** An array relationship */
  session_email_receipts: Array<Booking_Session_Email_Receipt>;
  /** An aggregate relationship */
  session_email_receipts_aggregate: Booking_Session_Email_Receipt_Aggregate;
  status?: Maybe<Booking_Table_Sessions_Status_Enum>;
  /** An object relationship */
  table: Seating_Table;
  table_id: Scalars['uuid'];
  /** An object relationship */
  table_sessions_status?: Maybe<Booking_Table_Sessions_Status>;
  /** A computed field, executes function "session_total_price" */
  total?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "session_total_paid_price" */
  total_paid?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsOrder_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsOrder_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsSession_Email_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions" */
export type Booking_Table_SessionsSession_Email_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};

/** aggregated selection of "booking.table_sessions" */
export type Booking_Table_Sessions_Aggregate = {
  __typename?: 'booking_table_sessions_aggregate';
  aggregate?: Maybe<Booking_Table_Sessions_Aggregate_Fields>;
  nodes: Array<Booking_Table_Sessions>;
};

/** aggregate fields of "booking.table_sessions" */
export type Booking_Table_Sessions_Aggregate_Fields = {
  __typename?: 'booking_table_sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Table_Sessions_Max_Fields>;
  min?: Maybe<Booking_Table_Sessions_Min_Fields>;
};


/** aggregate fields of "booking.table_sessions" */
export type Booking_Table_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booking.table_sessions" */
export type Booking_Table_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booking_Table_Sessions_Max_Order_By>;
  min?: InputMaybe<Booking_Table_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booking.table_sessions" */
export type Booking_Table_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Booking_Table_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Table_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booking.table_sessions". All fields are combined with a logical 'AND'. */
export type Booking_Table_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Table_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Table_Sessions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_payments?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  orders?: InputMaybe<Booking_Order_Bool_Exp>;
  reference?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  session_email_receipts?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
  status?: InputMaybe<Booking_Table_Sessions_Status_Enum_Comparison_Exp>;
  table?: InputMaybe<Seating_Table_Bool_Exp>;
  table_id?: InputMaybe<Uuid_Comparison_Exp>;
  table_sessions_status?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
  total_paid?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.table_sessions" */
export enum Booking_Table_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TableSessionsPkey = 'table_sessions_pkey',
  /** unique or primary key constraint on columns "reference", "restaurant_id" */
  TableSessionsRestaurantIdReferenceKey = 'table_sessions_restaurant_id_reference_key'
}

/** input type for inserting data into table "booking.table_sessions" */
export type Booking_Table_Sessions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_payments?: InputMaybe<Booking_Order_Payment_Arr_Rel_Insert_Input>;
  orders?: InputMaybe<Booking_Order_Arr_Rel_Insert_Input>;
  reference?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  session_email_receipts?: InputMaybe<Booking_Session_Email_Receipt_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Booking_Table_Sessions_Status_Enum>;
  table?: InputMaybe<Seating_Table_Obj_Rel_Insert_Input>;
  table_id?: InputMaybe<Scalars['uuid']>;
  table_sessions_status?: InputMaybe<Booking_Table_Sessions_Status_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Booking_Table_Sessions_Max_Fields = {
  __typename?: 'booking_table_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booking.table_sessions" */
export type Booking_Table_Sessions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reference?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booking_Table_Sessions_Min_Fields = {
  __typename?: 'booking_table_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reference?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booking.table_sessions" */
export type Booking_Table_Sessions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reference?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booking.table_sessions" */
export type Booking_Table_Sessions_Mutation_Response = {
  __typename?: 'booking_table_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Table_Sessions>;
};

/** input type for inserting object relation for remote table "booking.table_sessions" */
export type Booking_Table_Sessions_Obj_Rel_Insert_Input = {
  data: Booking_Table_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Table_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "booking.table_sessions" */
export type Booking_Table_Sessions_On_Conflict = {
  constraint: Booking_Table_Sessions_Constraint;
  update_columns?: Array<Booking_Table_Sessions_Update_Column>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.table_sessions". */
export type Booking_Table_Sessions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_payments_aggregate?: InputMaybe<Booking_Order_Payment_Aggregate_Order_By>;
  orders_aggregate?: InputMaybe<Booking_Order_Aggregate_Order_By>;
  reference?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  session_email_receipts_aggregate?: InputMaybe<Booking_Session_Email_Receipt_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  table?: InputMaybe<Seating_Table_Order_By>;
  table_id?: InputMaybe<Order_By>;
  table_sessions_status?: InputMaybe<Booking_Table_Sessions_Status_Order_By>;
  total?: InputMaybe<Order_By>;
  total_paid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_table_sessions */
export type Booking_Table_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "booking.table_sessions" */
export enum Booking_Table_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableId = 'table_id'
}

/** input type for updating data in table "booking.table_sessions" */
export type Booking_Table_Sessions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  reference?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Booking_Table_Sessions_Status_Enum>;
  table_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status = {
  __typename?: 'booking_table_sessions_status';
  /** An array relationship */
  table_sessions: Array<Booking_Table_Sessions>;
  /** An aggregate relationship */
  table_sessions_aggregate: Booking_Table_Sessions_Aggregate;
  values: Scalars['String'];
};


/** columns and relationships of "booking.table_sessions_status" */
export type Booking_Table_Sessions_StatusTable_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


/** columns and relationships of "booking.table_sessions_status" */
export type Booking_Table_Sessions_StatusTable_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};

/** aggregated selection of "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Aggregate = {
  __typename?: 'booking_table_sessions_status_aggregate';
  aggregate?: Maybe<Booking_Table_Sessions_Status_Aggregate_Fields>;
  nodes: Array<Booking_Table_Sessions_Status>;
};

/** aggregate fields of "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Aggregate_Fields = {
  __typename?: 'booking_table_sessions_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booking_Table_Sessions_Status_Max_Fields>;
  min?: Maybe<Booking_Table_Sessions_Status_Min_Fields>;
};


/** aggregate fields of "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booking_Table_Sessions_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "booking.table_sessions_status". All fields are combined with a logical 'AND'. */
export type Booking_Table_Sessions_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Table_Sessions_Status_Bool_Exp>>;
  _not?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Table_Sessions_Status_Bool_Exp>>;
  table_sessions?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  values?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking.table_sessions_status" */
export enum Booking_Table_Sessions_Status_Constraint {
  /** unique or primary key constraint on columns "values" */
  TableSessionsTypesPkey = 'table_sessions_types_pkey'
}

export enum Booking_Table_Sessions_Status_Enum {
  Active = 'active',
  Closed = 'closed',
  Draft = 'draft'
}

/** Boolean expression to compare columns of type "booking_table_sessions_status_enum". All fields are combined with logical 'AND'. */
export type Booking_Table_Sessions_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Booking_Table_Sessions_Status_Enum>;
  _in?: InputMaybe<Array<Booking_Table_Sessions_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Booking_Table_Sessions_Status_Enum>;
  _nin?: InputMaybe<Array<Booking_Table_Sessions_Status_Enum>>;
};

/** input type for inserting data into table "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Insert_Input = {
  table_sessions?: InputMaybe<Booking_Table_Sessions_Arr_Rel_Insert_Input>;
  values?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Booking_Table_Sessions_Status_Max_Fields = {
  __typename?: 'booking_table_sessions_status_max_fields';
  values?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Booking_Table_Sessions_Status_Min_Fields = {
  __typename?: 'booking_table_sessions_status_min_fields';
  values?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Mutation_Response = {
  __typename?: 'booking_table_sessions_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booking_Table_Sessions_Status>;
};

/** input type for inserting object relation for remote table "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Obj_Rel_Insert_Input = {
  data: Booking_Table_Sessions_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Table_Sessions_Status_On_Conflict>;
};

/** on_conflict condition type for table "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_On_Conflict = {
  constraint: Booking_Table_Sessions_Status_Constraint;
  update_columns?: Array<Booking_Table_Sessions_Status_Update_Column>;
  where?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "booking.table_sessions_status". */
export type Booking_Table_Sessions_Status_Order_By = {
  table_sessions_aggregate?: InputMaybe<Booking_Table_Sessions_Aggregate_Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_table_sessions_status */
export type Booking_Table_Sessions_Status_Pk_Columns_Input = {
  values: Scalars['String'];
};

/** select columns of table "booking.table_sessions_status" */
export enum Booking_Table_Sessions_Status_Select_Column {
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "booking.table_sessions_status" */
export type Booking_Table_Sessions_Status_Set_Input = {
  values?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking.table_sessions_status" */
export enum Booking_Table_Sessions_Status_Update_Column {
  /** column name */
  Values = 'values'
}

/** update columns of table "booking.table_sessions" */
export enum Booking_Table_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reference = 'reference',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  TableId = 'table_id'
}

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  code: Scalars['String'];
  currencyPrecision: Scalars['numeric'];
  currencySymol: Scalars['String'];
  fullName: Scalars['String'];
  /** An array relationship */
  restaurants: Array<Restaurants>;
  /** An aggregate relationship */
  restaurants_aggregate: Restaurants_Aggregate;
};


/** columns and relationships of "countries" */
export type CountriesRestaurantsArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};


/** columns and relationships of "countries" */
export type CountriesRestaurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};

/** aggregated selection of "countries" */
export type Countries_Aggregate = {
  __typename?: 'countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'countries_aggregate_fields';
  avg?: Maybe<Countries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
  stddev?: Maybe<Countries_Stddev_Fields>;
  stddev_pop?: Maybe<Countries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Countries_Stddev_Samp_Fields>;
  sum?: Maybe<Countries_Sum_Fields>;
  var_pop?: Maybe<Countries_Var_Pop_Fields>;
  var_samp?: Maybe<Countries_Var_Samp_Fields>;
  variance?: Maybe<Countries_Variance_Fields>;
};


/** aggregate fields of "countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Countries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Countries_Avg_Fields = {
  __typename?: 'countries_avg_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  currencyPrecision?: InputMaybe<Numeric_Comparison_Exp>;
  currencySymol?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  restaurants?: InputMaybe<Restaurants_Bool_Exp>;
};

/** unique or primary key constraints on table "countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "code" */
  CountriesPkey = 'countries_pkey'
}

/** input type for incrementing numeric columns in table "countries" */
export type Countries_Inc_Input = {
  currencyPrecision?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "countries" */
export type Countries_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  currencyPrecision?: InputMaybe<Scalars['numeric']>;
  currencySymol?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  restaurants?: InputMaybe<Restaurants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'countries_max_fields';
  code?: Maybe<Scalars['String']>;
  currencyPrecision?: Maybe<Scalars['numeric']>;
  currencySymol?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'countries_min_fields';
  code?: Maybe<Scalars['String']>;
  currencyPrecision?: Maybe<Scalars['numeric']>;
  currencySymol?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "countries" */
export type Countries_Mutation_Response = {
  __typename?: 'countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** input type for inserting object relation for remote table "countries" */
export type Countries_Obj_Rel_Insert_Input = {
  data: Countries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};

/** on_conflict condition type for table "countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  code?: InputMaybe<Order_By>;
  currencyPrecision?: InputMaybe<Order_By>;
  currencySymol?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  restaurants_aggregate?: InputMaybe<Restaurants_Aggregate_Order_By>;
};

/** primary key columns input for table: countries */
export type Countries_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CurrencyPrecision = 'currencyPrecision',
  /** column name */
  CurrencySymol = 'currencySymol',
  /** column name */
  FullName = 'fullName'
}

/** input type for updating data in table "countries" */
export type Countries_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  currencyPrecision?: InputMaybe<Scalars['numeric']>;
  currencySymol?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Countries_Stddev_Fields = {
  __typename?: 'countries_stddev_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Countries_Stddev_Pop_Fields = {
  __typename?: 'countries_stddev_pop_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Countries_Stddev_Samp_Fields = {
  __typename?: 'countries_stddev_samp_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Countries_Sum_Fields = {
  __typename?: 'countries_sum_fields';
  currencyPrecision?: Maybe<Scalars['numeric']>;
};

/** update columns of table "countries" */
export enum Countries_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CurrencyPrecision = 'currencyPrecision',
  /** column name */
  CurrencySymol = 'currencySymol',
  /** column name */
  FullName = 'fullName'
}

/** aggregate var_pop on columns */
export type Countries_Var_Pop_Fields = {
  __typename?: 'countries_var_pop_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Countries_Var_Samp_Fields = {
  __typename?: 'countries_var_samp_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Countries_Variance_Fields = {
  __typename?: 'countries_variance_fields';
  currencyPrecision?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "menu.item" */
export type Menu_Item = {
  __typename?: 'menu_item';
  active_price: Scalars['uuid'];
  /** An object relationship */
  active_price_item: Menu_Item_Price;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  item_extras: Array<Menu_Item_Extra>;
  /** An aggregate relationship */
  item_extras_aggregate: Menu_Item_Extra_Aggregate;
  labels: Scalars['jsonb'];
  live: Scalars['Boolean'];
  menu_parent: Scalars['uuid'];
  /** An object relationship */
  menu_tree: Menu_Tree;
  name: Scalars['String'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "menu.item" */
export type Menu_ItemItem_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


/** columns and relationships of "menu.item" */
export type Menu_ItemItem_Extras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


/** columns and relationships of "menu.item" */
export type Menu_ItemLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "menu.item" */
export type Menu_Item_Aggregate = {
  __typename?: 'menu_item_aggregate';
  aggregate?: Maybe<Menu_Item_Aggregate_Fields>;
  nodes: Array<Menu_Item>;
};

/** aggregate fields of "menu.item" */
export type Menu_Item_Aggregate_Fields = {
  __typename?: 'menu_item_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Menu_Item_Max_Fields>;
  min?: Maybe<Menu_Item_Min_Fields>;
};


/** aggregate fields of "menu.item" */
export type Menu_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "menu.item" */
export type Menu_Item_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menu_Item_Max_Order_By>;
  min?: InputMaybe<Menu_Item_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Menu_Item_Append_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "menu.item" */
export type Menu_Item_Arr_Rel_Insert_Input = {
  data: Array<Menu_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_On_Conflict>;
};

/** Boolean expression to filter rows from the table "menu.item". All fields are combined with a logical 'AND'. */
export type Menu_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Item_Bool_Exp>>;
  _not?: InputMaybe<Menu_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Item_Bool_Exp>>;
  active_price?: InputMaybe<Uuid_Comparison_Exp>;
  active_price_item?: InputMaybe<Menu_Item_Price_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  item_extras?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  live?: InputMaybe<Boolean_Comparison_Exp>;
  menu_parent?: InputMaybe<Uuid_Comparison_Exp>;
  menu_tree?: InputMaybe<Menu_Tree_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.item" */
export enum Menu_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  MenuItemPkey = 'menu_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Menu_Item_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Menu_Item_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Menu_Item_Delete_Key_Input = {
  labels?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "menu.item_extra" */
export type Menu_Item_Extra = {
  __typename?: 'menu_item_extra';
  active: Scalars['Boolean'];
  choices: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  item: Menu_Item;
  item_id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  order_items_extras: Array<Booking_Order_Items_Extra>;
  /** An aggregate relationship */
  order_items_extras_aggregate: Booking_Order_Items_Extra_Aggregate;
  price: Scalars['Int'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};


/** columns and relationships of "menu.item_extra" */
export type Menu_Item_ExtraChoicesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "menu.item_extra" */
export type Menu_Item_ExtraOrder_Items_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


/** columns and relationships of "menu.item_extra" */
export type Menu_Item_ExtraOrder_Items_Extras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};

/** aggregated selection of "menu.item_extra" */
export type Menu_Item_Extra_Aggregate = {
  __typename?: 'menu_item_extra_aggregate';
  aggregate?: Maybe<Menu_Item_Extra_Aggregate_Fields>;
  nodes: Array<Menu_Item_Extra>;
};

/** aggregate fields of "menu.item_extra" */
export type Menu_Item_Extra_Aggregate_Fields = {
  __typename?: 'menu_item_extra_aggregate_fields';
  avg?: Maybe<Menu_Item_Extra_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Menu_Item_Extra_Max_Fields>;
  min?: Maybe<Menu_Item_Extra_Min_Fields>;
  stddev?: Maybe<Menu_Item_Extra_Stddev_Fields>;
  stddev_pop?: Maybe<Menu_Item_Extra_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Menu_Item_Extra_Stddev_Samp_Fields>;
  sum?: Maybe<Menu_Item_Extra_Sum_Fields>;
  var_pop?: Maybe<Menu_Item_Extra_Var_Pop_Fields>;
  var_samp?: Maybe<Menu_Item_Extra_Var_Samp_Fields>;
  variance?: Maybe<Menu_Item_Extra_Variance_Fields>;
};


/** aggregate fields of "menu.item_extra" */
export type Menu_Item_Extra_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "menu.item_extra" */
export type Menu_Item_Extra_Aggregate_Order_By = {
  avg?: InputMaybe<Menu_Item_Extra_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menu_Item_Extra_Max_Order_By>;
  min?: InputMaybe<Menu_Item_Extra_Min_Order_By>;
  stddev?: InputMaybe<Menu_Item_Extra_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Menu_Item_Extra_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Menu_Item_Extra_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Menu_Item_Extra_Sum_Order_By>;
  var_pop?: InputMaybe<Menu_Item_Extra_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Menu_Item_Extra_Var_Samp_Order_By>;
  variance?: InputMaybe<Menu_Item_Extra_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Menu_Item_Extra_Append_Input = {
  choices?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "menu.item_extra" */
export type Menu_Item_Extra_Arr_Rel_Insert_Input = {
  data: Array<Menu_Item_Extra_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_Extra_On_Conflict>;
};

/** aggregate avg on columns */
export type Menu_Item_Extra_Avg_Fields = {
  __typename?: 'menu_item_extra_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "menu.item_extra". All fields are combined with a logical 'AND'. */
export type Menu_Item_Extra_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Item_Extra_Bool_Exp>>;
  _not?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Item_Extra_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  choices?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Menu_Item_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.item_extra" */
export enum Menu_Item_Extra_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemExtraPkey = 'item_extra_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Menu_Item_Extra_Delete_At_Path_Input = {
  choices?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Menu_Item_Extra_Delete_Elem_Input = {
  choices?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Menu_Item_Extra_Delete_Key_Input = {
  choices?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "menu.item_extra" */
export type Menu_Item_Extra_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "menu.item_extra" */
export type Menu_Item_Extra_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  choices?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item?: InputMaybe<Menu_Item_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['Int']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Menu_Item_Extra_Max_Fields = {
  __typename?: 'menu_item_extra_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Menu_Item_Extra_Min_Fields = {
  __typename?: 'menu_item_extra_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "menu.item_extra" */
export type Menu_Item_Extra_Mutation_Response = {
  __typename?: 'menu_item_extra_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Item_Extra>;
};

/** input type for inserting object relation for remote table "menu.item_extra" */
export type Menu_Item_Extra_Obj_Rel_Insert_Input = {
  data: Menu_Item_Extra_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_Extra_On_Conflict>;
};

/** on_conflict condition type for table "menu.item_extra" */
export type Menu_Item_Extra_On_Conflict = {
  constraint: Menu_Item_Extra_Constraint;
  update_columns?: Array<Menu_Item_Extra_Update_Column>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.item_extra". */
export type Menu_Item_Extra_Order_By = {
  active?: InputMaybe<Order_By>;
  choices?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Menu_Item_Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_items_extras_aggregate?: InputMaybe<Booking_Order_Items_Extra_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_item_extra */
export type Menu_Item_Extra_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Menu_Item_Extra_Prepend_Input = {
  choices?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "menu.item_extra" */
export enum Menu_Item_Extra_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Choices = 'choices',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "menu.item_extra" */
export type Menu_Item_Extra_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  choices?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Menu_Item_Extra_Stddev_Fields = {
  __typename?: 'menu_item_extra_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Menu_Item_Extra_Stddev_Pop_Fields = {
  __typename?: 'menu_item_extra_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Menu_Item_Extra_Stddev_Samp_Fields = {
  __typename?: 'menu_item_extra_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Menu_Item_Extra_Sum_Fields = {
  __typename?: 'menu_item_extra_sum_fields';
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "menu.item_extra" */
export enum Menu_Item_Extra_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Choices = 'choices',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** aggregate var_pop on columns */
export type Menu_Item_Extra_Var_Pop_Fields = {
  __typename?: 'menu_item_extra_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Menu_Item_Extra_Var_Samp_Fields = {
  __typename?: 'menu_item_extra_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Menu_Item_Extra_Variance_Fields = {
  __typename?: 'menu_item_extra_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "menu.item_extra" */
export type Menu_Item_Extra_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "menu.item" */
export type Menu_Item_Insert_Input = {
  active_price?: InputMaybe<Scalars['uuid']>;
  active_price_item?: InputMaybe<Menu_Item_Price_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  item_extras?: InputMaybe<Menu_Item_Extra_Arr_Rel_Insert_Input>;
  labels?: InputMaybe<Scalars['jsonb']>;
  live?: InputMaybe<Scalars['Boolean']>;
  menu_parent?: InputMaybe<Scalars['uuid']>;
  menu_tree?: InputMaybe<Menu_Tree_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "menu.item_label" */
export type Menu_Item_Label = {
  __typename?: 'menu_item_label';
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "menu.item_label" */
export type Menu_Item_Label_Aggregate = {
  __typename?: 'menu_item_label_aggregate';
  aggregate?: Maybe<Menu_Item_Label_Aggregate_Fields>;
  nodes: Array<Menu_Item_Label>;
};

/** aggregate fields of "menu.item_label" */
export type Menu_Item_Label_Aggregate_Fields = {
  __typename?: 'menu_item_label_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Menu_Item_Label_Max_Fields>;
  min?: Maybe<Menu_Item_Label_Min_Fields>;
};


/** aggregate fields of "menu.item_label" */
export type Menu_Item_Label_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Item_Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "menu.item_label". All fields are combined with a logical 'AND'. */
export type Menu_Item_Label_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Item_Label_Bool_Exp>>;
  _not?: InputMaybe<Menu_Item_Label_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Item_Label_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.item_label" */
export enum Menu_Item_Label_Constraint {
  /** unique or primary key constraint on columns "key" */
  ItemLabelPkey = 'item_label_pkey'
}

/** input type for inserting data into table "menu.item_label" */
export type Menu_Item_Label_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Menu_Item_Label_Max_Fields = {
  __typename?: 'menu_item_label_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Menu_Item_Label_Min_Fields = {
  __typename?: 'menu_item_label_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "menu.item_label" */
export type Menu_Item_Label_Mutation_Response = {
  __typename?: 'menu_item_label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Item_Label>;
};

/** on_conflict condition type for table "menu.item_label" */
export type Menu_Item_Label_On_Conflict = {
  constraint: Menu_Item_Label_Constraint;
  update_columns?: Array<Menu_Item_Label_Update_Column>;
  where?: InputMaybe<Menu_Item_Label_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.item_label". */
export type Menu_Item_Label_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_item_label */
export type Menu_Item_Label_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "menu.item_label" */
export enum Menu_Item_Label_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "menu.item_label" */
export type Menu_Item_Label_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "menu.item_label" */
export enum Menu_Item_Label_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  Type = 'type'
}

/** aggregate max on columns */
export type Menu_Item_Max_Fields = {
  __typename?: 'menu_item_max_fields';
  active_price?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  menu_parent?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "menu.item" */
export type Menu_Item_Max_Order_By = {
  active_price?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  menu_parent?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Menu_Item_Min_Fields = {
  __typename?: 'menu_item_min_fields';
  active_price?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  menu_parent?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "menu.item" */
export type Menu_Item_Min_Order_By = {
  active_price?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  menu_parent?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "menu.item" */
export type Menu_Item_Mutation_Response = {
  __typename?: 'menu_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Item>;
};

/** input type for inserting object relation for remote table "menu.item" */
export type Menu_Item_Obj_Rel_Insert_Input = {
  data: Menu_Item_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_On_Conflict>;
};

/** on_conflict condition type for table "menu.item" */
export type Menu_Item_On_Conflict = {
  constraint: Menu_Item_Constraint;
  update_columns?: Array<Menu_Item_Update_Column>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.item". */
export type Menu_Item_Order_By = {
  active_price?: InputMaybe<Order_By>;
  active_price_item?: InputMaybe<Menu_Item_Price_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  item_extras_aggregate?: InputMaybe<Menu_Item_Extra_Aggregate_Order_By>;
  labels?: InputMaybe<Order_By>;
  live?: InputMaybe<Order_By>;
  menu_parent?: InputMaybe<Order_By>;
  menu_tree?: InputMaybe<Menu_Tree_Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_item */
export type Menu_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Menu_Item_Prepend_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "menu.item_price" */
export type Menu_Item_Price = {
  __typename?: 'menu_item_price';
  /** An object relationship */
  active_menu_item?: Maybe<Menu_Item>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  menu_item_id: Scalars['uuid'];
  /** An array relationship */
  menu_items: Array<Menu_Item>;
  /** An aggregate relationship */
  menu_items_aggregate: Menu_Item_Aggregate;
  /** An array relationship */
  orders_items: Array<Booking_Order_Items>;
  /** An aggregate relationship */
  orders_items_aggregate: Booking_Order_Items_Aggregate;
  price: Scalars['Int'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};


/** columns and relationships of "menu.item_price" */
export type Menu_Item_PriceMenu_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


/** columns and relationships of "menu.item_price" */
export type Menu_Item_PriceMenu_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


/** columns and relationships of "menu.item_price" */
export type Menu_Item_PriceOrders_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


/** columns and relationships of "menu.item_price" */
export type Menu_Item_PriceOrders_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};

/** aggregated selection of "menu.item_price" */
export type Menu_Item_Price_Aggregate = {
  __typename?: 'menu_item_price_aggregate';
  aggregate?: Maybe<Menu_Item_Price_Aggregate_Fields>;
  nodes: Array<Menu_Item_Price>;
};

/** aggregate fields of "menu.item_price" */
export type Menu_Item_Price_Aggregate_Fields = {
  __typename?: 'menu_item_price_aggregate_fields';
  avg?: Maybe<Menu_Item_Price_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Menu_Item_Price_Max_Fields>;
  min?: Maybe<Menu_Item_Price_Min_Fields>;
  stddev?: Maybe<Menu_Item_Price_Stddev_Fields>;
  stddev_pop?: Maybe<Menu_Item_Price_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Menu_Item_Price_Stddev_Samp_Fields>;
  sum?: Maybe<Menu_Item_Price_Sum_Fields>;
  var_pop?: Maybe<Menu_Item_Price_Var_Pop_Fields>;
  var_samp?: Maybe<Menu_Item_Price_Var_Samp_Fields>;
  variance?: Maybe<Menu_Item_Price_Variance_Fields>;
};


/** aggregate fields of "menu.item_price" */
export type Menu_Item_Price_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "menu.item_price" */
export type Menu_Item_Price_Aggregate_Order_By = {
  avg?: InputMaybe<Menu_Item_Price_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menu_Item_Price_Max_Order_By>;
  min?: InputMaybe<Menu_Item_Price_Min_Order_By>;
  stddev?: InputMaybe<Menu_Item_Price_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Menu_Item_Price_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Menu_Item_Price_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Menu_Item_Price_Sum_Order_By>;
  var_pop?: InputMaybe<Menu_Item_Price_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Menu_Item_Price_Var_Samp_Order_By>;
  variance?: InputMaybe<Menu_Item_Price_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "menu.item_price" */
export type Menu_Item_Price_Arr_Rel_Insert_Input = {
  data: Array<Menu_Item_Price_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_Price_On_Conflict>;
};

/** aggregate avg on columns */
export type Menu_Item_Price_Avg_Fields = {
  __typename?: 'menu_item_price_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "menu.item_price" */
export type Menu_Item_Price_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "menu.item_price". All fields are combined with a logical 'AND'. */
export type Menu_Item_Price_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Item_Price_Bool_Exp>>;
  _not?: InputMaybe<Menu_Item_Price_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Item_Price_Bool_Exp>>;
  active_menu_item?: InputMaybe<Menu_Item_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_items?: InputMaybe<Menu_Item_Bool_Exp>;
  orders_items?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.item_price" */
export enum Menu_Item_Price_Constraint {
  /** unique or primary key constraint on columns "id" */
  MenuItemPricePkey = 'menu_item_price_pkey'
}

/** input type for incrementing numeric columns in table "menu.item_price" */
export type Menu_Item_Price_Inc_Input = {
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "menu.item_price" */
export type Menu_Item_Price_Insert_Input = {
  active_menu_item?: InputMaybe<Menu_Item_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_item_id?: InputMaybe<Scalars['uuid']>;
  menu_items?: InputMaybe<Menu_Item_Arr_Rel_Insert_Input>;
  orders_items?: InputMaybe<Booking_Order_Items_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['Int']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Menu_Item_Price_Max_Fields = {
  __typename?: 'menu_item_price_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  menu_item_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "menu.item_price" */
export type Menu_Item_Price_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Menu_Item_Price_Min_Fields = {
  __typename?: 'menu_item_price_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  menu_item_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "menu.item_price" */
export type Menu_Item_Price_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "menu.item_price" */
export type Menu_Item_Price_Mutation_Response = {
  __typename?: 'menu_item_price_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Item_Price>;
};

/** input type for inserting object relation for remote table "menu.item_price" */
export type Menu_Item_Price_Obj_Rel_Insert_Input = {
  data: Menu_Item_Price_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Item_Price_On_Conflict>;
};

/** on_conflict condition type for table "menu.item_price" */
export type Menu_Item_Price_On_Conflict = {
  constraint: Menu_Item_Price_Constraint;
  update_columns?: Array<Menu_Item_Price_Update_Column>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.item_price". */
export type Menu_Item_Price_Order_By = {
  active_menu_item?: InputMaybe<Menu_Item_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_item_id?: InputMaybe<Order_By>;
  menu_items_aggregate?: InputMaybe<Menu_Item_Aggregate_Order_By>;
  orders_items_aggregate?: InputMaybe<Booking_Order_Items_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_item_price */
export type Menu_Item_Price_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "menu.item_price" */
export enum Menu_Item_Price_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MenuItemId = 'menu_item_id',
  /** column name */
  Price = 'price',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "menu.item_price" */
export type Menu_Item_Price_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_item_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Menu_Item_Price_Stddev_Fields = {
  __typename?: 'menu_item_price_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "menu.item_price" */
export type Menu_Item_Price_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Menu_Item_Price_Stddev_Pop_Fields = {
  __typename?: 'menu_item_price_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "menu.item_price" */
export type Menu_Item_Price_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Menu_Item_Price_Stddev_Samp_Fields = {
  __typename?: 'menu_item_price_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "menu.item_price" */
export type Menu_Item_Price_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Menu_Item_Price_Sum_Fields = {
  __typename?: 'menu_item_price_sum_fields';
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "menu.item_price" */
export type Menu_Item_Price_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "menu.item_price" */
export enum Menu_Item_Price_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MenuItemId = 'menu_item_id',
  /** column name */
  Price = 'price',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** aggregate var_pop on columns */
export type Menu_Item_Price_Var_Pop_Fields = {
  __typename?: 'menu_item_price_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "menu.item_price" */
export type Menu_Item_Price_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Menu_Item_Price_Var_Samp_Fields = {
  __typename?: 'menu_item_price_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "menu.item_price" */
export type Menu_Item_Price_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Menu_Item_Price_Variance_Fields = {
  __typename?: 'menu_item_price_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "menu.item_price" */
export type Menu_Item_Price_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** select columns of table "menu.item" */
export enum Menu_Item_Select_Column {
  /** column name */
  ActivePrice = 'active_price',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Labels = 'labels',
  /** column name */
  Live = 'live',
  /** column name */
  MenuParent = 'menu_parent',
  /** column name */
  Name = 'name',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "menu.item" */
export type Menu_Item_Set_Input = {
  active_price?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  live?: InputMaybe<Scalars['Boolean']>;
  menu_parent?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "menu.item" */
export enum Menu_Item_Update_Column {
  /** column name */
  ActivePrice = 'active_price',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Labels = 'labels',
  /** column name */
  Live = 'live',
  /** column name */
  MenuParent = 'menu_parent',
  /** column name */
  Name = 'name',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "menu.producer" */
export type Menu_Producer = {
  __typename?: 'menu_producer';
  colors: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  menu_trees: Array<Menu_Tree>;
  /** An aggregate relationship */
  menu_trees_aggregate: Menu_Tree_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Booking_Order>;
  /** An aggregate relationship */
  orders_aggregate: Booking_Order_Aggregate;
  /** An object relationship */
  printer?: Maybe<Printer>;
  printer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
};


/** columns and relationships of "menu.producer" */
export type Menu_ProducerMenu_TreesArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "menu.producer" */
export type Menu_ProducerMenu_Trees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "menu.producer" */
export type Menu_ProducerOrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "menu.producer" */
export type Menu_ProducerOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};

/** aggregated selection of "menu.producer" */
export type Menu_Producer_Aggregate = {
  __typename?: 'menu_producer_aggregate';
  aggregate?: Maybe<Menu_Producer_Aggregate_Fields>;
  nodes: Array<Menu_Producer>;
};

/** aggregate fields of "menu.producer" */
export type Menu_Producer_Aggregate_Fields = {
  __typename?: 'menu_producer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Menu_Producer_Max_Fields>;
  min?: Maybe<Menu_Producer_Min_Fields>;
};


/** aggregate fields of "menu.producer" */
export type Menu_Producer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "menu.producer" */
export type Menu_Producer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menu_Producer_Max_Order_By>;
  min?: InputMaybe<Menu_Producer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "menu.producer" */
export type Menu_Producer_Arr_Rel_Insert_Input = {
  data: Array<Menu_Producer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Producer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "menu.producer". All fields are combined with a logical 'AND'. */
export type Menu_Producer_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Producer_Bool_Exp>>;
  _not?: InputMaybe<Menu_Producer_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Producer_Bool_Exp>>;
  colors?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_trees?: InputMaybe<Menu_Tree_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Booking_Order_Bool_Exp>;
  printer?: InputMaybe<Printer_Bool_Exp>;
  printer_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.producer" */
export enum Menu_Producer_Constraint {
  /** unique or primary key constraint on columns "id" */
  MenuProducerPkey = 'menu_producer_pkey'
}

/** input type for inserting data into table "menu.producer" */
export type Menu_Producer_Insert_Input = {
  colors?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_trees?: InputMaybe<Menu_Tree_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<Booking_Order_Arr_Rel_Insert_Input>;
  printer?: InputMaybe<Printer_Obj_Rel_Insert_Input>;
  printer_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Menu_Producer_Max_Fields = {
  __typename?: 'menu_producer_max_fields';
  colors?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "menu.producer" */
export type Menu_Producer_Max_Order_By = {
  colors?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Menu_Producer_Min_Fields = {
  __typename?: 'menu_producer_min_fields';
  colors?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  printer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "menu.producer" */
export type Menu_Producer_Min_Order_By = {
  colors?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "menu.producer" */
export type Menu_Producer_Mutation_Response = {
  __typename?: 'menu_producer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Producer>;
};

/** input type for inserting object relation for remote table "menu.producer" */
export type Menu_Producer_Obj_Rel_Insert_Input = {
  data: Menu_Producer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Producer_On_Conflict>;
};

/** on_conflict condition type for table "menu.producer" */
export type Menu_Producer_On_Conflict = {
  constraint: Menu_Producer_Constraint;
  update_columns?: Array<Menu_Producer_Update_Column>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.producer". */
export type Menu_Producer_Order_By = {
  colors?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_trees_aggregate?: InputMaybe<Menu_Tree_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Booking_Order_Aggregate_Order_By>;
  printer?: InputMaybe<Printer_Order_By>;
  printer_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_producer */
export type Menu_Producer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "menu.producer" */
export enum Menu_Producer_Select_Column {
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "menu.producer" */
export type Menu_Producer_Set_Input = {
  colors?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  printer_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "menu.producer" */
export enum Menu_Producer_Update_Column {
  /** column name */
  Colors = 'colors',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrinterId = 'printer_id',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** columns and relationships of "menu.tree" */
export type Menu_Tree = {
  __typename?: 'menu_tree';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  menu_childrens: Array<Menu_Tree>;
  /** An aggregate relationship */
  menu_childrens_aggregate: Menu_Tree_Aggregate;
  /** An array relationship */
  menu_items: Array<Menu_Item>;
  /** An aggregate relationship */
  menu_items_aggregate: Menu_Item_Aggregate;
  /** An object relationship */
  menu_parent?: Maybe<Menu_Tree>;
  /** An object relationship */
  menu_producer: Menu_Producer;
  parent_id?: Maybe<Scalars['uuid']>;
  producer_id: Scalars['uuid'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "menu.tree" */
export type Menu_TreeMenu_ChildrensArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "menu.tree" */
export type Menu_TreeMenu_Childrens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "menu.tree" */
export type Menu_TreeMenu_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


/** columns and relationships of "menu.tree" */
export type Menu_TreeMenu_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};

/** aggregated selection of "menu.tree" */
export type Menu_Tree_Aggregate = {
  __typename?: 'menu_tree_aggregate';
  aggregate?: Maybe<Menu_Tree_Aggregate_Fields>;
  nodes: Array<Menu_Tree>;
};

/** aggregate fields of "menu.tree" */
export type Menu_Tree_Aggregate_Fields = {
  __typename?: 'menu_tree_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Menu_Tree_Max_Fields>;
  min?: Maybe<Menu_Tree_Min_Fields>;
};


/** aggregate fields of "menu.tree" */
export type Menu_Tree_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "menu.tree" */
export type Menu_Tree_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Menu_Tree_Max_Order_By>;
  min?: InputMaybe<Menu_Tree_Min_Order_By>;
};

/** input type for inserting array relation for remote table "menu.tree" */
export type Menu_Tree_Arr_Rel_Insert_Input = {
  data: Array<Menu_Tree_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Tree_On_Conflict>;
};

/** Boolean expression to filter rows from the table "menu.tree". All fields are combined with a logical 'AND'. */
export type Menu_Tree_Bool_Exp = {
  _and?: InputMaybe<Array<Menu_Tree_Bool_Exp>>;
  _not?: InputMaybe<Menu_Tree_Bool_Exp>;
  _or?: InputMaybe<Array<Menu_Tree_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  menu_childrens?: InputMaybe<Menu_Tree_Bool_Exp>;
  menu_items?: InputMaybe<Menu_Item_Bool_Exp>;
  menu_parent?: InputMaybe<Menu_Tree_Bool_Exp>;
  menu_producer?: InputMaybe<Menu_Producer_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  producer_id?: InputMaybe<Uuid_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "menu.tree" */
export enum Menu_Tree_Constraint {
  /** unique or primary key constraint on columns "id" */
  MenuTreePkey = 'menu_tree_pkey'
}

/** input type for inserting data into table "menu.tree" */
export type Menu_Tree_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menu_childrens?: InputMaybe<Menu_Tree_Arr_Rel_Insert_Input>;
  menu_items?: InputMaybe<Menu_Item_Arr_Rel_Insert_Input>;
  menu_parent?: InputMaybe<Menu_Tree_Obj_Rel_Insert_Input>;
  menu_producer?: InputMaybe<Menu_Producer_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  producer_id?: InputMaybe<Scalars['uuid']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Menu_Tree_Max_Fields = {
  __typename?: 'menu_tree_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "menu.tree" */
export type Menu_Tree_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Menu_Tree_Min_Fields = {
  __typename?: 'menu_tree_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parent_id?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "menu.tree" */
export type Menu_Tree_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "menu.tree" */
export type Menu_Tree_Mutation_Response = {
  __typename?: 'menu_tree_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu_Tree>;
};

/** input type for inserting object relation for remote table "menu.tree" */
export type Menu_Tree_Obj_Rel_Insert_Input = {
  data: Menu_Tree_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Menu_Tree_On_Conflict>;
};

/** on_conflict condition type for table "menu.tree" */
export type Menu_Tree_On_Conflict = {
  constraint: Menu_Tree_Constraint;
  update_columns?: Array<Menu_Tree_Update_Column>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};

/** Ordering options when selecting data from "menu.tree". */
export type Menu_Tree_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  menu_childrens_aggregate?: InputMaybe<Menu_Tree_Aggregate_Order_By>;
  menu_items_aggregate?: InputMaybe<Menu_Item_Aggregate_Order_By>;
  menu_parent?: InputMaybe<Menu_Tree_Order_By>;
  menu_producer?: InputMaybe<Menu_Producer_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  producer_id?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: menu_tree */
export type Menu_Tree_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "menu.tree" */
export enum Menu_Tree_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "menu.tree" */
export type Menu_Tree_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  producer_id?: InputMaybe<Scalars['uuid']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "menu.tree" */
export enum Menu_Tree_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createStaffOrder: Array<BookingItemsCreated>;
  createStripeOrder: Array<BookingItemsCreated>;
  /** delete data from the table: "booking.order" */
  delete_booking_order?: Maybe<Booking_Order_Mutation_Response>;
  /** delete single row from the table: "booking.order" */
  delete_booking_order_by_pk?: Maybe<Booking_Order>;
  /** delete data from the table: "booking.order_items" */
  delete_booking_order_items?: Maybe<Booking_Order_Items_Mutation_Response>;
  /** delete single row from the table: "booking.order_items" */
  delete_booking_order_items_by_pk?: Maybe<Booking_Order_Items>;
  /** delete data from the table: "booking.order_items_extra" */
  delete_booking_order_items_extra?: Maybe<Booking_Order_Items_Extra_Mutation_Response>;
  /** delete single row from the table: "booking.order_items_extra" */
  delete_booking_order_items_extra_by_pk?: Maybe<Booking_Order_Items_Extra>;
  /** delete data from the table: "booking.order_payment" */
  delete_booking_order_payment?: Maybe<Booking_Order_Payment_Mutation_Response>;
  /** delete single row from the table: "booking.order_payment" */
  delete_booking_order_payment_by_pk?: Maybe<Booking_Order_Payment>;
  /** delete data from the table: "booking.order_payment_items" */
  delete_booking_order_payment_items?: Maybe<Booking_Order_Payment_Items_Mutation_Response>;
  /** delete single row from the table: "booking.order_payment_items" */
  delete_booking_order_payment_items_by_pk?: Maybe<Booking_Order_Payment_Items>;
  /** delete data from the table: "booking.order_payment_status" */
  delete_booking_order_payment_status?: Maybe<Booking_Order_Payment_Status_Mutation_Response>;
  /** delete single row from the table: "booking.order_payment_status" */
  delete_booking_order_payment_status_by_pk?: Maybe<Booking_Order_Payment_Status>;
  /** delete data from the table: "booking.order_status" */
  delete_booking_order_status?: Maybe<Booking_Order_Status_Mutation_Response>;
  /** delete single row from the table: "booking.order_status" */
  delete_booking_order_status_by_pk?: Maybe<Booking_Order_Status>;
  /** delete data from the table: "booking.order_type" */
  delete_booking_order_type?: Maybe<Booking_Order_Type_Mutation_Response>;
  /** delete single row from the table: "booking.order_type" */
  delete_booking_order_type_by_pk?: Maybe<Booking_Order_Type>;
  /** delete data from the table: "booking.printer_job_order" */
  delete_booking_printer_job_order?: Maybe<Booking_Printer_Job_Order_Mutation_Response>;
  /** delete single row from the table: "booking.printer_job_order" */
  delete_booking_printer_job_order_by_pk?: Maybe<Booking_Printer_Job_Order>;
  /** delete data from the table: "booking.session_email_receipt" */
  delete_booking_session_email_receipt?: Maybe<Booking_Session_Email_Receipt_Mutation_Response>;
  /** delete single row from the table: "booking.session_email_receipt" */
  delete_booking_session_email_receipt_by_pk?: Maybe<Booking_Session_Email_Receipt>;
  /** delete data from the table: "booking.table_sessions" */
  delete_booking_table_sessions?: Maybe<Booking_Table_Sessions_Mutation_Response>;
  /** delete single row from the table: "booking.table_sessions" */
  delete_booking_table_sessions_by_pk?: Maybe<Booking_Table_Sessions>;
  /** delete data from the table: "booking.table_sessions_status" */
  delete_booking_table_sessions_status?: Maybe<Booking_Table_Sessions_Status_Mutation_Response>;
  /** delete single row from the table: "booking.table_sessions_status" */
  delete_booking_table_sessions_status_by_pk?: Maybe<Booking_Table_Sessions_Status>;
  /** delete data from the table: "countries" */
  delete_countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "countries" */
  delete_countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "menu.item" */
  delete_menu_item?: Maybe<Menu_Item_Mutation_Response>;
  /** delete single row from the table: "menu.item" */
  delete_menu_item_by_pk?: Maybe<Menu_Item>;
  /** delete data from the table: "menu.item_extra" */
  delete_menu_item_extra?: Maybe<Menu_Item_Extra_Mutation_Response>;
  /** delete single row from the table: "menu.item_extra" */
  delete_menu_item_extra_by_pk?: Maybe<Menu_Item_Extra>;
  /** delete data from the table: "menu.item_label" */
  delete_menu_item_label?: Maybe<Menu_Item_Label_Mutation_Response>;
  /** delete single row from the table: "menu.item_label" */
  delete_menu_item_label_by_pk?: Maybe<Menu_Item_Label>;
  /** delete data from the table: "menu.item_price" */
  delete_menu_item_price?: Maybe<Menu_Item_Price_Mutation_Response>;
  /** delete single row from the table: "menu.item_price" */
  delete_menu_item_price_by_pk?: Maybe<Menu_Item_Price>;
  /** delete data from the table: "menu.producer" */
  delete_menu_producer?: Maybe<Menu_Producer_Mutation_Response>;
  /** delete single row from the table: "menu.producer" */
  delete_menu_producer_by_pk?: Maybe<Menu_Producer>;
  /** delete data from the table: "menu.tree" */
  delete_menu_tree?: Maybe<Menu_Tree_Mutation_Response>;
  /** delete single row from the table: "menu.tree" */
  delete_menu_tree_by_pk?: Maybe<Menu_Tree>;
  /** delete data from the table: "printer" */
  delete_printer?: Maybe<Printer_Mutation_Response>;
  /** delete single row from the table: "printer" */
  delete_printer_by_pk?: Maybe<Printer>;
  /** delete data from the table: "qr_template" */
  delete_qr_template?: Maybe<Qr_Template_Mutation_Response>;
  /** delete single row from the table: "qr_template" */
  delete_qr_template_by_pk?: Maybe<Qr_Template>;
  /** delete data from the table: "restaurant_stripe" */
  delete_restaurant_stripe?: Maybe<Restaurant_Stripe_Mutation_Response>;
  /** delete single row from the table: "restaurant_stripe" */
  delete_restaurant_stripe_by_pk?: Maybe<Restaurant_Stripe>;
  /** delete data from the table: "restaurant_wifi" */
  delete_restaurant_wifi?: Maybe<Restaurant_Wifi_Mutation_Response>;
  /** delete single row from the table: "restaurant_wifi" */
  delete_restaurant_wifi_by_pk?: Maybe<Restaurant_Wifi>;
  /** delete data from the table: "restaurants" */
  delete_restaurants?: Maybe<Restaurants_Mutation_Response>;
  /** delete single row from the table: "restaurants" */
  delete_restaurants_by_pk?: Maybe<Restaurants>;
  /** delete data from the table: "seating.qr" */
  delete_seating_qr?: Maybe<Seating_Qr_Mutation_Response>;
  /** delete single row from the table: "seating.qr" */
  delete_seating_qr_by_pk?: Maybe<Seating_Qr>;
  /** delete data from the table: "seating.table" */
  delete_seating_table?: Maybe<Seating_Table_Mutation_Response>;
  /** delete single row from the table: "seating.table" */
  delete_seating_table_by_pk?: Maybe<Seating_Table>;
  /** delete data from the table: "seating.table_position" */
  delete_seating_table_position?: Maybe<Seating_Table_Position_Mutation_Response>;
  /** delete single row from the table: "seating.table_position" */
  delete_seating_table_position_by_pk?: Maybe<Seating_Table_Position>;
  /** delete data from the table: "seating.table_room" */
  delete_seating_table_room?: Maybe<Seating_Table_Room_Mutation_Response>;
  /** delete single row from the table: "seating.table_room" */
  delete_seating_table_room_by_pk?: Maybe<Seating_Table_Room>;
  /** delete data from the table: "stripe_orders_status_enum" */
  delete_stripe_orders_status_enum?: Maybe<Stripe_Orders_Status_Enum_Mutation_Response>;
  /** delete single row from the table: "stripe_orders_status_enum" */
  delete_stripe_orders_status_enum_by_pk?: Maybe<Stripe_Orders_Status_Enum>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_roles_enum" */
  delete_user_roles_enum?: Maybe<User_Roles_Enum_Mutation_Response>;
  /** delete single row from the table: "user_roles_enum" */
  delete_user_roles_enum_by_pk?: Maybe<User_Roles_Enum>;
  generateTablesQrCodes: Array<QrData>;
  getStaffLoginToken?: Maybe<StaffLoginToken>;
  getStripeAccountLink?: Maybe<StripeAccountLink>;
  /** insert data into the table: "booking.order" */
  insert_booking_order?: Maybe<Booking_Order_Mutation_Response>;
  /** insert data into the table: "booking.order_items" */
  insert_booking_order_items?: Maybe<Booking_Order_Items_Mutation_Response>;
  /** insert data into the table: "booking.order_items_extra" */
  insert_booking_order_items_extra?: Maybe<Booking_Order_Items_Extra_Mutation_Response>;
  /** insert a single row into the table: "booking.order_items_extra" */
  insert_booking_order_items_extra_one?: Maybe<Booking_Order_Items_Extra>;
  /** insert a single row into the table: "booking.order_items" */
  insert_booking_order_items_one?: Maybe<Booking_Order_Items>;
  /** insert a single row into the table: "booking.order" */
  insert_booking_order_one?: Maybe<Booking_Order>;
  /** insert data into the table: "booking.order_payment" */
  insert_booking_order_payment?: Maybe<Booking_Order_Payment_Mutation_Response>;
  /** insert data into the table: "booking.order_payment_items" */
  insert_booking_order_payment_items?: Maybe<Booking_Order_Payment_Items_Mutation_Response>;
  /** insert a single row into the table: "booking.order_payment_items" */
  insert_booking_order_payment_items_one?: Maybe<Booking_Order_Payment_Items>;
  /** insert a single row into the table: "booking.order_payment" */
  insert_booking_order_payment_one?: Maybe<Booking_Order_Payment>;
  /** insert data into the table: "booking.order_payment_status" */
  insert_booking_order_payment_status?: Maybe<Booking_Order_Payment_Status_Mutation_Response>;
  /** insert a single row into the table: "booking.order_payment_status" */
  insert_booking_order_payment_status_one?: Maybe<Booking_Order_Payment_Status>;
  /** insert data into the table: "booking.order_status" */
  insert_booking_order_status?: Maybe<Booking_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "booking.order_status" */
  insert_booking_order_status_one?: Maybe<Booking_Order_Status>;
  /** insert data into the table: "booking.order_type" */
  insert_booking_order_type?: Maybe<Booking_Order_Type_Mutation_Response>;
  /** insert a single row into the table: "booking.order_type" */
  insert_booking_order_type_one?: Maybe<Booking_Order_Type>;
  /** insert data into the table: "booking.printer_job_order" */
  insert_booking_printer_job_order?: Maybe<Booking_Printer_Job_Order_Mutation_Response>;
  /** insert a single row into the table: "booking.printer_job_order" */
  insert_booking_printer_job_order_one?: Maybe<Booking_Printer_Job_Order>;
  /** insert data into the table: "booking.session_email_receipt" */
  insert_booking_session_email_receipt?: Maybe<Booking_Session_Email_Receipt_Mutation_Response>;
  /** insert a single row into the table: "booking.session_email_receipt" */
  insert_booking_session_email_receipt_one?: Maybe<Booking_Session_Email_Receipt>;
  /** insert data into the table: "booking.table_sessions" */
  insert_booking_table_sessions?: Maybe<Booking_Table_Sessions_Mutation_Response>;
  /** insert a single row into the table: "booking.table_sessions" */
  insert_booking_table_sessions_one?: Maybe<Booking_Table_Sessions>;
  /** insert data into the table: "booking.table_sessions_status" */
  insert_booking_table_sessions_status?: Maybe<Booking_Table_Sessions_Status_Mutation_Response>;
  /** insert a single row into the table: "booking.table_sessions_status" */
  insert_booking_table_sessions_status_one?: Maybe<Booking_Table_Sessions_Status>;
  /** insert data into the table: "countries" */
  insert_countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "countries" */
  insert_countries_one?: Maybe<Countries>;
  /** insert data into the table: "menu.item" */
  insert_menu_item?: Maybe<Menu_Item_Mutation_Response>;
  /** insert data into the table: "menu.item_extra" */
  insert_menu_item_extra?: Maybe<Menu_Item_Extra_Mutation_Response>;
  /** insert a single row into the table: "menu.item_extra" */
  insert_menu_item_extra_one?: Maybe<Menu_Item_Extra>;
  /** insert data into the table: "menu.item_label" */
  insert_menu_item_label?: Maybe<Menu_Item_Label_Mutation_Response>;
  /** insert a single row into the table: "menu.item_label" */
  insert_menu_item_label_one?: Maybe<Menu_Item_Label>;
  /** insert a single row into the table: "menu.item" */
  insert_menu_item_one?: Maybe<Menu_Item>;
  /** insert data into the table: "menu.item_price" */
  insert_menu_item_price?: Maybe<Menu_Item_Price_Mutation_Response>;
  /** insert a single row into the table: "menu.item_price" */
  insert_menu_item_price_one?: Maybe<Menu_Item_Price>;
  /** insert data into the table: "menu.producer" */
  insert_menu_producer?: Maybe<Menu_Producer_Mutation_Response>;
  /** insert a single row into the table: "menu.producer" */
  insert_menu_producer_one?: Maybe<Menu_Producer>;
  /** insert data into the table: "menu.tree" */
  insert_menu_tree?: Maybe<Menu_Tree_Mutation_Response>;
  /** insert a single row into the table: "menu.tree" */
  insert_menu_tree_one?: Maybe<Menu_Tree>;
  /** insert data into the table: "printer" */
  insert_printer?: Maybe<Printer_Mutation_Response>;
  /** insert a single row into the table: "printer" */
  insert_printer_one?: Maybe<Printer>;
  /** insert data into the table: "qr_template" */
  insert_qr_template?: Maybe<Qr_Template_Mutation_Response>;
  /** insert a single row into the table: "qr_template" */
  insert_qr_template_one?: Maybe<Qr_Template>;
  /** insert data into the table: "restaurant_stripe" */
  insert_restaurant_stripe?: Maybe<Restaurant_Stripe_Mutation_Response>;
  /** insert a single row into the table: "restaurant_stripe" */
  insert_restaurant_stripe_one?: Maybe<Restaurant_Stripe>;
  /** insert data into the table: "restaurant_wifi" */
  insert_restaurant_wifi?: Maybe<Restaurant_Wifi_Mutation_Response>;
  /** insert a single row into the table: "restaurant_wifi" */
  insert_restaurant_wifi_one?: Maybe<Restaurant_Wifi>;
  /** insert data into the table: "restaurants" */
  insert_restaurants?: Maybe<Restaurants_Mutation_Response>;
  /** insert a single row into the table: "restaurants" */
  insert_restaurants_one?: Maybe<Restaurants>;
  /** insert data into the table: "seating.qr" */
  insert_seating_qr?: Maybe<Seating_Qr_Mutation_Response>;
  /** insert a single row into the table: "seating.qr" */
  insert_seating_qr_one?: Maybe<Seating_Qr>;
  /** insert data into the table: "seating.table" */
  insert_seating_table?: Maybe<Seating_Table_Mutation_Response>;
  /** insert a single row into the table: "seating.table" */
  insert_seating_table_one?: Maybe<Seating_Table>;
  /** insert data into the table: "seating.table_position" */
  insert_seating_table_position?: Maybe<Seating_Table_Position_Mutation_Response>;
  /** insert a single row into the table: "seating.table_position" */
  insert_seating_table_position_one?: Maybe<Seating_Table_Position>;
  /** insert data into the table: "seating.table_room" */
  insert_seating_table_room?: Maybe<Seating_Table_Room_Mutation_Response>;
  /** insert a single row into the table: "seating.table_room" */
  insert_seating_table_room_one?: Maybe<Seating_Table_Room>;
  /** insert data into the table: "stripe_orders_status_enum" */
  insert_stripe_orders_status_enum?: Maybe<Stripe_Orders_Status_Enum_Mutation_Response>;
  /** insert a single row into the table: "stripe_orders_status_enum" */
  insert_stripe_orders_status_enum_one?: Maybe<Stripe_Orders_Status_Enum>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_roles_enum" */
  insert_user_roles_enum?: Maybe<User_Roles_Enum_Mutation_Response>;
  /** insert a single row into the table: "user_roles_enum" */
  insert_user_roles_enum_one?: Maybe<User_Roles_Enum>;
  /** update data of the table: "booking.order" */
  update_booking_order?: Maybe<Booking_Order_Mutation_Response>;
  /** update single row of the table: "booking.order" */
  update_booking_order_by_pk?: Maybe<Booking_Order>;
  /** update data of the table: "booking.order_items" */
  update_booking_order_items?: Maybe<Booking_Order_Items_Mutation_Response>;
  /** update single row of the table: "booking.order_items" */
  update_booking_order_items_by_pk?: Maybe<Booking_Order_Items>;
  /** update data of the table: "booking.order_items_extra" */
  update_booking_order_items_extra?: Maybe<Booking_Order_Items_Extra_Mutation_Response>;
  /** update single row of the table: "booking.order_items_extra" */
  update_booking_order_items_extra_by_pk?: Maybe<Booking_Order_Items_Extra>;
  /** update data of the table: "booking.order_payment" */
  update_booking_order_payment?: Maybe<Booking_Order_Payment_Mutation_Response>;
  /** update single row of the table: "booking.order_payment" */
  update_booking_order_payment_by_pk?: Maybe<Booking_Order_Payment>;
  /** update data of the table: "booking.order_payment_items" */
  update_booking_order_payment_items?: Maybe<Booking_Order_Payment_Items_Mutation_Response>;
  /** update single row of the table: "booking.order_payment_items" */
  update_booking_order_payment_items_by_pk?: Maybe<Booking_Order_Payment_Items>;
  /** update data of the table: "booking.order_payment_status" */
  update_booking_order_payment_status?: Maybe<Booking_Order_Payment_Status_Mutation_Response>;
  /** update single row of the table: "booking.order_payment_status" */
  update_booking_order_payment_status_by_pk?: Maybe<Booking_Order_Payment_Status>;
  /** update data of the table: "booking.order_status" */
  update_booking_order_status?: Maybe<Booking_Order_Status_Mutation_Response>;
  /** update single row of the table: "booking.order_status" */
  update_booking_order_status_by_pk?: Maybe<Booking_Order_Status>;
  /** update data of the table: "booking.order_type" */
  update_booking_order_type?: Maybe<Booking_Order_Type_Mutation_Response>;
  /** update single row of the table: "booking.order_type" */
  update_booking_order_type_by_pk?: Maybe<Booking_Order_Type>;
  /** update data of the table: "booking.printer_job_order" */
  update_booking_printer_job_order?: Maybe<Booking_Printer_Job_Order_Mutation_Response>;
  /** update single row of the table: "booking.printer_job_order" */
  update_booking_printer_job_order_by_pk?: Maybe<Booking_Printer_Job_Order>;
  /** update data of the table: "booking.session_email_receipt" */
  update_booking_session_email_receipt?: Maybe<Booking_Session_Email_Receipt_Mutation_Response>;
  /** update single row of the table: "booking.session_email_receipt" */
  update_booking_session_email_receipt_by_pk?: Maybe<Booking_Session_Email_Receipt>;
  /** update data of the table: "booking.table_sessions" */
  update_booking_table_sessions?: Maybe<Booking_Table_Sessions_Mutation_Response>;
  /** update single row of the table: "booking.table_sessions" */
  update_booking_table_sessions_by_pk?: Maybe<Booking_Table_Sessions>;
  /** update data of the table: "booking.table_sessions_status" */
  update_booking_table_sessions_status?: Maybe<Booking_Table_Sessions_Status_Mutation_Response>;
  /** update single row of the table: "booking.table_sessions_status" */
  update_booking_table_sessions_status_by_pk?: Maybe<Booking_Table_Sessions_Status>;
  /** update data of the table: "countries" */
  update_countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "countries" */
  update_countries_by_pk?: Maybe<Countries>;
  /** update data of the table: "menu.item" */
  update_menu_item?: Maybe<Menu_Item_Mutation_Response>;
  /** update single row of the table: "menu.item" */
  update_menu_item_by_pk?: Maybe<Menu_Item>;
  /** update data of the table: "menu.item_extra" */
  update_menu_item_extra?: Maybe<Menu_Item_Extra_Mutation_Response>;
  /** update single row of the table: "menu.item_extra" */
  update_menu_item_extra_by_pk?: Maybe<Menu_Item_Extra>;
  /** update data of the table: "menu.item_label" */
  update_menu_item_label?: Maybe<Menu_Item_Label_Mutation_Response>;
  /** update single row of the table: "menu.item_label" */
  update_menu_item_label_by_pk?: Maybe<Menu_Item_Label>;
  /** update data of the table: "menu.item_price" */
  update_menu_item_price?: Maybe<Menu_Item_Price_Mutation_Response>;
  /** update single row of the table: "menu.item_price" */
  update_menu_item_price_by_pk?: Maybe<Menu_Item_Price>;
  /** update data of the table: "menu.producer" */
  update_menu_producer?: Maybe<Menu_Producer_Mutation_Response>;
  /** update single row of the table: "menu.producer" */
  update_menu_producer_by_pk?: Maybe<Menu_Producer>;
  /** update data of the table: "menu.tree" */
  update_menu_tree?: Maybe<Menu_Tree_Mutation_Response>;
  /** update single row of the table: "menu.tree" */
  update_menu_tree_by_pk?: Maybe<Menu_Tree>;
  /** update data of the table: "printer" */
  update_printer?: Maybe<Printer_Mutation_Response>;
  /** update single row of the table: "printer" */
  update_printer_by_pk?: Maybe<Printer>;
  /** update data of the table: "qr_template" */
  update_qr_template?: Maybe<Qr_Template_Mutation_Response>;
  /** update single row of the table: "qr_template" */
  update_qr_template_by_pk?: Maybe<Qr_Template>;
  /** update data of the table: "restaurant_stripe" */
  update_restaurant_stripe?: Maybe<Restaurant_Stripe_Mutation_Response>;
  /** update single row of the table: "restaurant_stripe" */
  update_restaurant_stripe_by_pk?: Maybe<Restaurant_Stripe>;
  /** update data of the table: "restaurant_wifi" */
  update_restaurant_wifi?: Maybe<Restaurant_Wifi_Mutation_Response>;
  /** update single row of the table: "restaurant_wifi" */
  update_restaurant_wifi_by_pk?: Maybe<Restaurant_Wifi>;
  /** update data of the table: "restaurants" */
  update_restaurants?: Maybe<Restaurants_Mutation_Response>;
  /** update single row of the table: "restaurants" */
  update_restaurants_by_pk?: Maybe<Restaurants>;
  /** update data of the table: "seating.qr" */
  update_seating_qr?: Maybe<Seating_Qr_Mutation_Response>;
  /** update single row of the table: "seating.qr" */
  update_seating_qr_by_pk?: Maybe<Seating_Qr>;
  /** update data of the table: "seating.table" */
  update_seating_table?: Maybe<Seating_Table_Mutation_Response>;
  /** update single row of the table: "seating.table" */
  update_seating_table_by_pk?: Maybe<Seating_Table>;
  /** update data of the table: "seating.table_position" */
  update_seating_table_position?: Maybe<Seating_Table_Position_Mutation_Response>;
  /** update single row of the table: "seating.table_position" */
  update_seating_table_position_by_pk?: Maybe<Seating_Table_Position>;
  /** update data of the table: "seating.table_room" */
  update_seating_table_room?: Maybe<Seating_Table_Room_Mutation_Response>;
  /** update single row of the table: "seating.table_room" */
  update_seating_table_room_by_pk?: Maybe<Seating_Table_Room>;
  /** update data of the table: "stripe_orders_status_enum" */
  update_stripe_orders_status_enum?: Maybe<Stripe_Orders_Status_Enum_Mutation_Response>;
  /** update single row of the table: "stripe_orders_status_enum" */
  update_stripe_orders_status_enum_by_pk?: Maybe<Stripe_Orders_Status_Enum>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_roles_enum" */
  update_user_roles_enum?: Maybe<User_Roles_Enum_Mutation_Response>;
  /** update single row of the table: "user_roles_enum" */
  update_user_roles_enum_by_pk?: Maybe<User_Roles_Enum>;
};


/** mutation root */
export type Mutation_RootCreateStaffOrderArgs = {
  items: Array<OrderItems>;
  tableSessionId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootCreateStripeOrderArgs = {
  items: Array<OrderItems>;
  tableSessionId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_OrderArgs = {
  where: Booking_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_ItemsArgs = {
  where: Booking_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Items_ExtraArgs = {
  where: Booking_Order_Items_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Items_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_PaymentArgs = {
  where: Booking_Order_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Payment_ItemsArgs = {
  where: Booking_Order_Payment_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Payment_Items_By_PkArgs = {
  order_item_id: Scalars['uuid'];
  order_payment_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Payment_StatusArgs = {
  where: Booking_Order_Payment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Payment_Status_By_PkArgs = {
  values: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_StatusArgs = {
  where: Booking_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Status_By_PkArgs = {
  values: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_TypeArgs = {
  where: Booking_Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Order_Type_By_PkArgs = {
  values: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Printer_Job_OrderArgs = {
  where: Booking_Printer_Job_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Printer_Job_Order_By_PkArgs = {
  printer_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Session_Email_ReceiptArgs = {
  where: Booking_Session_Email_Receipt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Session_Email_Receipt_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Table_SessionsArgs = {
  where: Booking_Table_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Table_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Booking_Table_Sessions_StatusArgs = {
  where: Booking_Table_Sessions_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booking_Table_Sessions_Status_By_PkArgs = {
  values: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_ItemArgs = {
  where: Menu_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_ExtraArgs = {
  where: Menu_Item_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_LabelArgs = {
  where: Menu_Item_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_Label_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_PriceArgs = {
  where: Menu_Item_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Item_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_ProducerArgs = {
  where: Menu_Producer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Producer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Menu_TreeArgs = {
  where: Menu_Tree_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Menu_Tree_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PrinterArgs = {
  where: Printer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Printer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Qr_TemplateArgs = {
  where: Qr_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Qr_Template_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Restaurant_StripeArgs = {
  where: Restaurant_Stripe_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Restaurant_Stripe_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Restaurant_WifiArgs = {
  where: Restaurant_Wifi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Restaurant_Wifi_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RestaurantsArgs = {
  where: Restaurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Restaurants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Seating_QrArgs = {
  where: Seating_Qr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seating_Qr_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Seating_TableArgs = {
  where: Seating_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seating_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Seating_Table_PositionArgs = {
  where: Seating_Table_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seating_Table_Position_By_PkArgs = {
  table_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Seating_Table_RoomArgs = {
  where: Seating_Table_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Seating_Table_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Orders_Status_EnumArgs = {
  where: Stripe_Orders_Status_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Orders_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_EnumArgs = {
  where: User_Roles_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_Enum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerateTablesQrCodesArgs = {
  size?: InputMaybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootGetStaffLoginTokenArgs = {
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Booking_OrderArgs = {
  objects: Array<Booking_Order_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_ItemsArgs = {
  objects: Array<Booking_Order_Items_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Items_ExtraArgs = {
  objects: Array<Booking_Order_Items_Extra_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Items_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Items_Extra_OneArgs = {
  object: Booking_Order_Items_Extra_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Items_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Items_OneArgs = {
  object: Booking_Order_Items_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_OneArgs = {
  object: Booking_Order_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_PaymentArgs = {
  objects: Array<Booking_Order_Payment_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Payment_ItemsArgs = {
  objects: Array<Booking_Order_Payment_Items_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Payment_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Payment_Items_OneArgs = {
  object: Booking_Order_Payment_Items_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Payment_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Payment_OneArgs = {
  object: Booking_Order_Payment_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Payment_StatusArgs = {
  objects: Array<Booking_Order_Payment_Status_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Payment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Payment_Status_OneArgs = {
  object: Booking_Order_Payment_Status_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Payment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_StatusArgs = {
  objects: Array<Booking_Order_Status_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Status_OneArgs = {
  object: Booking_Order_Status_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_TypeArgs = {
  objects: Array<Booking_Order_Type_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Order_Type_OneArgs = {
  object: Booking_Order_Type_Insert_Input;
  on_conflict?: InputMaybe<Booking_Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Printer_Job_OrderArgs = {
  objects: Array<Booking_Printer_Job_Order_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Printer_Job_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Printer_Job_Order_OneArgs = {
  object: Booking_Printer_Job_Order_Insert_Input;
  on_conflict?: InputMaybe<Booking_Printer_Job_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Session_Email_ReceiptArgs = {
  objects: Array<Booking_Session_Email_Receipt_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Session_Email_Receipt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Session_Email_Receipt_OneArgs = {
  object: Booking_Session_Email_Receipt_Insert_Input;
  on_conflict?: InputMaybe<Booking_Session_Email_Receipt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Table_SessionsArgs = {
  objects: Array<Booking_Table_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Table_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Table_Sessions_OneArgs = {
  object: Booking_Table_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Booking_Table_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Table_Sessions_StatusArgs = {
  objects: Array<Booking_Table_Sessions_Status_Insert_Input>;
  on_conflict?: InputMaybe<Booking_Table_Sessions_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booking_Table_Sessions_Status_OneArgs = {
  object: Booking_Table_Sessions_Status_Insert_Input;
  on_conflict?: InputMaybe<Booking_Table_Sessions_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: InputMaybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_ItemArgs = {
  objects: Array<Menu_Item_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_ExtraArgs = {
  objects: Array<Menu_Item_Extra_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Item_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_Extra_OneArgs = {
  object: Menu_Item_Extra_Insert_Input;
  on_conflict?: InputMaybe<Menu_Item_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_LabelArgs = {
  objects: Array<Menu_Item_Label_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Item_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_Label_OneArgs = {
  object: Menu_Item_Label_Insert_Input;
  on_conflict?: InputMaybe<Menu_Item_Label_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_OneArgs = {
  object: Menu_Item_Insert_Input;
  on_conflict?: InputMaybe<Menu_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_PriceArgs = {
  objects: Array<Menu_Item_Price_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Item_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Item_Price_OneArgs = {
  object: Menu_Item_Price_Insert_Input;
  on_conflict?: InputMaybe<Menu_Item_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_ProducerArgs = {
  objects: Array<Menu_Producer_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Producer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Producer_OneArgs = {
  object: Menu_Producer_Insert_Input;
  on_conflict?: InputMaybe<Menu_Producer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_TreeArgs = {
  objects: Array<Menu_Tree_Insert_Input>;
  on_conflict?: InputMaybe<Menu_Tree_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Menu_Tree_OneArgs = {
  object: Menu_Tree_Insert_Input;
  on_conflict?: InputMaybe<Menu_Tree_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrinterArgs = {
  objects: Array<Printer_Insert_Input>;
  on_conflict?: InputMaybe<Printer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Printer_OneArgs = {
  object: Printer_Insert_Input;
  on_conflict?: InputMaybe<Printer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Qr_TemplateArgs = {
  objects: Array<Qr_Template_Insert_Input>;
  on_conflict?: InputMaybe<Qr_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Qr_Template_OneArgs = {
  object: Qr_Template_Insert_Input;
  on_conflict?: InputMaybe<Qr_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Restaurant_StripeArgs = {
  objects: Array<Restaurant_Stripe_Insert_Input>;
  on_conflict?: InputMaybe<Restaurant_Stripe_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Restaurant_Stripe_OneArgs = {
  object: Restaurant_Stripe_Insert_Input;
  on_conflict?: InputMaybe<Restaurant_Stripe_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Restaurant_WifiArgs = {
  objects: Array<Restaurant_Wifi_Insert_Input>;
  on_conflict?: InputMaybe<Restaurant_Wifi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Restaurant_Wifi_OneArgs = {
  object: Restaurant_Wifi_Insert_Input;
  on_conflict?: InputMaybe<Restaurant_Wifi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RestaurantsArgs = {
  objects: Array<Restaurants_Insert_Input>;
  on_conflict?: InputMaybe<Restaurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Restaurants_OneArgs = {
  object: Restaurants_Insert_Input;
  on_conflict?: InputMaybe<Restaurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_QrArgs = {
  objects: Array<Seating_Qr_Insert_Input>;
  on_conflict?: InputMaybe<Seating_Qr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Qr_OneArgs = {
  object: Seating_Qr_Insert_Input;
  on_conflict?: InputMaybe<Seating_Qr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_TableArgs = {
  objects: Array<Seating_Table_Insert_Input>;
  on_conflict?: InputMaybe<Seating_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Table_OneArgs = {
  object: Seating_Table_Insert_Input;
  on_conflict?: InputMaybe<Seating_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Table_PositionArgs = {
  objects: Array<Seating_Table_Position_Insert_Input>;
  on_conflict?: InputMaybe<Seating_Table_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Table_Position_OneArgs = {
  object: Seating_Table_Position_Insert_Input;
  on_conflict?: InputMaybe<Seating_Table_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Table_RoomArgs = {
  objects: Array<Seating_Table_Room_Insert_Input>;
  on_conflict?: InputMaybe<Seating_Table_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Seating_Table_Room_OneArgs = {
  object: Seating_Table_Room_Insert_Input;
  on_conflict?: InputMaybe<Seating_Table_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Orders_Status_EnumArgs = {
  objects: Array<Stripe_Orders_Status_Enum_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Orders_Status_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Orders_Status_Enum_OneArgs = {
  object: Stripe_Orders_Status_Enum_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Orders_Status_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_EnumArgs = {
  objects: Array<User_Roles_Enum_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_Enum_OneArgs = {
  object: User_Roles_Enum_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_Enum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_OrderArgs = {
  _set?: InputMaybe<Booking_Order_Set_Input>;
  where: Booking_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Set_Input>;
  pk_columns: Booking_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_ItemsArgs = {
  _inc?: InputMaybe<Booking_Order_Items_Inc_Input>;
  _set?: InputMaybe<Booking_Order_Items_Set_Input>;
  where: Booking_Order_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Items_By_PkArgs = {
  _inc?: InputMaybe<Booking_Order_Items_Inc_Input>;
  _set?: InputMaybe<Booking_Order_Items_Set_Input>;
  pk_columns: Booking_Order_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Items_ExtraArgs = {
  _set?: InputMaybe<Booking_Order_Items_Extra_Set_Input>;
  where: Booking_Order_Items_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Items_Extra_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Items_Extra_Set_Input>;
  pk_columns: Booking_Order_Items_Extra_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_PaymentArgs = {
  _append?: InputMaybe<Booking_Order_Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Booking_Order_Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Booking_Order_Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Booking_Order_Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Booking_Order_Payment_Inc_Input>;
  _prepend?: InputMaybe<Booking_Order_Payment_Prepend_Input>;
  _set?: InputMaybe<Booking_Order_Payment_Set_Input>;
  where: Booking_Order_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Payment_By_PkArgs = {
  _append?: InputMaybe<Booking_Order_Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Booking_Order_Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Booking_Order_Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Booking_Order_Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Booking_Order_Payment_Inc_Input>;
  _prepend?: InputMaybe<Booking_Order_Payment_Prepend_Input>;
  _set?: InputMaybe<Booking_Order_Payment_Set_Input>;
  pk_columns: Booking_Order_Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Payment_ItemsArgs = {
  _set?: InputMaybe<Booking_Order_Payment_Items_Set_Input>;
  where: Booking_Order_Payment_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Payment_Items_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Payment_Items_Set_Input>;
  pk_columns: Booking_Order_Payment_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Payment_StatusArgs = {
  _set?: InputMaybe<Booking_Order_Payment_Status_Set_Input>;
  where: Booking_Order_Payment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Payment_Status_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Payment_Status_Set_Input>;
  pk_columns: Booking_Order_Payment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_StatusArgs = {
  _set?: InputMaybe<Booking_Order_Status_Set_Input>;
  where: Booking_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Status_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Status_Set_Input>;
  pk_columns: Booking_Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_TypeArgs = {
  _set?: InputMaybe<Booking_Order_Type_Set_Input>;
  where: Booking_Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Order_Type_By_PkArgs = {
  _set?: InputMaybe<Booking_Order_Type_Set_Input>;
  pk_columns: Booking_Order_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Printer_Job_OrderArgs = {
  _set?: InputMaybe<Booking_Printer_Job_Order_Set_Input>;
  where: Booking_Printer_Job_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Printer_Job_Order_By_PkArgs = {
  _set?: InputMaybe<Booking_Printer_Job_Order_Set_Input>;
  pk_columns: Booking_Printer_Job_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Session_Email_ReceiptArgs = {
  _set?: InputMaybe<Booking_Session_Email_Receipt_Set_Input>;
  where: Booking_Session_Email_Receipt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Session_Email_Receipt_By_PkArgs = {
  _set?: InputMaybe<Booking_Session_Email_Receipt_Set_Input>;
  pk_columns: Booking_Session_Email_Receipt_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Table_SessionsArgs = {
  _set?: InputMaybe<Booking_Table_Sessions_Set_Input>;
  where: Booking_Table_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Table_Sessions_By_PkArgs = {
  _set?: InputMaybe<Booking_Table_Sessions_Set_Input>;
  pk_columns: Booking_Table_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Table_Sessions_StatusArgs = {
  _set?: InputMaybe<Booking_Table_Sessions_Status_Set_Input>;
  where: Booking_Table_Sessions_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booking_Table_Sessions_Status_By_PkArgs = {
  _set?: InputMaybe<Booking_Table_Sessions_Status_Set_Input>;
  pk_columns: Booking_Table_Sessions_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _inc?: InputMaybe<Countries_Inc_Input>;
  _set?: InputMaybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_ItemArgs = {
  _append?: InputMaybe<Menu_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Menu_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Menu_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Menu_Item_Delete_Key_Input>;
  _prepend?: InputMaybe<Menu_Item_Prepend_Input>;
  _set?: InputMaybe<Menu_Item_Set_Input>;
  where: Menu_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_By_PkArgs = {
  _append?: InputMaybe<Menu_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Menu_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Menu_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Menu_Item_Delete_Key_Input>;
  _prepend?: InputMaybe<Menu_Item_Prepend_Input>;
  _set?: InputMaybe<Menu_Item_Set_Input>;
  pk_columns: Menu_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_ExtraArgs = {
  _append?: InputMaybe<Menu_Item_Extra_Append_Input>;
  _delete_at_path?: InputMaybe<Menu_Item_Extra_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Menu_Item_Extra_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Menu_Item_Extra_Delete_Key_Input>;
  _inc?: InputMaybe<Menu_Item_Extra_Inc_Input>;
  _prepend?: InputMaybe<Menu_Item_Extra_Prepend_Input>;
  _set?: InputMaybe<Menu_Item_Extra_Set_Input>;
  where: Menu_Item_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_Extra_By_PkArgs = {
  _append?: InputMaybe<Menu_Item_Extra_Append_Input>;
  _delete_at_path?: InputMaybe<Menu_Item_Extra_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Menu_Item_Extra_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Menu_Item_Extra_Delete_Key_Input>;
  _inc?: InputMaybe<Menu_Item_Extra_Inc_Input>;
  _prepend?: InputMaybe<Menu_Item_Extra_Prepend_Input>;
  _set?: InputMaybe<Menu_Item_Extra_Set_Input>;
  pk_columns: Menu_Item_Extra_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_LabelArgs = {
  _set?: InputMaybe<Menu_Item_Label_Set_Input>;
  where: Menu_Item_Label_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_Label_By_PkArgs = {
  _set?: InputMaybe<Menu_Item_Label_Set_Input>;
  pk_columns: Menu_Item_Label_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_PriceArgs = {
  _inc?: InputMaybe<Menu_Item_Price_Inc_Input>;
  _set?: InputMaybe<Menu_Item_Price_Set_Input>;
  where: Menu_Item_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Item_Price_By_PkArgs = {
  _inc?: InputMaybe<Menu_Item_Price_Inc_Input>;
  _set?: InputMaybe<Menu_Item_Price_Set_Input>;
  pk_columns: Menu_Item_Price_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_ProducerArgs = {
  _set?: InputMaybe<Menu_Producer_Set_Input>;
  where: Menu_Producer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Producer_By_PkArgs = {
  _set?: InputMaybe<Menu_Producer_Set_Input>;
  pk_columns: Menu_Producer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_TreeArgs = {
  _set?: InputMaybe<Menu_Tree_Set_Input>;
  where: Menu_Tree_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Menu_Tree_By_PkArgs = {
  _set?: InputMaybe<Menu_Tree_Set_Input>;
  pk_columns: Menu_Tree_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrinterArgs = {
  _append?: InputMaybe<Printer_Append_Input>;
  _delete_at_path?: InputMaybe<Printer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Printer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Printer_Delete_Key_Input>;
  _prepend?: InputMaybe<Printer_Prepend_Input>;
  _set?: InputMaybe<Printer_Set_Input>;
  where: Printer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Printer_By_PkArgs = {
  _append?: InputMaybe<Printer_Append_Input>;
  _delete_at_path?: InputMaybe<Printer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Printer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Printer_Delete_Key_Input>;
  _prepend?: InputMaybe<Printer_Prepend_Input>;
  _set?: InputMaybe<Printer_Set_Input>;
  pk_columns: Printer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Qr_TemplateArgs = {
  _append?: InputMaybe<Qr_Template_Append_Input>;
  _delete_at_path?: InputMaybe<Qr_Template_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Qr_Template_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Qr_Template_Delete_Key_Input>;
  _inc?: InputMaybe<Qr_Template_Inc_Input>;
  _prepend?: InputMaybe<Qr_Template_Prepend_Input>;
  _set?: InputMaybe<Qr_Template_Set_Input>;
  where: Qr_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Qr_Template_By_PkArgs = {
  _append?: InputMaybe<Qr_Template_Append_Input>;
  _delete_at_path?: InputMaybe<Qr_Template_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Qr_Template_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Qr_Template_Delete_Key_Input>;
  _inc?: InputMaybe<Qr_Template_Inc_Input>;
  _prepend?: InputMaybe<Qr_Template_Prepend_Input>;
  _set?: InputMaybe<Qr_Template_Set_Input>;
  pk_columns: Qr_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Restaurant_StripeArgs = {
  _append?: InputMaybe<Restaurant_Stripe_Append_Input>;
  _delete_at_path?: InputMaybe<Restaurant_Stripe_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Restaurant_Stripe_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Restaurant_Stripe_Delete_Key_Input>;
  _inc?: InputMaybe<Restaurant_Stripe_Inc_Input>;
  _prepend?: InputMaybe<Restaurant_Stripe_Prepend_Input>;
  _set?: InputMaybe<Restaurant_Stripe_Set_Input>;
  where: Restaurant_Stripe_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Restaurant_Stripe_By_PkArgs = {
  _append?: InputMaybe<Restaurant_Stripe_Append_Input>;
  _delete_at_path?: InputMaybe<Restaurant_Stripe_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Restaurant_Stripe_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Restaurant_Stripe_Delete_Key_Input>;
  _inc?: InputMaybe<Restaurant_Stripe_Inc_Input>;
  _prepend?: InputMaybe<Restaurant_Stripe_Prepend_Input>;
  _set?: InputMaybe<Restaurant_Stripe_Set_Input>;
  pk_columns: Restaurant_Stripe_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Restaurant_WifiArgs = {
  _set?: InputMaybe<Restaurant_Wifi_Set_Input>;
  where: Restaurant_Wifi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Restaurant_Wifi_By_PkArgs = {
  _set?: InputMaybe<Restaurant_Wifi_Set_Input>;
  pk_columns: Restaurant_Wifi_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RestaurantsArgs = {
  _inc?: InputMaybe<Restaurants_Inc_Input>;
  _set?: InputMaybe<Restaurants_Set_Input>;
  where: Restaurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Restaurants_By_PkArgs = {
  _inc?: InputMaybe<Restaurants_Inc_Input>;
  _set?: InputMaybe<Restaurants_Set_Input>;
  pk_columns: Restaurants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_QrArgs = {
  _set?: InputMaybe<Seating_Qr_Set_Input>;
  where: Seating_Qr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Qr_By_PkArgs = {
  _set?: InputMaybe<Seating_Qr_Set_Input>;
  pk_columns: Seating_Qr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_TableArgs = {
  _inc?: InputMaybe<Seating_Table_Inc_Input>;
  _set?: InputMaybe<Seating_Table_Set_Input>;
  where: Seating_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Table_By_PkArgs = {
  _inc?: InputMaybe<Seating_Table_Inc_Input>;
  _set?: InputMaybe<Seating_Table_Set_Input>;
  pk_columns: Seating_Table_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Table_PositionArgs = {
  _inc?: InputMaybe<Seating_Table_Position_Inc_Input>;
  _set?: InputMaybe<Seating_Table_Position_Set_Input>;
  where: Seating_Table_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Table_Position_By_PkArgs = {
  _inc?: InputMaybe<Seating_Table_Position_Inc_Input>;
  _set?: InputMaybe<Seating_Table_Position_Set_Input>;
  pk_columns: Seating_Table_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Table_RoomArgs = {
  _set?: InputMaybe<Seating_Table_Room_Set_Input>;
  where: Seating_Table_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Seating_Table_Room_By_PkArgs = {
  _set?: InputMaybe<Seating_Table_Room_Set_Input>;
  pk_columns: Seating_Table_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Orders_Status_EnumArgs = {
  _set?: InputMaybe<Stripe_Orders_Status_Enum_Set_Input>;
  where: Stripe_Orders_Status_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Orders_Status_Enum_By_PkArgs = {
  _set?: InputMaybe<Stripe_Orders_Status_Enum_Set_Input>;
  pk_columns: Stripe_Orders_Status_Enum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_EnumArgs = {
  _set?: InputMaybe<User_Roles_Enum_Set_Input>;
  where: User_Roles_Enum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_Enum_By_PkArgs = {
  _set?: InputMaybe<User_Roles_Enum_Set_Input>;
  pk_columns: User_Roles_Enum_Pk_Columns_Input;
};

export type Numeric_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _cast?: InputMaybe<Numeric_Cast_Exp>;
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "printer" */
export type Printer = {
  __typename?: 'printer';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  printWidth: Scalars['String'];
  /** An object relationship */
  printer_job_order?: Maybe<Booking_Printer_Job_Order>;
  /** An array relationship */
  producers: Array<Menu_Producer>;
  /** An aggregate relationship */
  producers_aggregate: Menu_Producer_Aggregate;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  statusJson?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "printer" */
export type PrinterProducersArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


/** columns and relationships of "printer" */
export type PrinterProducers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


/** columns and relationships of "printer" */
export type PrinterStatusJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "printer" */
export type Printer_Aggregate = {
  __typename?: 'printer_aggregate';
  aggregate?: Maybe<Printer_Aggregate_Fields>;
  nodes: Array<Printer>;
};

/** aggregate fields of "printer" */
export type Printer_Aggregate_Fields = {
  __typename?: 'printer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Printer_Max_Fields>;
  min?: Maybe<Printer_Min_Fields>;
};


/** aggregate fields of "printer" */
export type Printer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Printer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "printer" */
export type Printer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Printer_Max_Order_By>;
  min?: InputMaybe<Printer_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Printer_Append_Input = {
  statusJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "printer" */
export type Printer_Arr_Rel_Insert_Input = {
  data: Array<Printer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Printer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "printer". All fields are combined with a logical 'AND'. */
export type Printer_Bool_Exp = {
  _and?: InputMaybe<Array<Printer_Bool_Exp>>;
  _not?: InputMaybe<Printer_Bool_Exp>;
  _or?: InputMaybe<Array<Printer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  printWidth?: InputMaybe<String_Comparison_Exp>;
  printer_job_order?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
  producers?: InputMaybe<Menu_Producer_Bool_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusJson?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "printer" */
export enum Printer_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrinterPkey = 'printer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Printer_Delete_At_Path_Input = {
  statusJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Printer_Delete_Elem_Input = {
  statusJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Printer_Delete_Key_Input = {
  statusJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "printer" */
export type Printer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  printWidth?: InputMaybe<Scalars['String']>;
  printer_job_order?: InputMaybe<Booking_Printer_Job_Order_Obj_Rel_Insert_Input>;
  producers?: InputMaybe<Menu_Producer_Arr_Rel_Insert_Input>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusJson?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Printer_Max_Fields = {
  __typename?: 'printer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  printWidth?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "printer" */
export type Printer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printWidth?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Printer_Min_Fields = {
  __typename?: 'printer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  printWidth?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "printer" */
export type Printer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printWidth?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "printer" */
export type Printer_Mutation_Response = {
  __typename?: 'printer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Printer>;
};

/** input type for inserting object relation for remote table "printer" */
export type Printer_Obj_Rel_Insert_Input = {
  data: Printer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Printer_On_Conflict>;
};

/** on_conflict condition type for table "printer" */
export type Printer_On_Conflict = {
  constraint: Printer_Constraint;
  update_columns?: Array<Printer_Update_Column>;
  where?: InputMaybe<Printer_Bool_Exp>;
};

/** Ordering options when selecting data from "printer". */
export type Printer_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  printWidth?: InputMaybe<Order_By>;
  printer_job_order?: InputMaybe<Booking_Printer_Job_Order_Order_By>;
  producers_aggregate?: InputMaybe<Menu_Producer_Aggregate_Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusJson?: InputMaybe<Order_By>;
};

/** primary key columns input for table: printer */
export type Printer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Printer_Prepend_Input = {
  statusJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "printer" */
export enum Printer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrintWidth = 'printWidth',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusJson = 'statusJson'
}

/** input type for updating data in table "printer" */
export type Printer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  printWidth?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusJson?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "printer" */
export enum Printer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrintWidth = 'printWidth',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusJson = 'statusJson'
}

/** columns and relationships of "qr_template" */
export type Qr_Template = {
  __typename?: 'qr_template';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  img: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  template: Scalars['jsonb'];
};


/** columns and relationships of "qr_template" */
export type Qr_TemplateTemplateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "qr_template" */
export type Qr_Template_Aggregate = {
  __typename?: 'qr_template_aggregate';
  aggregate?: Maybe<Qr_Template_Aggregate_Fields>;
  nodes: Array<Qr_Template>;
};

/** aggregate fields of "qr_template" */
export type Qr_Template_Aggregate_Fields = {
  __typename?: 'qr_template_aggregate_fields';
  avg?: Maybe<Qr_Template_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Qr_Template_Max_Fields>;
  min?: Maybe<Qr_Template_Min_Fields>;
  stddev?: Maybe<Qr_Template_Stddev_Fields>;
  stddev_pop?: Maybe<Qr_Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Qr_Template_Stddev_Samp_Fields>;
  sum?: Maybe<Qr_Template_Sum_Fields>;
  var_pop?: Maybe<Qr_Template_Var_Pop_Fields>;
  var_samp?: Maybe<Qr_Template_Var_Samp_Fields>;
  variance?: Maybe<Qr_Template_Variance_Fields>;
};


/** aggregate fields of "qr_template" */
export type Qr_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Qr_Template_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Qr_Template_Append_Input = {
  template?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Qr_Template_Avg_Fields = {
  __typename?: 'qr_template_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "qr_template". All fields are combined with a logical 'AND'. */
export type Qr_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Qr_Template_Bool_Exp>>;
  _not?: InputMaybe<Qr_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Qr_Template_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  img?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  template?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "qr_template" */
export enum Qr_Template_Constraint {
  /** unique or primary key constraint on columns "id" */
  QtTemplatePkey = 'qt_template_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Qr_Template_Delete_At_Path_Input = {
  template?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Qr_Template_Delete_Elem_Input = {
  template?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Qr_Template_Delete_Key_Input = {
  template?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "qr_template" */
export type Qr_Template_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "qr_template" */
export type Qr_Template_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  template?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Qr_Template_Max_Fields = {
  __typename?: 'qr_template_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Qr_Template_Min_Fields = {
  __typename?: 'qr_template_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  img?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "qr_template" */
export type Qr_Template_Mutation_Response = {
  __typename?: 'qr_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Qr_Template>;
};

/** on_conflict condition type for table "qr_template" */
export type Qr_Template_On_Conflict = {
  constraint: Qr_Template_Constraint;
  update_columns?: Array<Qr_Template_Update_Column>;
  where?: InputMaybe<Qr_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "qr_template". */
export type Qr_Template_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  img?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
};

/** primary key columns input for table: qr_template */
export type Qr_Template_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Qr_Template_Prepend_Input = {
  template?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "qr_template" */
export enum Qr_Template_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Template = 'template'
}

/** input type for updating data in table "qr_template" */
export type Qr_Template_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  img?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  template?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Qr_Template_Stddev_Fields = {
  __typename?: 'qr_template_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Qr_Template_Stddev_Pop_Fields = {
  __typename?: 'qr_template_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Qr_Template_Stddev_Samp_Fields = {
  __typename?: 'qr_template_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Qr_Template_Sum_Fields = {
  __typename?: 'qr_template_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "qr_template" */
export enum Qr_Template_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Img = 'img',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Template = 'template'
}

/** aggregate var_pop on columns */
export type Qr_Template_Var_Pop_Fields = {
  __typename?: 'qr_template_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Qr_Template_Var_Samp_Fields = {
  __typename?: 'qr_template_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Qr_Template_Variance_Fields = {
  __typename?: 'qr_template_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "booking.order" */
  booking_order: Array<Booking_Order>;
  /** fetch aggregated fields from the table: "booking.order" */
  booking_order_aggregate: Booking_Order_Aggregate;
  /** fetch data from the table: "booking.order" using primary key columns */
  booking_order_by_pk?: Maybe<Booking_Order>;
  /** fetch data from the table: "booking.order_items" */
  booking_order_items: Array<Booking_Order_Items>;
  /** fetch aggregated fields from the table: "booking.order_items" */
  booking_order_items_aggregate: Booking_Order_Items_Aggregate;
  /** fetch data from the table: "booking.order_items" using primary key columns */
  booking_order_items_by_pk?: Maybe<Booking_Order_Items>;
  /** fetch data from the table: "booking.order_items_extra" */
  booking_order_items_extra: Array<Booking_Order_Items_Extra>;
  /** fetch aggregated fields from the table: "booking.order_items_extra" */
  booking_order_items_extra_aggregate: Booking_Order_Items_Extra_Aggregate;
  /** fetch data from the table: "booking.order_items_extra" using primary key columns */
  booking_order_items_extra_by_pk?: Maybe<Booking_Order_Items_Extra>;
  /** fetch data from the table: "booking.order_payment" */
  booking_order_payment: Array<Booking_Order_Payment>;
  /** fetch aggregated fields from the table: "booking.order_payment" */
  booking_order_payment_aggregate: Booking_Order_Payment_Aggregate;
  /** fetch data from the table: "booking.order_payment" using primary key columns */
  booking_order_payment_by_pk?: Maybe<Booking_Order_Payment>;
  /** fetch data from the table: "booking.order_payment_items" */
  booking_order_payment_items: Array<Booking_Order_Payment_Items>;
  /** fetch aggregated fields from the table: "booking.order_payment_items" */
  booking_order_payment_items_aggregate: Booking_Order_Payment_Items_Aggregate;
  /** fetch data from the table: "booking.order_payment_items" using primary key columns */
  booking_order_payment_items_by_pk?: Maybe<Booking_Order_Payment_Items>;
  /** fetch data from the table: "booking.order_payment_status" */
  booking_order_payment_status: Array<Booking_Order_Payment_Status>;
  /** fetch aggregated fields from the table: "booking.order_payment_status" */
  booking_order_payment_status_aggregate: Booking_Order_Payment_Status_Aggregate;
  /** fetch data from the table: "booking.order_payment_status" using primary key columns */
  booking_order_payment_status_by_pk?: Maybe<Booking_Order_Payment_Status>;
  /** fetch data from the table: "booking.order_status" */
  booking_order_status: Array<Booking_Order_Status>;
  /** fetch aggregated fields from the table: "booking.order_status" */
  booking_order_status_aggregate: Booking_Order_Status_Aggregate;
  /** fetch data from the table: "booking.order_status" using primary key columns */
  booking_order_status_by_pk?: Maybe<Booking_Order_Status>;
  /** fetch data from the table: "booking.order_type" */
  booking_order_type: Array<Booking_Order_Type>;
  /** fetch aggregated fields from the table: "booking.order_type" */
  booking_order_type_aggregate: Booking_Order_Type_Aggregate;
  /** fetch data from the table: "booking.order_type" using primary key columns */
  booking_order_type_by_pk?: Maybe<Booking_Order_Type>;
  /** fetch data from the table: "booking.printer_job_order" */
  booking_printer_job_order: Array<Booking_Printer_Job_Order>;
  /** fetch aggregated fields from the table: "booking.printer_job_order" */
  booking_printer_job_order_aggregate: Booking_Printer_Job_Order_Aggregate;
  /** fetch data from the table: "booking.printer_job_order" using primary key columns */
  booking_printer_job_order_by_pk?: Maybe<Booking_Printer_Job_Order>;
  /** fetch data from the table: "booking.session_email_receipt" */
  booking_session_email_receipt: Array<Booking_Session_Email_Receipt>;
  /** fetch aggregated fields from the table: "booking.session_email_receipt" */
  booking_session_email_receipt_aggregate: Booking_Session_Email_Receipt_Aggregate;
  /** fetch data from the table: "booking.session_email_receipt" using primary key columns */
  booking_session_email_receipt_by_pk?: Maybe<Booking_Session_Email_Receipt>;
  /** fetch data from the table: "booking.table_sessions" */
  booking_table_sessions: Array<Booking_Table_Sessions>;
  /** fetch aggregated fields from the table: "booking.table_sessions" */
  booking_table_sessions_aggregate: Booking_Table_Sessions_Aggregate;
  /** fetch data from the table: "booking.table_sessions" using primary key columns */
  booking_table_sessions_by_pk?: Maybe<Booking_Table_Sessions>;
  /** fetch data from the table: "booking.table_sessions_status" */
  booking_table_sessions_status: Array<Booking_Table_Sessions_Status>;
  /** fetch aggregated fields from the table: "booking.table_sessions_status" */
  booking_table_sessions_status_aggregate: Booking_Table_Sessions_Status_Aggregate;
  /** fetch data from the table: "booking.table_sessions_status" using primary key columns */
  booking_table_sessions_status_by_pk?: Maybe<Booking_Table_Sessions_Status>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  getQrLinkRealId?: Maybe<GetQrLinkRealIdOutput>;
  getSignedUrl?: Maybe<SignedUrlOutput>;
  /** fetch data from the table: "menu.item" */
  menu_item: Array<Menu_Item>;
  /** fetch aggregated fields from the table: "menu.item" */
  menu_item_aggregate: Menu_Item_Aggregate;
  /** fetch data from the table: "menu.item" using primary key columns */
  menu_item_by_pk?: Maybe<Menu_Item>;
  /** fetch data from the table: "menu.item_extra" */
  menu_item_extra: Array<Menu_Item_Extra>;
  /** fetch aggregated fields from the table: "menu.item_extra" */
  menu_item_extra_aggregate: Menu_Item_Extra_Aggregate;
  /** fetch data from the table: "menu.item_extra" using primary key columns */
  menu_item_extra_by_pk?: Maybe<Menu_Item_Extra>;
  /** fetch data from the table: "menu.item_label" */
  menu_item_label: Array<Menu_Item_Label>;
  /** fetch aggregated fields from the table: "menu.item_label" */
  menu_item_label_aggregate: Menu_Item_Label_Aggregate;
  /** fetch data from the table: "menu.item_label" using primary key columns */
  menu_item_label_by_pk?: Maybe<Menu_Item_Label>;
  /** fetch data from the table: "menu.item_price" */
  menu_item_price: Array<Menu_Item_Price>;
  /** fetch aggregated fields from the table: "menu.item_price" */
  menu_item_price_aggregate: Menu_Item_Price_Aggregate;
  /** fetch data from the table: "menu.item_price" using primary key columns */
  menu_item_price_by_pk?: Maybe<Menu_Item_Price>;
  /** fetch data from the table: "menu.producer" */
  menu_producer: Array<Menu_Producer>;
  /** fetch aggregated fields from the table: "menu.producer" */
  menu_producer_aggregate: Menu_Producer_Aggregate;
  /** fetch data from the table: "menu.producer" using primary key columns */
  menu_producer_by_pk?: Maybe<Menu_Producer>;
  /** fetch data from the table: "menu.tree" */
  menu_tree: Array<Menu_Tree>;
  /** fetch aggregated fields from the table: "menu.tree" */
  menu_tree_aggregate: Menu_Tree_Aggregate;
  /** fetch data from the table: "menu.tree" using primary key columns */
  menu_tree_by_pk?: Maybe<Menu_Tree>;
  /** fetch data from the table: "printer" */
  printer: Array<Printer>;
  /** fetch aggregated fields from the table: "printer" */
  printer_aggregate: Printer_Aggregate;
  /** fetch data from the table: "printer" using primary key columns */
  printer_by_pk?: Maybe<Printer>;
  /** fetch data from the table: "qr_template" */
  qr_template: Array<Qr_Template>;
  /** fetch aggregated fields from the table: "qr_template" */
  qr_template_aggregate: Qr_Template_Aggregate;
  /** fetch data from the table: "qr_template" using primary key columns */
  qr_template_by_pk?: Maybe<Qr_Template>;
  /** fetch data from the table: "restaurant_stripe" */
  restaurant_stripe: Array<Restaurant_Stripe>;
  /** fetch aggregated fields from the table: "restaurant_stripe" */
  restaurant_stripe_aggregate: Restaurant_Stripe_Aggregate;
  /** fetch data from the table: "restaurant_stripe" using primary key columns */
  restaurant_stripe_by_pk?: Maybe<Restaurant_Stripe>;
  /** fetch data from the table: "restaurant_wifi" */
  restaurant_wifi: Array<Restaurant_Wifi>;
  /** fetch aggregated fields from the table: "restaurant_wifi" */
  restaurant_wifi_aggregate: Restaurant_Wifi_Aggregate;
  /** fetch data from the table: "restaurant_wifi" using primary key columns */
  restaurant_wifi_by_pk?: Maybe<Restaurant_Wifi>;
  /** An array relationship */
  restaurants: Array<Restaurants>;
  /** An aggregate relationship */
  restaurants_aggregate: Restaurants_Aggregate;
  /** fetch data from the table: "restaurants" using primary key columns */
  restaurants_by_pk?: Maybe<Restaurants>;
  /** fetch data from the table: "seating.qr" */
  seating_qr: Array<Seating_Qr>;
  /** fetch aggregated fields from the table: "seating.qr" */
  seating_qr_aggregate: Seating_Qr_Aggregate;
  /** fetch data from the table: "seating.qr" using primary key columns */
  seating_qr_by_pk?: Maybe<Seating_Qr>;
  /** fetch data from the table: "seating.table" */
  seating_table: Array<Seating_Table>;
  /** fetch aggregated fields from the table: "seating.table" */
  seating_table_aggregate: Seating_Table_Aggregate;
  /** fetch data from the table: "seating.table" using primary key columns */
  seating_table_by_pk?: Maybe<Seating_Table>;
  /** fetch data from the table: "seating.table_position" */
  seating_table_position: Array<Seating_Table_Position>;
  /** fetch aggregated fields from the table: "seating.table_position" */
  seating_table_position_aggregate: Seating_Table_Position_Aggregate;
  /** fetch data from the table: "seating.table_position" using primary key columns */
  seating_table_position_by_pk?: Maybe<Seating_Table_Position>;
  /** fetch data from the table: "seating.table_room" */
  seating_table_room: Array<Seating_Table_Room>;
  /** fetch aggregated fields from the table: "seating.table_room" */
  seating_table_room_aggregate: Seating_Table_Room_Aggregate;
  /** fetch data from the table: "seating.table_room" using primary key columns */
  seating_table_room_by_pk?: Maybe<Seating_Table_Room>;
  /** fetch data from the table: "stripe_orders_status_enum" */
  stripe_orders_status_enum: Array<Stripe_Orders_Status_Enum>;
  /** fetch aggregated fields from the table: "stripe_orders_status_enum" */
  stripe_orders_status_enum_aggregate: Stripe_Orders_Status_Enum_Aggregate;
  /** fetch data from the table: "stripe_orders_status_enum" using primary key columns */
  stripe_orders_status_enum_by_pk?: Maybe<Stripe_Orders_Status_Enum>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_roles_enum" */
  user_roles_enum: Array<User_Roles_Enum>;
  /** fetch aggregated fields from the table: "user_roles_enum" */
  user_roles_enum_aggregate: User_Roles_Enum_Aggregate;
  /** fetch data from the table: "user_roles_enum" using primary key columns */
  user_roles_enum_by_pk?: Maybe<User_Roles_Enum>;
};


export type Query_RootBooking_OrderArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


export type Query_RootBooking_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


export type Query_RootBooking_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


export type Query_RootBooking_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


export type Query_RootBooking_Order_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Order_Items_ExtraArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


export type Query_RootBooking_Order_Items_Extra_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


export type Query_RootBooking_Order_Items_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Order_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Order_Payment_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_Items_By_PkArgs = {
  order_item_id: Scalars['uuid'];
  order_payment_id: Scalars['uuid'];
};


export type Query_RootBooking_Order_Payment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
};


export type Query_RootBooking_Order_Payment_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Query_RootBooking_Order_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Status_Bool_Exp>;
};


export type Query_RootBooking_Order_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Status_Bool_Exp>;
};


export type Query_RootBooking_Order_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Query_RootBooking_Order_TypeArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Type_Order_By>>;
  where?: InputMaybe<Booking_Order_Type_Bool_Exp>;
};


export type Query_RootBooking_Order_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Type_Order_By>>;
  where?: InputMaybe<Booking_Order_Type_Bool_Exp>;
};


export type Query_RootBooking_Order_Type_By_PkArgs = {
  values: Scalars['String'];
};


export type Query_RootBooking_Printer_Job_OrderArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


export type Query_RootBooking_Printer_Job_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


export type Query_RootBooking_Printer_Job_Order_By_PkArgs = {
  printer_id: Scalars['uuid'];
};


export type Query_RootBooking_Session_Email_ReceiptArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


export type Query_RootBooking_Session_Email_Receipt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


export type Query_RootBooking_Session_Email_Receipt_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Table_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


export type Query_RootBooking_Table_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


export type Query_RootBooking_Table_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooking_Table_Sessions_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Status_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
};


export type Query_RootBooking_Table_Sessions_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Status_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
};


export type Query_RootBooking_Table_Sessions_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootGetQrLinkRealIdArgs = {
  link: Scalars['String'];
};


export type Query_RootGetSignedUrlArgs = {
  fileType: Scalars['String'];
};


export type Query_RootMenu_ItemArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


export type Query_RootMenu_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


export type Query_RootMenu_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMenu_Item_ExtraArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


export type Query_RootMenu_Item_Extra_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


export type Query_RootMenu_Item_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMenu_Item_LabelArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Label_Order_By>>;
  where?: InputMaybe<Menu_Item_Label_Bool_Exp>;
};


export type Query_RootMenu_Item_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Label_Order_By>>;
  where?: InputMaybe<Menu_Item_Label_Bool_Exp>;
};


export type Query_RootMenu_Item_Label_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootMenu_Item_PriceArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


export type Query_RootMenu_Item_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


export type Query_RootMenu_Item_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMenu_ProducerArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


export type Query_RootMenu_Producer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


export type Query_RootMenu_Producer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMenu_TreeArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


export type Query_RootMenu_Tree_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


export type Query_RootMenu_Tree_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPrinterArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


export type Query_RootPrinter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


export type Query_RootPrinter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQr_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Qr_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qr_Template_Order_By>>;
  where?: InputMaybe<Qr_Template_Bool_Exp>;
};


export type Query_RootQr_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Qr_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qr_Template_Order_By>>;
  where?: InputMaybe<Qr_Template_Bool_Exp>;
};


export type Query_RootQr_Template_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRestaurant_StripeArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Stripe_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Stripe_Order_By>>;
  where?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
};


export type Query_RootRestaurant_Stripe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Stripe_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Stripe_Order_By>>;
  where?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
};


export type Query_RootRestaurant_Stripe_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


export type Query_RootRestaurant_WifiArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Wifi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Wifi_Order_By>>;
  where?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
};


export type Query_RootRestaurant_Wifi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Wifi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Wifi_Order_By>>;
  where?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
};


export type Query_RootRestaurant_Wifi_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


export type Query_RootRestaurantsArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};


export type Query_RootRestaurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};


export type Query_RootRestaurants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSeating_QrArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


export type Query_RootSeating_Qr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


export type Query_RootSeating_Qr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSeating_TableArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


export type Query_RootSeating_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


export type Query_RootSeating_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSeating_Table_PositionArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


export type Query_RootSeating_Table_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


export type Query_RootSeating_Table_Position_By_PkArgs = {
  table_id: Scalars['uuid'];
};


export type Query_RootSeating_Table_RoomArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


export type Query_RootSeating_Table_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


export type Query_RootSeating_Table_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStripe_Orders_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Orders_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Orders_Status_Enum_Order_By>>;
  where?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
};


export type Query_RootStripe_Orders_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Orders_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Orders_Status_Enum_Order_By>>;
  where?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
};


export type Query_RootStripe_Orders_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Roles_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Enum_Order_By>>;
  where?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};


export type Query_RootUser_Roles_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Enum_Order_By>>;
  where?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};


export type Query_RootUser_Roles_Enum_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "restaurant_stripe" */
export type Restaurant_Stripe = {
  __typename?: 'restaurant_stripe';
  account_id: Scalars['String'];
  allowedPaymentMethods: Scalars['jsonb'];
  created_at?: Maybe<Scalars['timestamptz']>;
  namespace: Scalars['String'];
  qraveFee: Scalars['Int'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  stripeAccountData?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "restaurant_stripe" */
export type Restaurant_StripeAllowedPaymentMethodsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "restaurant_stripe" */
export type Restaurant_StripeStripeAccountDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "restaurant_stripe" */
export type Restaurant_Stripe_Aggregate = {
  __typename?: 'restaurant_stripe_aggregate';
  aggregate?: Maybe<Restaurant_Stripe_Aggregate_Fields>;
  nodes: Array<Restaurant_Stripe>;
};

/** aggregate fields of "restaurant_stripe" */
export type Restaurant_Stripe_Aggregate_Fields = {
  __typename?: 'restaurant_stripe_aggregate_fields';
  avg?: Maybe<Restaurant_Stripe_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Restaurant_Stripe_Max_Fields>;
  min?: Maybe<Restaurant_Stripe_Min_Fields>;
  stddev?: Maybe<Restaurant_Stripe_Stddev_Fields>;
  stddev_pop?: Maybe<Restaurant_Stripe_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Restaurant_Stripe_Stddev_Samp_Fields>;
  sum?: Maybe<Restaurant_Stripe_Sum_Fields>;
  var_pop?: Maybe<Restaurant_Stripe_Var_Pop_Fields>;
  var_samp?: Maybe<Restaurant_Stripe_Var_Samp_Fields>;
  variance?: Maybe<Restaurant_Stripe_Variance_Fields>;
};


/** aggregate fields of "restaurant_stripe" */
export type Restaurant_Stripe_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Restaurant_Stripe_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Restaurant_Stripe_Append_Input = {
  allowedPaymentMethods?: InputMaybe<Scalars['jsonb']>;
  stripeAccountData?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Restaurant_Stripe_Avg_Fields = {
  __typename?: 'restaurant_stripe_avg_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "restaurant_stripe". All fields are combined with a logical 'AND'. */
export type Restaurant_Stripe_Bool_Exp = {
  _and?: InputMaybe<Array<Restaurant_Stripe_Bool_Exp>>;
  _not?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
  _or?: InputMaybe<Array<Restaurant_Stripe_Bool_Exp>>;
  account_id?: InputMaybe<String_Comparison_Exp>;
  allowedPaymentMethods?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  namespace?: InputMaybe<String_Comparison_Exp>;
  qraveFee?: InputMaybe<Int_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  stripeAccountData?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "restaurant_stripe" */
export enum Restaurant_Stripe_Constraint {
  /** unique or primary key constraint on columns "restaurant_id" */
  RestaurantStripePkey = 'restaurant_stripe_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Restaurant_Stripe_Delete_At_Path_Input = {
  allowedPaymentMethods?: InputMaybe<Array<Scalars['String']>>;
  stripeAccountData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Restaurant_Stripe_Delete_Elem_Input = {
  allowedPaymentMethods?: InputMaybe<Scalars['Int']>;
  stripeAccountData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Restaurant_Stripe_Delete_Key_Input = {
  allowedPaymentMethods?: InputMaybe<Scalars['String']>;
  stripeAccountData?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "restaurant_stripe" */
export type Restaurant_Stripe_Inc_Input = {
  qraveFee?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "restaurant_stripe" */
export type Restaurant_Stripe_Insert_Input = {
  account_id?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  namespace?: InputMaybe<Scalars['String']>;
  qraveFee?: InputMaybe<Scalars['Int']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  stripeAccountData?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Restaurant_Stripe_Max_Fields = {
  __typename?: 'restaurant_stripe_max_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qraveFee?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Restaurant_Stripe_Min_Fields = {
  __typename?: 'restaurant_stripe_min_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  namespace?: Maybe<Scalars['String']>;
  qraveFee?: Maybe<Scalars['Int']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "restaurant_stripe" */
export type Restaurant_Stripe_Mutation_Response = {
  __typename?: 'restaurant_stripe_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Restaurant_Stripe>;
};

/** input type for inserting object relation for remote table "restaurant_stripe" */
export type Restaurant_Stripe_Obj_Rel_Insert_Input = {
  data: Restaurant_Stripe_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Restaurant_Stripe_On_Conflict>;
};

/** on_conflict condition type for table "restaurant_stripe" */
export type Restaurant_Stripe_On_Conflict = {
  constraint: Restaurant_Stripe_Constraint;
  update_columns?: Array<Restaurant_Stripe_Update_Column>;
  where?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
};

/** Ordering options when selecting data from "restaurant_stripe". */
export type Restaurant_Stripe_Order_By = {
  account_id?: InputMaybe<Order_By>;
  allowedPaymentMethods?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  qraveFee?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  stripeAccountData?: InputMaybe<Order_By>;
};

/** primary key columns input for table: restaurant_stripe */
export type Restaurant_Stripe_Pk_Columns_Input = {
  restaurant_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Restaurant_Stripe_Prepend_Input = {
  allowedPaymentMethods?: InputMaybe<Scalars['jsonb']>;
  stripeAccountData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "restaurant_stripe" */
export enum Restaurant_Stripe_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowedPaymentMethods = 'allowedPaymentMethods',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  QraveFee = 'qraveFee',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  StripeAccountData = 'stripeAccountData'
}

/** input type for updating data in table "restaurant_stripe" */
export type Restaurant_Stripe_Set_Input = {
  account_id?: InputMaybe<Scalars['String']>;
  allowedPaymentMethods?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  namespace?: InputMaybe<Scalars['String']>;
  qraveFee?: InputMaybe<Scalars['Int']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  stripeAccountData?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Restaurant_Stripe_Stddev_Fields = {
  __typename?: 'restaurant_stripe_stddev_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Restaurant_Stripe_Stddev_Pop_Fields = {
  __typename?: 'restaurant_stripe_stddev_pop_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Restaurant_Stripe_Stddev_Samp_Fields = {
  __typename?: 'restaurant_stripe_stddev_samp_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Restaurant_Stripe_Sum_Fields = {
  __typename?: 'restaurant_stripe_sum_fields';
  qraveFee?: Maybe<Scalars['Int']>;
};

/** update columns of table "restaurant_stripe" */
export enum Restaurant_Stripe_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowedPaymentMethods = 'allowedPaymentMethods',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  QraveFee = 'qraveFee',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  StripeAccountData = 'stripeAccountData'
}

/** aggregate var_pop on columns */
export type Restaurant_Stripe_Var_Pop_Fields = {
  __typename?: 'restaurant_stripe_var_pop_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Restaurant_Stripe_Var_Samp_Fields = {
  __typename?: 'restaurant_stripe_var_samp_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Restaurant_Stripe_Variance_Fields = {
  __typename?: 'restaurant_stripe_variance_fields';
  qraveFee?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "restaurant_wifi" */
export type Restaurant_Wifi = {
  __typename?: 'restaurant_wifi';
  encryption: Scalars['String'];
  password: Scalars['String'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  ssid: Scalars['String'];
};

/** aggregated selection of "restaurant_wifi" */
export type Restaurant_Wifi_Aggregate = {
  __typename?: 'restaurant_wifi_aggregate';
  aggregate?: Maybe<Restaurant_Wifi_Aggregate_Fields>;
  nodes: Array<Restaurant_Wifi>;
};

/** aggregate fields of "restaurant_wifi" */
export type Restaurant_Wifi_Aggregate_Fields = {
  __typename?: 'restaurant_wifi_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Restaurant_Wifi_Max_Fields>;
  min?: Maybe<Restaurant_Wifi_Min_Fields>;
};


/** aggregate fields of "restaurant_wifi" */
export type Restaurant_Wifi_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Restaurant_Wifi_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "restaurant_wifi". All fields are combined with a logical 'AND'. */
export type Restaurant_Wifi_Bool_Exp = {
  _and?: InputMaybe<Array<Restaurant_Wifi_Bool_Exp>>;
  _not?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
  _or?: InputMaybe<Array<Restaurant_Wifi_Bool_Exp>>;
  encryption?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  ssid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "restaurant_wifi" */
export enum Restaurant_Wifi_Constraint {
  /** unique or primary key constraint on columns "restaurant_id" */
  RestaurantWifiPkey = 'restaurant_wifi_pkey'
}

/** input type for inserting data into table "restaurant_wifi" */
export type Restaurant_Wifi_Insert_Input = {
  encryption?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  ssid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Restaurant_Wifi_Max_Fields = {
  __typename?: 'restaurant_wifi_max_fields';
  encryption?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  ssid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Restaurant_Wifi_Min_Fields = {
  __typename?: 'restaurant_wifi_min_fields';
  encryption?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  ssid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "restaurant_wifi" */
export type Restaurant_Wifi_Mutation_Response = {
  __typename?: 'restaurant_wifi_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Restaurant_Wifi>;
};

/** input type for inserting object relation for remote table "restaurant_wifi" */
export type Restaurant_Wifi_Obj_Rel_Insert_Input = {
  data: Restaurant_Wifi_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Restaurant_Wifi_On_Conflict>;
};

/** on_conflict condition type for table "restaurant_wifi" */
export type Restaurant_Wifi_On_Conflict = {
  constraint: Restaurant_Wifi_Constraint;
  update_columns?: Array<Restaurant_Wifi_Update_Column>;
  where?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
};

/** Ordering options when selecting data from "restaurant_wifi". */
export type Restaurant_Wifi_Order_By = {
  encryption?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  ssid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: restaurant_wifi */
export type Restaurant_Wifi_Pk_Columns_Input = {
  restaurant_id: Scalars['uuid'];
};

/** select columns of table "restaurant_wifi" */
export enum Restaurant_Wifi_Select_Column {
  /** column name */
  Encryption = 'encryption',
  /** column name */
  Password = 'password',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Ssid = 'ssid'
}

/** input type for updating data in table "restaurant_wifi" */
export type Restaurant_Wifi_Set_Input = {
  encryption?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  ssid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "restaurant_wifi" */
export enum Restaurant_Wifi_Update_Column {
  /** column name */
  Encryption = 'encryption',
  /** column name */
  Password = 'password',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Ssid = 'ssid'
}

/** columns and relationships of "restaurants" */
export type Restaurants = {
  __typename?: 'restaurants';
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  country: Countries;
  country_code: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  item_extras: Array<Menu_Item_Extra>;
  /** An aggregate relationship */
  item_extras_aggregate: Menu_Item_Extra_Aggregate;
  logoUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  menu_item_prices: Array<Menu_Item_Price>;
  /** An aggregate relationship */
  menu_item_prices_aggregate: Menu_Item_Price_Aggregate;
  /** An array relationship */
  menu_items: Array<Menu_Item>;
  /** An aggregate relationship */
  menu_items_aggregate: Menu_Item_Aggregate;
  /** An array relationship */
  menu_producers: Array<Menu_Producer>;
  /** An aggregate relationship */
  menu_producers_aggregate: Menu_Producer_Aggregate;
  /** An array relationship */
  menu_trees: Array<Menu_Tree>;
  /** An aggregate relationship */
  menu_trees_aggregate: Menu_Tree_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  order_items_extras: Array<Booking_Order_Items_Extra>;
  /** An aggregate relationship */
  order_items_extras_aggregate: Booking_Order_Items_Extra_Aggregate;
  /** An array relationship */
  order_payment_items: Array<Booking_Order_Payment_Items>;
  /** An aggregate relationship */
  order_payment_items_aggregate: Booking_Order_Payment_Items_Aggregate;
  /** An array relationship */
  order_payment_receipts: Array<Booking_Session_Email_Receipt>;
  /** An aggregate relationship */
  order_payment_receipts_aggregate: Booking_Session_Email_Receipt_Aggregate;
  /** An array relationship */
  order_payments: Array<Booking_Order_Payment>;
  /** An aggregate relationship */
  order_payments_aggregate: Booking_Order_Payment_Aggregate;
  /** An array relationship */
  orders: Array<Booking_Order>;
  /** An aggregate relationship */
  orders_aggregate: Booking_Order_Aggregate;
  /** An array relationship */
  printer_job_orders: Array<Booking_Printer_Job_Order>;
  /** An aggregate relationship */
  printer_job_orders_aggregate: Booking_Printer_Job_Order_Aggregate;
  /** An array relationship */
  printers: Array<Printer>;
  /** An aggregate relationship */
  printers_aggregate: Printer_Aggregate;
  /** An array relationship */
  qrs: Array<Seating_Qr>;
  /** An aggregate relationship */
  qrs_aggregate: Seating_Qr_Aggregate;
  receiptFooter?: Maybe<Scalars['String']>;
  /** An object relationship */
  restaurant_stripe?: Maybe<Restaurant_Stripe>;
  /** An object relationship */
  restaurant_wifi?: Maybe<Restaurant_Wifi>;
  /** An array relationship */
  stripe_orders_items: Array<Booking_Order_Items>;
  /** An aggregate relationship */
  stripe_orders_items_aggregate: Booking_Order_Items_Aggregate;
  /** An array relationship */
  table_positions: Array<Seating_Table_Position>;
  /** An aggregate relationship */
  table_positions_aggregate: Seating_Table_Position_Aggregate;
  /** An array relationship */
  table_rooms: Array<Seating_Table_Room>;
  /** An aggregate relationship */
  table_rooms_aggregate: Seating_Table_Room_Aggregate;
  /** An array relationship */
  table_sessions: Array<Booking_Table_Sessions>;
  /** An aggregate relationship */
  table_sessions_aggregate: Booking_Table_Sessions_Aggregate;
  /** An array relationship */
  tables: Array<Seating_Table>;
  /** An aggregate relationship */
  tables_aggregate: Seating_Table_Aggregate;
  timezone: Scalars['String'];
  tutorial: Scalars['Boolean'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
  vat: Scalars['Int'];
};


/** columns and relationships of "restaurants" */
export type RestaurantsItem_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsItem_Extras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_Item_PricesArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_Item_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_ProducersArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_Producers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_TreesArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsMenu_Trees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Items_ExtrasArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Items_Extras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Payment_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Payment_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Payment_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Payment_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrder_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsPrinter_Job_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsPrinter_Job_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsPrintersArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsPrinters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsQrsArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsQrs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsStripe_Orders_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsStripe_Orders_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTable_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTablesArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsTables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "restaurants" */
export type RestaurantsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "restaurants" */
export type Restaurants_Aggregate = {
  __typename?: 'restaurants_aggregate';
  aggregate?: Maybe<Restaurants_Aggregate_Fields>;
  nodes: Array<Restaurants>;
};

/** aggregate fields of "restaurants" */
export type Restaurants_Aggregate_Fields = {
  __typename?: 'restaurants_aggregate_fields';
  avg?: Maybe<Restaurants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Restaurants_Max_Fields>;
  min?: Maybe<Restaurants_Min_Fields>;
  stddev?: Maybe<Restaurants_Stddev_Fields>;
  stddev_pop?: Maybe<Restaurants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Restaurants_Stddev_Samp_Fields>;
  sum?: Maybe<Restaurants_Sum_Fields>;
  var_pop?: Maybe<Restaurants_Var_Pop_Fields>;
  var_samp?: Maybe<Restaurants_Var_Samp_Fields>;
  variance?: Maybe<Restaurants_Variance_Fields>;
};


/** aggregate fields of "restaurants" */
export type Restaurants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Restaurants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "restaurants" */
export type Restaurants_Aggregate_Order_By = {
  avg?: InputMaybe<Restaurants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Restaurants_Max_Order_By>;
  min?: InputMaybe<Restaurants_Min_Order_By>;
  stddev?: InputMaybe<Restaurants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Restaurants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Restaurants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Restaurants_Sum_Order_By>;
  var_pop?: InputMaybe<Restaurants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Restaurants_Var_Samp_Order_By>;
  variance?: InputMaybe<Restaurants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "restaurants" */
export type Restaurants_Arr_Rel_Insert_Input = {
  data: Array<Restaurants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Restaurants_On_Conflict>;
};

/** aggregate avg on columns */
export type Restaurants_Avg_Fields = {
  __typename?: 'restaurants_avg_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "restaurants" */
export type Restaurants_Avg_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "restaurants". All fields are combined with a logical 'AND'. */
export type Restaurants_Bool_Exp = {
  _and?: InputMaybe<Array<Restaurants_Bool_Exp>>;
  _not?: InputMaybe<Restaurants_Bool_Exp>;
  _or?: InputMaybe<Array<Restaurants_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Countries_Bool_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_extras?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  menu_item_prices?: InputMaybe<Menu_Item_Price_Bool_Exp>;
  menu_items?: InputMaybe<Menu_Item_Bool_Exp>;
  menu_producers?: InputMaybe<Menu_Producer_Bool_Exp>;
  menu_trees?: InputMaybe<Menu_Tree_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
  order_payment_receipts?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
  order_payments?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
  orders?: InputMaybe<Booking_Order_Bool_Exp>;
  printer_job_orders?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
  printers?: InputMaybe<Printer_Bool_Exp>;
  qrs?: InputMaybe<Seating_Qr_Bool_Exp>;
  receiptFooter?: InputMaybe<String_Comparison_Exp>;
  restaurant_stripe?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
  restaurant_wifi?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
  stripe_orders_items?: InputMaybe<Booking_Order_Items_Bool_Exp>;
  table_positions?: InputMaybe<Seating_Table_Position_Bool_Exp>;
  table_rooms?: InputMaybe<Seating_Table_Room_Bool_Exp>;
  table_sessions?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
  tables?: InputMaybe<Seating_Table_Bool_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  tutorial?: InputMaybe<Boolean_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  vat?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "restaurants" */
export enum Restaurants_Constraint {
  /** unique or primary key constraint on columns "id" */
  RestaurantsPkey = 'restaurants_pkey'
}

/** input type for incrementing numeric columns in table "restaurants" */
export type Restaurants_Inc_Input = {
  vat?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "restaurants" */
export type Restaurants_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries_Obj_Rel_Insert_Input>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_extras?: InputMaybe<Menu_Item_Extra_Arr_Rel_Insert_Input>;
  logoUrl?: InputMaybe<Scalars['String']>;
  menu_item_prices?: InputMaybe<Menu_Item_Price_Arr_Rel_Insert_Input>;
  menu_items?: InputMaybe<Menu_Item_Arr_Rel_Insert_Input>;
  menu_producers?: InputMaybe<Menu_Producer_Arr_Rel_Insert_Input>;
  menu_trees?: InputMaybe<Menu_Tree_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  order_items_extras?: InputMaybe<Booking_Order_Items_Extra_Arr_Rel_Insert_Input>;
  order_payment_items?: InputMaybe<Booking_Order_Payment_Items_Arr_Rel_Insert_Input>;
  order_payment_receipts?: InputMaybe<Booking_Session_Email_Receipt_Arr_Rel_Insert_Input>;
  order_payments?: InputMaybe<Booking_Order_Payment_Arr_Rel_Insert_Input>;
  orders?: InputMaybe<Booking_Order_Arr_Rel_Insert_Input>;
  printer_job_orders?: InputMaybe<Booking_Printer_Job_Order_Arr_Rel_Insert_Input>;
  printers?: InputMaybe<Printer_Arr_Rel_Insert_Input>;
  qrs?: InputMaybe<Seating_Qr_Arr_Rel_Insert_Input>;
  receiptFooter?: InputMaybe<Scalars['String']>;
  restaurant_stripe?: InputMaybe<Restaurant_Stripe_Obj_Rel_Insert_Input>;
  restaurant_wifi?: InputMaybe<Restaurant_Wifi_Obj_Rel_Insert_Input>;
  stripe_orders_items?: InputMaybe<Booking_Order_Items_Arr_Rel_Insert_Input>;
  table_positions?: InputMaybe<Seating_Table_Position_Arr_Rel_Insert_Input>;
  table_rooms?: InputMaybe<Seating_Table_Room_Arr_Rel_Insert_Input>;
  table_sessions?: InputMaybe<Booking_Table_Sessions_Arr_Rel_Insert_Input>;
  tables?: InputMaybe<Seating_Table_Arr_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['String']>;
  tutorial?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  vat?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Restaurants_Max_Fields = {
  __typename?: 'restaurants_max_fields';
  address?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  receiptFooter?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "restaurants" */
export type Restaurants_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  receiptFooter?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  vat?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Restaurants_Min_Fields = {
  __typename?: 'restaurants_min_fields';
  address?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  receiptFooter?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "restaurants" */
export type Restaurants_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  receiptFooter?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  vat?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "restaurants" */
export type Restaurants_Mutation_Response = {
  __typename?: 'restaurants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Restaurants>;
};

/** input type for inserting object relation for remote table "restaurants" */
export type Restaurants_Obj_Rel_Insert_Input = {
  data: Restaurants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Restaurants_On_Conflict>;
};

/** on_conflict condition type for table "restaurants" */
export type Restaurants_On_Conflict = {
  constraint: Restaurants_Constraint;
  update_columns?: Array<Restaurants_Update_Column>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};

/** Ordering options when selecting data from "restaurants". */
export type Restaurants_Order_By = {
  address?: InputMaybe<Order_By>;
  country?: InputMaybe<Countries_Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_extras_aggregate?: InputMaybe<Menu_Item_Extra_Aggregate_Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  menu_item_prices_aggregate?: InputMaybe<Menu_Item_Price_Aggregate_Order_By>;
  menu_items_aggregate?: InputMaybe<Menu_Item_Aggregate_Order_By>;
  menu_producers_aggregate?: InputMaybe<Menu_Producer_Aggregate_Order_By>;
  menu_trees_aggregate?: InputMaybe<Menu_Tree_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  order_items_extras_aggregate?: InputMaybe<Booking_Order_Items_Extra_Aggregate_Order_By>;
  order_payment_items_aggregate?: InputMaybe<Booking_Order_Payment_Items_Aggregate_Order_By>;
  order_payment_receipts_aggregate?: InputMaybe<Booking_Session_Email_Receipt_Aggregate_Order_By>;
  order_payments_aggregate?: InputMaybe<Booking_Order_Payment_Aggregate_Order_By>;
  orders_aggregate?: InputMaybe<Booking_Order_Aggregate_Order_By>;
  printer_job_orders_aggregate?: InputMaybe<Booking_Printer_Job_Order_Aggregate_Order_By>;
  printers_aggregate?: InputMaybe<Printer_Aggregate_Order_By>;
  qrs_aggregate?: InputMaybe<Seating_Qr_Aggregate_Order_By>;
  receiptFooter?: InputMaybe<Order_By>;
  restaurant_stripe?: InputMaybe<Restaurant_Stripe_Order_By>;
  restaurant_wifi?: InputMaybe<Restaurant_Wifi_Order_By>;
  stripe_orders_items_aggregate?: InputMaybe<Booking_Order_Items_Aggregate_Order_By>;
  table_positions_aggregate?: InputMaybe<Seating_Table_Position_Aggregate_Order_By>;
  table_rooms_aggregate?: InputMaybe<Seating_Table_Room_Aggregate_Order_By>;
  table_sessions_aggregate?: InputMaybe<Booking_Table_Sessions_Aggregate_Order_By>;
  tables_aggregate?: InputMaybe<Seating_Table_Aggregate_Order_By>;
  timezone?: InputMaybe<Order_By>;
  tutorial?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  vat?: InputMaybe<Order_By>;
};

/** primary key columns input for table: restaurants */
export type Restaurants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "restaurants" */
export enum Restaurants_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  ReceiptFooter = 'receiptFooter',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Tutorial = 'tutorial',
  /** column name */
  Vat = 'vat'
}

/** input type for updating data in table "restaurants" */
export type Restaurants_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  receiptFooter?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  tutorial?: InputMaybe<Scalars['Boolean']>;
  vat?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Restaurants_Stddev_Fields = {
  __typename?: 'restaurants_stddev_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "restaurants" */
export type Restaurants_Stddev_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Restaurants_Stddev_Pop_Fields = {
  __typename?: 'restaurants_stddev_pop_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "restaurants" */
export type Restaurants_Stddev_Pop_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Restaurants_Stddev_Samp_Fields = {
  __typename?: 'restaurants_stddev_samp_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "restaurants" */
export type Restaurants_Stddev_Samp_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Restaurants_Sum_Fields = {
  __typename?: 'restaurants_sum_fields';
  vat?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "restaurants" */
export type Restaurants_Sum_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** update columns of table "restaurants" */
export enum Restaurants_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  ReceiptFooter = 'receiptFooter',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Tutorial = 'tutorial',
  /** column name */
  Vat = 'vat'
}

/** aggregate var_pop on columns */
export type Restaurants_Var_Pop_Fields = {
  __typename?: 'restaurants_var_pop_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "restaurants" */
export type Restaurants_Var_Pop_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Restaurants_Var_Samp_Fields = {
  __typename?: 'restaurants_var_samp_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "restaurants" */
export type Restaurants_Var_Samp_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Restaurants_Variance_Fields = {
  __typename?: 'restaurants_variance_fields';
  vat?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "restaurants" */
export type Restaurants_Variance_Order_By = {
  vat?: InputMaybe<Order_By>;
};

/** columns and relationships of "seating.qr" */
export type Seating_Qr = {
  __typename?: 'seating_qr';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  nano_id_ref?: Maybe<Scalars['String']>;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  /** An object relationship */
  table?: Maybe<Seating_Table>;
  table_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "seating.qr" */
export type Seating_Qr_Aggregate = {
  __typename?: 'seating_qr_aggregate';
  aggregate?: Maybe<Seating_Qr_Aggregate_Fields>;
  nodes: Array<Seating_Qr>;
};

/** aggregate fields of "seating.qr" */
export type Seating_Qr_Aggregate_Fields = {
  __typename?: 'seating_qr_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Seating_Qr_Max_Fields>;
  min?: Maybe<Seating_Qr_Min_Fields>;
};


/** aggregate fields of "seating.qr" */
export type Seating_Qr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seating.qr" */
export type Seating_Qr_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seating_Qr_Max_Order_By>;
  min?: InputMaybe<Seating_Qr_Min_Order_By>;
};

/** input type for inserting array relation for remote table "seating.qr" */
export type Seating_Qr_Arr_Rel_Insert_Input = {
  data: Array<Seating_Qr_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Qr_On_Conflict>;
};

/** Boolean expression to filter rows from the table "seating.qr". All fields are combined with a logical 'AND'. */
export type Seating_Qr_Bool_Exp = {
  _and?: InputMaybe<Array<Seating_Qr_Bool_Exp>>;
  _not?: InputMaybe<Seating_Qr_Bool_Exp>;
  _or?: InputMaybe<Array<Seating_Qr_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nano_id_ref?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  table?: InputMaybe<Seating_Table_Bool_Exp>;
  table_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "seating.qr" */
export enum Seating_Qr_Constraint {
  /** unique or primary key constraint on columns "id" */
  QrPkey = 'qr_pkey'
}

/** input type for inserting data into table "seating.qr" */
export type Seating_Qr_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nano_id_ref?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Seating_Table_Obj_Rel_Insert_Input>;
  table_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Seating_Qr_Max_Fields = {
  __typename?: 'seating_qr_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nano_id_ref?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "seating.qr" */
export type Seating_Qr_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nano_id_ref?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seating_Qr_Min_Fields = {
  __typename?: 'seating_qr_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nano_id_ref?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "seating.qr" */
export type Seating_Qr_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nano_id_ref?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "seating.qr" */
export type Seating_Qr_Mutation_Response = {
  __typename?: 'seating_qr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seating_Qr>;
};

/** on_conflict condition type for table "seating.qr" */
export type Seating_Qr_On_Conflict = {
  constraint: Seating_Qr_Constraint;
  update_columns?: Array<Seating_Qr_Update_Column>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};

/** Ordering options when selecting data from "seating.qr". */
export type Seating_Qr_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nano_id_ref?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table?: InputMaybe<Seating_Table_Order_By>;
  table_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: seating_qr */
export type Seating_Qr_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "seating.qr" */
export enum Seating_Qr_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NanoIdRef = 'nano_id_ref',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableId = 'table_id'
}

/** input type for updating data in table "seating.qr" */
export type Seating_Qr_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nano_id_ref?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "seating.qr" */
export enum Seating_Qr_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NanoIdRef = 'nano_id_ref',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableId = 'table_id'
}

/** columns and relationships of "seating.table" */
export type Seating_Table = {
  __typename?: 'seating_table';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  open?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  qrs: Array<Seating_Qr>;
  /** An aggregate relationship */
  qrs_aggregate: Seating_Qr_Aggregate;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  room_id: Scalars['uuid'];
  seats?: Maybe<Scalars['Int']>;
  /** An object relationship */
  table_position?: Maybe<Seating_Table_Position>;
  /** An object relationship */
  table_room: Seating_Table_Room;
  /** An array relationship */
  table_sessions: Array<Booking_Table_Sessions>;
  /** An aggregate relationship */
  table_sessions_aggregate: Booking_Table_Sessions_Aggregate;
};


/** columns and relationships of "seating.table" */
export type Seating_TableQrsArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


/** columns and relationships of "seating.table" */
export type Seating_TableQrs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


/** columns and relationships of "seating.table" */
export type Seating_TableTable_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


/** columns and relationships of "seating.table" */
export type Seating_TableTable_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};

/** aggregated selection of "seating.table" */
export type Seating_Table_Aggregate = {
  __typename?: 'seating_table_aggregate';
  aggregate?: Maybe<Seating_Table_Aggregate_Fields>;
  nodes: Array<Seating_Table>;
};

/** aggregate fields of "seating.table" */
export type Seating_Table_Aggregate_Fields = {
  __typename?: 'seating_table_aggregate_fields';
  avg?: Maybe<Seating_Table_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Seating_Table_Max_Fields>;
  min?: Maybe<Seating_Table_Min_Fields>;
  stddev?: Maybe<Seating_Table_Stddev_Fields>;
  stddev_pop?: Maybe<Seating_Table_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Seating_Table_Stddev_Samp_Fields>;
  sum?: Maybe<Seating_Table_Sum_Fields>;
  var_pop?: Maybe<Seating_Table_Var_Pop_Fields>;
  var_samp?: Maybe<Seating_Table_Var_Samp_Fields>;
  variance?: Maybe<Seating_Table_Variance_Fields>;
};


/** aggregate fields of "seating.table" */
export type Seating_Table_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seating_Table_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seating.table" */
export type Seating_Table_Aggregate_Order_By = {
  avg?: InputMaybe<Seating_Table_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seating_Table_Max_Order_By>;
  min?: InputMaybe<Seating_Table_Min_Order_By>;
  stddev?: InputMaybe<Seating_Table_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Seating_Table_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Seating_Table_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Seating_Table_Sum_Order_By>;
  var_pop?: InputMaybe<Seating_Table_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Seating_Table_Var_Samp_Order_By>;
  variance?: InputMaybe<Seating_Table_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "seating.table" */
export type Seating_Table_Arr_Rel_Insert_Input = {
  data: Array<Seating_Table_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_On_Conflict>;
};

/** aggregate avg on columns */
export type Seating_Table_Avg_Fields = {
  __typename?: 'seating_table_avg_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "seating.table" */
export type Seating_Table_Avg_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "seating.table". All fields are combined with a logical 'AND'. */
export type Seating_Table_Bool_Exp = {
  _and?: InputMaybe<Array<Seating_Table_Bool_Exp>>;
  _not?: InputMaybe<Seating_Table_Bool_Exp>;
  _or?: InputMaybe<Array<Seating_Table_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  open?: InputMaybe<Boolean_Comparison_Exp>;
  qrs?: InputMaybe<Seating_Qr_Bool_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  room_id?: InputMaybe<Uuid_Comparison_Exp>;
  seats?: InputMaybe<Int_Comparison_Exp>;
  table_position?: InputMaybe<Seating_Table_Position_Bool_Exp>;
  table_room?: InputMaybe<Seating_Table_Room_Bool_Exp>;
  table_sessions?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};

/** unique or primary key constraints on table "seating.table" */
export enum Seating_Table_Constraint {
  /** unique or primary key constraint on columns "id" */
  TablePkey = 'table_pkey',
  /** unique or primary key constraint on columns "restaurant_id", "name" */
  TableRestaurantIdNameKey = 'table_restaurant_id_name_key'
}

/** input type for incrementing numeric columns in table "seating.table" */
export type Seating_Table_Inc_Input = {
  seats?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "seating.table" */
export type Seating_Table_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['Boolean']>;
  qrs?: InputMaybe<Seating_Qr_Arr_Rel_Insert_Input>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  seats?: InputMaybe<Scalars['Int']>;
  table_position?: InputMaybe<Seating_Table_Position_Obj_Rel_Insert_Input>;
  table_room?: InputMaybe<Seating_Table_Room_Obj_Rel_Insert_Input>;
  table_sessions?: InputMaybe<Booking_Table_Sessions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Seating_Table_Max_Fields = {
  __typename?: 'seating_table_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  seats?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "seating.table" */
export type Seating_Table_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seating_Table_Min_Fields = {
  __typename?: 'seating_table_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  room_id?: Maybe<Scalars['uuid']>;
  seats?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "seating.table" */
export type Seating_Table_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "seating.table" */
export type Seating_Table_Mutation_Response = {
  __typename?: 'seating_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seating_Table>;
};

/** input type for inserting object relation for remote table "seating.table" */
export type Seating_Table_Obj_Rel_Insert_Input = {
  data: Seating_Table_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_On_Conflict>;
};

/** on_conflict condition type for table "seating.table" */
export type Seating_Table_On_Conflict = {
  constraint: Seating_Table_Constraint;
  update_columns?: Array<Seating_Table_Update_Column>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};

/** Ordering options when selecting data from "seating.table". */
export type Seating_Table_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  open?: InputMaybe<Order_By>;
  qrs_aggregate?: InputMaybe<Seating_Qr_Aggregate_Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
  table_position?: InputMaybe<Seating_Table_Position_Order_By>;
  table_room?: InputMaybe<Seating_Table_Room_Order_By>;
  table_sessions_aggregate?: InputMaybe<Booking_Table_Sessions_Aggregate_Order_By>;
};

/** primary key columns input for table: seating_table */
export type Seating_Table_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "seating.table_position" */
export type Seating_Table_Position = {
  __typename?: 'seating_table_position';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  /** An object relationship */
  table: Seating_Table;
  table_id: Scalars['uuid'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

/** aggregated selection of "seating.table_position" */
export type Seating_Table_Position_Aggregate = {
  __typename?: 'seating_table_position_aggregate';
  aggregate?: Maybe<Seating_Table_Position_Aggregate_Fields>;
  nodes: Array<Seating_Table_Position>;
};

/** aggregate fields of "seating.table_position" */
export type Seating_Table_Position_Aggregate_Fields = {
  __typename?: 'seating_table_position_aggregate_fields';
  avg?: Maybe<Seating_Table_Position_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Seating_Table_Position_Max_Fields>;
  min?: Maybe<Seating_Table_Position_Min_Fields>;
  stddev?: Maybe<Seating_Table_Position_Stddev_Fields>;
  stddev_pop?: Maybe<Seating_Table_Position_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Seating_Table_Position_Stddev_Samp_Fields>;
  sum?: Maybe<Seating_Table_Position_Sum_Fields>;
  var_pop?: Maybe<Seating_Table_Position_Var_Pop_Fields>;
  var_samp?: Maybe<Seating_Table_Position_Var_Samp_Fields>;
  variance?: Maybe<Seating_Table_Position_Variance_Fields>;
};


/** aggregate fields of "seating.table_position" */
export type Seating_Table_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seating.table_position" */
export type Seating_Table_Position_Aggregate_Order_By = {
  avg?: InputMaybe<Seating_Table_Position_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seating_Table_Position_Max_Order_By>;
  min?: InputMaybe<Seating_Table_Position_Min_Order_By>;
  stddev?: InputMaybe<Seating_Table_Position_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Seating_Table_Position_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Seating_Table_Position_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Seating_Table_Position_Sum_Order_By>;
  var_pop?: InputMaybe<Seating_Table_Position_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Seating_Table_Position_Var_Samp_Order_By>;
  variance?: InputMaybe<Seating_Table_Position_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "seating.table_position" */
export type Seating_Table_Position_Arr_Rel_Insert_Input = {
  data: Array<Seating_Table_Position_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_Position_On_Conflict>;
};

/** aggregate avg on columns */
export type Seating_Table_Position_Avg_Fields = {
  __typename?: 'seating_table_position_avg_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "seating.table_position" */
export type Seating_Table_Position_Avg_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "seating.table_position". All fields are combined with a logical 'AND'. */
export type Seating_Table_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Seating_Table_Position_Bool_Exp>>;
  _not?: InputMaybe<Seating_Table_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Seating_Table_Position_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  table?: InputMaybe<Seating_Table_Bool_Exp>;
  table_id?: InputMaybe<Uuid_Comparison_Exp>;
  x?: InputMaybe<Int_Comparison_Exp>;
  y?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "seating.table_position" */
export enum Seating_Table_Position_Constraint {
  /** unique or primary key constraint on columns "table_id" */
  TablePositionPkey = 'table_position_pkey'
}

/** input type for incrementing numeric columns in table "seating.table_position" */
export type Seating_Table_Position_Inc_Input = {
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "seating.table_position" */
export type Seating_Table_Position_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Seating_Table_Obj_Rel_Insert_Input>;
  table_id?: InputMaybe<Scalars['uuid']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Seating_Table_Position_Max_Fields = {
  __typename?: 'seating_table_position_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "seating.table_position" */
export type Seating_Table_Position_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seating_Table_Position_Min_Fields = {
  __typename?: 'seating_table_position_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
  table_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "seating.table_position" */
export type Seating_Table_Position_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table_id?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "seating.table_position" */
export type Seating_Table_Position_Mutation_Response = {
  __typename?: 'seating_table_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seating_Table_Position>;
};

/** input type for inserting object relation for remote table "seating.table_position" */
export type Seating_Table_Position_Obj_Rel_Insert_Input = {
  data: Seating_Table_Position_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_Position_On_Conflict>;
};

/** on_conflict condition type for table "seating.table_position" */
export type Seating_Table_Position_On_Conflict = {
  constraint: Seating_Table_Position_Constraint;
  update_columns?: Array<Seating_Table_Position_Update_Column>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "seating.table_position". */
export type Seating_Table_Position_Order_By = {
  created_at?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  table?: InputMaybe<Seating_Table_Order_By>;
  table_id?: InputMaybe<Order_By>;
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** primary key columns input for table: seating_table_position */
export type Seating_Table_Position_Pk_Columns_Input = {
  table_id: Scalars['uuid'];
};

/** select columns of table "seating.table_position" */
export enum Seating_Table_Position_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableId = 'table_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "seating.table_position" */
export type Seating_Table_Position_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  table_id?: InputMaybe<Scalars['uuid']>;
  x?: InputMaybe<Scalars['Int']>;
  y?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Seating_Table_Position_Stddev_Fields = {
  __typename?: 'seating_table_position_stddev_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "seating.table_position" */
export type Seating_Table_Position_Stddev_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Seating_Table_Position_Stddev_Pop_Fields = {
  __typename?: 'seating_table_position_stddev_pop_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "seating.table_position" */
export type Seating_Table_Position_Stddev_Pop_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Seating_Table_Position_Stddev_Samp_Fields = {
  __typename?: 'seating_table_position_stddev_samp_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "seating.table_position" */
export type Seating_Table_Position_Stddev_Samp_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Seating_Table_Position_Sum_Fields = {
  __typename?: 'seating_table_position_sum_fields';
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "seating.table_position" */
export type Seating_Table_Position_Sum_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** update columns of table "seating.table_position" */
export enum Seating_Table_Position_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  TableId = 'table_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** aggregate var_pop on columns */
export type Seating_Table_Position_Var_Pop_Fields = {
  __typename?: 'seating_table_position_var_pop_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "seating.table_position" */
export type Seating_Table_Position_Var_Pop_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Seating_Table_Position_Var_Samp_Fields = {
  __typename?: 'seating_table_position_var_samp_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "seating.table_position" */
export type Seating_Table_Position_Var_Samp_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Seating_Table_Position_Variance_Fields = {
  __typename?: 'seating_table_position_variance_fields';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "seating.table_position" */
export type Seating_Table_Position_Variance_Order_By = {
  x?: InputMaybe<Order_By>;
  y?: InputMaybe<Order_By>;
};

/** columns and relationships of "seating.table_room" */
export type Seating_Table_Room = {
  __typename?: 'seating_table_room';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  /** An array relationship */
  tables: Array<Seating_Table>;
  /** An aggregate relationship */
  tables_aggregate: Seating_Table_Aggregate;
};


/** columns and relationships of "seating.table_room" */
export type Seating_Table_RoomTablesArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


/** columns and relationships of "seating.table_room" */
export type Seating_Table_RoomTables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};

/** aggregated selection of "seating.table_room" */
export type Seating_Table_Room_Aggregate = {
  __typename?: 'seating_table_room_aggregate';
  aggregate?: Maybe<Seating_Table_Room_Aggregate_Fields>;
  nodes: Array<Seating_Table_Room>;
};

/** aggregate fields of "seating.table_room" */
export type Seating_Table_Room_Aggregate_Fields = {
  __typename?: 'seating_table_room_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Seating_Table_Room_Max_Fields>;
  min?: Maybe<Seating_Table_Room_Min_Fields>;
};


/** aggregate fields of "seating.table_room" */
export type Seating_Table_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seating.table_room" */
export type Seating_Table_Room_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seating_Table_Room_Max_Order_By>;
  min?: InputMaybe<Seating_Table_Room_Min_Order_By>;
};

/** input type for inserting array relation for remote table "seating.table_room" */
export type Seating_Table_Room_Arr_Rel_Insert_Input = {
  data: Array<Seating_Table_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_Room_On_Conflict>;
};

/** Boolean expression to filter rows from the table "seating.table_room". All fields are combined with a logical 'AND'. */
export type Seating_Table_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Seating_Table_Room_Bool_Exp>>;
  _not?: InputMaybe<Seating_Table_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Seating_Table_Room_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  tables?: InputMaybe<Seating_Table_Bool_Exp>;
};

/** unique or primary key constraints on table "seating.table_room" */
export enum Seating_Table_Room_Constraint {
  /** unique or primary key constraint on columns "id" */
  TableRoomPkey = 'table_room_pkey'
}

/** input type for inserting data into table "seating.table_room" */
export type Seating_Table_Room_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  tables?: InputMaybe<Seating_Table_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Seating_Table_Room_Max_Fields = {
  __typename?: 'seating_table_room_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "seating.table_room" */
export type Seating_Table_Room_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seating_Table_Room_Min_Fields = {
  __typename?: 'seating_table_room_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "seating.table_room" */
export type Seating_Table_Room_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "seating.table_room" */
export type Seating_Table_Room_Mutation_Response = {
  __typename?: 'seating_table_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seating_Table_Room>;
};

/** input type for inserting object relation for remote table "seating.table_room" */
export type Seating_Table_Room_Obj_Rel_Insert_Input = {
  data: Seating_Table_Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Seating_Table_Room_On_Conflict>;
};

/** on_conflict condition type for table "seating.table_room" */
export type Seating_Table_Room_On_Conflict = {
  constraint: Seating_Table_Room_Constraint;
  update_columns?: Array<Seating_Table_Room_Update_Column>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "seating.table_room". */
export type Seating_Table_Room_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  tables_aggregate?: InputMaybe<Seating_Table_Aggregate_Order_By>;
};

/** primary key columns input for table: seating_table_room */
export type Seating_Table_Room_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "seating.table_room" */
export enum Seating_Table_Room_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** input type for updating data in table "seating.table_room" */
export type Seating_Table_Room_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "seating.table_room" */
export enum Seating_Table_Room_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RestaurantId = 'restaurant_id'
}

/** select columns of table "seating.table" */
export enum Seating_Table_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Open = 'open',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seats = 'seats'
}

/** input type for updating data in table "seating.table" */
export type Seating_Table_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['Boolean']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  room_id?: InputMaybe<Scalars['uuid']>;
  seats?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Seating_Table_Stddev_Fields = {
  __typename?: 'seating_table_stddev_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "seating.table" */
export type Seating_Table_Stddev_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Seating_Table_Stddev_Pop_Fields = {
  __typename?: 'seating_table_stddev_pop_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "seating.table" */
export type Seating_Table_Stddev_Pop_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Seating_Table_Stddev_Samp_Fields = {
  __typename?: 'seating_table_stddev_samp_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "seating.table" */
export type Seating_Table_Stddev_Samp_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Seating_Table_Sum_Fields = {
  __typename?: 'seating_table_sum_fields';
  seats?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "seating.table" */
export type Seating_Table_Sum_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** update columns of table "seating.table" */
export enum Seating_Table_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Open = 'open',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Seats = 'seats'
}

/** aggregate var_pop on columns */
export type Seating_Table_Var_Pop_Fields = {
  __typename?: 'seating_table_var_pop_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "seating.table" */
export type Seating_Table_Var_Pop_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Seating_Table_Var_Samp_Fields = {
  __typename?: 'seating_table_var_samp_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "seating.table" */
export type Seating_Table_Var_Samp_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Seating_Table_Variance_Fields = {
  __typename?: 'seating_table_variance_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "seating.table" */
export type Seating_Table_Variance_Order_By = {
  seats?: InputMaybe<Order_By>;
};

export type Smallint_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _cast?: InputMaybe<Smallint_Cast_Exp>;
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum = {
  __typename?: 'stripe_orders_status_enum';
  value: Scalars['String'];
};

/** aggregated selection of "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Aggregate = {
  __typename?: 'stripe_orders_status_enum_aggregate';
  aggregate?: Maybe<Stripe_Orders_Status_Enum_Aggregate_Fields>;
  nodes: Array<Stripe_Orders_Status_Enum>;
};

/** aggregate fields of "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Aggregate_Fields = {
  __typename?: 'stripe_orders_status_enum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stripe_Orders_Status_Enum_Max_Fields>;
  min?: Maybe<Stripe_Orders_Status_Enum_Min_Fields>;
};


/** aggregate fields of "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Orders_Status_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stripe_orders_status_enum". All fields are combined with a logical 'AND'. */
export type Stripe_Orders_Status_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Orders_Status_Enum_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Orders_Status_Enum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_orders_status_enum" */
export enum Stripe_Orders_Status_Enum_Constraint {
  /** unique or primary key constraint on columns "value" */
  StripeOrdersStatusEnumPkey = 'stripe_orders_status_enum_pkey'
}

/** input type for inserting data into table "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stripe_Orders_Status_Enum_Max_Fields = {
  __typename?: 'stripe_orders_status_enum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Stripe_Orders_Status_Enum_Min_Fields = {
  __typename?: 'stripe_orders_status_enum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Mutation_Response = {
  __typename?: 'stripe_orders_status_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Orders_Status_Enum>;
};

/** on_conflict condition type for table "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_On_Conflict = {
  constraint: Stripe_Orders_Status_Enum_Constraint;
  update_columns?: Array<Stripe_Orders_Status_Enum_Update_Column>;
  where?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_orders_status_enum". */
export type Stripe_Orders_Status_Enum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_orders_status_enum */
export type Stripe_Orders_Status_Enum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "stripe_orders_status_enum" */
export enum Stripe_Orders_Status_Enum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "stripe_orders_status_enum" */
export type Stripe_Orders_Status_Enum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stripe_orders_status_enum" */
export enum Stripe_Orders_Status_Enum_Update_Column {
  /** column name */
  Value = 'value'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "booking.order" */
  booking_order: Array<Booking_Order>;
  /** fetch aggregated fields from the table: "booking.order" */
  booking_order_aggregate: Booking_Order_Aggregate;
  /** fetch data from the table: "booking.order" using primary key columns */
  booking_order_by_pk?: Maybe<Booking_Order>;
  /** fetch data from the table: "booking.order_items" */
  booking_order_items: Array<Booking_Order_Items>;
  /** fetch aggregated fields from the table: "booking.order_items" */
  booking_order_items_aggregate: Booking_Order_Items_Aggregate;
  /** fetch data from the table: "booking.order_items" using primary key columns */
  booking_order_items_by_pk?: Maybe<Booking_Order_Items>;
  /** fetch data from the table: "booking.order_items_extra" */
  booking_order_items_extra: Array<Booking_Order_Items_Extra>;
  /** fetch aggregated fields from the table: "booking.order_items_extra" */
  booking_order_items_extra_aggregate: Booking_Order_Items_Extra_Aggregate;
  /** fetch data from the table: "booking.order_items_extra" using primary key columns */
  booking_order_items_extra_by_pk?: Maybe<Booking_Order_Items_Extra>;
  /** fetch data from the table: "booking.order_payment" */
  booking_order_payment: Array<Booking_Order_Payment>;
  /** fetch aggregated fields from the table: "booking.order_payment" */
  booking_order_payment_aggregate: Booking_Order_Payment_Aggregate;
  /** fetch data from the table: "booking.order_payment" using primary key columns */
  booking_order_payment_by_pk?: Maybe<Booking_Order_Payment>;
  /** fetch data from the table: "booking.order_payment_items" */
  booking_order_payment_items: Array<Booking_Order_Payment_Items>;
  /** fetch aggregated fields from the table: "booking.order_payment_items" */
  booking_order_payment_items_aggregate: Booking_Order_Payment_Items_Aggregate;
  /** fetch data from the table: "booking.order_payment_items" using primary key columns */
  booking_order_payment_items_by_pk?: Maybe<Booking_Order_Payment_Items>;
  /** fetch data from the table: "booking.order_payment_status" */
  booking_order_payment_status: Array<Booking_Order_Payment_Status>;
  /** fetch aggregated fields from the table: "booking.order_payment_status" */
  booking_order_payment_status_aggregate: Booking_Order_Payment_Status_Aggregate;
  /** fetch data from the table: "booking.order_payment_status" using primary key columns */
  booking_order_payment_status_by_pk?: Maybe<Booking_Order_Payment_Status>;
  /** fetch data from the table: "booking.order_status" */
  booking_order_status: Array<Booking_Order_Status>;
  /** fetch aggregated fields from the table: "booking.order_status" */
  booking_order_status_aggregate: Booking_Order_Status_Aggregate;
  /** fetch data from the table: "booking.order_status" using primary key columns */
  booking_order_status_by_pk?: Maybe<Booking_Order_Status>;
  /** fetch data from the table: "booking.order_type" */
  booking_order_type: Array<Booking_Order_Type>;
  /** fetch aggregated fields from the table: "booking.order_type" */
  booking_order_type_aggregate: Booking_Order_Type_Aggregate;
  /** fetch data from the table: "booking.order_type" using primary key columns */
  booking_order_type_by_pk?: Maybe<Booking_Order_Type>;
  /** fetch data from the table: "booking.printer_job_order" */
  booking_printer_job_order: Array<Booking_Printer_Job_Order>;
  /** fetch aggregated fields from the table: "booking.printer_job_order" */
  booking_printer_job_order_aggregate: Booking_Printer_Job_Order_Aggregate;
  /** fetch data from the table: "booking.printer_job_order" using primary key columns */
  booking_printer_job_order_by_pk?: Maybe<Booking_Printer_Job_Order>;
  /** fetch data from the table: "booking.session_email_receipt" */
  booking_session_email_receipt: Array<Booking_Session_Email_Receipt>;
  /** fetch aggregated fields from the table: "booking.session_email_receipt" */
  booking_session_email_receipt_aggregate: Booking_Session_Email_Receipt_Aggregate;
  /** fetch data from the table: "booking.session_email_receipt" using primary key columns */
  booking_session_email_receipt_by_pk?: Maybe<Booking_Session_Email_Receipt>;
  /** fetch data from the table: "booking.table_sessions" */
  booking_table_sessions: Array<Booking_Table_Sessions>;
  /** fetch aggregated fields from the table: "booking.table_sessions" */
  booking_table_sessions_aggregate: Booking_Table_Sessions_Aggregate;
  /** fetch data from the table: "booking.table_sessions" using primary key columns */
  booking_table_sessions_by_pk?: Maybe<Booking_Table_Sessions>;
  /** fetch data from the table: "booking.table_sessions_status" */
  booking_table_sessions_status: Array<Booking_Table_Sessions_Status>;
  /** fetch aggregated fields from the table: "booking.table_sessions_status" */
  booking_table_sessions_status_aggregate: Booking_Table_Sessions_Status_Aggregate;
  /** fetch data from the table: "booking.table_sessions_status" using primary key columns */
  booking_table_sessions_status_by_pk?: Maybe<Booking_Table_Sessions_Status>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "menu.item" */
  menu_item: Array<Menu_Item>;
  /** fetch aggregated fields from the table: "menu.item" */
  menu_item_aggregate: Menu_Item_Aggregate;
  /** fetch data from the table: "menu.item" using primary key columns */
  menu_item_by_pk?: Maybe<Menu_Item>;
  /** fetch data from the table: "menu.item_extra" */
  menu_item_extra: Array<Menu_Item_Extra>;
  /** fetch aggregated fields from the table: "menu.item_extra" */
  menu_item_extra_aggregate: Menu_Item_Extra_Aggregate;
  /** fetch data from the table: "menu.item_extra" using primary key columns */
  menu_item_extra_by_pk?: Maybe<Menu_Item_Extra>;
  /** fetch data from the table: "menu.item_label" */
  menu_item_label: Array<Menu_Item_Label>;
  /** fetch aggregated fields from the table: "menu.item_label" */
  menu_item_label_aggregate: Menu_Item_Label_Aggregate;
  /** fetch data from the table: "menu.item_label" using primary key columns */
  menu_item_label_by_pk?: Maybe<Menu_Item_Label>;
  /** fetch data from the table: "menu.item_price" */
  menu_item_price: Array<Menu_Item_Price>;
  /** fetch aggregated fields from the table: "menu.item_price" */
  menu_item_price_aggregate: Menu_Item_Price_Aggregate;
  /** fetch data from the table: "menu.item_price" using primary key columns */
  menu_item_price_by_pk?: Maybe<Menu_Item_Price>;
  /** fetch data from the table: "menu.producer" */
  menu_producer: Array<Menu_Producer>;
  /** fetch aggregated fields from the table: "menu.producer" */
  menu_producer_aggregate: Menu_Producer_Aggregate;
  /** fetch data from the table: "menu.producer" using primary key columns */
  menu_producer_by_pk?: Maybe<Menu_Producer>;
  /** fetch data from the table: "menu.tree" */
  menu_tree: Array<Menu_Tree>;
  /** fetch aggregated fields from the table: "menu.tree" */
  menu_tree_aggregate: Menu_Tree_Aggregate;
  /** fetch data from the table: "menu.tree" using primary key columns */
  menu_tree_by_pk?: Maybe<Menu_Tree>;
  /** fetch data from the table: "printer" */
  printer: Array<Printer>;
  /** fetch aggregated fields from the table: "printer" */
  printer_aggregate: Printer_Aggregate;
  /** fetch data from the table: "printer" using primary key columns */
  printer_by_pk?: Maybe<Printer>;
  /** fetch data from the table: "qr_template" */
  qr_template: Array<Qr_Template>;
  /** fetch aggregated fields from the table: "qr_template" */
  qr_template_aggregate: Qr_Template_Aggregate;
  /** fetch data from the table: "qr_template" using primary key columns */
  qr_template_by_pk?: Maybe<Qr_Template>;
  /** fetch data from the table: "restaurant_stripe" */
  restaurant_stripe: Array<Restaurant_Stripe>;
  /** fetch aggregated fields from the table: "restaurant_stripe" */
  restaurant_stripe_aggregate: Restaurant_Stripe_Aggregate;
  /** fetch data from the table: "restaurant_stripe" using primary key columns */
  restaurant_stripe_by_pk?: Maybe<Restaurant_Stripe>;
  /** fetch data from the table: "restaurant_wifi" */
  restaurant_wifi: Array<Restaurant_Wifi>;
  /** fetch aggregated fields from the table: "restaurant_wifi" */
  restaurant_wifi_aggregate: Restaurant_Wifi_Aggregate;
  /** fetch data from the table: "restaurant_wifi" using primary key columns */
  restaurant_wifi_by_pk?: Maybe<Restaurant_Wifi>;
  /** An array relationship */
  restaurants: Array<Restaurants>;
  /** An aggregate relationship */
  restaurants_aggregate: Restaurants_Aggregate;
  /** fetch data from the table: "restaurants" using primary key columns */
  restaurants_by_pk?: Maybe<Restaurants>;
  /** fetch data from the table: "seating.qr" */
  seating_qr: Array<Seating_Qr>;
  /** fetch aggregated fields from the table: "seating.qr" */
  seating_qr_aggregate: Seating_Qr_Aggregate;
  /** fetch data from the table: "seating.qr" using primary key columns */
  seating_qr_by_pk?: Maybe<Seating_Qr>;
  /** fetch data from the table: "seating.table" */
  seating_table: Array<Seating_Table>;
  /** fetch aggregated fields from the table: "seating.table" */
  seating_table_aggregate: Seating_Table_Aggregate;
  /** fetch data from the table: "seating.table" using primary key columns */
  seating_table_by_pk?: Maybe<Seating_Table>;
  /** fetch data from the table: "seating.table_position" */
  seating_table_position: Array<Seating_Table_Position>;
  /** fetch aggregated fields from the table: "seating.table_position" */
  seating_table_position_aggregate: Seating_Table_Position_Aggregate;
  /** fetch data from the table: "seating.table_position" using primary key columns */
  seating_table_position_by_pk?: Maybe<Seating_Table_Position>;
  /** fetch data from the table: "seating.table_room" */
  seating_table_room: Array<Seating_Table_Room>;
  /** fetch aggregated fields from the table: "seating.table_room" */
  seating_table_room_aggregate: Seating_Table_Room_Aggregate;
  /** fetch data from the table: "seating.table_room" using primary key columns */
  seating_table_room_by_pk?: Maybe<Seating_Table_Room>;
  /** fetch data from the table: "stripe_orders_status_enum" */
  stripe_orders_status_enum: Array<Stripe_Orders_Status_Enum>;
  /** fetch aggregated fields from the table: "stripe_orders_status_enum" */
  stripe_orders_status_enum_aggregate: Stripe_Orders_Status_Enum_Aggregate;
  /** fetch data from the table: "stripe_orders_status_enum" using primary key columns */
  stripe_orders_status_enum_by_pk?: Maybe<Stripe_Orders_Status_Enum>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_roles_enum" */
  user_roles_enum: Array<User_Roles_Enum>;
  /** fetch aggregated fields from the table: "user_roles_enum" */
  user_roles_enum_aggregate: User_Roles_Enum_Aggregate;
  /** fetch data from the table: "user_roles_enum" using primary key columns */
  user_roles_enum_by_pk?: Maybe<User_Roles_Enum>;
};


export type Subscription_RootBooking_OrderArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


export type Subscription_RootBooking_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Order_By>>;
  where?: InputMaybe<Booking_Order_Bool_Exp>;
};


export type Subscription_RootBooking_Order_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Order_Items_ExtraArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Items_Extra_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Items_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Items_Extra_Order_By>>;
  where?: InputMaybe<Booking_Order_Items_Extra_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Items_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Order_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Order_Payment_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Items_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Items_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_Items_By_PkArgs = {
  order_item_id: Scalars['uuid'];
  order_payment_id: Scalars['uuid'];
};


export type Subscription_RootBooking_Order_Payment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Payment_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Payment_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Payment_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Subscription_RootBooking_Order_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Status_Order_By>>;
  where?: InputMaybe<Booking_Order_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Subscription_RootBooking_Order_TypeArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Type_Order_By>>;
  where?: InputMaybe<Booking_Order_Type_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Order_Type_Order_By>>;
  where?: InputMaybe<Booking_Order_Type_Bool_Exp>;
};


export type Subscription_RootBooking_Order_Type_By_PkArgs = {
  values: Scalars['String'];
};


export type Subscription_RootBooking_Printer_Job_OrderArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


export type Subscription_RootBooking_Printer_Job_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Printer_Job_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Printer_Job_Order_Order_By>>;
  where?: InputMaybe<Booking_Printer_Job_Order_Bool_Exp>;
};


export type Subscription_RootBooking_Printer_Job_Order_By_PkArgs = {
  printer_id: Scalars['uuid'];
};


export type Subscription_RootBooking_Session_Email_ReceiptArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


export type Subscription_RootBooking_Session_Email_Receipt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Session_Email_Receipt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Session_Email_Receipt_Order_By>>;
  where?: InputMaybe<Booking_Session_Email_Receipt_Bool_Exp>;
};


export type Subscription_RootBooking_Session_Email_Receipt_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Table_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


export type Subscription_RootBooking_Table_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Bool_Exp>;
};


export type Subscription_RootBooking_Table_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooking_Table_Sessions_StatusArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Status_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Table_Sessions_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booking_Table_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booking_Table_Sessions_Status_Order_By>>;
  where?: InputMaybe<Booking_Table_Sessions_Status_Bool_Exp>;
};


export type Subscription_RootBooking_Table_Sessions_Status_By_PkArgs = {
  values: Scalars['String'];
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootMenu_ItemArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


export type Subscription_RootMenu_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Order_By>>;
  where?: InputMaybe<Menu_Item_Bool_Exp>;
};


export type Subscription_RootMenu_Item_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMenu_Item_ExtraArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Extra_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Extra_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Extra_Order_By>>;
  where?: InputMaybe<Menu_Item_Extra_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Extra_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMenu_Item_LabelArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Label_Order_By>>;
  where?: InputMaybe<Menu_Item_Label_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Label_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Label_Order_By>>;
  where?: InputMaybe<Menu_Item_Label_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Label_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootMenu_Item_PriceArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Item_Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Item_Price_Order_By>>;
  where?: InputMaybe<Menu_Item_Price_Bool_Exp>;
};


export type Subscription_RootMenu_Item_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMenu_ProducerArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


export type Subscription_RootMenu_Producer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Producer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Producer_Order_By>>;
  where?: InputMaybe<Menu_Producer_Bool_Exp>;
};


export type Subscription_RootMenu_Producer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMenu_TreeArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


export type Subscription_RootMenu_Tree_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Menu_Tree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Menu_Tree_Order_By>>;
  where?: InputMaybe<Menu_Tree_Bool_Exp>;
};


export type Subscription_RootMenu_Tree_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPrinterArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


export type Subscription_RootPrinter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Printer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Printer_Order_By>>;
  where?: InputMaybe<Printer_Bool_Exp>;
};


export type Subscription_RootPrinter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQr_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Qr_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qr_Template_Order_By>>;
  where?: InputMaybe<Qr_Template_Bool_Exp>;
};


export type Subscription_RootQr_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Qr_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qr_Template_Order_By>>;
  where?: InputMaybe<Qr_Template_Bool_Exp>;
};


export type Subscription_RootQr_Template_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRestaurant_StripeArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Stripe_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Stripe_Order_By>>;
  where?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
};


export type Subscription_RootRestaurant_Stripe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Stripe_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Stripe_Order_By>>;
  where?: InputMaybe<Restaurant_Stripe_Bool_Exp>;
};


export type Subscription_RootRestaurant_Stripe_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


export type Subscription_RootRestaurant_WifiArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Wifi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Wifi_Order_By>>;
  where?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
};


export type Subscription_RootRestaurant_Wifi_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurant_Wifi_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurant_Wifi_Order_By>>;
  where?: InputMaybe<Restaurant_Wifi_Bool_Exp>;
};


export type Subscription_RootRestaurant_Wifi_By_PkArgs = {
  restaurant_id: Scalars['uuid'];
};


export type Subscription_RootRestaurantsArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};


export type Subscription_RootRestaurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Restaurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Restaurants_Order_By>>;
  where?: InputMaybe<Restaurants_Bool_Exp>;
};


export type Subscription_RootRestaurants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeating_QrArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


export type Subscription_RootSeating_Qr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Qr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Qr_Order_By>>;
  where?: InputMaybe<Seating_Qr_Bool_Exp>;
};


export type Subscription_RootSeating_Qr_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeating_TableArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


export type Subscription_RootSeating_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Order_By>>;
  where?: InputMaybe<Seating_Table_Bool_Exp>;
};


export type Subscription_RootSeating_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSeating_Table_PositionArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


export type Subscription_RootSeating_Table_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Position_Order_By>>;
  where?: InputMaybe<Seating_Table_Position_Bool_Exp>;
};


export type Subscription_RootSeating_Table_Position_By_PkArgs = {
  table_id: Scalars['uuid'];
};


export type Subscription_RootSeating_Table_RoomArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


export type Subscription_RootSeating_Table_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seating_Table_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seating_Table_Room_Order_By>>;
  where?: InputMaybe<Seating_Table_Room_Bool_Exp>;
};


export type Subscription_RootSeating_Table_Room_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStripe_Orders_Status_EnumArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Orders_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Orders_Status_Enum_Order_By>>;
  where?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
};


export type Subscription_RootStripe_Orders_Status_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Orders_Status_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Orders_Status_Enum_Order_By>>;
  where?: InputMaybe<Stripe_Orders_Status_Enum_Bool_Exp>;
};


export type Subscription_RootStripe_Orders_Status_Enum_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Roles_EnumArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Enum_Order_By>>;
  where?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};


export type Subscription_RootUser_Roles_Enum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Enum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Enum_Order_By>>;
  where?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};


export type Subscription_RootUser_Roles_Enum_By_PkArgs = {
  value: Scalars['String'];
};

export type Timestamptz_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _cast?: InputMaybe<Timestamptz_Cast_Exp>;
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  created_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  login_token?: Maybe<Scalars['String']>;
  /** An object relationship */
  restaurant: Restaurants;
  restaurant_id: Scalars['uuid'];
  role: User_Roles_Enum_Enum;
  /** An object relationship */
  user_roles_enum: User_Roles_Enum;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  login_token?: InputMaybe<String_Comparison_Exp>;
  restaurant?: InputMaybe<Restaurants_Bool_Exp>;
  restaurant_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<User_Roles_Enum_Enum_Comparison_Exp>;
  user_roles_enum?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  EmailIdx = 'email_idx',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  login_token?: InputMaybe<Scalars['String']>;
  restaurant?: InputMaybe<Restaurants_Obj_Rel_Insert_Input>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<User_Roles_Enum_Enum>;
  user_roles_enum?: InputMaybe<User_Roles_Enum_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  login_token?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  login_token?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  login_token?: Maybe<Scalars['String']>;
  restaurant_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  login_token?: InputMaybe<Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  login_token?: InputMaybe<Order_By>;
  restaurant?: InputMaybe<Restaurants_Order_By>;
  restaurant_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user_roles_enum?: InputMaybe<User_Roles_Enum_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "user_roles_enum" */
export type User_Roles_Enum = {
  __typename?: 'user_roles_enum';
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "user_roles_enum" */
export type User_Roles_EnumUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "user_roles_enum" */
export type User_Roles_EnumUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "user_roles_enum" */
export type User_Roles_Enum_Aggregate = {
  __typename?: 'user_roles_enum_aggregate';
  aggregate?: Maybe<User_Roles_Enum_Aggregate_Fields>;
  nodes: Array<User_Roles_Enum>;
};

/** aggregate fields of "user_roles_enum" */
export type User_Roles_Enum_Aggregate_Fields = {
  __typename?: 'user_roles_enum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Enum_Max_Fields>;
  min?: Maybe<User_Roles_Enum_Min_Fields>;
};


/** aggregate fields of "user_roles_enum" */
export type User_Roles_Enum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Enum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_roles_enum". All fields are combined with a logical 'AND'. */
export type User_Roles_Enum_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Enum_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Enum_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Enum_Bool_Exp>>;
  users?: InputMaybe<User_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles_enum" */
export enum User_Roles_Enum_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserRolesEnumPkey = 'user_roles_enum_pkey'
}

export enum User_Roles_Enum_Enum {
  Manager = 'manager',
  Owner = 'owner',
  Staff = 'staff'
}

/** Boolean expression to compare columns of type "user_roles_enum_enum". All fields are combined with logical 'AND'. */
export type User_Roles_Enum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Roles_Enum_Enum>;
  _in?: InputMaybe<Array<User_Roles_Enum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Roles_Enum_Enum>;
  _nin?: InputMaybe<Array<User_Roles_Enum_Enum>>;
};

/** input type for inserting data into table "user_roles_enum" */
export type User_Roles_Enum_Insert_Input = {
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Roles_Enum_Max_Fields = {
  __typename?: 'user_roles_enum_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Roles_Enum_Min_Fields = {
  __typename?: 'user_roles_enum_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_roles_enum" */
export type User_Roles_Enum_Mutation_Response = {
  __typename?: 'user_roles_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles_Enum>;
};

/** input type for inserting object relation for remote table "user_roles_enum" */
export type User_Roles_Enum_Obj_Rel_Insert_Input = {
  data: User_Roles_Enum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Roles_Enum_On_Conflict>;
};

/** on_conflict condition type for table "user_roles_enum" */
export type User_Roles_Enum_On_Conflict = {
  constraint: User_Roles_Enum_Constraint;
  update_columns?: Array<User_Roles_Enum_Update_Column>;
  where?: InputMaybe<User_Roles_Enum_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles_enum". */
export type User_Roles_Enum_Order_By = {
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles_enum */
export type User_Roles_Enum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_roles_enum" */
export enum User_Roles_Enum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_roles_enum" */
export type User_Roles_Enum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_roles_enum" */
export enum User_Roles_Enum_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LoginToken = 'login_token',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  login_token?: InputMaybe<Scalars['String']>;
  restaurant_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<User_Roles_Enum_Enum>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LoginToken = 'login_token',
  /** column name */
  RestaurantId = 'restaurant_id',
  /** column name */
  Role = 'role'
}

export type Uuid_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _cast?: InputMaybe<Uuid_Cast_Exp>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetRestaurantCurrencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRestaurantCurrencyQuery = { __typename?: 'query_root', restaurants: Array<{ __typename?: 'restaurants', country: { __typename?: 'countries', currencySymol: string, currencyPrecision: any } }> };

export type TutorialCheckSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TutorialCheckSubscription = { __typename?: 'subscription_root', restaurants: Array<{ __typename?: 'restaurants', tutorial: boolean, name: string, restaurant_stripe?: { __typename?: 'restaurant_stripe', namespace: string } | null }> };

export type InsertItemExtraMutationVariables = Exact<{
  input: Menu_Item_Extra_Insert_Input;
}>;


export type InsertItemExtraMutation = { __typename?: 'mutation_root', insert_menu_item_extra_one?: { __typename?: 'menu_item_extra', id: any } | null };

export type GetItemExtraQueryVariables = Exact<{
  itemId: Scalars['uuid'];
}>;


export type GetItemExtraQuery = { __typename?: 'query_root', menu_item_extra: Array<{ __typename?: 'menu_item_extra', id: any, choices: any, created_at: any, name: string, price: number }> };

export type LoadMenuDetailQueryVariables = Exact<{
  menuFilter: Menu_Item_Bool_Exp;
}>;


export type LoadMenuDetailQuery = { __typename?: 'query_root', menuTrees: Array<{ __typename?: 'menu_tree', id: any, title: string, parent_id?: any | null }>, items: Array<{ __typename?: 'menu_item', id: any, description?: string | null, labels: any, live: boolean, menu_parent: any, name: string, imageUrl?: string | null, created_at?: any | null, active_price_item: { __typename?: 'menu_item_price', price: number } }> };

export type LoadMenuItemDetailQueryVariables = Exact<{
  itemId: Scalars['uuid'];
}>;


export type LoadMenuItemDetailQuery = { __typename?: 'query_root', labels: Array<{ __typename?: 'menu_item_label', key: string, type: string }>, menuTrees: Array<{ __typename?: 'menu_tree', id: any, title: string, parent_id?: any | null }>, item?: { __typename?: 'menu_item', id: any, description?: string | null, labels: any, live: boolean, menu_parent: any, name: string, imageUrl?: string | null, created_at?: any | null, active_price_item: { __typename?: 'menu_item_price', price: number } } | null };

export type DeleteMenuItemByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteMenuItemByPkMutation = { __typename?: 'mutation_root', delete_menu_item_by_pk?: { __typename?: 'menu_item', id: any } | null };

export type UpsertMenuItemMutationVariables = Exact<{
  menuItem: Menu_Item_Insert_Input;
}>;


export type UpsertMenuItemMutation = { __typename?: 'mutation_root', insert_menu_item_one?: { __typename?: 'menu_item', id: any } | null };

export type UpsertMenuItemWithoutPriceMutationVariables = Exact<{
  id: Scalars['uuid'];
  menuItem: Menu_Item_Set_Input;
}>;


export type UpsertMenuItemWithoutPriceMutation = { __typename?: 'mutation_root', update_menu_item_by_pk?: { __typename?: 'menu_item', id: any } | null };

export type UpsertMenuTreeMutationVariables = Exact<{
  menu: Array<Menu_Tree_Insert_Input> | Menu_Tree_Insert_Input;
  allTreeIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpsertMenuTreeMutation = { __typename?: 'mutation_root', insert_menu_tree?: { __typename?: 'menu_tree_mutation_response', affected_rows: number } | null, delete_menu_tree?: { __typename?: 'menu_tree_mutation_response', affected_rows: number } | null };

export type GetMenuTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenuTreeQuery = { __typename?: 'query_root', menu: Array<{ __typename?: 'menu_tree', id: any, title: string, producer_id: any, parentId?: any | null, menu_items_aggregate: { __typename?: 'menu_item_aggregate', aggregate?: { __typename?: 'menu_item_aggregate_fields', count: number } | null } }> };

export type InsertMenuProducerMutationVariables = Exact<{
  input: Menu_Producer_Insert_Input;
}>;


export type InsertMenuProducerMutation = { __typename?: 'mutation_root', insert_menu_producer_one?: { __typename?: 'menu_producer', id: any } | null };

export type UpdateMenuProducerMutationVariables = Exact<{
  pk: Scalars['uuid'];
  update: Menu_Producer_Set_Input;
}>;


export type UpdateMenuProducerMutation = { __typename?: 'mutation_root', update_menu_producer_by_pk?: { __typename?: 'menu_producer', id: any } | null };

export type GetMenuProducersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenuProducersQuery = { __typename?: 'query_root', menu_producer: Array<{ __typename?: 'menu_producer', id: any, name: string, colors: string, created_at: any }> };

export type GetSessionHistoryQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  createdAtFilter?: InputMaybe<Timestamptz_Comparison_Exp>;
}>;


export type GetSessionHistoryQuery = { __typename?: 'query_root', sessions: Array<{ __typename?: 'booking_table_sessions', id: any, total?: any | null, total_paid?: any | null, created_at?: any | null, table: { __typename?: 'seating_table', name: string } }>, sessions_aggregate: { __typename?: 'booking_table_sessions_aggregate', aggregate?: { __typename?: 'booking_table_sessions_aggregate_fields', count: number } | null } };

export type GetRoomOverviewSubscriptionVariables = Exact<{
  roomId: Scalars['uuid'];
}>;


export type GetRoomOverviewSubscription = { __typename?: 'subscription_root', seating_table_room_by_pk?: { __typename?: 'seating_table_room', tables: Array<{ __typename?: 'seating_table', id: any, name: string, seats?: number | null, table_position?: { __typename?: 'seating_table_position', x: number, y: number } | null, table_sessions: Array<{ __typename?: 'booking_table_sessions', id: any, total?: any | null, total_paid?: any | null, orders: Array<{ __typename?: 'booking_order', status: Booking_Order_Status_Enum }> }> }> } | null };

export type GetPrintersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetPrintersSubscription = { __typename?: 'subscription_root', printers: Array<{ __typename?: 'printer', id: any, name: string, online?: any | null }> };

export type GetOrdersSubscriptionVariables = Exact<{
  orderBy?: InputMaybe<Array<Booking_Order_Order_By> | Booking_Order_Order_By>;
}>;


export type GetOrdersSubscription = { __typename?: 'subscription_root', orders: Array<{ __typename?: 'booking_order', created_at: any, currency: string, id: any, paid_at?: any | null, status: Booking_Order_Status_Enum, type: Booking_Order_Type_Enum, menu_producer: { __typename?: 'menu_producer', name: string }, items: { __typename?: 'booking_order_items_aggregate', aggregate?: { __typename?: 'booking_order_items_aggregate_fields', count: number } | null }, order_items: Array<{ __typename?: 'booking_order_items', id: any, note?: string | null, extras: Array<{ __typename?: 'booking_order_items_extra', item_extra: { __typename?: 'menu_item_extra', name: string } }>, menu_item_price: { __typename?: 'menu_item_price', active_menu_item?: { __typename?: 'menu_item', name: string } | null } }>, table_session: { __typename?: 'booking_table_sessions', id: any, table: { __typename?: 'seating_table', name: string } } }> };

export type GetOrderFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrderFiltersQuery = { __typename?: 'query_root', types: Array<{ __typename?: 'booking_order_type', values: string }>, statuses: Array<{ __typename?: 'booking_order_status', values: string }> };

export type StartPreparingOrderMutationVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type StartPreparingOrderMutation = { __typename?: 'mutation_root', update_booking_order?: { __typename?: 'booking_order_mutation_response', affected_rows: number } | null };

export type MarkOrderAsCompleteMutationVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type MarkOrderAsCompleteMutation = { __typename?: 'mutation_root', update_booking_order?: { __typename?: 'booking_order_mutation_response', affected_rows: number } | null };

export type MarkOrderAsDeliveredMutationVariables = Exact<{
  orderId: Scalars['uuid'];
}>;


export type MarkOrderAsDeliveredMutation = { __typename?: 'mutation_root', update_booking_order?: { __typename?: 'booking_order_mutation_response', affected_rows: number } | null };

export type OveriteOrderStatusMutationVariables = Exact<{
  orderId: Scalars['uuid'];
  orderStatus: Booking_Order_Status_Enum;
}>;


export type OveriteOrderStatusMutation = { __typename?: 'mutation_root', update_booking_order?: { __typename?: 'booking_order_mutation_response', affected_rows: number } | null };

export type GetSessionOrdersWithItemsQueryVariables = Exact<{
  sessionId: Scalars['uuid'];
}>;


export type GetSessionOrdersWithItemsQuery = { __typename?: 'query_root', booking_table_sessions_by_pk?: { __typename?: 'booking_table_sessions', total?: any | null, orders: Array<{ __typename?: 'booking_order', id: any, total?: any | null, order_items: Array<{ __typename?: 'booking_order_items', menu_item_price: { __typename?: 'menu_item_price', active_menu_item?: { __typename?: 'menu_item', name: string } | null } }> }> } | null };

export type LoadMenuItemsQueryVariables = Exact<{
  menuFilter: Menu_Item_Bool_Exp;
}>;


export type LoadMenuItemsQuery = { __typename?: 'query_root', items: Array<{ __typename?: 'menu_item', id: any, description?: string | null, live: boolean, menu_parent: any, name: string, imageUrl?: string | null, created_at?: any | null, active_price_item: { __typename?: 'menu_item_price', id: any, price: number } }> };

export type LoadMenuTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadMenuTreeQuery = { __typename?: 'query_root', menuTrees: Array<{ __typename?: 'menu_tree', id: any, title: string, parent_id?: any | null }> };

export type GetSimpleTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSimpleTablesQuery = { __typename?: 'query_root', tables: Array<{ __typename?: 'seating_table', id: any, name: string }> };

export type CreateOrderFromStaffMutationVariables = Exact<{
  tableSessionId: Scalars['uuid'];
  items: Array<OrderItems> | OrderItems;
}>;


export type CreateOrderFromStaffMutation = { __typename?: 'mutation_root', createStaffOrder: Array<{ __typename?: 'BookingItemsCreated', orderId: any }> };

export type GetSessionOverviewStaticQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionOverviewStaticQuery = { __typename?: 'query_root', orderStatus: Array<{ __typename?: 'booking_order_status', values: string }> };

export type GetSessionOverviewSubscriptionVariables = Exact<{
  sessionId: Scalars['uuid'];
}>;


export type GetSessionOverviewSubscription = { __typename?: 'subscription_root', session?: { __typename?: 'booking_table_sessions', total?: any | null, reference: string, status?: Booking_Table_Sessions_Status_Enum | null, created_at?: any | null, paid?: any | null, table: { __typename?: 'seating_table', name: string } } | null };

export type GetSessionOrdersOverviewSubscriptionVariables = Exact<{
  sessionId: Scalars['uuid'];
}>;


export type GetSessionOrdersOverviewSubscription = { __typename?: 'subscription_root', orders: Array<{ __typename?: 'booking_order', id: any, status: Booking_Order_Status_Enum, total?: any | null, created_at: any, order_items: Array<{ __typename?: 'booking_order_items', id: any, orders_id: any, note?: string | null, computed_total: number, discount: any, order_payment_items_aggregate: { __typename?: 'booking_order_payment_items_aggregate', aggregate?: { __typename?: 'booking_order_payment_items_aggregate_fields', count: number } | null }, extras: Array<{ __typename?: 'booking_order_items_extra', item_extra: { __typename?: 'menu_item_extra', name: string } }>, menu_item_price: { __typename?: 'menu_item_price', price: number, menu_item?: { __typename?: 'menu_item', name: string } | null } }> }> };

export type InsertOrderPaymentMutationVariables = Exact<{
  tableSessionId: Scalars['uuid'];
  total: Scalars['Int'];
  items: Array<Booking_Order_Payment_Items_Insert_Input> | Booking_Order_Payment_Items_Insert_Input;
}>;


export type InsertOrderPaymentMutation = { __typename?: 'mutation_root', insert_booking_order_payment_one?: { __typename?: 'booking_order_payment', id: any } | null };

export type UpdateSessionStatusMutationVariables = Exact<{
  sessionId: Scalars['uuid'];
  status: Booking_Table_Sessions_Status_Enum;
}>;


export type UpdateSessionStatusMutation = { __typename?: 'mutation_root', update_booking_table_sessions_by_pk?: { __typename?: 'booking_table_sessions', id: any } | null };

export type GetCompleteMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompleteMenuQuery = { __typename?: 'query_root', menu: Array<{ __typename?: 'menu_tree', id: any, title: string, parent_id?: any | null, items: Array<{ __typename?: 'menu_item', id: any, name: string, description?: string | null, imageUrl?: string | null, active_price_item: { __typename?: 'menu_item_price', price: number, id: any }, extras: Array<{ __typename?: 'menu_item_extra', id: any, price: number, name: string }> }> }> };

export type GetSessionPaymentsQueryVariables = Exact<{
  sessionId: Scalars['uuid'];
}>;


export type GetSessionPaymentsQuery = { __typename?: 'query_root', session?: { __typename?: 'booking_table_sessions', total?: any | null, session_receipts: Array<{ __typename?: 'booking_session_email_receipt', id: any, email: string, created_at?: any | null }>, payments: Array<{ __typename?: 'booking_order_payment', total: number, refund?: number | null, stripe_payment_id?: string | null, created_at: any, id: any, items_aggregate: { __typename?: 'booking_order_payment_items_aggregate', aggregate?: { __typename?: 'booking_order_payment_items_aggregate_fields', count: number } | null }, receipts: Array<{ __typename?: 'booking_session_email_receipt', id: any, email: string, created_at?: any | null }> }> } | null };

export type SendReceiptMutationVariables = Exact<{
  email: Scalars['String'];
  paymentId?: InputMaybe<Scalars['uuid']>;
  sessionId: Scalars['uuid'];
}>;


export type SendReceiptMutation = { __typename?: 'mutation_root', insert_booking_session_email_receipt_one?: { __typename?: 'booking_session_email_receipt', id: any } | null };

export type UpdateBookingItemDiscountMutationVariables = Exact<{
  itemId: Scalars['uuid'];
  discount: Scalars['smallint'];
}>;


export type UpdateBookingItemDiscountMutation = { __typename?: 'mutation_root', update_booking_order_items_by_pk?: { __typename?: 'booking_order_items', id: any } | null };

export type CreateTableSessionMutationVariables = Exact<{
  tableId: Scalars['uuid'];
}>;


export type CreateTableSessionMutation = { __typename?: 'mutation_root', insert_booking_table_sessions_one?: { __typename?: 'booking_table_sessions', id: any } | null };

export type LoadTableActiveSessionsQueryVariables = Exact<{
  tableId: Scalars['uuid'];
}>;


export type LoadTableActiveSessionsQuery = { __typename?: 'query_root', table?: { __typename?: 'seating_table', name: string, sessions: Array<{ __typename?: 'booking_table_sessions', id: any, created_at?: any | null, total?: any | null, paid?: any | null, orders: Array<{ __typename?: 'booking_order', id: any, status: Booking_Order_Status_Enum }> }> } | null };

export type GetQrCodeIdFromLinkQueryVariables = Exact<{
  link: Scalars['String'];
}>;


export type GetQrCodeIdFromLinkQuery = { __typename?: 'query_root', getQrLinkRealId?: { __typename?: 'GetQrLinkRealIdOutput', qrId: string, tableId?: string | null, tableName?: string | null } | null };

export type UpdateTableQrCodeMutationVariables = Exact<{
  tableId: Scalars['uuid'];
  qrId: Scalars['uuid'];
}>;


export type UpdateTableQrCodeMutation = { __typename?: 'mutation_root', update_seating_qr_by_pk?: { __typename?: 'seating_qr', id: any } | null };

export type ListPrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPrintersQuery = { __typename?: 'query_root', printers: Array<{ __typename?: 'printer', id: any, name: string, online?: any | null, producers: Array<{ __typename?: 'menu_producer', id: any, name: string }> }> };

export type GetQrTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQrTemplatesQuery = { __typename?: 'query_root', qr_template: Array<{ __typename?: 'qr_template', id: any, img: string, name: string, template: any }> };

export type GenerateTableQrCodesMutationVariables = Exact<{
  size?: InputMaybe<Scalars['Int']>;
}>;


export type GenerateTableQrCodesMutation = { __typename?: 'mutation_root', codes: Array<{ __typename?: 'QrData', id: string, qrId: string }> };

export type GetAvailableTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableTablesQuery = { __typename?: 'query_root', rooms: Array<{ __typename?: 'seating_table_room', name: string, id: any, tables: Array<{ __typename?: 'seating_table', name: string, id: any }> }> };

export type GetRestaurantInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRestaurantInfoQuery = { __typename?: 'query_root', restaurants: Array<{ __typename?: 'restaurants', id: any, logoUrl?: string | null, name: string, description?: string | null, address?: string | null, receiptFooter?: string | null }> };

export type UpdateRestaurantMutationVariables = Exact<{
  resturantId: Scalars['uuid'];
  set?: InputMaybe<Restaurants_Set_Input>;
}>;


export type UpdateRestaurantMutation = { __typename?: 'mutation_root', update_restaurants_by_pk?: { __typename?: 'restaurants', id: any } | null };

export type GetSignedUrlQueryVariables = Exact<{
  fileType: Scalars['String'];
}>;


export type GetSignedUrlQuery = { __typename?: 'query_root', signedUrl?: { __typename?: 'SignedUrlOutput', url: string, fields: any } | null };

export type GetStripeAccountLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountLinkMutation = { __typename?: 'mutation_root', accountLink?: { __typename?: 'StripeAccountLink', link: string } | null };

export type UpdateWifiSettingMutationVariables = Exact<{
  wifi: Restaurant_Wifi_Insert_Input;
}>;


export type UpdateWifiSettingMutation = { __typename?: 'mutation_root', insert_restaurant_wifi_one?: { __typename?: 'restaurant_wifi', ssid: string } | null };

export type GetWifiSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWifiSettingQuery = { __typename?: 'query_root', restaurant_wifi: Array<{ __typename?: 'restaurant_wifi', ssid: string, password: string, encryption: string }> };

export type GetStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaffQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'user', email: string, id: any, role: User_Roles_Enum_Enum, created_at?: any | null }>, roles_emum: Array<{ __typename?: 'user_roles_enum', value: string }> };

export type InsertStaffMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type InsertStaffMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: any } | null };

export type UpdateStaffMutationVariables = Exact<{
  userId: Scalars['uuid'];
  updatedFields: User_Set_Input;
}>;


export type UpdateStaffMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: any } | null };

export type DeleteStaffMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type DeleteStaffMutation = { __typename?: 'mutation_root', delete_user_by_pk?: { __typename?: 'user', id: any } | null };

export type GetStaffLoginTokenMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetStaffLoginTokenMutation = { __typename?: 'mutation_root', getStaffLoginToken?: { __typename?: 'StaffLoginToken', token: string } | null };

export type GetRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomsQuery = { __typename?: 'query_root', seating_table_room: Array<{ __typename?: 'seating_table_room', id: any, name: string, created_at: any, tables_aggregate: { __typename?: 'seating_table_aggregate', aggregate?: { __typename?: 'seating_table_aggregate_fields', count: number } | null } }> };

export type UpdateRoomNameMutationVariables = Exact<{
  roomId: Scalars['uuid'];
  roomName: Scalars['String'];
}>;


export type UpdateRoomNameMutation = { __typename?: 'mutation_root', update_seating_table_room_by_pk?: { __typename?: 'seating_table_room', id: any } | null };

export type DeleteRoomByIdMutationVariables = Exact<{
  roomId: Scalars['uuid'];
}>;


export type DeleteRoomByIdMutation = { __typename?: 'mutation_root', delete_seating_table_room_by_pk?: { __typename?: 'seating_table_room', id: any } | null };

export type GetTablePositionsQueryVariables = Exact<{
  roomId: Scalars['uuid'];
}>;


export type GetTablePositionsQuery = { __typename?: 'query_root', room?: { __typename?: 'seating_table_room', tables: Array<{ __typename?: 'seating_table', id: any, name: string, seats?: number | null, table_position?: { __typename?: 'seating_table_position', x: number, y: number } | null }> } | null };

export type UpdateTablePositionMutationVariables = Exact<{
  tablePositions: Array<Seating_Table_Position_Insert_Input> | Seating_Table_Position_Insert_Input;
}>;


export type UpdateTablePositionMutation = { __typename?: 'mutation_root', insert_seating_table_position?: { __typename?: 'seating_table_position_mutation_response', affected_rows: number } | null };

export type InsertTableMutationVariables = Exact<{
  tableData: Seating_Table_Insert_Input;
}>;


export type InsertTableMutation = { __typename?: 'mutation_root', insert_seating_table_one?: { __typename?: 'seating_table', id: any } | null };

export type UpdateTableMutationVariables = Exact<{
  tableId: Scalars['uuid'];
  tableData: Seating_Table_Set_Input;
}>;


export type UpdateTableMutation = { __typename?: 'mutation_root', update_seating_table_by_pk?: { __typename?: 'seating_table', id: any } | null };

export type InsertQrMutationVariables = Exact<{
  tableId: Scalars['uuid'];
  qrId: Scalars['uuid'];
}>;


export type InsertQrMutation = { __typename?: 'mutation_root', insert_seating_qr_one?: { __typename?: 'seating_qr', id: any } | null };

export type GetTableByIdQueryVariables = Exact<{
  tableId: Scalars['uuid'];
}>;


export type GetTableByIdQuery = { __typename?: 'query_root', table?: { __typename?: 'seating_table', id: any, created_at: any, name: string, seats?: number | null, open?: boolean | null, room_id: any } | null };

export type GetTablesQueryVariables = Exact<{
  tablefilter?: InputMaybe<Seating_Table_Bool_Exp>;
  tableOrderBy?: Array<Seating_Table_Order_By> | Seating_Table_Order_By;
}>;


export type GetTablesQuery = { __typename?: 'query_root', seating_table: Array<{ __typename?: 'seating_table', id: any, created_at: any, name: string, seats?: number | null, open?: boolean | null, room_id: any, room: { __typename?: 'seating_table_room', name: string } }> };

export type CreateRoomMutationVariables = Exact<{
  roomName: Scalars['String'];
}>;


export type CreateRoomMutation = { __typename?: 'mutation_root', insert_seating_table_room_one?: { __typename?: 'seating_table_room', id: any } | null };

export type GetTutorialStepsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTutorialStepsQuery = { __typename?: 'query_root', restaurants: Array<{ __typename?: 'restaurants', logoUrl?: string | null }>, table: { __typename?: 'seating_table_aggregate', aggregate?: { __typename?: 'seating_table_aggregate_fields', count: number } | null }, item: { __typename?: 'menu_item_aggregate', aggregate?: { __typename?: 'menu_item_aggregate_fields', count: number } | null }, qr: { __typename?: 'seating_qr_aggregate', aggregate?: { __typename?: 'seating_qr_aggregate_fields', count: number } | null } };

export type CompleteTutorialMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteTutorialMutation = { __typename?: 'mutation_root', update_restaurants?: { __typename?: 'restaurants_mutation_response', affected_rows: number } | null };


export const GetRestaurantCurrencyDocument = gql`
    query GetRestaurantCurrency {
  restaurants {
    country {
      currencySymol
      currencyPrecision
    }
  }
}
    `;

/**
 * __useGetRestaurantCurrencyQuery__
 *
 * To run a query within a React component, call `useGetRestaurantCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRestaurantCurrencyQuery(baseOptions?: Apollo.QueryHookOptions<GetRestaurantCurrencyQuery, GetRestaurantCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRestaurantCurrencyQuery, GetRestaurantCurrencyQueryVariables>(GetRestaurantCurrencyDocument, options);
      }
export function useGetRestaurantCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantCurrencyQuery, GetRestaurantCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRestaurantCurrencyQuery, GetRestaurantCurrencyQueryVariables>(GetRestaurantCurrencyDocument, options);
        }
export type GetRestaurantCurrencyQueryHookResult = ReturnType<typeof useGetRestaurantCurrencyQuery>;
export type GetRestaurantCurrencyLazyQueryHookResult = ReturnType<typeof useGetRestaurantCurrencyLazyQuery>;
export type GetRestaurantCurrencyQueryResult = Apollo.QueryResult<GetRestaurantCurrencyQuery, GetRestaurantCurrencyQueryVariables>;
export const TutorialCheckDocument = gql`
    subscription TutorialCheck {
  restaurants {
    tutorial
    name
    restaurant_stripe {
      namespace
    }
  }
}
    `;

/**
 * __useTutorialCheckSubscription__
 *
 * To run a query within a React component, call `useTutorialCheckSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTutorialCheckSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutorialCheckSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTutorialCheckSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TutorialCheckSubscription, TutorialCheckSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TutorialCheckSubscription, TutorialCheckSubscriptionVariables>(TutorialCheckDocument, options);
      }
export type TutorialCheckSubscriptionHookResult = ReturnType<typeof useTutorialCheckSubscription>;
export type TutorialCheckSubscriptionResult = Apollo.SubscriptionResult<TutorialCheckSubscription>;
export const InsertItemExtraDocument = gql`
    mutation InsertItemExtra($input: menu_item_extra_insert_input!) {
  insert_menu_item_extra_one(object: $input) {
    id
  }
}
    `;
export type InsertItemExtraMutationFn = Apollo.MutationFunction<InsertItemExtraMutation, InsertItemExtraMutationVariables>;

/**
 * __useInsertItemExtraMutation__
 *
 * To run a mutation, you first call `useInsertItemExtraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertItemExtraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertItemExtraMutation, { data, loading, error }] = useInsertItemExtraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertItemExtraMutation(baseOptions?: Apollo.MutationHookOptions<InsertItemExtraMutation, InsertItemExtraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertItemExtraMutation, InsertItemExtraMutationVariables>(InsertItemExtraDocument, options);
      }
export type InsertItemExtraMutationHookResult = ReturnType<typeof useInsertItemExtraMutation>;
export type InsertItemExtraMutationResult = Apollo.MutationResult<InsertItemExtraMutation>;
export type InsertItemExtraMutationOptions = Apollo.BaseMutationOptions<InsertItemExtraMutation, InsertItemExtraMutationVariables>;
export const GetItemExtraDocument = gql`
    query GetItemExtra($itemId: uuid!) {
  menu_item_extra(where: {item_id: {_eq: $itemId}}, order_by: {created_at: desc}) {
    id
    choices
    created_at
    name
    price
  }
}
    `;

/**
 * __useGetItemExtraQuery__
 *
 * To run a query within a React component, call `useGetItemExtraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemExtraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemExtraQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemExtraQuery(baseOptions: Apollo.QueryHookOptions<GetItemExtraQuery, GetItemExtraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemExtraQuery, GetItemExtraQueryVariables>(GetItemExtraDocument, options);
      }
export function useGetItemExtraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemExtraQuery, GetItemExtraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemExtraQuery, GetItemExtraQueryVariables>(GetItemExtraDocument, options);
        }
export type GetItemExtraQueryHookResult = ReturnType<typeof useGetItemExtraQuery>;
export type GetItemExtraLazyQueryHookResult = ReturnType<typeof useGetItemExtraLazyQuery>;
export type GetItemExtraQueryResult = Apollo.QueryResult<GetItemExtraQuery, GetItemExtraQueryVariables>;
export const LoadMenuDetailDocument = gql`
    query LoadMenuDetail($menuFilter: menu_item_bool_exp!) {
  menuTrees: menu_tree {
    id
    title
    parent_id
  }
  items: menu_item(where: $menuFilter) {
    id
    description
    labels
    active_price_item {
      price
    }
    live
    menu_parent
    name
    imageUrl
    created_at
  }
}
    `;

/**
 * __useLoadMenuDetailQuery__
 *
 * To run a query within a React component, call `useLoadMenuDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMenuDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMenuDetailQuery({
 *   variables: {
 *      menuFilter: // value for 'menuFilter'
 *   },
 * });
 */
export function useLoadMenuDetailQuery(baseOptions: Apollo.QueryHookOptions<LoadMenuDetailQuery, LoadMenuDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMenuDetailQuery, LoadMenuDetailQueryVariables>(LoadMenuDetailDocument, options);
      }
export function useLoadMenuDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMenuDetailQuery, LoadMenuDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMenuDetailQuery, LoadMenuDetailQueryVariables>(LoadMenuDetailDocument, options);
        }
export type LoadMenuDetailQueryHookResult = ReturnType<typeof useLoadMenuDetailQuery>;
export type LoadMenuDetailLazyQueryHookResult = ReturnType<typeof useLoadMenuDetailLazyQuery>;
export type LoadMenuDetailQueryResult = Apollo.QueryResult<LoadMenuDetailQuery, LoadMenuDetailQueryVariables>;
export const LoadMenuItemDetailDocument = gql`
    query LoadMenuItemDetail($itemId: uuid!) {
  labels: menu_item_label {
    key
    type
  }
  menuTrees: menu_tree {
    id
    title
    parent_id
  }
  item: menu_item_by_pk(id: $itemId) {
    id
    description
    labels
    active_price_item {
      price
    }
    live
    menu_parent
    name
    imageUrl
    created_at
  }
}
    `;

/**
 * __useLoadMenuItemDetailQuery__
 *
 * To run a query within a React component, call `useLoadMenuItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMenuItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMenuItemDetailQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useLoadMenuItemDetailQuery(baseOptions: Apollo.QueryHookOptions<LoadMenuItemDetailQuery, LoadMenuItemDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMenuItemDetailQuery, LoadMenuItemDetailQueryVariables>(LoadMenuItemDetailDocument, options);
      }
export function useLoadMenuItemDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMenuItemDetailQuery, LoadMenuItemDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMenuItemDetailQuery, LoadMenuItemDetailQueryVariables>(LoadMenuItemDetailDocument, options);
        }
export type LoadMenuItemDetailQueryHookResult = ReturnType<typeof useLoadMenuItemDetailQuery>;
export type LoadMenuItemDetailLazyQueryHookResult = ReturnType<typeof useLoadMenuItemDetailLazyQuery>;
export type LoadMenuItemDetailQueryResult = Apollo.QueryResult<LoadMenuItemDetailQuery, LoadMenuItemDetailQueryVariables>;
export const DeleteMenuItemByPkDocument = gql`
    mutation DeleteMenuItemByPk($id: uuid!) {
  delete_menu_item_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteMenuItemByPkMutationFn = Apollo.MutationFunction<DeleteMenuItemByPkMutation, DeleteMenuItemByPkMutationVariables>;

/**
 * __useDeleteMenuItemByPkMutation__
 *
 * To run a mutation, you first call `useDeleteMenuItemByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuItemByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuItemByPkMutation, { data, loading, error }] = useDeleteMenuItemByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuItemByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuItemByPkMutation, DeleteMenuItemByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuItemByPkMutation, DeleteMenuItemByPkMutationVariables>(DeleteMenuItemByPkDocument, options);
      }
export type DeleteMenuItemByPkMutationHookResult = ReturnType<typeof useDeleteMenuItemByPkMutation>;
export type DeleteMenuItemByPkMutationResult = Apollo.MutationResult<DeleteMenuItemByPkMutation>;
export type DeleteMenuItemByPkMutationOptions = Apollo.BaseMutationOptions<DeleteMenuItemByPkMutation, DeleteMenuItemByPkMutationVariables>;
export const UpsertMenuItemDocument = gql`
    mutation UpsertMenuItem($menuItem: menu_item_insert_input!) {
  insert_menu_item_one(
    object: $menuItem
    on_conflict: {constraint: menu_item_pkey, update_columns: [name, description, live, imageUrl, active_price, labels]}
  ) {
    id
  }
}
    `;
export type UpsertMenuItemMutationFn = Apollo.MutationFunction<UpsertMenuItemMutation, UpsertMenuItemMutationVariables>;

/**
 * __useUpsertMenuItemMutation__
 *
 * To run a mutation, you first call `useUpsertMenuItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMenuItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMenuItemMutation, { data, loading, error }] = useUpsertMenuItemMutation({
 *   variables: {
 *      menuItem: // value for 'menuItem'
 *   },
 * });
 */
export function useUpsertMenuItemMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMenuItemMutation, UpsertMenuItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMenuItemMutation, UpsertMenuItemMutationVariables>(UpsertMenuItemDocument, options);
      }
export type UpsertMenuItemMutationHookResult = ReturnType<typeof useUpsertMenuItemMutation>;
export type UpsertMenuItemMutationResult = Apollo.MutationResult<UpsertMenuItemMutation>;
export type UpsertMenuItemMutationOptions = Apollo.BaseMutationOptions<UpsertMenuItemMutation, UpsertMenuItemMutationVariables>;
export const UpsertMenuItemWithoutPriceDocument = gql`
    mutation UpsertMenuItemWithoutPrice($id: uuid!, $menuItem: menu_item_set_input!) {
  update_menu_item_by_pk(pk_columns: {id: $id}, _set: $menuItem) {
    id
  }
}
    `;
export type UpsertMenuItemWithoutPriceMutationFn = Apollo.MutationFunction<UpsertMenuItemWithoutPriceMutation, UpsertMenuItemWithoutPriceMutationVariables>;

/**
 * __useUpsertMenuItemWithoutPriceMutation__
 *
 * To run a mutation, you first call `useUpsertMenuItemWithoutPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMenuItemWithoutPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMenuItemWithoutPriceMutation, { data, loading, error }] = useUpsertMenuItemWithoutPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      menuItem: // value for 'menuItem'
 *   },
 * });
 */
export function useUpsertMenuItemWithoutPriceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMenuItemWithoutPriceMutation, UpsertMenuItemWithoutPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMenuItemWithoutPriceMutation, UpsertMenuItemWithoutPriceMutationVariables>(UpsertMenuItemWithoutPriceDocument, options);
      }
export type UpsertMenuItemWithoutPriceMutationHookResult = ReturnType<typeof useUpsertMenuItemWithoutPriceMutation>;
export type UpsertMenuItemWithoutPriceMutationResult = Apollo.MutationResult<UpsertMenuItemWithoutPriceMutation>;
export type UpsertMenuItemWithoutPriceMutationOptions = Apollo.BaseMutationOptions<UpsertMenuItemWithoutPriceMutation, UpsertMenuItemWithoutPriceMutationVariables>;
export const UpsertMenuTreeDocument = gql`
    mutation UpsertMenuTree($menu: [menu_tree_insert_input!]!, $allTreeIds: [uuid!]!) {
  insert_menu_tree(
    objects: $menu
    on_conflict: {constraint: menu_tree_pkey, update_columns: [parent_id, title, producer_id]}
  ) {
    affected_rows
  }
  delete_menu_tree(where: {id: {_nin: $allTreeIds}}) {
    affected_rows
  }
}
    `;
export type UpsertMenuTreeMutationFn = Apollo.MutationFunction<UpsertMenuTreeMutation, UpsertMenuTreeMutationVariables>;

/**
 * __useUpsertMenuTreeMutation__
 *
 * To run a mutation, you first call `useUpsertMenuTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMenuTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMenuTreeMutation, { data, loading, error }] = useUpsertMenuTreeMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *      allTreeIds: // value for 'allTreeIds'
 *   },
 * });
 */
export function useUpsertMenuTreeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMenuTreeMutation, UpsertMenuTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMenuTreeMutation, UpsertMenuTreeMutationVariables>(UpsertMenuTreeDocument, options);
      }
export type UpsertMenuTreeMutationHookResult = ReturnType<typeof useUpsertMenuTreeMutation>;
export type UpsertMenuTreeMutationResult = Apollo.MutationResult<UpsertMenuTreeMutation>;
export type UpsertMenuTreeMutationOptions = Apollo.BaseMutationOptions<UpsertMenuTreeMutation, UpsertMenuTreeMutationVariables>;
export const GetMenuTreeDocument = gql`
    query GetMenuTree {
  menu: menu_tree {
    id
    title
    producer_id
    parentId: parent_id
    menu_items_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetMenuTreeQuery__
 *
 * To run a query within a React component, call `useGetMenuTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuTreeQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuTreeQuery, GetMenuTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuTreeQuery, GetMenuTreeQueryVariables>(GetMenuTreeDocument, options);
      }
export function useGetMenuTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuTreeQuery, GetMenuTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuTreeQuery, GetMenuTreeQueryVariables>(GetMenuTreeDocument, options);
        }
export type GetMenuTreeQueryHookResult = ReturnType<typeof useGetMenuTreeQuery>;
export type GetMenuTreeLazyQueryHookResult = ReturnType<typeof useGetMenuTreeLazyQuery>;
export type GetMenuTreeQueryResult = Apollo.QueryResult<GetMenuTreeQuery, GetMenuTreeQueryVariables>;
export const InsertMenuProducerDocument = gql`
    mutation InsertMenuProducer($input: menu_producer_insert_input!) {
  insert_menu_producer_one(object: $input) {
    id
  }
}
    `;
export type InsertMenuProducerMutationFn = Apollo.MutationFunction<InsertMenuProducerMutation, InsertMenuProducerMutationVariables>;

/**
 * __useInsertMenuProducerMutation__
 *
 * To run a mutation, you first call `useInsertMenuProducerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMenuProducerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMenuProducerMutation, { data, loading, error }] = useInsertMenuProducerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertMenuProducerMutation(baseOptions?: Apollo.MutationHookOptions<InsertMenuProducerMutation, InsertMenuProducerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMenuProducerMutation, InsertMenuProducerMutationVariables>(InsertMenuProducerDocument, options);
      }
export type InsertMenuProducerMutationHookResult = ReturnType<typeof useInsertMenuProducerMutation>;
export type InsertMenuProducerMutationResult = Apollo.MutationResult<InsertMenuProducerMutation>;
export type InsertMenuProducerMutationOptions = Apollo.BaseMutationOptions<InsertMenuProducerMutation, InsertMenuProducerMutationVariables>;
export const UpdateMenuProducerDocument = gql`
    mutation UpdateMenuProducer($pk: uuid!, $update: menu_producer_set_input!) {
  update_menu_producer_by_pk(pk_columns: {id: $pk}, _set: $update) {
    id
  }
}
    `;
export type UpdateMenuProducerMutationFn = Apollo.MutationFunction<UpdateMenuProducerMutation, UpdateMenuProducerMutationVariables>;

/**
 * __useUpdateMenuProducerMutation__
 *
 * To run a mutation, you first call `useUpdateMenuProducerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuProducerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuProducerMutation, { data, loading, error }] = useUpdateMenuProducerMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateMenuProducerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuProducerMutation, UpdateMenuProducerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuProducerMutation, UpdateMenuProducerMutationVariables>(UpdateMenuProducerDocument, options);
      }
export type UpdateMenuProducerMutationHookResult = ReturnType<typeof useUpdateMenuProducerMutation>;
export type UpdateMenuProducerMutationResult = Apollo.MutationResult<UpdateMenuProducerMutation>;
export type UpdateMenuProducerMutationOptions = Apollo.BaseMutationOptions<UpdateMenuProducerMutation, UpdateMenuProducerMutationVariables>;
export const GetMenuProducersDocument = gql`
    query GetMenuProducers {
  menu_producer {
    id
    name
    colors
    created_at
  }
}
    `;

/**
 * __useGetMenuProducersQuery__
 *
 * To run a query within a React component, call `useGetMenuProducersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuProducersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuProducersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuProducersQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuProducersQuery, GetMenuProducersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuProducersQuery, GetMenuProducersQueryVariables>(GetMenuProducersDocument, options);
      }
export function useGetMenuProducersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuProducersQuery, GetMenuProducersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuProducersQuery, GetMenuProducersQueryVariables>(GetMenuProducersDocument, options);
        }
export type GetMenuProducersQueryHookResult = ReturnType<typeof useGetMenuProducersQuery>;
export type GetMenuProducersLazyQueryHookResult = ReturnType<typeof useGetMenuProducersLazyQuery>;
export type GetMenuProducersQueryResult = Apollo.QueryResult<GetMenuProducersQuery, GetMenuProducersQueryVariables>;
export const GetSessionHistoryDocument = gql`
    query GetSessionHistory($offset: Int = 0, $createdAtFilter: timestamptz_comparison_exp = {}) {
  sessions: booking_table_sessions(
    order_by: {created_at: desc}
    limit: 10
    offset: $offset
    where: {status: {_eq: closed}, created_at: $createdAtFilter}
  ) {
    table {
      name
    }
    id
    total
    total_paid
    created_at
  }
  sessions_aggregate: booking_table_sessions_aggregate(
    where: {created_at: $createdAtFilter}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSessionHistoryQuery__
 *
 * To run a query within a React component, call `useGetSessionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionHistoryQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      createdAtFilter: // value for 'createdAtFilter'
 *   },
 * });
 */
export function useGetSessionHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionHistoryQuery, GetSessionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionHistoryQuery, GetSessionHistoryQueryVariables>(GetSessionHistoryDocument, options);
      }
export function useGetSessionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionHistoryQuery, GetSessionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionHistoryQuery, GetSessionHistoryQueryVariables>(GetSessionHistoryDocument, options);
        }
export type GetSessionHistoryQueryHookResult = ReturnType<typeof useGetSessionHistoryQuery>;
export type GetSessionHistoryLazyQueryHookResult = ReturnType<typeof useGetSessionHistoryLazyQuery>;
export type GetSessionHistoryQueryResult = Apollo.QueryResult<GetSessionHistoryQuery, GetSessionHistoryQueryVariables>;
export const GetRoomOverviewDocument = gql`
    subscription GetRoomOverview($roomId: uuid!) {
  seating_table_room_by_pk(id: $roomId) {
    tables {
      id
      name
      seats
      table_position {
        x
        y
      }
      table_sessions(where: {status: {_eq: active}}) {
        id
        total
        total_paid
        orders {
          status
        }
      }
    }
  }
}
    `;

/**
 * __useGetRoomOverviewSubscription__
 *
 * To run a query within a React component, call `useGetRoomOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomOverviewSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetRoomOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetRoomOverviewSubscription, GetRoomOverviewSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetRoomOverviewSubscription, GetRoomOverviewSubscriptionVariables>(GetRoomOverviewDocument, options);
      }
export type GetRoomOverviewSubscriptionHookResult = ReturnType<typeof useGetRoomOverviewSubscription>;
export type GetRoomOverviewSubscriptionResult = Apollo.SubscriptionResult<GetRoomOverviewSubscription>;
export const GetPrintersDocument = gql`
    subscription GetPrinters {
  printers: printer {
    id
    name
    online: statusJson(path: "Online")
  }
}
    `;

/**
 * __useGetPrintersSubscription__
 *
 * To run a query within a React component, call `useGetPrintersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPrintersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetPrintersSubscription, GetPrintersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetPrintersSubscription, GetPrintersSubscriptionVariables>(GetPrintersDocument, options);
      }
export type GetPrintersSubscriptionHookResult = ReturnType<typeof useGetPrintersSubscription>;
export type GetPrintersSubscriptionResult = Apollo.SubscriptionResult<GetPrintersSubscription>;
export const GetOrdersDocument = gql`
    subscription GetOrders($orderBy: [booking_order_order_by!] = {}) {
  orders: booking_order(
    order_by: $orderBy
    where: {status: {_nin: [S_050_DELIVERED]}}
  ) {
    created_at
    currency
    id
    paid_at
    created_at
    status
    type
    menu_producer {
      name
    }
    items: order_items_aggregate {
      aggregate {
        count
      }
    }
    order_items {
      id
      note
      extras: order_items_extras {
        item_extra {
          name
        }
      }
      menu_item_price {
        active_menu_item {
          name
        }
      }
    }
    table_session {
      id
      table {
        name
      }
    }
  }
}
    `;

/**
 * __useGetOrdersSubscription__
 *
 * To run a query within a React component, call `useGetOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersSubscription({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetOrdersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetOrdersSubscription, GetOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetOrdersSubscription, GetOrdersSubscriptionVariables>(GetOrdersDocument, options);
      }
export type GetOrdersSubscriptionHookResult = ReturnType<typeof useGetOrdersSubscription>;
export type GetOrdersSubscriptionResult = Apollo.SubscriptionResult<GetOrdersSubscription>;
export const GetOrderFiltersDocument = gql`
    query GetOrderFilters {
  types: booking_order_type {
    values
  }
  statuses: booking_order_status(where: {values: {_neq: "draft"}}) {
    values
  }
}
    `;

/**
 * __useGetOrderFiltersQuery__
 *
 * To run a query within a React component, call `useGetOrderFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>(GetOrderFiltersDocument, options);
      }
export function useGetOrderFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>(GetOrderFiltersDocument, options);
        }
export type GetOrderFiltersQueryHookResult = ReturnType<typeof useGetOrderFiltersQuery>;
export type GetOrderFiltersLazyQueryHookResult = ReturnType<typeof useGetOrderFiltersLazyQuery>;
export type GetOrderFiltersQueryResult = Apollo.QueryResult<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>;
export const StartPreparingOrderDocument = gql`
    mutation StartPreparingOrder($orderId: uuid!) {
  update_booking_order(
    where: {id: {_eq: $orderId}, status: {_eq: S_020_WAITTING}}
    _set: {status: S_030_PREPARING}
  ) {
    affected_rows
  }
}
    `;
export type StartPreparingOrderMutationFn = Apollo.MutationFunction<StartPreparingOrderMutation, StartPreparingOrderMutationVariables>;

/**
 * __useStartPreparingOrderMutation__
 *
 * To run a mutation, you first call `useStartPreparingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPreparingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPreparingOrderMutation, { data, loading, error }] = useStartPreparingOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useStartPreparingOrderMutation(baseOptions?: Apollo.MutationHookOptions<StartPreparingOrderMutation, StartPreparingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPreparingOrderMutation, StartPreparingOrderMutationVariables>(StartPreparingOrderDocument, options);
      }
export type StartPreparingOrderMutationHookResult = ReturnType<typeof useStartPreparingOrderMutation>;
export type StartPreparingOrderMutationResult = Apollo.MutationResult<StartPreparingOrderMutation>;
export type StartPreparingOrderMutationOptions = Apollo.BaseMutationOptions<StartPreparingOrderMutation, StartPreparingOrderMutationVariables>;
export const MarkOrderAsCompleteDocument = gql`
    mutation MarkOrderAsComplete($orderId: uuid!) {
  update_booking_order(
    where: {id: {_eq: $orderId}, status: {_eq: S_030_PREPARING}}
    _set: {status: S_040_COMPLETED}
  ) {
    affected_rows
  }
}
    `;
export type MarkOrderAsCompleteMutationFn = Apollo.MutationFunction<MarkOrderAsCompleteMutation, MarkOrderAsCompleteMutationVariables>;

/**
 * __useMarkOrderAsCompleteMutation__
 *
 * To run a mutation, you first call `useMarkOrderAsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOrderAsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOrderAsCompleteMutation, { data, loading, error }] = useMarkOrderAsCompleteMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMarkOrderAsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<MarkOrderAsCompleteMutation, MarkOrderAsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkOrderAsCompleteMutation, MarkOrderAsCompleteMutationVariables>(MarkOrderAsCompleteDocument, options);
      }
export type MarkOrderAsCompleteMutationHookResult = ReturnType<typeof useMarkOrderAsCompleteMutation>;
export type MarkOrderAsCompleteMutationResult = Apollo.MutationResult<MarkOrderAsCompleteMutation>;
export type MarkOrderAsCompleteMutationOptions = Apollo.BaseMutationOptions<MarkOrderAsCompleteMutation, MarkOrderAsCompleteMutationVariables>;
export const MarkOrderAsDeliveredDocument = gql`
    mutation MarkOrderAsDelivered($orderId: uuid!) {
  update_booking_order(
    where: {id: {_eq: $orderId}, status: {_eq: S_040_COMPLETED}}
    _set: {status: S_050_DELIVERED}
  ) {
    affected_rows
  }
}
    `;
export type MarkOrderAsDeliveredMutationFn = Apollo.MutationFunction<MarkOrderAsDeliveredMutation, MarkOrderAsDeliveredMutationVariables>;

/**
 * __useMarkOrderAsDeliveredMutation__
 *
 * To run a mutation, you first call `useMarkOrderAsDeliveredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOrderAsDeliveredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOrderAsDeliveredMutation, { data, loading, error }] = useMarkOrderAsDeliveredMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMarkOrderAsDeliveredMutation(baseOptions?: Apollo.MutationHookOptions<MarkOrderAsDeliveredMutation, MarkOrderAsDeliveredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkOrderAsDeliveredMutation, MarkOrderAsDeliveredMutationVariables>(MarkOrderAsDeliveredDocument, options);
      }
export type MarkOrderAsDeliveredMutationHookResult = ReturnType<typeof useMarkOrderAsDeliveredMutation>;
export type MarkOrderAsDeliveredMutationResult = Apollo.MutationResult<MarkOrderAsDeliveredMutation>;
export type MarkOrderAsDeliveredMutationOptions = Apollo.BaseMutationOptions<MarkOrderAsDeliveredMutation, MarkOrderAsDeliveredMutationVariables>;
export const OveriteOrderStatusDocument = gql`
    mutation OveriteOrderStatus($orderId: uuid!, $orderStatus: booking_order_status_enum!) {
  update_booking_order(where: {id: {_eq: $orderId}}, _set: {status: $orderStatus}) {
    affected_rows
  }
}
    `;
export type OveriteOrderStatusMutationFn = Apollo.MutationFunction<OveriteOrderStatusMutation, OveriteOrderStatusMutationVariables>;

/**
 * __useOveriteOrderStatusMutation__
 *
 * To run a mutation, you first call `useOveriteOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOveriteOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overiteOrderStatusMutation, { data, loading, error }] = useOveriteOrderStatusMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useOveriteOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<OveriteOrderStatusMutation, OveriteOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OveriteOrderStatusMutation, OveriteOrderStatusMutationVariables>(OveriteOrderStatusDocument, options);
      }
export type OveriteOrderStatusMutationHookResult = ReturnType<typeof useOveriteOrderStatusMutation>;
export type OveriteOrderStatusMutationResult = Apollo.MutationResult<OveriteOrderStatusMutation>;
export type OveriteOrderStatusMutationOptions = Apollo.BaseMutationOptions<OveriteOrderStatusMutation, OveriteOrderStatusMutationVariables>;
export const GetSessionOrdersWithItemsDocument = gql`
    query GetSessionOrdersWithItems($sessionId: uuid!) {
  booking_table_sessions_by_pk(id: $sessionId) {
    total
    orders {
      id
      total
      order_items {
        menu_item_price {
          active_menu_item {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSessionOrdersWithItemsQuery__
 *
 * To run a query within a React component, call `useGetSessionOrdersWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionOrdersWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionOrdersWithItemsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionOrdersWithItemsQuery(baseOptions: Apollo.QueryHookOptions<GetSessionOrdersWithItemsQuery, GetSessionOrdersWithItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionOrdersWithItemsQuery, GetSessionOrdersWithItemsQueryVariables>(GetSessionOrdersWithItemsDocument, options);
      }
export function useGetSessionOrdersWithItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionOrdersWithItemsQuery, GetSessionOrdersWithItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionOrdersWithItemsQuery, GetSessionOrdersWithItemsQueryVariables>(GetSessionOrdersWithItemsDocument, options);
        }
export type GetSessionOrdersWithItemsQueryHookResult = ReturnType<typeof useGetSessionOrdersWithItemsQuery>;
export type GetSessionOrdersWithItemsLazyQueryHookResult = ReturnType<typeof useGetSessionOrdersWithItemsLazyQuery>;
export type GetSessionOrdersWithItemsQueryResult = Apollo.QueryResult<GetSessionOrdersWithItemsQuery, GetSessionOrdersWithItemsQueryVariables>;
export const LoadMenuItemsDocument = gql`
    query LoadMenuItems($menuFilter: menu_item_bool_exp!) {
  items: menu_item(where: $menuFilter) {
    id
    description
    active_price_item {
      id
      price
    }
    live
    menu_parent
    name
    imageUrl
    created_at
  }
}
    `;

/**
 * __useLoadMenuItemsQuery__
 *
 * To run a query within a React component, call `useLoadMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMenuItemsQuery({
 *   variables: {
 *      menuFilter: // value for 'menuFilter'
 *   },
 * });
 */
export function useLoadMenuItemsQuery(baseOptions: Apollo.QueryHookOptions<LoadMenuItemsQuery, LoadMenuItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMenuItemsQuery, LoadMenuItemsQueryVariables>(LoadMenuItemsDocument, options);
      }
export function useLoadMenuItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMenuItemsQuery, LoadMenuItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMenuItemsQuery, LoadMenuItemsQueryVariables>(LoadMenuItemsDocument, options);
        }
export type LoadMenuItemsQueryHookResult = ReturnType<typeof useLoadMenuItemsQuery>;
export type LoadMenuItemsLazyQueryHookResult = ReturnType<typeof useLoadMenuItemsLazyQuery>;
export type LoadMenuItemsQueryResult = Apollo.QueryResult<LoadMenuItemsQuery, LoadMenuItemsQueryVariables>;
export const LoadMenuTreeDocument = gql`
    query LoadMenuTree {
  menuTrees: menu_tree {
    id
    title
    parent_id
  }
}
    `;

/**
 * __useLoadMenuTreeQuery__
 *
 * To run a query within a React component, call `useLoadMenuTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMenuTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMenuTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMenuTreeQuery(baseOptions?: Apollo.QueryHookOptions<LoadMenuTreeQuery, LoadMenuTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadMenuTreeQuery, LoadMenuTreeQueryVariables>(LoadMenuTreeDocument, options);
      }
export function useLoadMenuTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadMenuTreeQuery, LoadMenuTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadMenuTreeQuery, LoadMenuTreeQueryVariables>(LoadMenuTreeDocument, options);
        }
export type LoadMenuTreeQueryHookResult = ReturnType<typeof useLoadMenuTreeQuery>;
export type LoadMenuTreeLazyQueryHookResult = ReturnType<typeof useLoadMenuTreeLazyQuery>;
export type LoadMenuTreeQueryResult = Apollo.QueryResult<LoadMenuTreeQuery, LoadMenuTreeQueryVariables>;
export const GetSimpleTablesDocument = gql`
    query GetSimpleTables {
  tables: seating_table {
    id
    name
  }
}
    `;

/**
 * __useGetSimpleTablesQuery__
 *
 * To run a query within a React component, call `useGetSimpleTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSimpleTablesQuery(baseOptions?: Apollo.QueryHookOptions<GetSimpleTablesQuery, GetSimpleTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimpleTablesQuery, GetSimpleTablesQueryVariables>(GetSimpleTablesDocument, options);
      }
export function useGetSimpleTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimpleTablesQuery, GetSimpleTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimpleTablesQuery, GetSimpleTablesQueryVariables>(GetSimpleTablesDocument, options);
        }
export type GetSimpleTablesQueryHookResult = ReturnType<typeof useGetSimpleTablesQuery>;
export type GetSimpleTablesLazyQueryHookResult = ReturnType<typeof useGetSimpleTablesLazyQuery>;
export type GetSimpleTablesQueryResult = Apollo.QueryResult<GetSimpleTablesQuery, GetSimpleTablesQueryVariables>;
export const CreateOrderFromStaffDocument = gql`
    mutation CreateOrderFromStaff($tableSessionId: uuid!, $items: [OrderItems!]!) {
  createStaffOrder(tableSessionId: $tableSessionId, items: $items) {
    orderId
  }
}
    `;
export type CreateOrderFromStaffMutationFn = Apollo.MutationFunction<CreateOrderFromStaffMutation, CreateOrderFromStaffMutationVariables>;

/**
 * __useCreateOrderFromStaffMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromStaffMutation, { data, loading, error }] = useCreateOrderFromStaffMutation({
 *   variables: {
 *      tableSessionId: // value for 'tableSessionId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreateOrderFromStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderFromStaffMutation, CreateOrderFromStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderFromStaffMutation, CreateOrderFromStaffMutationVariables>(CreateOrderFromStaffDocument, options);
      }
export type CreateOrderFromStaffMutationHookResult = ReturnType<typeof useCreateOrderFromStaffMutation>;
export type CreateOrderFromStaffMutationResult = Apollo.MutationResult<CreateOrderFromStaffMutation>;
export type CreateOrderFromStaffMutationOptions = Apollo.BaseMutationOptions<CreateOrderFromStaffMutation, CreateOrderFromStaffMutationVariables>;
export const GetSessionOverviewStaticDocument = gql`
    query GetSessionOverviewStatic {
  orderStatus: booking_order_status {
    values
  }
}
    `;

/**
 * __useGetSessionOverviewStaticQuery__
 *
 * To run a query within a React component, call `useGetSessionOverviewStaticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionOverviewStaticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionOverviewStaticQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionOverviewStaticQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionOverviewStaticQuery, GetSessionOverviewStaticQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionOverviewStaticQuery, GetSessionOverviewStaticQueryVariables>(GetSessionOverviewStaticDocument, options);
      }
export function useGetSessionOverviewStaticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionOverviewStaticQuery, GetSessionOverviewStaticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionOverviewStaticQuery, GetSessionOverviewStaticQueryVariables>(GetSessionOverviewStaticDocument, options);
        }
export type GetSessionOverviewStaticQueryHookResult = ReturnType<typeof useGetSessionOverviewStaticQuery>;
export type GetSessionOverviewStaticLazyQueryHookResult = ReturnType<typeof useGetSessionOverviewStaticLazyQuery>;
export type GetSessionOverviewStaticQueryResult = Apollo.QueryResult<GetSessionOverviewStaticQuery, GetSessionOverviewStaticQueryVariables>;
export const GetSessionOverviewDocument = gql`
    subscription GetSessionOverview($sessionId: uuid!) {
  session: booking_table_sessions_by_pk(id: $sessionId) {
    total
    paid: total_paid
    reference
    status
    created_at
    table {
      name
    }
  }
}
    `;

/**
 * __useGetSessionOverviewSubscription__
 *
 * To run a query within a React component, call `useGetSessionOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionOverviewSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetSessionOverviewSubscription, GetSessionOverviewSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSessionOverviewSubscription, GetSessionOverviewSubscriptionVariables>(GetSessionOverviewDocument, options);
      }
export type GetSessionOverviewSubscriptionHookResult = ReturnType<typeof useGetSessionOverviewSubscription>;
export type GetSessionOverviewSubscriptionResult = Apollo.SubscriptionResult<GetSessionOverviewSubscription>;
export const GetSessionOrdersOverviewDocument = gql`
    subscription GetSessionOrdersOverview($sessionId: uuid!) {
  orders: booking_order(
    order_by: [{created_at: desc}, {id: desc}]
    where: {table_session_id: {_eq: $sessionId}}
  ) {
    id
    status
    total
    created_at
    order_items {
      id
      orders_id
      note
      computed_total
      discount
      order_payment_items_aggregate {
        aggregate {
          count
        }
      }
      extras: order_items_extras {
        item_extra {
          name
        }
      }
      menu_item_price {
        price
        menu_item: active_menu_item {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetSessionOrdersOverviewSubscription__
 *
 * To run a query within a React component, call `useGetSessionOrdersOverviewSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionOrdersOverviewSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionOrdersOverviewSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionOrdersOverviewSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetSessionOrdersOverviewSubscription, GetSessionOrdersOverviewSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSessionOrdersOverviewSubscription, GetSessionOrdersOverviewSubscriptionVariables>(GetSessionOrdersOverviewDocument, options);
      }
export type GetSessionOrdersOverviewSubscriptionHookResult = ReturnType<typeof useGetSessionOrdersOverviewSubscription>;
export type GetSessionOrdersOverviewSubscriptionResult = Apollo.SubscriptionResult<GetSessionOrdersOverviewSubscription>;
export const InsertOrderPaymentDocument = gql`
    mutation InsertOrderPayment($tableSessionId: uuid!, $total: Int!, $items: [booking_order_payment_items_insert_input!]!) {
  insert_booking_order_payment_one(
    object: {status: paid, total: $total, table_session_id: $tableSessionId, order_payment_items: {data: $items}}
  ) {
    id
  }
}
    `;
export type InsertOrderPaymentMutationFn = Apollo.MutationFunction<InsertOrderPaymentMutation, InsertOrderPaymentMutationVariables>;

/**
 * __useInsertOrderPaymentMutation__
 *
 * To run a mutation, you first call `useInsertOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderPaymentMutation, { data, loading, error }] = useInsertOrderPaymentMutation({
 *   variables: {
 *      tableSessionId: // value for 'tableSessionId'
 *      total: // value for 'total'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useInsertOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InsertOrderPaymentMutation, InsertOrderPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOrderPaymentMutation, InsertOrderPaymentMutationVariables>(InsertOrderPaymentDocument, options);
      }
export type InsertOrderPaymentMutationHookResult = ReturnType<typeof useInsertOrderPaymentMutation>;
export type InsertOrderPaymentMutationResult = Apollo.MutationResult<InsertOrderPaymentMutation>;
export type InsertOrderPaymentMutationOptions = Apollo.BaseMutationOptions<InsertOrderPaymentMutation, InsertOrderPaymentMutationVariables>;
export const UpdateSessionStatusDocument = gql`
    mutation UpdateSessionStatus($sessionId: uuid!, $status: booking_table_sessions_status_enum!) {
  update_booking_table_sessions_by_pk(
    pk_columns: {id: $sessionId}
    _set: {status: $status}
  ) {
    id
  }
}
    `;
export type UpdateSessionStatusMutationFn = Apollo.MutationFunction<UpdateSessionStatusMutation, UpdateSessionStatusMutationVariables>;

/**
 * __useUpdateSessionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSessionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionStatusMutation, { data, loading, error }] = useUpdateSessionStatusMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSessionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionStatusMutation, UpdateSessionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionStatusMutation, UpdateSessionStatusMutationVariables>(UpdateSessionStatusDocument, options);
      }
export type UpdateSessionStatusMutationHookResult = ReturnType<typeof useUpdateSessionStatusMutation>;
export type UpdateSessionStatusMutationResult = Apollo.MutationResult<UpdateSessionStatusMutation>;
export type UpdateSessionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSessionStatusMutation, UpdateSessionStatusMutationVariables>;
export const GetCompleteMenuDocument = gql`
    query GetCompleteMenu {
  menu: menu_tree {
    id
    title
    parent_id
    items: menu_items(where: {live: {_eq: true}}) {
      id
      name
      active_price_item {
        price
        id
      }
      extras: item_extras {
        id
        price
        name
      }
      description
      imageUrl
    }
  }
}
    `;

/**
 * __useGetCompleteMenuQuery__
 *
 * To run a query within a React component, call `useGetCompleteMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompleteMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompleteMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompleteMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetCompleteMenuQuery, GetCompleteMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompleteMenuQuery, GetCompleteMenuQueryVariables>(GetCompleteMenuDocument, options);
      }
export function useGetCompleteMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompleteMenuQuery, GetCompleteMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompleteMenuQuery, GetCompleteMenuQueryVariables>(GetCompleteMenuDocument, options);
        }
export type GetCompleteMenuQueryHookResult = ReturnType<typeof useGetCompleteMenuQuery>;
export type GetCompleteMenuLazyQueryHookResult = ReturnType<typeof useGetCompleteMenuLazyQuery>;
export type GetCompleteMenuQueryResult = Apollo.QueryResult<GetCompleteMenuQuery, GetCompleteMenuQueryVariables>;
export const GetSessionPaymentsDocument = gql`
    query GetSessionPayments($sessionId: uuid!) {
  session: booking_table_sessions_by_pk(id: $sessionId) {
    total
    session_receipts: session_email_receipts(where: {payment_id: {_is_null: true}}) {
      id
      email
      created_at
    }
    payments: order_payments {
      total
      refund
      stripe_payment_id
      created_at
      id
      items_aggregate: order_payment_items_aggregate {
        aggregate {
          count
        }
      }
      receipts: order_payment_receipts {
        id
        email
        created_at
      }
    }
  }
}
    `;

/**
 * __useGetSessionPaymentsQuery__
 *
 * To run a query within a React component, call `useGetSessionPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionPaymentsQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetSessionPaymentsQuery, GetSessionPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionPaymentsQuery, GetSessionPaymentsQueryVariables>(GetSessionPaymentsDocument, options);
      }
export function useGetSessionPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionPaymentsQuery, GetSessionPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionPaymentsQuery, GetSessionPaymentsQueryVariables>(GetSessionPaymentsDocument, options);
        }
export type GetSessionPaymentsQueryHookResult = ReturnType<typeof useGetSessionPaymentsQuery>;
export type GetSessionPaymentsLazyQueryHookResult = ReturnType<typeof useGetSessionPaymentsLazyQuery>;
export type GetSessionPaymentsQueryResult = Apollo.QueryResult<GetSessionPaymentsQuery, GetSessionPaymentsQueryVariables>;
export const SendReceiptDocument = gql`
    mutation SendReceipt($email: String!, $paymentId: uuid, $sessionId: uuid!) {
  insert_booking_session_email_receipt_one(
    object: {email: $email, table_session_id: $sessionId, payment_id: $paymentId}
  ) {
    id
  }
}
    `;
export type SendReceiptMutationFn = Apollo.MutationFunction<SendReceiptMutation, SendReceiptMutationVariables>;

/**
 * __useSendReceiptMutation__
 *
 * To run a mutation, you first call `useSendReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReceiptMutation, { data, loading, error }] = useSendReceiptMutation({
 *   variables: {
 *      email: // value for 'email'
 *      paymentId: // value for 'paymentId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSendReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SendReceiptMutation, SendReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReceiptMutation, SendReceiptMutationVariables>(SendReceiptDocument, options);
      }
export type SendReceiptMutationHookResult = ReturnType<typeof useSendReceiptMutation>;
export type SendReceiptMutationResult = Apollo.MutationResult<SendReceiptMutation>;
export type SendReceiptMutationOptions = Apollo.BaseMutationOptions<SendReceiptMutation, SendReceiptMutationVariables>;
export const UpdateBookingItemDiscountDocument = gql`
    mutation UpdateBookingItemDiscount($itemId: uuid!, $discount: smallint!) {
  update_booking_order_items_by_pk(
    pk_columns: {id: $itemId}
    _set: {discount: $discount}
  ) {
    id
  }
}
    `;
export type UpdateBookingItemDiscountMutationFn = Apollo.MutationFunction<UpdateBookingItemDiscountMutation, UpdateBookingItemDiscountMutationVariables>;

/**
 * __useUpdateBookingItemDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateBookingItemDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingItemDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingItemDiscountMutation, { data, loading, error }] = useUpdateBookingItemDiscountMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUpdateBookingItemDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingItemDiscountMutation, UpdateBookingItemDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingItemDiscountMutation, UpdateBookingItemDiscountMutationVariables>(UpdateBookingItemDiscountDocument, options);
      }
export type UpdateBookingItemDiscountMutationHookResult = ReturnType<typeof useUpdateBookingItemDiscountMutation>;
export type UpdateBookingItemDiscountMutationResult = Apollo.MutationResult<UpdateBookingItemDiscountMutation>;
export type UpdateBookingItemDiscountMutationOptions = Apollo.BaseMutationOptions<UpdateBookingItemDiscountMutation, UpdateBookingItemDiscountMutationVariables>;
export const CreateTableSessionDocument = gql`
    mutation CreateTableSession($tableId: uuid!) {
  insert_booking_table_sessions_one(object: {table_id: $tableId}) {
    id
  }
}
    `;
export type CreateTableSessionMutationFn = Apollo.MutationFunction<CreateTableSessionMutation, CreateTableSessionMutationVariables>;

/**
 * __useCreateTableSessionMutation__
 *
 * To run a mutation, you first call `useCreateTableSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableSessionMutation, { data, loading, error }] = useCreateTableSessionMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useCreateTableSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTableSessionMutation, CreateTableSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTableSessionMutation, CreateTableSessionMutationVariables>(CreateTableSessionDocument, options);
      }
export type CreateTableSessionMutationHookResult = ReturnType<typeof useCreateTableSessionMutation>;
export type CreateTableSessionMutationResult = Apollo.MutationResult<CreateTableSessionMutation>;
export type CreateTableSessionMutationOptions = Apollo.BaseMutationOptions<CreateTableSessionMutation, CreateTableSessionMutationVariables>;
export const LoadTableActiveSessionsDocument = gql`
    query LoadTableActiveSessions($tableId: uuid!) {
  table: seating_table_by_pk(id: $tableId) {
    name
    sessions: table_sessions(
      where: {table_id: {_eq: $tableId}, status: {_eq: active}}
    ) {
      id
      created_at
      total
      paid: total_paid
      orders {
        id
        status
      }
    }
  }
}
    `;

/**
 * __useLoadTableActiveSessionsQuery__
 *
 * To run a query within a React component, call `useLoadTableActiveSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTableActiveSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTableActiveSessionsQuery({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useLoadTableActiveSessionsQuery(baseOptions: Apollo.QueryHookOptions<LoadTableActiveSessionsQuery, LoadTableActiveSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadTableActiveSessionsQuery, LoadTableActiveSessionsQueryVariables>(LoadTableActiveSessionsDocument, options);
      }
export function useLoadTableActiveSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadTableActiveSessionsQuery, LoadTableActiveSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadTableActiveSessionsQuery, LoadTableActiveSessionsQueryVariables>(LoadTableActiveSessionsDocument, options);
        }
export type LoadTableActiveSessionsQueryHookResult = ReturnType<typeof useLoadTableActiveSessionsQuery>;
export type LoadTableActiveSessionsLazyQueryHookResult = ReturnType<typeof useLoadTableActiveSessionsLazyQuery>;
export type LoadTableActiveSessionsQueryResult = Apollo.QueryResult<LoadTableActiveSessionsQuery, LoadTableActiveSessionsQueryVariables>;
export const GetQrCodeIdFromLinkDocument = gql`
    query GetQrCodeIdFromLink($link: String!) {
  getQrLinkRealId(link: $link) {
    qrId
    tableId
    tableName
  }
}
    `;

/**
 * __useGetQrCodeIdFromLinkQuery__
 *
 * To run a query within a React component, call `useGetQrCodeIdFromLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrCodeIdFromLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrCodeIdFromLinkQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useGetQrCodeIdFromLinkQuery(baseOptions: Apollo.QueryHookOptions<GetQrCodeIdFromLinkQuery, GetQrCodeIdFromLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQrCodeIdFromLinkQuery, GetQrCodeIdFromLinkQueryVariables>(GetQrCodeIdFromLinkDocument, options);
      }
export function useGetQrCodeIdFromLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQrCodeIdFromLinkQuery, GetQrCodeIdFromLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQrCodeIdFromLinkQuery, GetQrCodeIdFromLinkQueryVariables>(GetQrCodeIdFromLinkDocument, options);
        }
export type GetQrCodeIdFromLinkQueryHookResult = ReturnType<typeof useGetQrCodeIdFromLinkQuery>;
export type GetQrCodeIdFromLinkLazyQueryHookResult = ReturnType<typeof useGetQrCodeIdFromLinkLazyQuery>;
export type GetQrCodeIdFromLinkQueryResult = Apollo.QueryResult<GetQrCodeIdFromLinkQuery, GetQrCodeIdFromLinkQueryVariables>;
export const UpdateTableQrCodeDocument = gql`
    mutation UpdateTableQrCode($tableId: uuid!, $qrId: uuid!) {
  update_seating_qr_by_pk(_set: {table_id: $tableId}, pk_columns: {id: $qrId}) {
    id
  }
}
    `;
export type UpdateTableQrCodeMutationFn = Apollo.MutationFunction<UpdateTableQrCodeMutation, UpdateTableQrCodeMutationVariables>;

/**
 * __useUpdateTableQrCodeMutation__
 *
 * To run a mutation, you first call `useUpdateTableQrCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableQrCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableQrCodeMutation, { data, loading, error }] = useUpdateTableQrCodeMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      qrId: // value for 'qrId'
 *   },
 * });
 */
export function useUpdateTableQrCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTableQrCodeMutation, UpdateTableQrCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTableQrCodeMutation, UpdateTableQrCodeMutationVariables>(UpdateTableQrCodeDocument, options);
      }
export type UpdateTableQrCodeMutationHookResult = ReturnType<typeof useUpdateTableQrCodeMutation>;
export type UpdateTableQrCodeMutationResult = Apollo.MutationResult<UpdateTableQrCodeMutation>;
export type UpdateTableQrCodeMutationOptions = Apollo.BaseMutationOptions<UpdateTableQrCodeMutation, UpdateTableQrCodeMutationVariables>;
export const ListPrintersDocument = gql`
    query ListPrinters {
  printers: printer {
    id
    name
    online: statusJson(path: "Online")
    producers {
      id
      name
    }
  }
}
    `;

/**
 * __useListPrintersQuery__
 *
 * To run a query within a React component, call `useListPrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrintersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPrintersQuery(baseOptions?: Apollo.QueryHookOptions<ListPrintersQuery, ListPrintersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPrintersQuery, ListPrintersQueryVariables>(ListPrintersDocument, options);
      }
export function useListPrintersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPrintersQuery, ListPrintersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPrintersQuery, ListPrintersQueryVariables>(ListPrintersDocument, options);
        }
export type ListPrintersQueryHookResult = ReturnType<typeof useListPrintersQuery>;
export type ListPrintersLazyQueryHookResult = ReturnType<typeof useListPrintersLazyQuery>;
export type ListPrintersQueryResult = Apollo.QueryResult<ListPrintersQuery, ListPrintersQueryVariables>;
export const GetQrTemplatesDocument = gql`
    query GetQrTemplates {
  qr_template(order_by: {order: asc}) {
    id
    img
    name
    template
  }
}
    `;

/**
 * __useGetQrTemplatesQuery__
 *
 * To run a query within a React component, call `useGetQrTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQrTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetQrTemplatesQuery, GetQrTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQrTemplatesQuery, GetQrTemplatesQueryVariables>(GetQrTemplatesDocument, options);
      }
export function useGetQrTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQrTemplatesQuery, GetQrTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQrTemplatesQuery, GetQrTemplatesQueryVariables>(GetQrTemplatesDocument, options);
        }
export type GetQrTemplatesQueryHookResult = ReturnType<typeof useGetQrTemplatesQuery>;
export type GetQrTemplatesLazyQueryHookResult = ReturnType<typeof useGetQrTemplatesLazyQuery>;
export type GetQrTemplatesQueryResult = Apollo.QueryResult<GetQrTemplatesQuery, GetQrTemplatesQueryVariables>;
export const GenerateTableQrCodesDocument = gql`
    mutation GenerateTableQrCodes($size: Int = 21) {
  codes: generateTablesQrCodes(size: $size) {
    id
    qrId
  }
}
    `;
export type GenerateTableQrCodesMutationFn = Apollo.MutationFunction<GenerateTableQrCodesMutation, GenerateTableQrCodesMutationVariables>;

/**
 * __useGenerateTableQrCodesMutation__
 *
 * To run a mutation, you first call `useGenerateTableQrCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTableQrCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTableQrCodesMutation, { data, loading, error }] = useGenerateTableQrCodesMutation({
 *   variables: {
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGenerateTableQrCodesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTableQrCodesMutation, GenerateTableQrCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTableQrCodesMutation, GenerateTableQrCodesMutationVariables>(GenerateTableQrCodesDocument, options);
      }
export type GenerateTableQrCodesMutationHookResult = ReturnType<typeof useGenerateTableQrCodesMutation>;
export type GenerateTableQrCodesMutationResult = Apollo.MutationResult<GenerateTableQrCodesMutation>;
export type GenerateTableQrCodesMutationOptions = Apollo.BaseMutationOptions<GenerateTableQrCodesMutation, GenerateTableQrCodesMutationVariables>;
export const GetAvailableTablesDocument = gql`
    query GetAvailableTables {
  rooms: seating_table_room {
    name
    id
    tables {
      name
      id
    }
  }
}
    `;

/**
 * __useGetAvailableTablesQuery__
 *
 * To run a query within a React component, call `useGetAvailableTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableTablesQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableTablesQuery, GetAvailableTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableTablesQuery, GetAvailableTablesQueryVariables>(GetAvailableTablesDocument, options);
      }
export function useGetAvailableTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTablesQuery, GetAvailableTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableTablesQuery, GetAvailableTablesQueryVariables>(GetAvailableTablesDocument, options);
        }
export type GetAvailableTablesQueryHookResult = ReturnType<typeof useGetAvailableTablesQuery>;
export type GetAvailableTablesLazyQueryHookResult = ReturnType<typeof useGetAvailableTablesLazyQuery>;
export type GetAvailableTablesQueryResult = Apollo.QueryResult<GetAvailableTablesQuery, GetAvailableTablesQueryVariables>;
export const GetRestaurantInfoDocument = gql`
    query GetRestaurantInfo {
  restaurants {
    id
    logoUrl
    name
    description
    address
    receiptFooter
  }
}
    `;

/**
 * __useGetRestaurantInfoQuery__
 *
 * To run a query within a React component, call `useGetRestaurantInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRestaurantInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetRestaurantInfoQuery, GetRestaurantInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRestaurantInfoQuery, GetRestaurantInfoQueryVariables>(GetRestaurantInfoDocument, options);
      }
export function useGetRestaurantInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantInfoQuery, GetRestaurantInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRestaurantInfoQuery, GetRestaurantInfoQueryVariables>(GetRestaurantInfoDocument, options);
        }
export type GetRestaurantInfoQueryHookResult = ReturnType<typeof useGetRestaurantInfoQuery>;
export type GetRestaurantInfoLazyQueryHookResult = ReturnType<typeof useGetRestaurantInfoLazyQuery>;
export type GetRestaurantInfoQueryResult = Apollo.QueryResult<GetRestaurantInfoQuery, GetRestaurantInfoQueryVariables>;
export const UpdateRestaurantDocument = gql`
    mutation UpdateRestaurant($resturantId: uuid!, $set: restaurants_set_input) {
  update_restaurants_by_pk(pk_columns: {id: $resturantId}, _set: $set) {
    id
  }
}
    `;
export type UpdateRestaurantMutationFn = Apollo.MutationFunction<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>;

/**
 * __useUpdateRestaurantMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantMutation, { data, loading, error }] = useUpdateRestaurantMutation({
 *   variables: {
 *      resturantId: // value for 'resturantId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>(UpdateRestaurantDocument, options);
      }
export type UpdateRestaurantMutationHookResult = ReturnType<typeof useUpdateRestaurantMutation>;
export type UpdateRestaurantMutationResult = Apollo.MutationResult<UpdateRestaurantMutation>;
export type UpdateRestaurantMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>;
export const GetSignedUrlDocument = gql`
    query getSignedUrl($fileType: String!) {
  signedUrl: getSignedUrl(fileType: $fileType) {
    url
    fields
  }
}
    `;

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetSignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
      }
export function useGetSignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, options);
        }
export type GetSignedUrlQueryHookResult = ReturnType<typeof useGetSignedUrlQuery>;
export type GetSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUrlLazyQuery>;
export type GetSignedUrlQueryResult = Apollo.QueryResult<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
export const GetStripeAccountLinkDocument = gql`
    mutation getStripeAccountLink {
  accountLink: getStripeAccountLink {
    link
  }
}
    `;
export type GetStripeAccountLinkMutationFn = Apollo.MutationFunction<GetStripeAccountLinkMutation, GetStripeAccountLinkMutationVariables>;

/**
 * __useGetStripeAccountLinkMutation__
 *
 * To run a mutation, you first call `useGetStripeAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStripeAccountLinkMutation, { data, loading, error }] = useGetStripeAccountLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetStripeAccountLinkMutation, GetStripeAccountLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStripeAccountLinkMutation, GetStripeAccountLinkMutationVariables>(GetStripeAccountLinkDocument, options);
      }
export type GetStripeAccountLinkMutationHookResult = ReturnType<typeof useGetStripeAccountLinkMutation>;
export type GetStripeAccountLinkMutationResult = Apollo.MutationResult<GetStripeAccountLinkMutation>;
export type GetStripeAccountLinkMutationOptions = Apollo.BaseMutationOptions<GetStripeAccountLinkMutation, GetStripeAccountLinkMutationVariables>;
export const UpdateWifiSettingDocument = gql`
    mutation UpdateWifiSetting($wifi: restaurant_wifi_insert_input!) {
  insert_restaurant_wifi_one(
    object: $wifi
    on_conflict: {constraint: restaurant_wifi_pkey, update_columns: [ssid, password, encryption]}
  ) {
    ssid
  }
}
    `;
export type UpdateWifiSettingMutationFn = Apollo.MutationFunction<UpdateWifiSettingMutation, UpdateWifiSettingMutationVariables>;

/**
 * __useUpdateWifiSettingMutation__
 *
 * To run a mutation, you first call `useUpdateWifiSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWifiSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWifiSettingMutation, { data, loading, error }] = useUpdateWifiSettingMutation({
 *   variables: {
 *      wifi: // value for 'wifi'
 *   },
 * });
 */
export function useUpdateWifiSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWifiSettingMutation, UpdateWifiSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWifiSettingMutation, UpdateWifiSettingMutationVariables>(UpdateWifiSettingDocument, options);
      }
export type UpdateWifiSettingMutationHookResult = ReturnType<typeof useUpdateWifiSettingMutation>;
export type UpdateWifiSettingMutationResult = Apollo.MutationResult<UpdateWifiSettingMutation>;
export type UpdateWifiSettingMutationOptions = Apollo.BaseMutationOptions<UpdateWifiSettingMutation, UpdateWifiSettingMutationVariables>;
export const GetWifiSettingDocument = gql`
    query GetWifiSetting {
  restaurant_wifi {
    ssid
    password
    encryption
  }
}
    `;

/**
 * __useGetWifiSettingQuery__
 *
 * To run a query within a React component, call `useGetWifiSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWifiSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWifiSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWifiSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetWifiSettingQuery, GetWifiSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWifiSettingQuery, GetWifiSettingQueryVariables>(GetWifiSettingDocument, options);
      }
export function useGetWifiSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWifiSettingQuery, GetWifiSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWifiSettingQuery, GetWifiSettingQueryVariables>(GetWifiSettingDocument, options);
        }
export type GetWifiSettingQueryHookResult = ReturnType<typeof useGetWifiSettingQuery>;
export type GetWifiSettingLazyQueryHookResult = ReturnType<typeof useGetWifiSettingLazyQuery>;
export type GetWifiSettingQueryResult = Apollo.QueryResult<GetWifiSettingQuery, GetWifiSettingQueryVariables>;
export const GetStaffDocument = gql`
    query GetStaff {
  users: user {
    email
    id
    role
    created_at
  }
  roles_emum: user_roles_enum {
    value
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions?: Apollo.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
      }
export function useGetStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffQueryResult = Apollo.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const InsertStaffDocument = gql`
    mutation InsertStaff($email: String!) {
  insert_user_one(object: {email: $email}) {
    id
  }
}
    `;
export type InsertStaffMutationFn = Apollo.MutationFunction<InsertStaffMutation, InsertStaffMutationVariables>;

/**
 * __useInsertStaffMutation__
 *
 * To run a mutation, you first call `useInsertStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStaffMutation, { data, loading, error }] = useInsertStaffMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsertStaffMutation(baseOptions?: Apollo.MutationHookOptions<InsertStaffMutation, InsertStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertStaffMutation, InsertStaffMutationVariables>(InsertStaffDocument, options);
      }
export type InsertStaffMutationHookResult = ReturnType<typeof useInsertStaffMutation>;
export type InsertStaffMutationResult = Apollo.MutationResult<InsertStaffMutation>;
export type InsertStaffMutationOptions = Apollo.BaseMutationOptions<InsertStaffMutation, InsertStaffMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation UpdateStaff($userId: uuid!, $updatedFields: user_set_input!) {
  update_user_by_pk(pk_columns: {id: $userId}, _set: $updatedFields) {
    id
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      updatedFields: // value for 'updatedFields'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation DeleteStaff($userId: uuid!) {
  delete_user_by_pk(id: $userId) {
    id
  }
}
    `;
export type DeleteStaffMutationFn = Apollo.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const GetStaffLoginTokenDocument = gql`
    mutation getStaffLoginToken($userId: uuid!) {
  getStaffLoginToken(userId: $userId) {
    token
  }
}
    `;
export type GetStaffLoginTokenMutationFn = Apollo.MutationFunction<GetStaffLoginTokenMutation, GetStaffLoginTokenMutationVariables>;

/**
 * __useGetStaffLoginTokenMutation__
 *
 * To run a mutation, you first call `useGetStaffLoginTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStaffLoginTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStaffLoginTokenMutation, { data, loading, error }] = useGetStaffLoginTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetStaffLoginTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetStaffLoginTokenMutation, GetStaffLoginTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStaffLoginTokenMutation, GetStaffLoginTokenMutationVariables>(GetStaffLoginTokenDocument, options);
      }
export type GetStaffLoginTokenMutationHookResult = ReturnType<typeof useGetStaffLoginTokenMutation>;
export type GetStaffLoginTokenMutationResult = Apollo.MutationResult<GetStaffLoginTokenMutation>;
export type GetStaffLoginTokenMutationOptions = Apollo.BaseMutationOptions<GetStaffLoginTokenMutation, GetStaffLoginTokenMutationVariables>;
export const GetRoomsDocument = gql`
    query GetRooms {
  seating_table_room {
    id
    name
    created_at
    tables_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetRoomsQuery__
 *
 * To run a query within a React component, call `useGetRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetRoomsQuery, GetRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomsQuery, GetRoomsQueryVariables>(GetRoomsDocument, options);
      }
export function useGetRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomsQuery, GetRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomsQuery, GetRoomsQueryVariables>(GetRoomsDocument, options);
        }
export type GetRoomsQueryHookResult = ReturnType<typeof useGetRoomsQuery>;
export type GetRoomsLazyQueryHookResult = ReturnType<typeof useGetRoomsLazyQuery>;
export type GetRoomsQueryResult = Apollo.QueryResult<GetRoomsQuery, GetRoomsQueryVariables>;
export const UpdateRoomNameDocument = gql`
    mutation UpdateRoomName($roomId: uuid!, $roomName: String!) {
  update_seating_table_room_by_pk(
    pk_columns: {id: $roomId}
    _set: {name: $roomName}
  ) {
    id
  }
}
    `;
export type UpdateRoomNameMutationFn = Apollo.MutationFunction<UpdateRoomNameMutation, UpdateRoomNameMutationVariables>;

/**
 * __useUpdateRoomNameMutation__
 *
 * To run a mutation, you first call `useUpdateRoomNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomNameMutation, { data, loading, error }] = useUpdateRoomNameMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      roomName: // value for 'roomName'
 *   },
 * });
 */
export function useUpdateRoomNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomNameMutation, UpdateRoomNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomNameMutation, UpdateRoomNameMutationVariables>(UpdateRoomNameDocument, options);
      }
export type UpdateRoomNameMutationHookResult = ReturnType<typeof useUpdateRoomNameMutation>;
export type UpdateRoomNameMutationResult = Apollo.MutationResult<UpdateRoomNameMutation>;
export type UpdateRoomNameMutationOptions = Apollo.BaseMutationOptions<UpdateRoomNameMutation, UpdateRoomNameMutationVariables>;
export const DeleteRoomByIdDocument = gql`
    mutation DeleteRoomById($roomId: uuid!) {
  delete_seating_table_room_by_pk(id: $roomId) {
    id
  }
}
    `;
export type DeleteRoomByIdMutationFn = Apollo.MutationFunction<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>;

/**
 * __useDeleteRoomByIdMutation__
 *
 * To run a mutation, you first call `useDeleteRoomByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomByIdMutation, { data, loading, error }] = useDeleteRoomByIdMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useDeleteRoomByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>(DeleteRoomByIdDocument, options);
      }
export type DeleteRoomByIdMutationHookResult = ReturnType<typeof useDeleteRoomByIdMutation>;
export type DeleteRoomByIdMutationResult = Apollo.MutationResult<DeleteRoomByIdMutation>;
export type DeleteRoomByIdMutationOptions = Apollo.BaseMutationOptions<DeleteRoomByIdMutation, DeleteRoomByIdMutationVariables>;
export const GetTablePositionsDocument = gql`
    query GetTablePositions($roomId: uuid!) {
  room: seating_table_room_by_pk(id: $roomId) {
    tables {
      id
      name
      seats
      table_position {
        x
        y
      }
    }
  }
}
    `;

/**
 * __useGetTablePositionsQuery__
 *
 * To run a query within a React component, call `useGetTablePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablePositionsQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetTablePositionsQuery(baseOptions: Apollo.QueryHookOptions<GetTablePositionsQuery, GetTablePositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTablePositionsQuery, GetTablePositionsQueryVariables>(GetTablePositionsDocument, options);
      }
export function useGetTablePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTablePositionsQuery, GetTablePositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTablePositionsQuery, GetTablePositionsQueryVariables>(GetTablePositionsDocument, options);
        }
export type GetTablePositionsQueryHookResult = ReturnType<typeof useGetTablePositionsQuery>;
export type GetTablePositionsLazyQueryHookResult = ReturnType<typeof useGetTablePositionsLazyQuery>;
export type GetTablePositionsQueryResult = Apollo.QueryResult<GetTablePositionsQuery, GetTablePositionsQueryVariables>;
export const UpdateTablePositionDocument = gql`
    mutation UpdateTablePosition($tablePositions: [seating_table_position_insert_input!]!) {
  insert_seating_table_position(
    on_conflict: {constraint: table_position_pkey, update_columns: [x, y]}
    objects: $tablePositions
  ) {
    affected_rows
  }
}
    `;
export type UpdateTablePositionMutationFn = Apollo.MutationFunction<UpdateTablePositionMutation, UpdateTablePositionMutationVariables>;

/**
 * __useUpdateTablePositionMutation__
 *
 * To run a mutation, you first call `useUpdateTablePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTablePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTablePositionMutation, { data, loading, error }] = useUpdateTablePositionMutation({
 *   variables: {
 *      tablePositions: // value for 'tablePositions'
 *   },
 * });
 */
export function useUpdateTablePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTablePositionMutation, UpdateTablePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTablePositionMutation, UpdateTablePositionMutationVariables>(UpdateTablePositionDocument, options);
      }
export type UpdateTablePositionMutationHookResult = ReturnType<typeof useUpdateTablePositionMutation>;
export type UpdateTablePositionMutationResult = Apollo.MutationResult<UpdateTablePositionMutation>;
export type UpdateTablePositionMutationOptions = Apollo.BaseMutationOptions<UpdateTablePositionMutation, UpdateTablePositionMutationVariables>;
export const InsertTableDocument = gql`
    mutation insertTable($tableData: seating_table_insert_input!) {
  insert_seating_table_one(object: $tableData) {
    id
  }
}
    `;
export type InsertTableMutationFn = Apollo.MutationFunction<InsertTableMutation, InsertTableMutationVariables>;

/**
 * __useInsertTableMutation__
 *
 * To run a mutation, you first call `useInsertTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTableMutation, { data, loading, error }] = useInsertTableMutation({
 *   variables: {
 *      tableData: // value for 'tableData'
 *   },
 * });
 */
export function useInsertTableMutation(baseOptions?: Apollo.MutationHookOptions<InsertTableMutation, InsertTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTableMutation, InsertTableMutationVariables>(InsertTableDocument, options);
      }
export type InsertTableMutationHookResult = ReturnType<typeof useInsertTableMutation>;
export type InsertTableMutationResult = Apollo.MutationResult<InsertTableMutation>;
export type InsertTableMutationOptions = Apollo.BaseMutationOptions<InsertTableMutation, InsertTableMutationVariables>;
export const UpdateTableDocument = gql`
    mutation updateTable($tableId: uuid!, $tableData: seating_table_set_input!) {
  update_seating_table_by_pk(pk_columns: {id: $tableId}, _set: $tableData) {
    id
  }
}
    `;
export type UpdateTableMutationFn = Apollo.MutationFunction<UpdateTableMutation, UpdateTableMutationVariables>;

/**
 * __useUpdateTableMutation__
 *
 * To run a mutation, you first call `useUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableMutation, { data, loading, error }] = useUpdateTableMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      tableData: // value for 'tableData'
 *   },
 * });
 */
export function useUpdateTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTableMutation, UpdateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTableMutation, UpdateTableMutationVariables>(UpdateTableDocument, options);
      }
export type UpdateTableMutationHookResult = ReturnType<typeof useUpdateTableMutation>;
export type UpdateTableMutationResult = Apollo.MutationResult<UpdateTableMutation>;
export type UpdateTableMutationOptions = Apollo.BaseMutationOptions<UpdateTableMutation, UpdateTableMutationVariables>;
export const InsertQrDocument = gql`
    mutation insertQr($tableId: uuid!, $qrId: uuid!) {
  insert_seating_qr_one(object: {table_id: $tableId, id: $qrId}) {
    id
  }
}
    `;
export type InsertQrMutationFn = Apollo.MutationFunction<InsertQrMutation, InsertQrMutationVariables>;

/**
 * __useInsertQrMutation__
 *
 * To run a mutation, you first call `useInsertQrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQrMutation, { data, loading, error }] = useInsertQrMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      qrId: // value for 'qrId'
 *   },
 * });
 */
export function useInsertQrMutation(baseOptions?: Apollo.MutationHookOptions<InsertQrMutation, InsertQrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertQrMutation, InsertQrMutationVariables>(InsertQrDocument, options);
      }
export type InsertQrMutationHookResult = ReturnType<typeof useInsertQrMutation>;
export type InsertQrMutationResult = Apollo.MutationResult<InsertQrMutation>;
export type InsertQrMutationOptions = Apollo.BaseMutationOptions<InsertQrMutation, InsertQrMutationVariables>;
export const GetTableByIdDocument = gql`
    query GetTableById($tableId: uuid!) {
  table: seating_table_by_pk(id: $tableId) {
    id
    created_at
    name
    seats
    open
    room_id
  }
}
    `;

/**
 * __useGetTableByIdQuery__
 *
 * To run a query within a React component, call `useGetTableByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableByIdQuery({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useGetTableByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTableByIdQuery, GetTableByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableByIdQuery, GetTableByIdQueryVariables>(GetTableByIdDocument, options);
      }
export function useGetTableByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableByIdQuery, GetTableByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableByIdQuery, GetTableByIdQueryVariables>(GetTableByIdDocument, options);
        }
export type GetTableByIdQueryHookResult = ReturnType<typeof useGetTableByIdQuery>;
export type GetTableByIdLazyQueryHookResult = ReturnType<typeof useGetTableByIdLazyQuery>;
export type GetTableByIdQueryResult = Apollo.QueryResult<GetTableByIdQuery, GetTableByIdQueryVariables>;
export const GetTablesDocument = gql`
    query GetTables($tablefilter: seating_table_bool_exp = {}, $tableOrderBy: [seating_table_order_by!]! = {}) {
  seating_table(where: $tablefilter, order_by: $tableOrderBy) {
    id
    created_at
    name
    seats
    open
    room_id
    room: table_room {
      name
    }
  }
}
    `;

/**
 * __useGetTablesQuery__
 *
 * To run a query within a React component, call `useGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesQuery({
 *   variables: {
 *      tablefilter: // value for 'tablefilter'
 *      tableOrderBy: // value for 'tableOrderBy'
 *   },
 * });
 */
export function useGetTablesQuery(baseOptions?: Apollo.QueryHookOptions<GetTablesQuery, GetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options);
      }
export function useGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTablesQuery, GetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTablesQuery, GetTablesQueryVariables>(GetTablesDocument, options);
        }
export type GetTablesQueryHookResult = ReturnType<typeof useGetTablesQuery>;
export type GetTablesLazyQueryHookResult = ReturnType<typeof useGetTablesLazyQuery>;
export type GetTablesQueryResult = Apollo.QueryResult<GetTablesQuery, GetTablesQueryVariables>;
export const CreateRoomDocument = gql`
    mutation CreateRoom($roomName: String!) {
  insert_seating_table_room_one(object: {name: $roomName}) {
    id
  }
}
    `;
export type CreateRoomMutationFn = Apollo.MutationFunction<CreateRoomMutation, CreateRoomMutationVariables>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      roomName: // value for 'roomName'
 *   },
 * });
 */
export function useCreateRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomMutation, CreateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument, options);
      }
export type CreateRoomMutationHookResult = ReturnType<typeof useCreateRoomMutation>;
export type CreateRoomMutationResult = Apollo.MutationResult<CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<CreateRoomMutation, CreateRoomMutationVariables>;
export const GetTutorialStepsDocument = gql`
    query GetTutorialSteps {
  restaurants {
    logoUrl
  }
  table: seating_table_aggregate {
    aggregate {
      count
    }
  }
  item: menu_item_aggregate {
    aggregate {
      count
    }
  }
  qr: seating_qr_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTutorialStepsQuery__
 *
 * To run a query within a React component, call `useGetTutorialStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTutorialStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTutorialStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTutorialStepsQuery(baseOptions?: Apollo.QueryHookOptions<GetTutorialStepsQuery, GetTutorialStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTutorialStepsQuery, GetTutorialStepsQueryVariables>(GetTutorialStepsDocument, options);
      }
export function useGetTutorialStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTutorialStepsQuery, GetTutorialStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTutorialStepsQuery, GetTutorialStepsQueryVariables>(GetTutorialStepsDocument, options);
        }
export type GetTutorialStepsQueryHookResult = ReturnType<typeof useGetTutorialStepsQuery>;
export type GetTutorialStepsLazyQueryHookResult = ReturnType<typeof useGetTutorialStepsLazyQuery>;
export type GetTutorialStepsQueryResult = Apollo.QueryResult<GetTutorialStepsQuery, GetTutorialStepsQueryVariables>;
export const CompleteTutorialDocument = gql`
    mutation CompleteTutorial {
  update_restaurants(_set: {tutorial: false}, where: {}) {
    affected_rows
  }
}
    `;
export type CompleteTutorialMutationFn = Apollo.MutationFunction<CompleteTutorialMutation, CompleteTutorialMutationVariables>;

/**
 * __useCompleteTutorialMutation__
 *
 * To run a mutation, you first call `useCompleteTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTutorialMutation, { data, loading, error }] = useCompleteTutorialMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteTutorialMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTutorialMutation, CompleteTutorialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTutorialMutation, CompleteTutorialMutationVariables>(CompleteTutorialDocument, options);
      }
export type CompleteTutorialMutationHookResult = ReturnType<typeof useCompleteTutorialMutation>;
export type CompleteTutorialMutationResult = Apollo.MutationResult<CompleteTutorialMutation>;
export type CompleteTutorialMutationOptions = Apollo.BaseMutationOptions<CompleteTutorialMutation, CompleteTutorialMutationVariables>;