import { PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Table as TableAntd } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AlignerComponent } from '../../components/layout/Aligner';
import { LoaderPage } from '../../components/utils/loader';
import { useGetTablesQuery, Seating_Table, useGetRoomsQuery, Seating_Table_Bool_Exp, Seating_Table_Order_By, Order_By} from '../../generated/graphql';

export function Tables() {

    const {data, loading, refetch: refetchTable} = useGetTablesQuery({
      fetchPolicy: 'network-only'
    });
    const {data: rooms, loading: roomLoading} = useGetRoomsQuery();

    const history = useHistory();

    if (roomLoading) {
        return (<LoaderPage />);
    }

    if (rooms?.seating_table_room.length === 0) {

      return (
        <AlignerComponent>
          <Alert 
            type="warning"
            showIcon
            description={<>
                <p>You should create a room before creating a table</p>
                <Link to="/tables/rooms"><Button>Create a room</Button></Link>

            </>}
          />
        </AlignerComponent>
      )
    }

    
    const columns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },
        {
          title: 'Seats',
          dataIndex: 'seats',
          key: 'seats',
          sorter: true,
        },
        {
          title: 'room',
          dataIndex: 'room',
          key: 'room',
          filters: rooms?.seating_table_room.map((room) => ({text: room.name, value: room.id})),
          render: (room: {name: string}) => <span>{room.name}</span>,
        },
        // {
        //   title: 'open',
        //   dataIndex: 'open',
        //   key: 'open',
        //   render: (isOpen: boolean) => <span><Checkbox disabled checked={isOpen} /></span>,
        // },
        // {
        //     title: 'qrcodes',
        //     render: (_ : void, table: any) => <span>{table.qrs_aggregate.aggregate?.count}</span>,
        // },

        {
            title: 'created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (d: string) => <span>{(new Date(d)).toLocaleDateString()}</span>,
            sorter: true,
        },
    ];
    

  return (
    <>

        {/* <TableRoomManagement 
            refetchRooms={refetch}
            rooms={rooms!}
          /> */}




            <div className='flex justify-between m-4'>
              <h1 className='text-2xl'>Tables:</h1>

              <Link to="/tables/list/new">
                <Button
                  type='primary'
                  >
                    <PlusCircleOutlined />
                  Create new Table
                </Button>
               </Link>
            </div>
    
            <TableAntd
                rowKey="id" 
                onChange={(pagination, filter, sorter: SorterResult<any> | SorterResult<any>[]) => {
                  console.log({filter,sorter});

                  let tablefilter : Seating_Table_Bool_Exp = {};
                  if (filter.room) {
                    tablefilter.room_id = {
                      _in: filter.room
                    }
                  }

                  let tableOrderBy : Seating_Table_Order_By = {};
                  if (!Array.isArray(sorter) && sorter.column) {
                    const field = sorter.field as ('created_at' | 'seats');
                    if (sorter.order === 'ascend') {
                      tableOrderBy[field] = Order_By.Asc;
                    } else if (sorter.order == 'descend') {
                      tableOrderBy[field] = Order_By.Desc;
                    }
                  }

                  refetchTable({
                    tablefilter,
                    tableOrderBy,
                  })
                }}
                loading={loading}
                onRow={(record: any) => {
                    return {
                      onClick: () => {
                        console.log(record);
                        history.push(`/tables/list/${record.id}`)
                        // props.onItemSelect(record);
                      },
                    };
                  }}
                dataSource={data?.seating_table}
                columns={columns} />;
        
    </>
  );
}

