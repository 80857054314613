import { Button } from 'antd'
import React from 'react'
import { UserDeleteOutlined } from '@ant-design/icons';
import { AuthContextHub } from '../../context/Auth';

export function ProfilePage() {

    function logout() {
        AuthContextHub.signout();
    }

    return (
        <div className='flex justify-center'>
             <Button
                className='mt-20'
                icon={<UserDeleteOutlined />}
                onClick={logout}> 
                    Logout from my profile
            </Button>
        </div>
    );
}