import { Alert } from 'antd';
import React from 'react'
import { useListPrintersQuery } from '../../generated/graphql';

export function PrinterPage() {

    const {data} = useListPrintersQuery();

    return (
        <>
            <Alert 
                message="Printers" 
                type="info"
                showIcon
                description={<>
                    <p>When you have a printer connected to Qrave. They will print ticket immediately when a customer order</p>
                    <p>Printer are linked to one or more menu providers</p>
                    <br />

                    <p>It's not possible to add a printer yourself yet, you can chat with use below so we can help you</p>
                </>}
                />


             <div>
                {data?.printers.length === 0 ? (
                    <p>No printers yet</p>
                ) : (
                    <div>
                        {data?.printers.map(printer => (
                            <div key={printer.id} className='border-2 bg-lightblue inline-block m-10 p-4'>
                                <p>🖨️ name: {printer.name}</p>
                                <p>printing for: {printer.producers.map(p => p.name).join(', ')}</p>
                                <p>is online: {printer.online ? 'Yes' : 'No'}</p>
                            </div>
                        ))}
                    </div>
                )}
             </div>
        </>
    );
}