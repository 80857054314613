import React, { useState, useEffect, useRef } from 'react'
import Phaser from 'phaser'
import { IonPhaser, GameInstance } from '@ion-phaser/react'
import { GetTablePositionsQuery, Seating_Table_Position_Insert_Input, useGetTablePositionsLazyQuery, useUpdateTablePositionMutation } from '../../../generated/graphql';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

type Tables = Exclude<GetTablePositionsQuery["room"], null | undefined>["tables"];
type UpdateTable = (ReturnType<typeof useUpdateTablePositionMutation>)[0];

function getGame(tables: Tables, update : UpdateTable) {
    const tableposition: Map<string, {x: number, y: number}> = new Map();

    const debounced = debounce(function() {
        console.log("update, update, update, update")

        let tablePositions: Seating_Table_Position_Insert_Input[] = [];

        tableposition.forEach((value, tableId) => {
            tablePositions.push({
                table_id: tableId,
                x: Math.floor(value.x),
                y: Math.floor(value.y),
            })
        })


        update({
            variables: {
                tablePositions,
            }
        })

    }, 200);


    class FloorPlanScene extends Phaser.Scene {
        init () {
          this.cameras.main.setBackgroundColor('#40739e')
        }
      
        preload () {
          this.load.image('table_s_2', '/assets/tables/square_2.png');
          this.load.image('table_s_4', '/assets/tables/square_4.png');
          this.load.image('table_s_6', '/assets/tables/square_6.png');
        }
      
        create () {
      
          console.log("create");
          for (let table of tables) {

              const seats = Math.min(Math.floor((table.seats || 4) / 2), 3) * 2;


              const bg = this.add
                  .image(0,0, `table_s_${seats}`)
                  .setOrigin(.5,.5);


                // this.tweens.add({
                //     targets: bg,
                //     scaleX: 1.1,
                //     scaleY: 1.1,
                //     ease: 'Sine.easeInOut',
                //     duration: 0.5,
                //     repeat: -1,
                //     yoyo: true,
                //     repeatDelay: 800
                // });
      
              const text = this.add
                  .text(0, 0, table.name, {font: "16px Arial"})
                  .setOrigin(.5,.5);
      
      
              const container = this.add.container(table.table_position?.x || 0, table.table_position?.y || 0, [ bg, text ]);
      
              container.setSize(bg.width, bg.height);

              container.setData("tableId", table.id)
      
              container.setInteractive();
      
              this.input.setDraggable(container);
      
              container.on('pointerover', function () {
                  bg.setTint(0x44ff44);
              });
      
              container.on('pointerout', function () {
                  bg.clearTint();
              });
          }
      
          this.input.on('drag', function (_: void, container: any, dragX: any, dragY: any) {

                const tableId = container.getData("tableId");

                container.x = dragX;
                container.y = dragY;
                tableposition.set(tableId, {
                    x: dragX,
                    y: dragY,
                })

                debounced();



            //   console.log({
            //       tableId,
            //       x: dragX,
            //       y: dragY,
            //   })
          });
      
        }
      
      }
      
      const gameConfig: GameInstance = {
        width: 1000,
        height: 600,
        type: Phaser.AUTO,
        scale: {
          mode: Phaser.Scale.CENTER_BOTH,
          // autoCenter: Phaser.Scale.CENTER_BOTH,
          // width: "400",
          // height: "200",
        },
        scene: FloorPlanScene
      };

      let output = Object.assign({}, gameConfig);
      return output;
}


interface UrlParams {
    roomId: string;
}

export function RoomFloorPlan () {
  const {roomId} = useParams<UrlParams>()
  const [getRoomPosision] = useGetTablePositionsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [updateTablePos] = useUpdateTablePositionMutation();
  const gameRef = useRef<HTMLIonPhaserElement>(null)
  const [game, setGame] = useState<GameInstance>()

  useEffect(() => {
    getRoomPosision({
        variables: {
            roomId
        },
        
    }).then((roomData) => {
        console.log("roomData", roomData);
        const tables  = roomData.data?.room?.tables;
        if (tables) {
            setGame(getGame(tables, updateTablePos))
        }
    })

    return () => {
        gameRef.current?.destroy()
        setGame(undefined)
    }
  }, [roomId]);

  function save() {

  }

  return (
    <div>
      <header>
        { game && (
          <>
            <IonPhaser ref={gameRef} game={game} initialize={true} />
          </>
        )}
      </header>
    </div>
  );
}