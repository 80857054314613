import { ContainerOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react'

interface Props {
    note?: string | null;
}
export function ItemNote({note} : Props) {

    if (!note) {
        return null;
    }

    return (
        <span onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}>
              <Tooltip placement="topRight" title={note}>
                <ContainerOutlined />
             </Tooltip>
             
        </span>
    );
}