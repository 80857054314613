import { Button, Empty, notification, Select, Tag } from 'antd';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateTableSessionMutation, useGetSimpleTablesQuery, useLoadTableActiveSessionsQuery } from '../../../generated/graphql';
import { Card } from 'antd';
import { pennyToPrice } from '../../../utils/price';
import { LoaderPage } from '../../../components/utils/loader';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CurrencyPrice } from '../../../components/currency/price';


interface UrlParams {
    id: string;
}
const OrderTableId : React.FunctionComponent = () => {

//   const {data: tableData} = useGetSimpleTablesQuery();
  const history = useHistory();


    const params = useParams<UrlParams>();
    const { t } = useTranslation('orders');
    const tableId = params.id;

    const {data, loading} = useLoadTableActiveSessionsQuery({
        fetchPolicy: 'network-only',
        variables: {
            tableId,
        }
    });
    const [createSession] = useCreateTableSessionMutation();

    async function createNewSession() {

        try {
            const result = await createSession( {
                variables: {
                    tableId,
                }
            })
            notification.success({
                message: "session created",
                duration: 3,
            });
            history.push(`/orders/session/${result.data!.insert_booking_table_sessions_one!.id}/new`)
        } catch (e) {
            notification.error({
                message: "unable to create session"
            });
        }
    }


    if (loading) {
        return <LoaderPage />
    }


    const tableSession = data?.table?.sessions || [];
    
  return (
      <div className='flex flex-col justify-center items-center'>

            <h1 className="my-4 text-2xl">Table {data?.table?.name}</h1>


            {(tableSession.length == 0) ? (
                <Empty description="No sessions currently open"/>
            ): (
                <div>
                    {tableSession.map((session) => (
                        <div className='p-3 my-2 border-2 relative mb-6' key={session.id} onClick={() => {
                            history.push(`/orders/session/${session.id}`)
                        }}>
                            <p className='m-0'>Created: {(new Date(session.created_at)).toLocaleString()}</p>
                            <p>paid: <CurrencyPrice price={session.paid} hideCurrency />/<CurrencyPrice price={session.total}  /></p>

                            {session.orders.map((order) => (
                                <div key={order.id}>Order: <Tag>{t(`status.${order.status}`)}</Tag></div>     
                            ))}

                            <PlusCircleOutlined 
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    history.push(`/orders/session/${session.id}/new`)
                                }}
                                className="absolute bg-white cursor-pointer bottom-0 right-0 text-4xl translate-x-2/4 translate-y-2/4" />
                        </div>
                    ))}
                </div>
            )}

            <div className="mt-4">
                <Button type='primary' onClick={createNewSession}>
                    Create new Session
                </Button>
            </div>

      </div>
  );
};

export default OrderTableId;
function useLoadTableSessions(): {} {
    throw new Error('Function not implemented.');
}

