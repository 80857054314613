import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { OrdersPage } from "../../pages/orders/progress";
import { Tables } from "../../pages/tables";
import { EditTables } from "../../pages/tables/_id";
import Login from "../login/login";
import MenuItem from "../../pages/menu/items";
import FoodMenu from "../../pages/menu/list";
import { StaffList } from "../../pages/staff";
import { MenuProducers } from "../../pages/menu/producers";
import QrCodeApp from "../../pages/qr";
import OrderTable from "../../pages/orders/table";
import OrderTableId from "../../pages/orders/table/id";
import { ProfilePage } from "../../pages/profile";
import { TableRooms } from "../../pages/tables/rooms";
import { RoomFloorPlan } from "../../pages/tables/rooms/floorplan";
import { OrderRoomsOverview } from "../../pages/orders/overview/roomOverviews";
import { OrderHistory } from "../../pages/orders/history/history";
import { OrderOverview } from "../../pages/orders/session/overview";
import { OrderSession } from "../../pages/orders/session";
import { MobileRoomOverview } from "../../pages/orders/overview/mobileRoomOverview";
import { MakeOrderV2 } from "../../pages/orders/session/new/next";
import { MenuItemComponent } from "../../pages/menu/items/id";
import { MagicLogin } from "../../pages/magic/magic";
import { RestaurantPage } from "../../pages/restaurant/restaurant";
import { AuthContext, SignedInStatus } from "../../context/Auth";
import { Spin } from "antd";
import { TutorialPage } from "../../pages/tutorial";
import { SanddBoxPage } from "../../pages/sandbox";
import { isDeviceDesktop } from "../../utils/isDesktop";
import { PrinterPage } from "../../pages/printers";
import { DoItYourselfPage } from "../../pages/qr/do_it_yourself/do_it_yourself";

function LoggedOutRoutes() {
  return (
    <Switch>
        <Route exact path="/mlogin/:token">
            <MagicLogin />
        </Route>
        <Route  path="*">
          <Login />
        </Route>
    </Switch>
  )
}

function LogginInRoutes() {
  const isDesktop = isDeviceDesktop();

  return (
    <Switch>
        <Route exact path="/mlogin/:token">
            <MagicLogin />
        </Route>


        <Route path="/tutorial/">
          <TutorialPage />
        </Route>

        <Route path="/sandbox/">
          <SanddBoxPage />
        </Route>
        
        <Route path="/restaurant/">
          <RestaurantPage />
        </Route>

        <Route exact path="/staff/">
          <StaffList />
        </Route>

        <Route exact path="/menu/producers/">
          <MenuProducers />
        </Route>

        <Route exact path="/menu/tree/">
            <FoodMenu />
        </Route>

        <Route exact path="/menu/items/">
          <MenuItem />
        </Route>

        <Route path="/menu/items/:itemId">
          <MenuItemComponent />
        </Route>

        <Route path="/qr/">
          <DoItYourselfPage />
        </Route>

        <Route path="/printers/">
          <PrinterPage />
        </Route>

        <Route exact path="/tables/rooms">
            <TableRooms/>
        </Route>

        <Route exact path="/tables/rooms/:roomId/">
          <RoomFloorPlan />
        </Route>

        <Route exact path="/tables/list">
            <Tables/>
        </Route>

        <Route path="/tables/list/:id/">
            <EditTables/>
        </Route> 


        <Route path="/orders/overview">
          {isDesktop ? (<OrderRoomsOverview />) : (<MobileRoomOverview />)}
        </Route>

        <Route exact path="/orders/table">
           <OrderTable />
        </Route>

        <Route path="/orders/table/:id">
          <OrderTableId />
        </Route>

        <Route path="/orders/progress">
           <OrdersPage />
        </Route>

        <Route path="/orders/history">
          <OrderHistory />
        </Route>
        
        <Route path="/orders/session/:sessionId/new">
           <MakeOrderV2 />
        </Route>

        <Route path="/orders/session/:sessionId">
           <OrderSession />
        </Route>

        <Route path="/profile">
          <ProfilePage />
        </Route>
        <Route exact path="/">
          <Redirect to="/orders/overview" />
        </Route>
        <Route path="*">
          404 route not founds
        </Route>
    </Switch>
  )

}

export default function Pages() {


  const {user} = useContext(AuthContext);

  if (user.status === SignedInStatus.UNKNOWN) {
    return <Spin />
  }
  
  return (
    <>

      {(user.status === SignedInStatus.SIGNED_IN) ? (
        <LogginInRoutes />
      ): (
        <LoggedOutRoutes />
      )}

    </>
  );
}


