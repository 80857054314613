import { List, Tabs, Tag } from 'antd';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { LoaderPage } from '../../../components/utils/loader';
import { Booking_Order_Status_Enum, useGetRoomOverviewSubscription, useGetRoomsQuery } from '../../../generated/graphql';

const {TabPane} = Tabs;


interface MobileRoomTablesListProps {
    roomId: string;
}
function MobileRoomTablesList(props: MobileRoomTablesListProps) {
    const history = useHistory();
    const {data, loading} = useGetRoomOverviewSubscription({
        variables: {
          roomId: props.roomId,
        }
      })


    return (
        <List
            loading={loading}
            dataSource={data?.seating_table_room_by_pk?.tables}
            itemLayout="horizontal"
            renderItem={table => {
                const hasSession = table.table_sessions.length > 0;
                const hasUnpaid = table.table_sessions.find(s => s.total > 0 && (s.total - s.total_paid) > 0)

                let sesssionTag = (<Tag>No session opened</Tag>);
                if (hasSession) {
                    sesssionTag = (<Tag color="green">all items paid</Tag>);
                } 
                if (hasUnpaid) {
                    sesssionTag = (<Tag color="red">Unpaid items</Tag>);
                }

                const hasOrderNotDelivered = table.table_sessions.find(s => {
                    return s.orders.find(o => {
                      return !([
                        Booking_Order_Status_Enum.S_010Draft,
                        Booking_Order_Status_Enum.S_050Delivered,
                      ].includes(o.status));
                    })
                });
                let deliveredTag = null;
                if (hasOrderNotDelivered) {
                    deliveredTag = (<Tag color="red">Delivery needed</Tag>);
                }
                  
                return (
                    <List.Item 
                        onClick={() => {
                            history.push(`/orders/table/${table.id}`);
                        }}
                        key={table.id}>
                        Table: {table.name} {sesssionTag} {deliveredTag}
                    </List.Item>
                )
            }}
        />
 
    )
}

export function MobileRoomOverview() {
    const {data, loading} = useGetRoomsQuery();

    if (loading || !data?.seating_table_room) {
        return <LoaderPage />
    }

    return (
        <>
            <Tabs defaultActiveKey={data.seating_table_room[0].id}>
                {data.seating_table_room.map(room => (
                    <TabPane tab={room.name} key={room.id}>
                        <MobileRoomTablesList roomId={room.id} />
                    </TabPane>
                ))}

            </Tabs>
        </>
    );
}