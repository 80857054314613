import React from 'react'

interface Props {
}
export const AlignerComponent: React.FC<Props> = ({
    children
  }) => (
    <div className={'flex justify-center items-center h-full'}>
        <div>
            {children}
        </div>
    </div>
  );