import { Button, notification } from 'antd';
import React from 'react'
import { Booking_Order, Booking_Order_Status_Enum, useMarkOrderAsCompleteMutation, useMarkOrderAsDeliveredMutation, useStartPreparingOrderMutation } from '../../generated/graphql';
import { isDeviceDesktop } from '../../utils/isDesktop';

interface MoveOrderStatusProps {
    order: Pick<Booking_Order, "id" | "status">;
    callback?: () => void;
}

export function MoveOrderStatus(props: MoveOrderStatusProps) {

    const {order} = props;

    const [startPreparingOrder, a] = useStartPreparingOrderMutation();
    const [markAsComplete, b] = useMarkOrderAsCompleteMutation();
    const [MarkOrderAsDelivered, c] = useMarkOrderAsDeliveredMutation();

    const loading = a.loading || b.loading || c.loading;
    const isDesktop = isDeviceDesktop();


    if (order.status === Booking_Order_Status_Enum.S_020Waitting) {
        return (<Button 
          size={isDesktop ? "middle" : "small"}
          loading={loading}
            onClick={async () => {
            const res = await startPreparingOrder({
              variables: {
                orderId: order.id,
              }
            })

            if (props.callback) {
                props.callback();
            }

            notification.success({
              duration: 1,
              message: res.data?.update_booking_order?.affected_rows == 1 ? "Success" : "Order Failed update"
            })
          }}>Start Preparing</Button>)
    }

    if (order.status === Booking_Order_Status_Enum.S_030Preparing) {
        return (<Button
            loading={loading}
            size={isDesktop ? "middle" : "small"}
            onClick={async () => {
            const res = await markAsComplete({
              variables: {
                orderId: order.id,
              }
            })

            if (props.callback) {
                props.callback();
            }

            notification.success({
              duration: 1,
              message: res.data?.update_booking_order?.affected_rows == 1 ? "Order ready" : "Order Failed update"
            })
          }}>Ready to deliver</Button>);
    }

    if (order.status === Booking_Order_Status_Enum.S_040Completed) {
        return (<Button
            loading={loading}
            size={isDesktop ? "middle" : "small"}
            onClick={async () => {
            const res = await MarkOrderAsDelivered({
              variables: {
                orderId: order.id,
              }
            })

            if (props.callback) {
                props.callback();
            }
            
            notification.success({
              duration: 1,
              message: res.data?.update_booking_order?.affected_rows == 1 ? "Order delivered" : "Order Failed update"
            })
          }}>Order Delivered</Button>);
    }

    return null;
}