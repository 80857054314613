import React, { useState, useEffect, useRef } from 'react'
import Phaser from 'phaser'
import { IonPhaser, GameInstance } from '@ion-phaser/react'
import { Booking_Order_Status_Enum, GetRoomOverviewSubscription, GetRoomsQuery, GetTablePositionsQuery, Seating_Table_Position_Insert_Input, useGetPrintersSubscription, useGetRoomOverviewSubscription, useGetRoomsLazyQuery, useGetTablePositionsLazyQuery, useUpdateTablePositionMutation } from '../../../generated/graphql';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Radio, Tag } from 'antd';

// type Tables = Exclude<GetTablePositionsQuery["room"], null | undefined>["tables"];
// type UpdateTable = (ReturnType<typeof useUpdateTablePositionMutation>)[0];

type Tables =  Exclude<GetRoomOverviewSubscription["seating_table_room_by_pk"], null | undefined>["tables"];
type Rooms = GetRoomsQuery["seating_table_room"];

function getGame(onTableClick: (tableId: string) => void) {

    const tables: any[] = [];

    class FloorPlanScene extends Phaser.Scene {


        private arg : GetRoomOverviewSubscription | null = null;
        private maps:  Map<string, Phaser.GameObjects.Container> = new Map();

        init () {
          this.cameras.main.setBackgroundColor('#40739e');


          // setTimeout((res) => {
          //   this.add
          //   .image(0,0, 'table')
          //   .setOrigin(.5,.5);
          // }, 200);



          this.maps = new Map();


          this.events.on("tableReceived", (arg: GetRoomOverviewSubscription) => {
            this.arg = arg;
            this.renderFromEvent();
          })
        }


        renderFromEvent() {
          this.maps.forEach((container) => {
            container.destroy();
          })
          const tables : Tables | undefined = this.arg?.seating_table_room_by_pk?.tables;
          if (!tables) {
            console.log("not table found");
          } else {
            for (let table of tables) {

              // this convert 5->6 3->4... and 6 is the max
              const seats = Math.min(Math.floor((table.seats || 4) / 2), 3) * 2;
              const bg = this.add
                  .image(0,0, `table_s_${seats}`)
                  .setOrigin(.5,.5);


                const hasSession = table.table_sessions.length > 0;
                const hasUnpaid = table.table_sessions.find(s => s.total > 0 && (s.total - s.total_paid) > 0)


              let textColor = "white";
              if (hasSession) {
                textColor = "green";
              } 
              if (hasUnpaid) {
                textColor = "red";
              }
      
              const text = this.add
                  .text(0, 0, table.name, {
                    font: "16px Arial",
                    color: textColor
                  })
                  .setOrigin(.5,.5);
      
                const hasOrderNotDelivered = table.table_sessions.find(s => {
                  return s.orders.find(o => {
                    return !([
                      Booking_Order_Status_Enum.S_010Draft,
                      Booking_Order_Status_Enum.S_050Delivered,
                    ].includes(o.status));
                  })
                });

                if (hasOrderNotDelivered) {
                  this.tweens.add({
                    targets: bg,
                    scaleX: 1.1,
                    scaleY: 1.1,
                    ease: 'Sine.easeInOut',
                    duration: 1000,
                    repeat: -1,
                    yoyo: true,
                    repeatDelay: 800
                  });
                }

              const container = this.add.container(table.table_position?.x || 0, table.table_position?.y || 0, [ bg, text ]);
      
              container.setSize(bg.width, bg.height);

              container.setData("tableId", table.id)
      
              this.maps.set(table.id, container);


              container.setInteractive({ cursor: 'pointer' });
              container.on('pointerdown', function () {
                  console.log("clicked");
                  onTableClick(table.id);
              });

            }
          }
        }
      
        preload () {
          this.load.image('table_s_2', '/assets/tables/square_2.png');
          this.load.image('table_s_4', '/assets/tables/square_4.png');
          this.load.image('table_s_6', '/assets/tables/square_6.png');

        }
      
        create () {
          console.log("booted");
          if (this.arg) {
            this.renderFromEvent();
          }

      
          // this.input.on('drag', function (_: void, container: any, dragX: any, dragY: any) {

          //       const tableId = container.getData("tableId");

          //       container.x = dragX;
          //       container.y = dragY;
          //   //   console.log({
          //   //       tableId,
          //   //       x: dragX,
          //   //       y: dragY,
          //   //   })
          // });
      
        }
      
      }
      
      const gameConfig: GameInstance = {
        width: 1000,
        height: 600,
        type: Phaser.AUTO,
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          // width: "400",
          // height: "200",
        },
        scene: FloorPlanScene
      };

      let output = Object.assign({}, gameConfig);


      // setTimeout(function() {
      //   output.instance?.scene.getAt(0)?.events.emit("tableReceived", "world");
      // }, 500);

  

      return output;
}


interface UrlParams {
    roomId: string;
}

export function OrderRoomsOverview () {
  const [getRooms]  = useGetRoomsLazyQuery();
  const history = useHistory();
  const gameRef = useRef<HTMLIonPhaserElement>(null)
  const [game, setGame] = useState<GameInstance>();
  const [rooms, setRooms] = useState<Rooms>([]);
  const [selectedRoom, setSelectedRoom] = useState<string>();

  const {data} = useGetRoomOverviewSubscription({
    skip: !selectedRoom,
    variables: {
      roomId: selectedRoom,
    }
  })

  const {data: printer} = useGetPrintersSubscription();

  console.log("receive data from sub data", data);
  if (game && data) {
    console.log("ready", game.instance?.isRunning);
    game.instance?.scene.getAt(0)?.events.emit("tableReceived", data);
  }

  useEffect(() => {

    getRooms()
      .then(result => {
        const rooms : Rooms | undefined = result.data?.seating_table_room;
        console.log("rooms", rooms);

        if (rooms) {
          setRooms(rooms);
          if (rooms.length > 0) {
            setSelectedRoom(rooms[0].id);
          }
        }
      })

      setGame(getGame(function(tableId) {
        history.push(`/orders/table/${tableId}`);
      }));

      return () => {
        gameRef.current?.destroy()
        setGame(undefined)
      }

  }, []);

  return (
    <div className='bg-phaser h-screen flex-row items-center'>

      <div className='grid grid-cols-3 gap-3'>

        <div className='bg-phaser p-4'>
          <p className='m-0'> <span className='bg-white w-3 h-3 inline-block'></span> no session opened</p>
          <p className='m-0'> <span className='bg-green w-3 h-3 inline-block'></span> all items paid</p>
          <p className='m-0'> <span className='bg-red w-3 h-3 inline-block'> </span> unpaid items</p>
          <p className='m-0'> <span className='border w-3 h-3 inline-block animate-pulse'> </span> waitting for items</p>
        </div>


      {rooms.length === 0 ? (
        <p className='text-xl mt-40'>You don't have any rooms yet</p>
      ) : (
        <div className="m-auto">
          <Radio.Group 
              value={selectedRoom}
              buttonStyle="solid"
              onChange={(e) => {
                console.log("selected", e.target.value);
                setSelectedRoom(e.target.value);
              }}>
              {rooms.map(room => (
                <Radio.Button className='focus:outline-none focus:shadow-none focus-within:shadow-none'
                  style={{
                    backgroundColor: "grey",
                    outline: 0,
                    boxShadow: "none",
                    borderColor: "grey",
                  }}
                  key={room.id}
                  value={room.id}>{room.name}</Radio.Button>
              ))}
            </Radio.Group>
        </div>
      )}



      <div className="m-auto">
          {printer?.printers.map(printer => (
            <div key={printer.id}>
              {printer.name}: {printer.online ? (<span>online</span> ) : <Tag color="red">offline</Tag>}
            </div>
          ))}
      </div>


      </div>


      <div className='grow shrink'>
        { game && (        
            <IonPhaser ref={gameRef} game={game} initialize={true} />
        )}
      </div>
    </div>
  );
}