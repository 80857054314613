import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext, SignedInStatus } from '../../context/Auth';

export function MobileFooterMenu() {

    const {user} = useContext(AuthContext);

    if (user.status !== SignedInStatus.SIGNED_IN) {
      return null;
    }

    return (
        <section id="bottom-navigation" className="z-10 bg-slate-200 shadow">
          <div id="tabs" className="flex justify-between">
            <Link to="/orders/overview" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <div className='inline-block text-2xl'>🍽</div>
              <span className="tab tab-home block text-xs">Rooms</span>
            </Link>
            <Link to="/orders/progress" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <div className='inline-block text-2xl'>👟</div>
              <span className="tab tab-home block text-xs">Delivery</span>
            </Link>
            <Link to="/orders/table" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <div className=' inline-block text-2xl'>🪑</div>
              <span className="tab tab-kategori block text-xs">Table</span>
            </Link>

            <Link to="/profile" className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
              <div className='inline-block text-2xl'>⚙️</div>
              <span className="tab tab-explore block text-xs">Settings</span>
            </Link>

          </div>
        </section>
    );
}