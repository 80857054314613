import { CameraOutlined } from '@ant-design/icons';
import { notification, Select } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { KeyPad } from '../../../components/utils/keypad';
import { useGetSimpleTablesQuery } from '../../../generated/graphql';
import { QrScanIcon } from './qrScan';


const OrderTable : React.FunctionComponent = () => {

  const {data: tableData} = useGetSimpleTablesQuery();
  const history = useHistory();

  return (
      <div className='flex flex-col justify-center items-center'>

          <div className='my-8'>
            
            <Select
                    placeholder="Select a table"
                
                    allowClear
                    onChange={(tableId: string) => {
                        console.log(tableId);
                        history.push(`/orders/table/${tableId}`)
                    }}
                >
                    {tableData?.tables.map(table => (
                        <Select.Option
                            key={table.id}
                            value={table.id}
                            >
                                {table.name}
                        </Select.Option>
                    ))}
                </Select>
                <span className="absolute ml-2">
                    <QrScanIcon tables={tableData?.tables || []} />
                </span>
          </div>


            <KeyPad onTableSelected={(tableNumber) => {
                const table = tableData?.tables.find(table => table.name === tableNumber)

                if (table) {
                    history.push(`/orders/table/${table.id}`)
                } else {
                    notification.error({
                        message: "table not found",
                        duration: 1.3
                    })
                }

            }} />

      </div>
  );
};

export default OrderTable;
