import React, { useEffect } from "react";
import './App.css';

import {
  BrowserRouter as Router,
} from "react-router-dom";

// import Apollo from "./context/Apollo";
import Main from "./Main";
import { AuthContextProvider } from "./context/Auth";
import Apollo from "./context/Apollo";


function App() {

  
    return (
          <Apollo>
              <AuthContextProvider>

              <Router>
                <Main />
              </Router>
            </AuthContextProvider>

          </Apollo>
    );
}


export default App;