
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { QRAVE_DOMAIN } from '../../config/environnement';

import { useGetSignedUrlLazyQuery} from '../../generated/graphql';
const { Dragger } = Upload;

export function ImageFormInput(props: any) {
    const {value, onChange} = props;
    const [query] = useGetSignedUrlLazyQuery({
        fetchPolicy: 'no-cache'
    });

    async function customRequest(options: RcCustomRequestOptions) {
        console.log("options", options);

        const {data, error} = await query({
            variables: {
                fileType: (options.file as RcFile).type.split('/').at(-1) as string,
            }
        });
        console.log(data);

        if (error) {
            options?.onError!({
                name: error.name,
                message: error.message,
            })
        }

        const {fields, url} = data!.signedUrl!;

        const form = new FormData();
        Object.keys(fields).forEach(key => form.append(key, fields[key]));

        form.append('file', options.file);

        return fetch(url, { method: 'POST', body: form })
            .then(options.onSuccess)
            .catch(options.onError)
            .then(() => {
                onChange(`https://static.${QRAVE_DOMAIN}/` +  fields.key);
            })
    }


    return (
        <Row>
            <Col>
                <img
                    width="80px" 
                    src={value || "https://via.placeholder.com/150"}
                    alt="logo" />
            </Col>
            <Col>
                <Dragger 
                        name={'file'}
                        customRequest={customRequest}
                    >
                        <p className="ant-upload-text">Upload new image</p>
                </Dragger>
   
            </Col>
        </Row>
    )

}
