import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputNumber, notification, Space, Tag } from 'antd';
import { flatten, pick } from 'lodash';
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { CurrencyPrice } from '../../../../components/currency/price';
import { ItemNote } from '../../../../components/orders/itemNote';
import { GetCompleteMenuQuery, LoadMenuItemsQuery, OrderItems, useCreateOrderFromStaffMutation } from '../../../../generated/graphql';
import { pennyToPrice } from '../../../../utils/price';
import { ItemsByProductIdsMap, OrderItemsForm } from './type';



interface OrderFormData {
    table_id: string;
    products: Array<OrderItemsForm>
}

interface NewOrderFormProps {
    itemsByProductIds: ItemsByProductIdsMap;
    orderTotal: number;
    update: (next: ItemsByProductIdsMap) => void;
}

interface UrlParams {
    sessionId: string;
}


export function NewOrderForm(props: NewOrderFormProps) {
    const [createOrder, isCreatingOrder] = useCreateOrderFromStaffMutation();
    const history = useHistory();
    const {sessionId} = useParams<UrlParams>();

    const {itemsByProductIds} = props;

    const items = itemsByProductIds.valueSeq();

    async function onFinish() {


        const orderItems : OrderItems[] = [];

        for (let itemsGroup of Array.from(items)) {
            for (let item of itemsGroup.items) {
                orderItems.push({
                   itemPriceId: item.itemPriceId, 
                   note: item.note,
                   extra: item.extra.map(e => ({id : e.id}))
                })
            }
        }

        try {
            await createOrder({
                variables: {
                    tableSessionId: sessionId,
                    items: orderItems,
                }
            })
    
            notification.success({
                message: "order created!",
            })
    
            history.push(`/orders/session/${sessionId}`);
        } catch (err) {
            notification.error({
                message: "unable to create your order",
            })
        }
        

    }

    function DisabledPrice({value}: any) {
        return (
            <span><CurrencyPrice price={value} /></span>
        )
    }

    function removeLineItem(productId: string, itemId: string) {
        const items = itemsByProductIds.get(productId);

        if (items) {
            items.items = items.items.filter(i => i.id != itemId);
            if (items.items.length > 0) {
                props.update(itemsByProductIds.set(productId, items));
            } else {
                props.update(itemsByProductIds.delete(productId));
            }
        }

    }

    return (
        <div>

            <div>
                {items.map((value) => (
                    <div className=' mb-2' key={value.productId}>
                        <p className='text-xl m-0'>{value.productName}</p>
                        {value.items.map(v => (
                            <div key={v.id} className="flex">
                                <p className='m-0'>1x <CurrencyPrice price={v.price} /> {v.note && (
                                   <ItemNote note={v.note} />
                                )}</p>
                                <div>
                                    {v.extra.map(e => (
                                        <Tag key={e.id}>
                                            {e.name} {e.price != 0 && (
                                                <>+ <CurrencyPrice price={e.price} /></>
                                            )}
                                        </Tag>
                                    ))}
                                </div>
                                <div className='grow'> </div>
                                <div className='cursor-pointer' onClick={() => removeLineItem(value.productId, v.id) }>
                                    
                                    <Button>Remove <MinusCircleOutlined /></Button>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <p className='text-m'>Total: <CurrencyPrice price={props.orderTotal} /></p>
            <Button loading={isCreatingOrder.loading} onClick={onFinish}>Create order</Button>
        </div>
    )
}