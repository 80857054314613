import { Spin } from 'antd';
import React from 'react'
import { useGetQrTemplatesQuery } from '../../../generated/graphql';


interface QrChooseDesignProps {
    onSelect: (data: any) => void;
}

export function QrChooseDesign(props: QrChooseDesignProps) {
    const {data, loading} = useGetQrTemplatesQuery();

    if (loading) {
        return (<Spin></Spin>)
    }

    if (!data || data.qr_template.length === 0) {
        return (
            <p>No template available</p>
        )
    }

    return (
        <div className='flex flex-wrap'>
             {data.qr_template.map((template) => (
                <div 
                    onClick={() => {
                        props.onSelect({layers: template.template})
                    }}
                    key={template.id} 
                    className="max-w-xs text-center p-4 m-2 cursor-pointer border border-black bg-blue-200">
                    <img className="mx-auto" height={"auto"} width="200" src={template.img} />
                    <p className="text-xl text-bold">{template.name}</p>
                </div>
             ))}
        </div>
    );
}