import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Popconfirm, Space, Table, TreeSelect } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TreeNode } from "antd/lib/tree-select";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadMenuDetailQuery, Menu_Item_Bool_Exp, Menu_Tree, useDeleteMenuItemByPkMutation } from "../../../generated/graphql";
import { pennyToPrice } from "../../../utils/price";
import { convertToTree, UiTreeItem } from "../../../utils/tree";

type QueryMenuItems = LoadMenuDetailQuery["items"][number];

interface MenuItemsTableProps {
    items: QueryMenuItems[];
    menuTrees: Pick<Menu_Tree, "id" | "title" | "parent_id">[];
    loading: boolean;
    refetch: () => void;
    onItemSelect: (item: QueryMenuItems) => void;
}

export default function MenuItemsTable(props: MenuItemsTableProps) {

  const [deleteProduct] = useDeleteMenuItemByPkMutation();
  const history = useHistory();


const tree = convertToTree(props.menuTrees)

const nodeLabels : Map<string, string[]> = new Map();


console.log({tree});


function displayTreeNodes(node: UiTreeItem, parent_id: string | null) {

  if (parent_id) {
    nodeLabels.set(node.key, [...(nodeLabels.get(parent_id) || []), node.title])
  } else {
    nodeLabels.set(node.key, [node.title])
  }

  node.children.forEach((child) => {
    displayTreeNodes(child, node.key)
  })

}

tree.forEach(t => displayTreeNodes(t, null))

console.log(nodeLabels);

  
const columns: ColumnsType<QueryMenuItems> = [
        {
          title: 'image',
          dataIndex: 'imageUrl',
          key: 'imageUrl',
          render: (imageUrl: string) => <span>
            {(imageUrl) && (
              <img style={{maxHeight: "80px"}} src={imageUrl} />
            )}
          </span>,
      },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search Menu name`}
                  value={selectedKeys[0]}
                  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => confirm({closeDropdown: true})}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() => confirm({closeDropdown: true})}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Search
                  </Button>
                  <Button onClick={() => {
                    setSelectedKeys([]);
                    confirm({closeDropdown: true})
                  }} size="small" style={{ width: 90 }}>
                    Reset
                  </Button>
                </Space>
              </div>
            ),
        },
        {
          title: 'menu parent',
          dataIndex: 'menu_parent',
          key: 'menu_parent',
          filterDropdown: ({setSelectedKeys, confirm}) => {
            return (
              <div style={{ padding: 24 }}>
                    <TreeSelect
                        showSearch
                        style={{ width: '300px' }}
                        // value={value}
                        treeData={tree}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        treeCheckable
                        // tagRender={(props) => <span>{props.value}</span>}
                        showCheckedStrategy={"SHOW_CHILD"}
                        onChange={(changed) => {
                          console.log("changed", changed);
                          setSelectedKeys(changed)
                        }}
                        onDropdownVisibleChange={(open) => {
                          if (!open) {
                            confirm({ closeDropdown: true });
                          }
                        }}
                      >
                        {/* {tree.map(displayTreeNodes)} */}
                      </TreeSelect>
              </div>
            );
          },
          filters: props.menuTrees.map((t) => ({text: t.title, value: t.id})),
          render: (menuTreeId: string) => {
            const labels = nodeLabels.get(menuTreeId) || [];
            return (<span>{labels.join(" > ")}</span>)
          }
      },

        {
          title: 'price',
          render: (_: void, record: QueryMenuItems) => <span>{pennyToPrice(record.active_price_item.price)}</span>,

        },
        {
          title: 'live',
          dataIndex: 'live',
          key: 'live',
          render: (live: boolean) => <span> <Checkbox disabled checked={live} /> </span>,
        },
        {
            title: 'created',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (d: any) => <span>{(new Date(d)).toLocaleDateString()}</span>,
        },
      //  {
      //     title: 'actions',
      //     dataIndex: 'actions',
      //     key: 'actions',
      //     render: (_ : void, record: QueryMenuItems) => <span onClick={(e) => e.stopPropagation()}>
      //       <Popconfirm
      //         placement="leftBottom"
      //         title={"Do you want to permanently delete this product?"}
      //         onConfirm={async () => {
      //           await deleteProduct({
      //             variables: {
      //               id: record.id,
      //             }
      //           })
      //           props.refetch();
      //         }}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //          <DeleteOutlined />
      //       </Popconfirm>
      //     </span>,
      // },
    ];
    

  return (
      <>
            <Table
                rowKey="id" 
                onChange={(pagination, filter) => {
                  const params : Record<string, string> = {};


                  console.log({filter});

                  if (filter.name && filter.name.length > 0) {
                    params.name = filter.name[0] as string;

                  }

                  if (filter.menu_parent && filter.menu_parent.length > 0) {
                    params.menu_parent = filter.menu_parent.join(",");
                  }


                  history.replace(`/menu/items/?${new URLSearchParams(params).toString()}`);
                }}
                loading={props.loading}
                onRow={(record) => {
                    return {
                      onClick: () => {
                        console.log(record);
                        props.onItemSelect(record);
                      },
                    };
                  }}
                dataSource={props.items}
                columns={columns} />;
      </>
  );
}

