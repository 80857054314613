import React, { useContext } from "react";
import { createContext, useState, useEffect } from "react";
import { useGetRestaurantCurrencyLazyQuery, User_Roles_Enum_Enum } from "../generated/graphql";
import jwt_decode from "jwt-decode";
import EventEmitter from 'eventemitter3';

export enum SignedInStatus {
    UNKNOWN,
    SIGNED_IN,
    SIGNED_OUT,
}

interface UserLocalState {
    status: SignedInStatus;
    userRole?: User_Roles_Enum_Enum;
    jwt?: string;
}

type ICurrencyDetails = {currency: string, precision: number} | null | undefined;

interface UserContext {
    user: UserLocalState;
    setUser? : React.Dispatch<React.SetStateAction<UserLocalState>>;
    currencyDetails?: ICurrencyDetails;
}

interface HasuraJwt {
    "https://hasura.io/jwt/claims": {
        "x-hasura-default-role": User_Roles_Enum_Enum;
        "x-hasura-user-id": string;
        "x-hasura-tenant-id": string;
    };
    "iat": number;
    "exp": number;
}

class JwtAuthContext {

    public hub : EventEmitter = new  EventEmitter();
    private _state : UserLocalState = { status : SignedInStatus.UNKNOWN };

    constructor() {
        let jwt = localStorage.getItem('jwt');
        if (jwt) {
            this.receiveToken(jwt);
        } else {
            this.updateState({
                status: SignedInStatus.SIGNED_OUT,
            })
        }
    }

    signIn(jwt: string) : void {
        localStorage.setItem('jwt', jwt);
        this.receiveToken(jwt);
    }

    signout() : void {
        localStorage.removeItem('jwt');
        this.updateState({
            status: SignedInStatus.SIGNED_OUT,
        })
    }

    getState() : UserLocalState {
        return this._state;
    }

    getJwt() : Promise<string | null> {
        return Promise.resolve(this._state.jwt || null);
    }


    private receiveToken(jwt: string) {

        const decode = jwt_decode<HasuraJwt>(jwt);
        const claim = decode["https://hasura.io/jwt/claims"];

        const msToExpire =  (decode.exp * 1000) - Date.now();

        if (msToExpire > 5000) {
            this.updateState({
                status: SignedInStatus.SIGNED_IN,
                userRole: claim["x-hasura-default-role"],
                jwt, 
            })
    
    
            setTimeout(() => {
                this.updateState({
                    status: SignedInStatus.SIGNED_OUT,
                })
            }, msToExpire)
        } else {
            this.updateState({
                status: SignedInStatus.SIGNED_OUT,
            })
        }

    }

    private updateState(state: UserLocalState) {
        this._state = state;
        this.hub.emit("auth", state);
    }


}

export const AuthContextHub = new JwtAuthContext();


export const AuthContext = createContext<UserContext>({user: AuthContextHub.getState()});

export function AuthContextProvider(props : any) {

    console.log('PROBIDER');
    const [user, setUser] = useState<UserLocalState>(AuthContextHub.getState());
    const [currencyDetails, setCurrencyDetails] = useState<ICurrencyDetails>(null);
    const [getCurrencyInfo] = useGetRestaurantCurrencyLazyQuery();


    useEffect(() => {

        console.log(user);

        if (user.status === SignedInStatus.SIGNED_IN) {
            getCurrencyInfo().then((currency) => {
                const details = currency.data?.restaurants[0]?.country;
                if (details) {
                    setCurrencyDetails({
                        currency: details.currencySymol,
                        precision: details.currencyPrecision,
                    })
                }
            })
        }

        const updateStsate = (nextState: UserLocalState) => {
            if (nextState.status === SignedInStatus.SIGNED_IN) {
                getCurrencyInfo().then((currency) => {
                    const details = currency.data?.restaurants[0]?.country;
                    if (details) {
                        setCurrencyDetails({
                            currency: details.currencySymol,
                            precision: details.currencyPrecision,
                        })
                    }
                })
            }
            setUser(nextState)
        }

        AuthContextHub.hub.on("auth", updateStsate);

        return (() => {
            AuthContextHub.hub.off("auth", updateStsate);
        })



    }, [])



  return (
    <AuthContext.Provider value={{ user, setUser, currencyDetails }}>
      {props.children}
    </AuthContext.Provider>
  );

 
};
