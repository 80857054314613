import { Button, Form, Modal, notification, Slider, Tag } from 'antd';
import React, { useState } from 'react'
import { GetSessionOrdersOverviewSubscription, useUpdateBookingItemDiscountMutation } from '../../generated/graphql';
import { pennyToPrice } from '../../utils/price';
import { CurrencyPrice } from '../currency/price';
import { ItemNote } from './itemNote';

type Item = GetSessionOrdersOverviewSubscription["orders"][number]["order_items"][number];

export function OrderItemPriceWithDiscount({item} : {item: Item}) {

    const [isModalOpen, setModalOpen] = useState(false);
    const [updateDiscount] = useUpdateBookingItemDiscountMutation();

    async function formFinish({discount}: {discount: number}) {

        try {
            await updateDiscount({
                variables: {
                    itemId: item.id,
                    discount,
                }
            })

            notification.success({
                message: "Discount updated"
            })

            setModalOpen(false);
        } catch (e) {
            notification.error({
                message: "Unable to edit discount"
            })
        }


    }

    return (
        <>
            <Modal
                visible={isModalOpen}
                onCancel={() => setModalOpen(false)}
                footer={null}
            >

                Update discount for: {item.menu_item_price.menu_item?.name}

                <Form
                    className='m-3'
                    onFinish={formFinish}
                    initialValues={{discount: item.discount}}
                >


             <Form.Item name="discount">
                    <Slider
                        min={0}
                        max={100}
                        marks={{
                            0: 'default',
                            50: 'half price',
                            100: 'free',
                        }}
                    />
                </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update Discount
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>
            <Tag onClick={(e) => {
                setModalOpen(true);
            }}>
                <CurrencyPrice price={item.computed_total} />
            </Tag>
        </>
    );
}