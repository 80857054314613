import { Button, Checkbox, Form, Input, InputNumber, Modal, notification, Select, TreeSelect } from "antd";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import {  LoadMenuDetailQuery, Menu_Item_Bool_Exp, Menu_Item_Insert_Input, useDeleteMenuItemByPkMutation, useLoadMenuDetailQuery, useUpsertMenuItemMutation, useUpsertMenuItemWithoutPriceMutation } from "../../../generated/graphql";
import { ImageFormInput } from "../../../components/forms/imageFormInput";
import MenuItemsTable from "./table";
import { v4 as uuidv4 } from 'uuid';
import { PlusCircleOutlined } from "@ant-design/icons";

const {Option, OptGroup} = Select;
type QueryMenuItems = LoadMenuDetailQuery["items"][number];

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function MenuItem() {
  let query = useQuery();
  const history = useHistory();
  console.log("route query", query);


  let filters : Menu_Item_Bool_Exp = {};
  const listParents = query.get("menu_parent");
  if (listParents) {
    filters.menu_parent = {
        _in: listParents.split(",")
    }
  }

  const name = query.get("name");
  if (name) {
      filters.name = {
          _ilike: `%${name}%`
      }
  }

  const {loading, data, refetch, error} = useLoadMenuDetailQuery({
      fetchPolicy: "network-only",
      variables: {
        menuFilter: filters  
      }
  });

    if (error) {
        return (<div onLoad={() => {
            notification.error({
                message: 'unable to load'
            })
        }}>
            Error
        </div>);

    }


  return (
      <>
          <div className='flex justify-between m-4'>
              <h1 className='text-2xl'>Menu Items</h1>

              <Link to={`/menu/items/${uuidv4()}`}>
                <Button
                  type='primary'
                  >
                    <PlusCircleOutlined />
                    Create new menu item
                </Button>
               </Link>
            </div>


          <MenuItemsTable 
             onItemSelect={(item) => {
                 history.push(`/menu/items/${item.id}`)
            }}
            loading={loading}
            refetch={refetch}
            items={data ? data.items : []}
            menuTrees={data ? data.menuTrees: []}
          />
      </>
  );
}

