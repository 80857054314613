import { Button, Form, Input, notification } from 'antd';
import { omit, pick } from 'lodash';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCreateRoomMutation, useDeleteRoomByIdMutation, useGetRoomsQuery, useUpdateRoomNameMutation } from '../../../generated/graphql';
import { EditableTable } from './editableTable';
import { RoomType } from './type';


export function TableRooms() {
    const {data: rooms, loading: roomLoading, refetch} = useGetRoomsQuery({
        fetchPolicy: 'network-only'
    });

    const [createRoom] = useCreateRoomMutation();
    const [updateRoomNameMut] = useUpdateRoomNameMutation();
    const [deleteRoom] = useDeleteRoomByIdMutation();

    async function deleteRow(id: string) {
        try {
            await deleteRoom({
                variables: {
                    roomId: id
                }
            })
            notification.success({
                message: 'Room deleted',
            })
            refetch();
        } catch(err) {
            notification.error({
                message: 'Unable to delete room',
            })
        }

    }

    async function onRoomUpdated(roomId: string, room: Partial<RoomType>) {
        const roomName = room.name;
        if (!roomName) {
            notification.error({
                message: 'invalid room',
            })
            return;       
        }

        try {

            await updateRoomNameMut({
                variables: {
                    roomId,
                    roomName,
                }
            })
            notification.success({
                duration: 1,
                message: 'Room modified',
            })
        } catch (e) {
            notification.error({
                message: 'Unable to edit room',
            })
        }

        refetch();

    }


    async function addRoom({roomName} : {roomName: string}) {
        try {
            await createRoom({
                variables: {
                    roomName,
                }
            })
        } catch (err) {
            console.log("err", err);
            let message =  "coudn't add room";

            // if (err instanceof Error && err.message.includes("Uniqueness violation")) {
            //     message = "User with this email already exist";
            // }

            notification.error({
                message,
            })
            return;
        }

        notification.success({
            duration: 1,
            message: 'room created',
        })
        refetch();
    }
    

  return (
    <>


            <Form
                onFinish={addRoom}
                layout='inline'
            >
                <Form.Item required name="roomName" label="Room Name"  >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add new Room
                    </Button>
               </Form.Item>  
            </Form>

            <EditableTable
                     onRowUpdated={onRoomUpdated}
                     deleteRow={deleteRow}
                     loading={roomLoading}
                     data={rooms?.seating_table_room}
                 />

        
    </>
  );
}

