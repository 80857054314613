import React from 'react'
import { Tabs } from 'antd';
import { RestaurantInfo } from './info';
import { useHistory } from 'react-router-dom';
import RestaurantStripe from './stripe';
import { RestaurantWifi } from './wifi/wifi';

const { TabPane } = Tabs;


export function RestaurantPage() {

    const history = useHistory();

    console.log({history});

    const defaultActiveKey = history.location.pathname || "/restaurant/info"

    function callback(key: string) {
        history.push(key);
    }

    return (
        <Tabs defaultActiveKey={defaultActiveKey} onChange={callback}>
            <TabPane tab="Restaurant Info" key="/restaurant/info">
                <RestaurantInfo />
            </TabPane>
            <TabPane disabled tab="Table Payments" key="/restaurant/stripe">
                <RestaurantStripe />
            </TabPane>
            <TabPane disabled tab="Wifi" key="/restaurant/wifi">
                <RestaurantWifi />
            </TabPane>
        </Tabs>
    );
}