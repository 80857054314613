import { Button, Form, Input, notification, Table } from 'antd';
import { omit, pick } from 'lodash';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { useDeleteStaffMutation, useGetStaffQuery, useInsertStaffMutation, User_Roles_Enum_Enum, useUpdateStaffMutation } from '../../generated/graphql';
import { EditableTable } from './editableTable';
import { UserType } from './type';


export function StaffList() {

    const {user} = useContext(AuthContext);
    const {data, loading, refetch} = useGetStaffQuery();
    const [addUserMutation] = useInsertStaffMutation();
    const [updateUser] = useUpdateStaffMutation();
    const [deleteUser] = useDeleteStaffMutation();

    async function deleteRow(id: string) {
        await deleteUser({
            variables: {
                userId: id
            }
        })
        notification.success({
            message: 'User deleted',
        })
        refetch();
    }

    async function onUserUpdated(id: string, user: Partial<UserType>) {
        try {
            await updateUser({
                variables: {
                    userId: id,
                    updatedFields: omit(user, 'id'),
                }
            })
            notification.success({
                message: 'User modified',
            })
        } catch (e) {
            notification.error({
                message: 'Unable to edit user',
            })
        }

        refetch();

    }


    async function addUser({email} : {email: string}) {
        try {
            await addUserMutation({
                variables: {
                    email,
                }
            })
        } catch (err) {
            console.log("err", err);
            let message =  "coudn't add staff";

            if (err instanceof Error && err.message.includes("Uniqueness violation")) {
                message = "User with this email already exist";
            }

            notification.error({
                message,
            })
            return;
        }

        notification.success({
            message: 'New Staff member added',
        })
        refetch();
    }
    

  return (
    <>


            <Form
                onFinish={addUser}
                layout='inline'
            >
                <Form.Item required name="email" label="Email" rules={[{ type: 'email' }]} >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add new Staff
                    </Button>
               </Form.Item>  
            </Form>

            <EditableTable
                     hasEditValue={ user.userRole === User_Roles_Enum_Enum.Owner }
                     onRowUpdated={onUserUpdated}
                     deleteRow={deleteRow}
                     loading={loading}
                     roles={data ? data.roles_emum.map((r) => r.value) : []}
                     data={data?.users}
                 />

            {/* <Table
                rowKey="id" 

                loading={loading}
                dataSource={data?.users}
                columns={columns} />; */}
        
    </>
  );
}

